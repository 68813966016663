import React from "react";
import _ from "underscore";
import { Row, Card, Col } from "react-bootstrap";

class WagyuGraphs extends React.Component
{
	constructor(props)
	{
		super(props);
	}

	componentDidMount = () =>
	{
		window.google.charts.load("current", {"packages":["bar", "corechart"]});
		window.google.charts.setOnLoadCallback(this.renderGraphs);
	}

	componentDidUpdate = () =>
	{
		window.google.charts.setOnLoadCallback(this.renderGraphs);
	}

	updateOutcomeGraph = (e) =>
	{
		const outcomeData = [["Outcome", "%"]];

		if(e && this.props.stats.userData[e.currentTarget.value])
			_.each(this.props.stats.userData[e.currentTarget.value].outcomeTotals, (value, outcome) => outcomeData.push([outcome, value]));
		else
			_.each(this.props.stats.outcomeTotals, (value, outcome) => outcomeData.push([outcome, value]));

		const outcomeChart = new window.google.visualization.PieChart(document.getElementById("crm-outcome-chart"));

		outcomeChart.draw(window.google.visualization.arrayToDataTable(outcomeData), {height: 250});
	}

	updateNoContactReasonsGraph = (e) =>
	{
		const reasonsData = [["Reasons", "%"]];

		if(e && this.props.stats.userData[e.currentTarget.value])
			_.each(this.props.stats.userData[e.currentTarget.value].noContactReasons, (value, reason) => reasonsData.push([reason, value]));
		else
			_.each(this.props.stats.noContactReasons, (value, reason) => reasonsData.push([reason, value]));

		const reasonsChart = new window.google.visualization.PieChart(document.getElementById("crm-reasons-chart"));

		reasonsChart.draw(window.google.visualization.arrayToDataTable(reasonsData), {height: 250});
	}

	updateOutcomeContactedGraph = (e) =>
	{
		const outcomeContactedData = [["", "Called", {role: "annotation"}, "Contacted",  {role: "annotation"}]];
		const columns = [
			  "AGENT_WILL_CONTACT"
			, "OUT_OF_OFFICE_LEFT_MESSAGE_WITH_GK"
			, "OUT_OF_OFFICE_VOICEMAIL"
			, "OUT_OF_OFFICE_STAFF_WILL_CALL"
			, "AWNC"
		];

		const dataRow = (e && this.props.stats.userData[e.currentTarget.value]) ? this.props.stats.userData[e.currentTarget.value] : this.props.stats;
		let totalCalls = 0;

		_.each(columns, (column) =>
		{
			totalCalls += dataRow.outcomeTotals[column];
		});

		_.each(columns, (column) =>
		{
			const total = dataRow.outcomeTotals[column];
			const contacted = dataRow.contactedTotals[column];
			const percentage = Math.round((total/totalCalls)*100);
			const contactPercentage = Math.round((contacted/total)*100);

			outcomeContactedData.push([column, dataRow.outcomeTotals[column], `${percentage}%`, dataRow.contactedTotals[column], `${contactPercentage}%`]);
		});

		const outcomeContactedChart = new window.google.visualization.ColumnChart(document.getElementById("crm-outcome-contacted-chart"));

		outcomeContactedChart.draw(window.google.visualization.arrayToDataTable(outcomeContactedData), {height: 250});
	}

	renderGraphs = () =>
	{
		const teamData = [["", "Chased", "AWNC", "Contacted", "Conversion (%)", "Actionable (%)"]];

		_.each(this.props.stats.userData, (row) => teamData.push([
			row.name,
			row.outcomes.chased,
			row.outcomes.awnc,
			row.outcomes.contacted,
			row.outcomes.conversion,
			row.outcomes.actionable
		]));

		const crmTeamChart = new window.google.charts.Bar(document.getElementById("crm-team-chart"));

		crmTeamChart.draw(window.google.visualization.arrayToDataTable(teamData), {
            height: 250,
            series: {
                    0: { axis: "calls" },
                    1: { axis: "calls" },
                    2: { axis: "calls" },
                    3: { axis: "percent" },
                    4: { axis: "percent" }
            },
            axes: {
                calls: { label: "calls"},
                percent: { label: "percent"}
            }
		});

		this.updateOutcomeContactedGraph();

		this.updateOutcomeGraph();

		this.updateNoContactReasonsGraph();
	};

	render()
	{
		return (
			<>
				<Card className="mt-4">
					<Card.Body>
						<div className="text-center mb-4">
							<small className="text-muted">CRM team calls overview</small>
						</div>
						<div id="crm-team-chart"></div>
					</Card.Body>
				</Card>

				<Row className="mt-4">
					<Col lg={6}>
						<Card>
							<Card.Body>
								<div className="text-center">
									<small className="text-muted">Outcomes</small>
								</div>
								<div id="crm-outcome-chart"></div>
								<div>
									<select onChange={this.updateOutcomeGraph}>
										<option value="-">All team</option>
										{Object.keys(this.props.stats.userData).map((userId, index) =>
											(
												<option key={index} value={userId}>{this.props.stats.userData[userId].name}</option>
											))}
									</select>
								</div>
							</Card.Body>
						</Card>
					</Col>
					<Col lg={6}>
						<Card>
							<Card.Body>
								<div className="text-center">
									<small className="text-muted">Reasons agent didn't call</small>
								</div>
								<div id="crm-reasons-chart"></div>
								<div>
									<select onChange={this.updateNoContactReasonsGraph}>
										<option value="-">All team</option>
										{Object.keys(this.props.stats.userData).map((userId, index) =>
											(
												<option key={index} value={userId}>{this.props.stats.userData[userId].name}</option>
											))}
									</select>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>

				<Row>
					<Col>
						<Card>
							<Card.Body>
								<div className="text-center mb-4">
									<small className="text-muted">Outcomes v Contacted</small>
								</div>
								<div id="crm-outcome-contacted-chart"></div>
								<div>
									<select onChange={this.updateOutcomeContactedGraph}>
										<option value="-">All team</option>
										{Object.keys(this.props.stats.userData).map((userId, index) =>
											(
												<option key={index} value={userId}>{this.props.stats.userData[userId].name}</option>
											))}
									</select>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</>
		);
	}
}

export default WagyuGraphs;