import React from "react";
import { Card, Row, Col, Table, Spinner } from "react-bootstrap";
import { gaNiceFormatPrice } from "utils/common";

const RevChasingAnalytics = ({analytics}) =>
{
	const { withOutcomeStatsArray, pendingCalls, noOutcomeStats } = analytics;


	const waitingForStats = (! withOutcomeStatsArray || ! pendingCalls || ! noOutcomeStats);

	if(waitingForStats)
	{
		return (
			<Card className="my-5">
				<Card.Header>SSTC Analytics</Card.Header>
				<Card.Body>
					<Spinner animation="grow" />
				</Card.Body>
			</Card>);
	}


	const withOutcomeLocalTotal = {
		count: 0,
		value: 0
	};

	// also do a total for the outcome distribution
	// which we can then use to calc % update in current month as well
	withOutcomeStatsArray.forEach(o =>
	{
		withOutcomeLocalTotal.count += o.count;
		withOutcomeLocalTotal.value += o.value;
	});

	const pendingOutcome = (
		<Col md={3}>
			<Card className="text-center">
				<Card.Header className="bg-primary text-white">Pending Outcome</Card.Header>
				<Card.Body>
					<h2>£{gaNiceFormatPrice(noOutcomeStats.value)}</h2>
					<p className="text-muted">{noOutcomeStats.count} properties</p>
					<p className="text-muted">(of which {noOutcomeStats.presciousSSTC} to be chased)</p>
				</Card.Body>
			</Card>
		</Col>
	);

	const revChaseCalls = (
		<Col md={6}>
			<Card className="text-center">
				<Card.Header className="bg-primary text-white">Outstanding Calls</Card.Header>
				<Card.Body>
					<Row>
						<Col md={6}>
							<h2>{pendingCalls.normalCalls}</h2>
							<p className="text-muted">normal rev chasing</p>
						</Col>
						<Col md={6}>
							<h2>{pendingCalls.nowCalls}</h2>
							<p className="text-muted">selling this month</p>
						</Col>
					</Row>

				</Card.Body>
			</Card>
		</Col>
	);

	const percentUpdatedThisMonth = (
		<Col md={3}>
			<Card className="text-center">
				<Card.Header className="bg-primary text-white">Progress</Card.Header>
				<Card.Body>
					<h2>{Math.round((withOutcomeLocalTotal.count / (noOutcomeStats.count + withOutcomeLocalTotal.count))*100)}%</h2>
					<p className="text-muted">updated this month</p>
				</Card.Body>
			</Card>
		</Col>
	);

	return (
		<Card className="my-5">
			<Card.Header>SSTC Analytics</Card.Header>
			<Card.Body>
				<Row className="mb-3">
					{pendingOutcome}
					{percentUpdatedThisMonth}
					{revChaseCalls}
				</Row>
				<Card>
					<Card.Header className="bg-primary text-white">Outcomes</Card.Header>

					<Table bordered striped hover size="sm">
						<colgroup align="left">
							<col width="50%" />
							<col width="15%" />
							<col width="35%" />
						</colgroup>
						<thead>
							<tr className="text-left">
								<th>Outcome</th>
								<th>Count</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody align="left">
							{withOutcomeStatsArray.map((distribution, index) =>
							{
								return (
									<tr key={index}>
										<td>{distribution.type}</td>
										<td>{distribution.count}</td>
										<td>£{gaNiceFormatPrice(distribution.value)}</td>
									</tr>
								);
							})}
							<tr className="table-success font-weight-bold">
								<td>Total:</td>
								<td>{withOutcomeLocalTotal.count}</td>
								<td>£{gaNiceFormatPrice(withOutcomeLocalTotal.value)}</td>
							</tr>
						</tbody>
					</Table>
				</Card>
			</Card.Body>
		</Card>
	);
};

export default RevChasingAnalytics;