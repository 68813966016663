import React, { useState } from "react";
import {Form, Row, Col, Button, FormControl, Spinner } from "react-bootstrap";
import moment from "moment";
import { postAPI } from "utils/requestAPI";


const VpSaleUpdateCallback = ({ vendorId, branchId, verticalMargin, updateFunction }) =>
{
	const [loading, setLoading] = useState(false);
	const [savedSuccess, setSavedSuccess] = useState(false);
	const [callbackSpecificDate, setCallbackSpecificDate] = useState("");
	const [callbackSpecificTime, setCallbackSpecificTime] = useState("");
	const callTimeOptions = [30, 1, 2, 3, 4, 5];

	const saveCallbackForBranch = () =>
	{
		if(!callbackSpecificDate && !callbackSpecificTime)
			return alert("Please select an option first.");

		// we also need to guard against "select" as an option
		if(callbackSpecificDate && callbackSpecificTime && callbackSpecificTime !== "select")
			return alert("Please only pick one of the two options.");

		let whenToCallback = null;

		if(callbackSpecificDate)
			whenToCallback = moment(callbackSpecificDate, "YYYY-MM-DD").utc().format();

		if(callbackSpecificTime && callbackSpecificTime !== "select")
		{
			whenToCallback = moment().add(parseInt(callbackSpecificTime), callbackSpecificTime > 24 ? "minutes" : "hours").utc()
				.format();
		}

		// make a last sanity check
		if(moment(whenToCallback).isAfter(moment().endOf("month").utc()))
			return alert("Can't schedule call in a new month. Please pick a date/time this month.");

		setLoading(true);

		const postData = {nextActionAt: whenToCallback,};

		if(vendorId)
			postData.vendorId = vendorId;

		const url = `/admin/vendor/revChasing/setCallback/${branchId}`;

		postAPI(url, postData)
			.then(res =>
			{
			})

			.catch(e => alert(e))
			.finally(() =>
			{
				setSavedSuccess(true);
				setLoading(false);
			});

		if(updateFunction)
			updateFunction(true);

	};

	return (
		<Row className={verticalMargin || "my-1"}>
			<Col md={4}>
				<Form.Label>Call again, today:</Form.Label>
				<FormControl
					as="select"
					onChange={(e) => setCallbackSpecificTime(e.target.value)}
					value={callbackSpecificTime}
				>
					<option value="select">Select Time...</option>
					{callTimeOptions.map(o => <option key={o} value={o}>{o} {o > 24 ? " minutes" : " hours"}</option>)}
				</FormControl>
			</Col>
			<Col md={4}>
				<Form.Label>Or specific date:</Form.Label>
				<Form.Control type="date" onChange={(e) => setCallbackSpecificDate(e.target.value)} value={callbackSpecificDate}></Form.Control>
			</Col>
			<Col md={4}>
				<Button style={{height: "100%"}} variant="light" className="btn-block" onClick={saveCallbackForBranch}>{loading ? <Spinner size="md" variant="primary" animation="border" /> : savedSuccess ? "Saved" : "Set callback"}</Button>
			</Col>
		</Row>
	);
};

export default VpSaleUpdateCallback;