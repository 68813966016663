import React, { useState, useEffect, Suspense, lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import AdminNav from "./shared/adminNav.jsx";
import VendorRouter from "../routers/vendorRouter";

const AdminPanel = () =>
{
	const location = useLocation();
	const [container, setContainer] = useState(true);
	const [showNav, setShowNav] = useState(true);
	const fullWidthPages = ["/vendor/property", "/vendor/matchListings", "/listings/search", "/vendor/invoices/overview", "/", "/vendor/analyticsDashboard/cohort", "/vendor/customerservice/wallboard"];

	useEffect(() => setContainer(fullWidthPages.includes(location?.pathname?.toLowerCase())), [location, fullWidthPages]);

	useEffect(() =>
	{
		let showNavbar = true;

		if(location.pathname.match(/\/(vendor|branch)\/.*\/phone/i))
			showNavbar = false;

		if(location?.pathname?.toLowerCase() === "/vendor/customerservice/wallboard")
			showNavbar = false;

		setShowNav(showNavbar);
	}, [location]);

	const WorkerLog = lazy(() => import("./worker-log/workerLogList"));
	const LandregSearch = lazy(() => import("./landreg/landregSearch"));
	const CloudDashboard = lazy(() => import("./cloud-dashboard/cloudDashboard"));
	const PrDataExport = lazy(() => import("./pr-export/prDataExport"));
	const SalesReport = lazy(() => import("./sales-report/salesReport"));
	const LandingPageRouter = lazy(() => import("../routers/landingPageRouter"));
	const LettersFlow = lazy(() => import("./letters/lettersFlow"));
	const PropertyList = lazy(() => import("./listings/propertyList"));
	const Reminders = lazy(() => import("./users/reminders"));
	const LeaderboardPage = lazy(() => import("./leaderboard/Leaderboard"));
	const AdminHomepage = lazy(() => import("./admin-homepage/adminHomepage"));

	const BranchRouter = lazy(() => import("../routers/branchRouter"));
	const CorporatePage = lazy(() => import("./corporate/corporatePage"));
	const AdminUserEdit = lazy(() => import("./users/adminUserEdit"));
	const FourOhFour = lazy(() => import("./shared/fourOhFour"));
	const AdminControl = lazy(() => import("./users/adminControl"));
	const CallsSettings = lazy(() => import("./users/callsSettings"));
	const TagsSettings = lazy(() => import("./tags/tagSettings"));
	const TagsSearch = lazy(() => import("./tags/tagSearch"));


	return (
		<>
			{showNav ? <AdminNav /> : null}
			<div className={container ? null : "container"}>
				<Suspense fallback={<div>Loading...</div>}>
					<Switch>
						<Route path="/workerLog" component={WorkerLog} />
						<Route path="/vendor" component={VendorRouter} />
						<Route path="/user/dashboard" component={AdminControl} />
						<Route path="/branch" component={BranchRouter} />
						<Route path="/reminders" component={Reminders} />
						<Route path="/leaderboard" component={LeaderboardPage} />
						<Route path="/techStatus" component={CloudDashboard} />
						<Route path="/analytics" component={PrDataExport} />
						<Route path="/salesReport" component={SalesReport} />
						<Route path="/landingPage" component={LandingPageRouter} />
						<Route path="/letters" component={LettersFlow} />
						<Route path="/listings/search" component={PropertyList} />
						<Route path="/landreg/search" component={LandregSearch} />
						<Route path="/calls/settings" component={CallsSettings} />
						<Route path="/tags/settings" component={TagsSettings} />
						<Route path="/tags/search" component={TagsSearch} />
						<Route path="/corporate/:corpId">
							{({ match: { params }}) => <CorporatePage key={params.corpId} corpId={params.corpId} />}
						</Route>
						<Route path="/user/:userId/edit" component={AdminUserEdit} />
						<Route exact path="/" component={AdminHomepage}/>

						<Route component={FourOhFour} />
					</Switch>
				</Suspense>
			</div>
		</>
	);
};

export default AdminPanel;