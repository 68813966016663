import React from 'react'
import { FaSortDown, FaSortUp } from 'react-icons/fa'

function SortIcon({ sortType, sortToWatch, sortDirection }) {

    if (sortType !== sortToWatch)
        return null

    if (sortDirection === 'asc')
        return <FaSortUp />


    if (sortDirection === 'desc')
        return <FaSortDown />

    return null
}

export default SortIcon
