import React from "react";

function FujisanVendorsArchived({ csUsers, csUserFilter, show  })
{

	const renderGraph = () =>
	{

		const data = new window.google.visualization.DataTable();
		const whyNoValsArr = ["ALREADY_CHOSEN", "ALREADY_LIVE", "ALREADY_CONTACTED", "GO_DIRECT", "UNKNOWN_REASON", "PROBATE", "NOT_READY", "NOT_SELLING", "OTHER"];

		data.addColumn("string", "Users");
		whyNoValsArr.forEach((val) =>
		{
			data.addColumn("number", val);
		});

		const chartArr = [];

		if(csUsers)
		{
			csUsers.forEach((user) =>
			{

				if(!csUserFilter.includes(user.csUserId))
					return;

				const row = [];

				row.push(user.name);

				whyNoValsArr.forEach((val) =>
				{
					if(user.totalWhyNoVals[val])
						row.push(user.totalWhyNoVals[val]);
					else
						row.push(0);
				});

				chartArr.push(row);
			});
		}

		data.addRows(chartArr);

		const options =
        {
            height: 350,
            vAxis: { title: "No of Vendors With No Vals Per User", minValue: 0  },
            legend: { position: "none"},
            chartArea: {"width": "90%", "height": "80%"},
            isStacked: true
        };

		const chart = new window.google.visualization.ColumnChart(document.getElementById("fujisan-vendors-archived"));

		chart.draw(data, options);

	};

	window.google.charts.load("current", {packages: ["corechart", "bar", "line"]});
	window.google.charts.setOnLoadCallback(renderGraph);

	return (
		<div id="fujisan-vendors-archived" className={!show ? "d-none" : null}></div>
	);
}

export default FujisanVendorsArchived;
