import React from "react";
import {Card, Col, Row, Button, Table, FormControl, Badge} from "react-bootstrap";
import moment from "moment";
import _ from "underscore";
import {FaPhone} from "react-icons/fa";
import { getAPI, postAPI } from "utils/requestAPI";
import { adminPanelURL } from "utils/common";
import iconRightmove from "images/icons/icon-rightmove.png";
import iconZoopla from "images/icons/icon-zoopla.png";
import iconOTM from "images/icons/icon-otm.png";
import { gaNiceFormatPrice } from "../../utils/common";

class VendorListingsEdit extends React.PureComponent
{

    portalOptions = {
		default: "Select Portal",
		rm: "Rightmove",
		z: "Zoopla",
		am: "OnTheMarket"
    }

    constructor(props)
    {
    	super(props);
    	this.state =
        {
            editing: false,
            listingDetails: [],
            portalId: "",
            portalCode: "default"
        };
    }

    componentDidUpdate = (prevProps, prevState) =>
    {
    	if(this.state.editing !== prevState.editing)
    		this.fetchListings();

    	if(this.props.vendorProp.id !== prevProps.vendorProp.id)
    		this.fetchListings();
    }

    componentDidMount = () =>
    {
    	this.fetchListings();
    }

    fetchListings = ()=>
    {

    	getAPI(`/admin/vendor/property/${this.props.vendorProp.id}/listing?notMatched=1`)
    		.then(data =>
    		{

    			this.setState({listingDetails: data.listings});


    		})
    		.catch(e => console.log(e));
    }

    cancelEditing = () =>
    {
    	this.setState({editing:false});
    }

    addListing = () =>
    {
    	const { portalCode, portalId } = this.state;

    	if((! portalId) || (portalCode == "default"))
    		return alert("Please try putting in a portal ID and choosing a portal 🙄");

    	// move to listing live and put in IDs
    	const listingParams = {
            zoopla_id:  portalCode === "z" ? portalId : 0,
            rightmove_id:  portalCode === "rm" ? portalId : 0,
            am_id: portalCode === "am" ? portalId : 0,
            is_match: 1
    	};

    	this.sendListingStatus(listingParams);
    }


    sendListingStatus = (listingParams) =>
    {
    	postAPI("/admin/vendor/property/" + this.props.vendorProp.id + "/listing", listingParams)
    		.then(data =>
    		{
    			// stop editing
    			this.setState({editing:false});

    			// reload vendor
    			this.props.reloadVendor();
    			return;
    		})
    		.catch(e => alert(e || "An unknown error occurred"));
    }

    removeListing(ld)
    {
    	if(window.confirm("Are you sure you want to remove this listing?"))
    	{
    		// move to listing live and put in IDs
    		const listingParams = {
                zoopla_id: ld.z_id || 0,
                rightmove_id: ld.rm_id || 0,
                am_id: ld.am_id || 0,
                is_match: 0
    		};

    		this.sendListingStatus(listingParams);
    	}
    }

    confirmListingIsMatch(ld)
    {
    	if(window.confirm("Are you sure you want to confirm this listing as a match?"))
    	{
    		// move to listing live and put in IDs
    		const listingParams = {
                zoopla_id: ld.z_id || 0,
                rightmove_id: ld.rm_id || 0,
                am_id: ld.am_id || 0,
                is_match: 1
    		};

    		this.sendListingStatus(listingParams);
    	}
    }

    selectPortal = (e) =>
    {
    	const {target: {name, value}} = e;

    	this.setState({[name]: value});
    }

    setPortalIdOrUrl = e =>
    {
    	const {target: {name, value}} = e;

    	const checks = [
    		{
                name: "z",
                reg: /https:\/\/www.zoopla.co.uk\/for-sale\/details\/([0-9]+)/
    		}, {
                name: "rm",
                reg: /https:\/\/www.rightmove.co.uk\/property-for-sale\/property-([0-9]+)/
    		}, {
                name: "am",
                reg: /https:\/\/www.onthemarket.com\/details\/([0-9]+)/
    		}, {
                name: "z",
                reg: /https:\/\/www.primelocation.com\/for-sale\/details\/([0-9]+)/
    		},
    		{
                name: "rm",
                reg: /https:\/\/www.rightmove.co.uk\/properties\/([0-9]+)/
    		},
    	];

    	for (let i = 0; i < checks.length; i++ )
    	{
    		if(checks[i].reg.test(value))
    		{
    			return this.setState({
                    portalCode: checks[i].name,
                    portalId: checks[i].reg.exec(value)[1]
    			});
    		}
    	}

    	this.setState({portalId: value});

    }


    startEditing = () =>
    {
    	this.setState({editing:true});
    }

    openCallWindow = (branch, e) =>
    {
    	e.preventDefault();
    	window.open(adminPanelURL("/branch/" + branch.id + "/phone/"), "callwin", "width=500,height=500");
    }


    render()
    {
    	const {listingDetails, editing, portalId, portalCode} = this.state;
    	const { vendorProp, hideIfNoListings } = this.props;

    	if(listingDetails.length === 0 && hideIfNoListings)
    		return null;

    	const listingsPanel = () =>
    	{
    		if(listingDetails.length === 0 && editing === false)
    			return null;

    		const matchedListings = listingDetails.filter(listing => (listing.confirmed_by && listing.confirmed_by.match === 3));
    		const nonMatchedListings = listingDetails.filter(listing => (listing.confirmed_by && listing.confirmed_by.match === 1));

    		return (
    			<Table className="mb-0">
    				<thead>
    					<tr>
    						<th width="8">Portals</th>
    						<th width="50">Address</th>
    						<th width="25">Branch</th>
    						<th width="3">Status</th>
    						{!editing && <th width="7">Date Added</th> }
    						{!editing && <th width="7">Date Removed</th> }
    						{!editing && <th width="7">Price</th> }
    						{editing && <th width="10">Confirmed By</th> }
    						{editing && <th className="text-center" width="4"></th> }
    					</tr>
    				</thead>
    				<tbody>
    					{matchedListings.map((listing, index) => (<ListingRow key={index} listing={listing} editing={editing} />))}
    					{editing && nonMatchedListings.map((listing, index) => (<ListingRow key={index} listing={listing} editing={editing} nonMatched/>))}
    					{editing && (
    						<tr key="editrow">
    							<td colSpan="60">
    								<Row>
    									<Col md={3} lg={2}>
                                            Match with a listing:
    									</Col>
    									<Col md={3}>
    										<FormControl as="select"  onChange={this.selectPortal} value={portalCode} name="portalCode">

    											{_.map(this.portalOptions, (val, key) =>
    											{
    												return <option key={key} value={key}>{val}</option>;
    											})}
    										</FormControl>
    									</Col>
    									<Col md={4}>
    										<FormControl as="input"  onChange={this.setPortalIdOrUrl} value={portalId} name="portalId" placeholder="Enter Portal Id or paste listing url" />
    									</Col>
    									<Col md={2}>
    										<Button className="float-right" variant="success"  onClick={this.addListing}>Add Listing</Button>
    									</Col>
    								</Row>


    							</td>
    						</tr>)}
    				</tbody>
    			</Table>
    		);
    	};

    	const panelFooter = () =>
    	{
    		if(this.state.editing === true)
    		{
    			return (
    				<Row>
    					<Col sm={4}>
    						<Button onClick={this.cancelEditing} disabled={this.state.saving}>Cancel</Button>
    					</Col>
    					<Col sm={4} className="text-center">

    					</Col>
    					<Col sm={4} className="text-right">

    					</Col>
    				</Row>
    			);
    		}
    		else
    		{
    			return (
    				<Button onClick={this.startEditing}>Edit</Button>
    			);
    		}
    	};

    	const ListingRow = ({listing, editing, nonMatched}) => (
    		<tr className={nonMatched ? "table-danger" : null}>
    			<td>
    				{listing.z_id && <a href={`http://www.zoopla.co.uk/for-sale/details/${listing.z_id}`} target="_blank"><img src={iconZoopla} width="20" /></a>} {listing.z_id && " "}
    				{listing.rm_id && <a href={`http://www.rightmove.co.uk/properties/${listing.rm_id}`} target="_blank"><img src={iconRightmove} width="20" /></a>}  {listing.am_id && " "}
    				{listing.am_id && <a href={`https://www.onthemarket.com/details/${listing.am_id}/`} target="_blank"><img src={iconOTM} width="20" /></a>  }
    			</td>

    			<td> {listing.address} </td>

    			<td>
    				<a href={adminPanelURL(`/branch/${listing.branch.id}/edit`)} target="_blank" className={listing.branch.registered == true ? "" :  "text-danger"}>
    					{listing.branch.name}
    				</a> &nbsp;
    				<a href="#" onClick={this.openCallWindow.bind(this, listing.branch)}><FaPhone /></a>
    			</td>

    			<td>
    				{listing.statusString == "sstc" && <Badge variant="success">SSTC</Badge>}
    				{listing.statusString == "live" && <Badge variant="info">Live</Badge>}
    				{listing.statusString == "under offer" && <Badge variant="warning">Under Offer</Badge>}
    			</td>

    			{editing ? (
    				<>
    					<td> {listing.confirmed_by && listing.confirmed_by.name} </td>
    					{nonMatched ? (
    						<td className="text-center"> <Button variant="danger" onClick={this.confirmListingIsMatch.bind(this, listing)}>Match</Button> </td>
    					): (
    						<td className="text-center"> <Button onClick={this.removeListing.bind(this, listing)}>Remove</Button> </td>
    					)}
    				</>
    			) : (
    				<>
    					<td> {moment.utc(listing.dateAdded).format("Do MMM YYYY")}</td>
    					<td> {listing.dateRemoved ? moment.utc(listing.dateRemoved).format("Do MMM YYYY") : "Still Live"} </td>
    					<td> £{gaNiceFormatPrice(listing.price)} </td>
    				</>
    			)}
    		</tr>
    	);

    	return (
    		<Card className={this.props.className}>
    			<Card.Header>
                    Listings
    			</Card.Header>

    			{(listingDetails.length === 0 && editing === false) ? null : (listingsPanel())}

    			<Card.Footer>
    				{panelFooter()}
    			</Card.Footer>
    		</Card>
    	);
    }
}

export default VendorListingsEdit;