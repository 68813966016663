import React from "react";
import moment from "moment";
import {Row, Col, FormLabel, FormControl, Button, InputGroup, ListGroupItem, Spinner} from "react-bootstrap";
import {GAConfigContext} from "../../contexts/gaConfigContext";
import { FaCalendarAlt } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";
import _ from "underscore";
import { postAPI, getAPI } from "utils/requestAPI";
import xeroIcon from "images/admin/xero.png";


class InvoicePanel extends React.Component
{

	state = {
		loading: true,
		invoice: false,
		error: false,
		dueDate: null,
		editingDueDate: false,
		value: null,
		editingValue: false,
		editingValueInXero: false
	};

	static contextType = GAConfigContext;

	componentDidMount()
	{
		this.loadInvoiceStatus(this.props.vendorProp.id);
	}

	componentDidUpdate(prevProps)
	{
		if(prevProps.vendorProp.id !== this.props.vendorProp.id)
			this.loadInvoiceStatus(this.props.vendorProp.id);
	}

	loadInvoiceStatus(id)
	{
		this.setState({loading: true});

		getAPI("/admin/vendor/property/" + id + "/invoice")
			.then(data =>
			{
				if(data.success)
				{
					const defaultDueDate = 14;

					const existingDueDate = data.invoice.dueDate ? moment(data.invoice.dueDate) : null;

					this.setState({
						loading: false,
						invoice: data.invoice,
						dueDate: existingDueDate || moment().add(defaultDueDate, "days"),
						value: data.invoice.subTotal,
						error: false
					});
				}
				else
				{
					this.setState({
						loading: false,
						invoice: false,
						error: data.msg || "An unknown error occurred"
					});
				}
			})
			.catch(err => this.setState({loading: false, invoice: false, error: "AuthenticationError"}));
	}

	createInvoice(e)
	{
		e.preventDefault();

		this.setState({loading: true});

		const additionalConfig = {};

		additionalConfig.dueDate = this.state.dueDate.format("YYYY-MM-DD");

		postAPI("/admin/vendor/property/" + this.props.vendorProp.id + "/invoice", additionalConfig, {skipValidate: true})
			.then(data =>
			{
				if(data.success)
				{
					this.setState({
					loading: false,
					error: false,
					});

					// load the new invoice in state with correct keys and updated data
					this.loadInvoiceStatus(this.props.vendorProp.id);

					window.open("https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=" + data.invoiceId);

				}
				else
				{
					this.setState({
					loading: false,
					invoice: false,
					error: data.msg || "An unknown error occurred"
					});
				}
			});
	}

	onDueDateChange(name, e)
	{
		const { value } = e.target;

		if(name == "dueDateChanged")
			this.setState({dueDate: moment(value, "YYYY-MM-DD")});

	}

	toggleEditDueDateInterface()
	{
		if(this.state.invoice.amountCredited > 0)
			return alert("We can't edit the due date for this invoice programatically. Please speak to Peter.");

		this.setState({editingDueDate: !this.state.editingDueDate});
	}

	editDueDateInXero()
	{
		this.setState({ editingDueDateInXero: true });

		const invoiceId = this.state.invoice.invoiceID;

		const url = `/admin/invoice/${invoiceId}/editInvoice`;

		const { dueDate } = this.state;

		const postObj = {
			updateAction: "dueDate",
			dueDate: dueDate.format("YYYY-MM-DD")
		};

		postAPI(url, postObj, {skipValidate: true})
			.then(res =>
			{
				if(! res.success)
					return alert("Error: The new due date WAS NOT saved.");

				this.setState({
					editingDueDateInXero: false,
					editingDueDate: false,
				});

				// set new values in state so we have 100% up to date data
				this.loadInvoiceStatus(this.props.vendorProp.id);
			});
	}

	editValueInXero()
	{
		this.setState({ editingValueInXero: true });

		const invoiceId = this.state.invoice.invoiceID;

		const url = `/admin/invoice/${invoiceId}/editInvoice`;

		const lineItems = _.extend({}, _.first(this.state.invoice.lineItems));

		const { value } = this.state;

		lineItems.unitAmount = value;
		lineItems.lineAmount = value;
		lineItems.taxAmount = Math.round((value * 0.2) * 100)/100;

		const postObj = {
			updateAction: "value",
			lineItems: lineItems
		};

		postAPI(url, postObj, {skipValidate: true})
			.then(res =>
			{
				if(! res.success)
					return alert("Error: The new value WAS NOT saved.");

				// when we update an invoice, we still have the old invoice
				// in state so to show the updated value in the view we need to set these values
				this.setState({
					editingValueInXero: false,
					editingValue: false,
				});

				// load the new invoice in state with correct keys and updated data
				this.loadInvoiceStatus(this.props.vendorProp.id);
			});
	}

	onValueChange(name, e)
	{
		const { value } = e.target;

		if(name == "valueChanged")
			this.setState({value});
	}

	toggleEditValueInterface()
	{
		if(this.state.invoice.amountCredited > 0 || this.state.invoice.amountPaid > 0)
			return alert("We can't edit the value for this invoice programatically. Please speak to Peter.");

		this.setState({editingValue: !this.state.editingValue});
	}

	render()
	{
		const editValueIcon = this.state.invoice.status === "AUTHORISED" ? <span className="text-primary mx-2"><FiEdit3 style={{cursor: "pointer"}} onClick={this.toggleEditValueInterface.bind(this)}/></span> : null;
		const canEditInvoice = this.context.GA_CONFIG.user.adminOptions[this.context.GA_CONFIG.adminOpts.CAN_CHANGE_INVOICE];

		if(this.state.loading)
		{
			return (
				<ListGroupItem>
					Loading from Xero...

				</ListGroupItem>
			);
		}
		else if(this.state.error)
		{
			return (
				<ListGroupItem>
					<span style={{color:"red"}}>Looking for the invoice?</span> Ask Peter for access grant. {this.state.error}

				</ListGroupItem>
			);
		}
		else
		{
			if(this.state.invoice === false)
			{
				// no invoice created
				return (
					<ListGroupItem style={{marginLeft: "-20px", marginRight: "-20px", marginBottom: "-20px"}} className="border-0 mt-2" variant={this.props.vendorProp.status >= this.context.GA_CONFIG.vp.status. SALE_COMPLETE ? "danger" : "default"}>
						<Row>
							<Col xs={12} md={4}>
								<FormLabel>Invoice Status:</FormLabel>
								<p>No invoice found on Xero</p>
							</Col>
							<Col xs={12} md={3}>
								<FormLabel>Set due date:</FormLabel>
								<InputGroup className="date">
									<FormControl type="date" name="dueDate" onChange={this.onDueDateChange.bind(this, "dueDateChanged")} value={this.state.dueDate.format("YYYY-MM-DD")}/>
									<InputGroup.Text>
										<InputGroup.Prepend>
											<FaCalendarAlt />
										</InputGroup.Prepend>
									</InputGroup.Text>
								</InputGroup>
							</Col>
							<Col xs={12} md={3}>
								<FormLabel>Create new invoice:</FormLabel>
								<Button onClick={this.createInvoice.bind(this)}>Create invoice on Xero</Button>
							</Col>
						</Row>
					</ListGroupItem>
				);

			}
			else
			{
				const subTotal = this.state.invoice?.subTotal || 0;
				const amountCredited = this.state.invoice?.amountCredited || 0;
				const invoiceAmount = Math.round(100 * (subTotal - (amountCredited/1.2)))/100,
					  dueDate = this.state.dueDate,
					  invoiceIdLink = this.state.invoice.invoiceID;


				let style = null;

				if(this.state.invoice.status === "PAID")
					style = "success";
				else if(Math.round(invoiceAmount * 100)/100 !== Math.round(this.props.vendorProp.estvalue * 0.25)/100)
					style = "danger";

				return (
					<ListGroupItem style={{marginLeft: "-20px", marginRight: "-20px", marginBottom: "-20px"}} className="border-0 mt-2" variant={style}>
						<Row>
							<Col xs={12} md={1}>
								<a href={"https://go.xero.com/AccountsReceivable/View.aspx?invoiceid=" + invoiceIdLink} target="_blank">
									<img src={xeroIcon} style={{height: "50px"}} />
								</a>
							</Col>
							<Col xs={12} md={2}>
								<FormLabel>Invoice Status:</FormLabel>
								{
									(this.state.invoice.status === "PAID") &&
											<span className="text-white d-block">Paid</span>
								}
								{
									(this.state.invoice.status === "DRAFT") &&
											<strong className="text-warning d-block">Draft</strong>
								}
								{
									(this.state.invoice.status === "AUTHORISED") &&
											<strong className={style === "danger" ? "text-white d-block" : "text-danger d-block"}>Unpaid</strong>
								}
								{
									(this.state.invoice.status === "VOIDED") &&
											<strong className={style === "danger" ? "text-white d-block" : "text-danger d-block"}>Voided</strong>
								}
							</Col>
							{
								(this.state.invoice.status === "PAID") ?
									<Col xs={12} md={3} className="text-center">
										<img src="https://i.giphy.com/media/3oz8xGoEtS4H6uUT8k/giphy-downsized.gif" style={{height: "60px"}} />
									</Col>
									:
									<Col xs={12} md={3}>
										<FormLabel>Sent To Agent:</FormLabel>

										{this.state.invoice.sentToContact ?
											<span className="d-block">Sent</span>
											:
											<strong className={style === "danger" ? "text-white d-block" : "text-danger d-block"}>Not Sent</strong>
										}

									</Col>
							}

							<Col xs={12} md={3}>
								<FormLabel>Invoice Amount:</FormLabel>
								{invoiceAmount === Math.round(this.props.vendorProp.estvalue * 0.25)/100 ? (
									<span className="d-block">&pound;{subTotal}
										{ canEditInvoice ? editValueIcon : null }
										{(amountCredited > 0) && (
											<small>(&pound;{Math.round(amountCredited/0.012)/100} credited)</small>
										)}
									</span>
								):(
									<div>
										<strong className="d-block">&pound;{invoiceAmount} { canEditInvoice ? editValueIcon : null }</strong>
										<p>Should be &pound;{Math.round(this.props.vendorProp.estvalue * 0.25)/100}!</p>
									</div>
								)}

							</Col>
							{this.state.invoice.status === "PAID" ? (
								<Col xs={12} md={3}>
									<FormLabel>Paid On:</FormLabel>
									<span className={"d-block"}>{moment(this.state.invoice.fullyPaidOnDate).format("ddd Do MMM YYYY")}</span>
								</Col>
							):(
								<Col xs={12} md={3}>
									<FormLabel className="d-flex">Due:</FormLabel>
									<span className={dueDate.isAfter(moment()) ? "d-block" : "weight-bold d-block"}>{dueDate.format("ddd Do MMM YYYY")}
										{canEditInvoice ? <span className="text-primary mx-2"><FiEdit3 style={{cursor: "pointer"}} onClick={this.toggleEditDueDateInterface.bind(this)}/></span> : null}
									</span>
								</Col>

							)}

						</Row>

						{this.state.editingValue || this.state.editingDueDate ?
							<Row className="mt-2">
								{this.state.editingValue ?
									<Col xs={12} md={3} className="offset-6">
										<FormLabel>New value:</FormLabel>
										<FormControl type="number" name="value" onChange={this.onValueChange.bind(this, "valueChanged")} value={this.state.value}/>
										{this.state.editingValueInXero ?
											<Button variant="warning" disabled className="mr-2 mt-2">
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"
												/> Wait
											</Button>
											:
											<Button variant="warning" className="mr-2 mt-2" onClick={this.editValueInXero.bind(this)}>
												Save
											</Button>
										}
										<Button className="btn-light mt-2" onClick={this.toggleEditValueInterface.bind(this)}>Cancel</Button>
									</Col>
									: null
								}

								{this.state.editingDueDate ?
									<Col xs={12} md={3} className="offset-9">
										<FormLabel>New due date:</FormLabel>
										<InputGroup className="date">
											<FormControl type="date" name="dueDate" onChange={this.onDueDateChange.bind(this, "dueDateChanged")} value={this.state.dueDate.format("YYYY-MM-DD")}/>
											<InputGroup.Prepend>
												<InputGroup.Text>
													<FaCalendarAlt />
												</InputGroup.Text>
											</InputGroup.Prepend>
										</InputGroup>
										{this.state.editingDueDateInXero ?
											<Button variant="warning" disabled className="mr-2 mt-2">
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"
												/> Wait
											</Button>
											:
											<Button variant="warning" className="mr-2 mt-2" onClick={this.editDueDateInXero.bind(this)}>
												Save
											</Button>
										}
										<Button className="btn-light mt-2" onClick={this.toggleEditDueDateInterface.bind(this)}>Cancel</Button>
									</Col>
									: null}
							</Row>
							: null}
					</ListGroupItem>
				);
			}
		}
	}
}

export default InvoicePanel;