import React, { useEffect, useState } from "react";
import {
	Button,
	Alert,
	Row,
	Col,
	InputGroup,
	FormControl,
	Spinner,
	Badge,
	Form,
	Card,
	Table
} from "react-bootstrap";
import PropertySaleUpdate from "./propertySaleUpdate";
import Caller from "../call-form/caller";
import DocumentTitle from "../shared/documentTitle";
import {MdOpenInNew} from "react-icons/md";
import _ from "underscore";
import moment from "moment";
import VpSaleUpdateCallback from "./vpSaleUpdateCallback";
import RevChasingStats from "./revChasingStats";
import RevChasingAnalytics from "./revChasingAnalytics";
import RevChasingConversionRate from "./revChasingConversionRate";
import { getAPI, postAPI } from "utils/requestAPI";
import { adminPanelURL } from "utils/common";

const RevChasing = () =>
{
	// caller
	const [twilioToken, setTwilioToken] = useState(null);
	const [callerReady, setCallerReady] = useState(false);
	const [startCall, setStartCall] = useState(false);
	const [callClientId, setCallClientId] = useState(null);

	// branch
	const [savedNewNumberToBranch, setSavedNewNumberToBranch] = useState(false);
	const [savedSalesEmailToBranch, setSavedSalesEmailToBranch] = useState(false);
	const [billingEmail, setBillingEmail] = useState("");
	const [branchNote, setBranchNote] = useState("");
	const [savingNote, setSavingNote] = useState(false);
	const [isNoteSaved, setIsNoteSaved] = useState(false);

	// view
	const [loading, setLoading] = useState(false);
	const [vpSaleDataNoOutcome, setVpSaleDataNoOutcome] = useState([]);
	const [vpSaleDataWithOutcome, setVpSaleDataWithOutcome] = useState([]);
	const [branchData, setBranchData] = useState(null);
	const [noResults, setNoResults] = useState(false);
	const [outcomeStrings, setOutcomeStrings] = useState({});
	const [numberOfPendingCalls, setNumberOfPendingCalls] = useState(null);
	const [sellingThisMonthMode, setSellingThisMonthMode] = useState(false);

	// statistics
	const [updateStats, setUpdateStats] = useState({});
	// analytics
	const [analytics, setAnalytics] = useState({});

	const fetchTwilioToken = async() =>
	{
		try
		{
			const res = await getAPI("/admin/twilioToken.json");
			const { twilioToken } = res;

			setTwilioToken(twilioToken);
		}
		catch (e)
		{
			console.log(e);
		}
	};

	const handleCallStart = () =>
	{
		if(branchData && branchData.phone)
		{
			setStartCall(true);
			setCallerReady(false);
		}
	};

	const handleOnCallEnd = () =>
	{
		setStartCall(false);
		setCallerReady(true);
	};

	const setCallClientIdInParent = (id) =>
	{
		setStartCall(false); // prevent a second connection
		setCallClientId(id);
	};

	const hasOutcomeInCurrentMonth = (vp) =>
	{
		const outcomeInCurrent = vp.vpSaleUpdates.filter(su =>
			moment(su.outcome_updated_at).isSameOrAfter(moment().startOf("month").utc()));

		return !! outcomeInCurrent.length;
	};

	const doNextCall = () =>
	{
		// reset state here before loading new data
		setCallClientId(null);
		setSavedNewNumberToBranch(false);
		setSavedSalesEmailToBranch(false);
		setBillingEmail("");
		setBranchData(null);
		setBranchNote("");
		setIsNoteSaved(false);


		if(sellingThisMonthMode)
			fetchVpSaleData(true);
		else
			fetchVpSaleData();
	};


	const doNormalRevChase = () =>
	{
		setSellingThisMonthMode(false);
		fetchVpSaleData();
	};

	const doSellThisMonthRevChase = () =>
	{
		setSellingThisMonthMode(true);
		fetchVpSaleData(true);
	};


	const fetchVpSaleData = async(sellingThisMonth = false) =>
	{

		setLoading(true);

		try
		{

			const url = `/admin/vendor/revChasing.json${sellingThisMonth ? "?sellingThisMonth=1" : ""}`;

			const res = await getAPI(url);
			const { branch, data, outcomes, numberOfPendingCalls } = res;

			setLoading(false);

			if(data.length === 0)
			{
				setBranchData(null); // hmm
				setNoResults(true);
				return null;
			}

			const [vendorsWithOutcome, vendorsWithNoOutcome] = _.partition(data, hasOutcomeInCurrentMonth);

			const sortInOrderOfSSTC = vendorsWithNoOutcome.sort((a,b) => moment(a.sstcDate).diff(moment(b.sstcDate)));

			setBranchData(branch);

			if(branch.billing_email)
				setBillingEmail(branch.billing_email);

			setVpSaleDataNoOutcome(sortInOrderOfSSTC);

			setVpSaleDataWithOutcome(vendorsWithOutcome);

			setOutcomeStrings(outcomes);

			setNumberOfPendingCalls(numberOfPendingCalls);
		}
		catch (err)
		{
			setLoading(false);
			alert(err);
		}
	};


	const changePhoneNumber = (e) =>
	{
		const branch = {...branchData, phone: e.target.value};

		setBranchData(branch);
	};

	const savePhoneNumber = () =>
	{

		if(! branchData.phone)
			return alert("No phone number to save to branch!");

		postAPI(`/admin/branch/${branchData.id}/edit`, {phone: branchData.phone.trim()})
			.then(data =>
			{
			})
			.catch(e => ("Error: could not update phone number for branch"));

		// flip for the view
		setSavedNewNumberToBranch(! savedNewNumberToBranch);
	};

	const changeSalesEmail = (e) =>
	{
		const branch = {...branchData, sale_update_email: e.target.value};

		setBranchData(branch);
	};

	const saveSalesEmail = () =>
	{
		if(! branchData.sale_update_email)
		{
			const deleteEmail = confirm("Are you sure you want to remove this email address?");

			if(!deleteEmail)
				return;
		}

		postAPI(`/admin/branch/${branchData.id}/edit`, {sale_update_email: branchData.sale_update_email.trim()})
			.then(data =>
			{
				setSavedSalesEmailToBranch(! savedSalesEmailToBranch);
			})
			.catch(e =>
			{
				alert("This email is not valid!");
				return;
			});
	};

	const branchLink = () => (
		<a className="text-primary" href={adminPanelURL("/branch/" + branchData.id + "/edit")} target="_blank">
		&nbsp; | &nbsp; view &nbsp;
			<MdOpenInNew />
		</a>
	);

	const saveBranchNote = () =>
	{
		setIsNoteSaved(false);
		setSavingNote(true);

		if(branchNote.length <= 2)
		{
			setSavingNote(false);
			return alert("Want to try writing a better note please?");
		}

		const noteObj = {note: branchNote};

		postAPI("/admin/branch/" + branchData.id + "/note", noteObj)
			.then(res =>
			{
				setSavingNote(false);
				setIsNoteSaved(true);
			})
			.catch(e =>
			{
				setSavingNote(false);
				setIsNoteSaved(false);
				alert("Could not save branch note. Please try again!");
			});

	};


	const fetchRevChaseStats = async() =>
	{

		try
		{
			const res = await getAPI("/admin/vendor/revChasingStats.json");
			const { lastUpdateRows, archived, withOutcomeStatsArray, pendingCalls, noOutcomeStats, inBadState } = res;

			setUpdateStats({lastUpdateRows, archived, inBadState});
			setAnalytics({withOutcomeStatsArray, pendingCalls, noOutcomeStats});
		}
		catch (e)
		{
			console.error("Something went wrong with fetching rev chasing stats");
		}
	};


	useEffect(() =>
	{

		fetchTwilioToken();

	}, [twilioToken]);

	useEffect(() =>
	{
		fetchRevChaseStats();

	}, []);

	return (
		<>
			<DocumentTitle title={branchData ? `Call ${branchData.name}` : "Revenue Chasing View"} />


			{  loading && ! noResults && <Alert variant="info">Loading...</Alert>}
			{! loading && ! branchData && <div className="text-center">
				<h2 className="text-center my-5">Welcome to the Revenue Chasing view!</h2>
				<Button onClick={doNormalRevChase} variant="primary" disabled={false} size="lg" className="p-3 mx-2">Start Rev Chasing</Button>
				<Button onClick={doSellThisMonthRevChase} variant="warning" disabled={false} size="lg" className="p-3 mx-2">Selling This Month</Button>

				{ noResults && <div className="d-flex justify-content-center my-3"><Alert style={{width: "40%"}} className="my-3" variant={"success"}>🥳 Looks like we are up to date. No more calls to make.</Alert></div>}

				<RevChasingStats updateStats={updateStats} />

				<RevChasingAnalytics margin={"my-5"} analytics={analytics} />

				<RevChasingConversionRate />

			</div>}


			{branchData &&
				<>
					<Row>
						<Col md={5}>
							<h3 className="m-0">Call {branchData.name} {branchLink()}</h3>
						</Col>
						<Col sm={3} style={{ verticalAlign: "top"}}>
							<Caller
								branch={branchData}
								phoneNum={branchData.phone}
								callerReady={(ready) => setCallerReady(ready)}
								twilioToken={twilioToken}
								startCall={startCall}
								onCallEnd={handleOnCallEnd}
								setCallClientId={setCallClientIdInParent}
							/>
						</Col>
						<Col md={2}>
							<Button
								variant="success"
								onClick={handleCallStart}
								disabled={! callerReady}
								className="btn-block"
							>
								Ring
							</Button>
						</Col>
						<Col md={2}>
							<Button
								onClick={doNextCall}
								variant="primary"
								disabled={false}
								className="btn-block"
							>
								Next Call
								{" "}
								<Badge variant="light">
									{numberOfPendingCalls}
								</Badge>
							</Button>
						</Col>
					</Row>
					<Row className="my-4">
						<Col md={5}>
							<Form.Label className="my-3">
								Branch Sales Update Email:
							</Form.Label>
							<InputGroup>
								<FormControl type="text"
									value={branchData.sale_update_email}
									placeholder= "Enter Branch Sales Update Email Here"
									onChange={changeSalesEmail}
								/>
								<InputGroup.Append>
									<Button
										onClick={saveSalesEmail}
										variant="outline-secondary"
										disabled={savedSalesEmailToBranch}>
										{savedSalesEmailToBranch ?  "Done" : "Save"}
									</Button>
								</InputGroup.Append>
							</InputGroup>
							<InputGroup className="my-3">
								<FormControl type="text"
									value={branchData.phone}
									onChange={changePhoneNumber}
								/>
								<InputGroup.Append>
									<Button
										onClick={savePhoneNumber}
										variant="outline-secondary"
										disabled={savedNewNumberToBranch}>
										{savedNewNumberToBranch ?  "Done" : "Save"}
									</Button>
								</InputGroup.Append>
							</InputGroup>
							{branchData.revChasingNotes?.length ?
								<>
									<Form.Label className="my-3">
											Rev Chasing Notes:
									</Form.Label>
									<Card
										style={{
											maxHeight:"75px",
											overflowY: "auto",
											marginBottom:"0"
										}}
									>
										<Table className="table-sm mb-0" striped>
											<tbody>
												{branchData.revChasingNotes.map(note => (
													<tr key={note.id}>
														<td style={{ verticalAlign: "top", width: "104px" }}>
															{moment(note.created_at).format("YYYY-MM-DD HH:mm")}
														</td>
														<td>{note.note.replace("[REV_CHASING] ", "")}</td>
													</tr>
												))}
											</tbody>
										</Table>
									</Card>
								</>
								: null}
						</Col>
						<Col className="d-flex flex-column">
							<VpSaleUpdateCallback
								branchId={branchData.id}
								verticalMargin="my-5"
							/>
							<Row className="mt-auto">
								<Col md={8}>
									<Form.Label className="my-3">
											Add Branch Note:
									</Form.Label>
									{/*leave note on top level branch */}
									<FormControl
										as="textarea"
										size="lg"
										placeholder="Start note with [REV_CHASING] to pin"
										value={branchNote}
										onChange={(e) =>
										{
											setIsNoteSaved(false);
											setBranchNote(e.target.value);
										}}
									/>
								</Col>
								<Col md={4}>
									<Button
										onClick={saveBranchNote}
										className="btn-block"
										style={{height: "100%"}}
										variant="light"
										disabled={savingNote || isNoteSaved}
									>
										{savingNote ? <Spinner size="md" /> : isNoteSaved ? "Saved" : "Save Note"}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
					{/* no outcomes */}
					{vpSaleDataNoOutcome && vpSaleDataNoOutcome.map((vp, i) =>
						<PropertySaleUpdate
							key={i}
							vendor={vp}
							outcomes={outcomeStrings}
							billingEmail={billingEmail}
							setNewBillingEmail={setBillingEmail}
							hasOutcomeInMonth={false}
							branchId={branchData.id}
							callClientId={callClientId}
						/>)}

					{/* have outcomes */}
					{vpSaleDataWithOutcome && vpSaleDataWithOutcome.map((vp, i) =>
						<PropertySaleUpdate
							key={i}
							vendor={vp}
							outcomes={outcomeStrings}
							billingEmail={billingEmail}
							setNewBillingEmail={setBillingEmail}
							hasOutcomeInMonth={true}
							branchId={branchData.id}
							callClientId={callClientId}
						/>)}
				</>
			}
		</>
	);
};

export default RevChasing;