import React from "react";
import moment from "moment";
import DocumentTitle from "../shared/documentTitle";
import WagyuNav from "./wagyuNav";
import WagyuDashboard from "./wagyuDashboard";
import WagyuCalls from "./wagyuCalls";
import WagyuOutstandingCalls from "./wagyuOutstandingCalls";
import WagyuBacklog from "./wagyuBacklog";
import { Route } from "react-router-dom";

class Wagyu extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			toDate: this.getUrlParameter("to") || moment().format("YYYY-MM-DD"),
			fromDate: this.getUrlParameter("from") || moment().format("YYYY-MM-DD")
		};
	}

	getUrlParameter = (name) =>
	{
		name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
		const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
		const results = regex.exec(window.location.search);

		return results ? decodeURIComponent(results[1].replace(/\+/g, " ")) : "";
	};

	render()
	{
		const {
			fromDate,
			toDate
		} = this.state;

		return (
			<div>
				<DocumentTitle title="Wagyu" />

				<WagyuNav
					toDate={toDate}
					fromDate={fromDate}
					setFromDate={(date) => this.setState({ fromDate: date })}
					setToDate={(date) => this.setState({ toDate: date })}
				/>

				<Route exact path="/vendor/wagyu/dashboard">
					<WagyuDashboard to={toDate} from={fromDate} />
				</Route>

				<Route exact path="/vendor/wagyu/calls">
					<WagyuCalls to={toDate} from={fromDate} />
				</Route>

				<Route exact path="/vendor/wagyu/outstanding">
					<WagyuOutstandingCalls />
				</Route>

				<Route exact path="/vendor/wagyu/backlog">
					<WagyuBacklog />
				</Route>

			</div>
		);
	}
}

export default Wagyu;