import React, { useContext, useEffect, useState, useMemo } from "react";
import { Card, Row, Col, Button, ListGroup, FormControl, Form, Alert } from "react-bootstrap";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import TagBadge from "../shared/tagBadge";
import TagGroup from "../shared/tagGroup";
import TagValueInput from "../shared/tagValueInput";
import _ from "underscore";

const AttachTag = ({ onFetchTags, onSave, onSaved }) =>
{
	const [chosenTagId, setChosenTagId] = useState(-1);
	const [tagValue, setTagValue] = useState("");
	const [errorMsg, setErrorMsg] = useState(null);
	const [isSaving, setIsSaving] = useState(false);

	const [tags, setTags] = useState([]);

	const { GA_CONFIG } = useContext(GAConfigContext);

	const isSuperAdmin = useMemo(() => !! GA_CONFIG.user.adminOptions[GA_CONFIG.adminOpts.ARE_EXTRA_PRIV], [GA_CONFIG]);

	const chosenTag = useMemo(() =>
	{
		if(! chosenTagId || (chosenTagId < 0))
			return null;

		return _.findWhere(tags, { id: parseInt(chosenTagId) });

	}, [chosenTagId, tags]);

	useEffect(() =>
	{
		fetchTags();
	}, []);

	const fetchTags = async() =>
	{
		const tags = await onFetchTags();

		setTags(tags);
	};

	const saveAttachTag = async() =>
	{
		setIsSaving(true);

		try
		{
			// send to server
			await onSave(chosenTagId, tagValue);
		}
		catch (err)
		{
			setIsSaving(false);
			setErrorMsg(err.toString());
			return;
		}

		setErrorMsg(null);
		setChosenTagId(-1);
		setTagValue(null);
		setIsSaving(false);

		onSaved();
	};

	return (<ListGroup.Item>
		{errorMsg ? <Alert variant="danger">{errorMsg}</Alert> : null}
		<Form>
			<Row>
				<Col md={3}>
					<Form.Label>Tag type:</Form.Label>
					<FormControl
						as="select"
						value={chosenTagId}
						onChange={(e)=> setChosenTagId(e.target.value)}
					>
						<option value="-1" disabled></option>
						{tags.map(t =>
						{
							if(t.enabled === 0)
								return null;

							if((t.protected === 1) && (isSuperAdmin === false))
								return null;

							return <option key={t.id} title={t.description} value={t.id}>{t.name}</option>;
						})}
					</FormControl>

					{chosenTag && chosenTag.description ? <Form.Text id="tagDescription" muted>{chosenTag.description}</Form.Text> : null}
				</Col>

				<Col md={6}>
					{chosenTag && <><Form.Label className="pr-2">Value:</Form.Label><TagValueInput
						tagValue={tagValue}
						onValueChange={setTagValue}
						tagId={chosenTagId}
						tagType={chosenTag.options?.type}
						tagListValues={chosenTag.options?.listValues}
					/></>}
				</Col>

				<Col md={3}>{(chosenTagId > 0) && tagValue ? <><Form.Label>&nbsp;</Form.Label><Button className="form-control" onClick={saveAttachTag} variant="success" disabled={isSaving}>Save</Button></> : null}</Col>
			</Row>
		</Form>
	</ListGroup.Item>);
};

const TagEdit = ({
	tags,
	onFetchTags,
	onSave,
	onSaved,
	onDelete,
	isEditable = true
}) =>
{
	const [isEditing, setIsEditing] = useState(false);

	const panelFooter = () =>
	{
		if(isEditing === true)
		{
			return (
				<Row>
					<Col sm={4}>
						<Button onClick={() => setIsEditing(false)}>Cancel</Button>
					</Col>
				</Row>
			);
		}

		if(isEditable)
		{
			return (
				<Button onClick={() => setIsEditing(true)}>Edit</Button>
			);
		}
	};

	const enabledTags = tags.filter(t => t.tag.enabled === 1);
	const sortedTags = _.sortBy(enabledTags, "created_at").reverse();
	const groupedTags = _.groupBy(sortedTags, t => t.tag.name);
	// Only group if tag type has 5 tags or more
	const groups = _.omit(groupedTags, value => value.length < 5);
	const ungroupedTags = sortedTags.filter(t => !_.has(groups, t.tag.name));

	const panelBody = () =>
	{
		return (<ListGroup variant="flush">
			{ungroupedTags.map((t) =>
				<TagBadge
					key={t.id}
					tagInstance={t}
					showDelete={isEditing}
					onDelete={onDelete}
				/>
			)}
			{Object.entries(groups).map(([name, tags]) => (
				<TagGroup
					key={name}
					groupName={name}
					tags={tags}
					showDelete={isEditing}
					onDelete={onDelete}
				/>
			))}
			{isEditing ?
				<AttachTag
					onFetchTags={onFetchTags}
					onSave={onSave}
					onSaved={onSaved}
				/> : null}
		</ListGroup>);
	};

	const footer = panelFooter();

	return (
		<Card>
			<Card.Header>Tags</Card.Header>
			<Card.Body>{panelBody()}</Card.Body>
			{footer ? <Card.Footer>{footer}</Card.Footer> : null}
		</Card>
	);
};

export default TagEdit;