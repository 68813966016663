import React from 'react';
import $ from 'jquery';
import _ from 'underscore';
import { GAConfigContext } from '../../contexts/gaConfigContext';
import VendorStateListBox from './vendorStateListBox';
import { getAPI } from "../../utils/requestAPI";

class VendorStateList extends React.Component 
{
	
	static contextType = GAConfigContext;

	state = {
		loadedUpTo: 0,
		numInState: 0,
		initialLoading: true,
		startView: 0,
		endView: 0,
		visibleVendorProps: []
	};

	vendorProps = [];
	boxHeight = 96;
	buffer = 20; // number of boxes above or below required or load more
	boxLoadSize = 30; // load to the nearest 30
	scrollDelay = 1000;
	ulOffset = 220 ;// this will be set when component mounts
	_currentlyLoading = false;

	// times in minutes between reloads
	reloadTimes = {
		NEW: 1,
		GATHERING_QUOTES: 1,
		READY_FOR_VALS: 5,
		VALUATION_REQ: 1,
		INTROS_DONE: 5,
		AGENT_CHOSEN: 10,
		LISTING_LIVE: 30,
		SALE_AGREED: 30,
		SALE_COMPLETE: 30,
		MONEY_PAID: 60
	}


	componentDidUpdate(prevProps)
	{
		// only reload if important props change
		if((prevProps.unAssigned !== this.props.unAssigned) || (prevProps.userFilter !== this.props.userFilter))
			this.doLoad();
	}

	doLoad()
	{
		this.loadCount()
		.then(a =>
		{
			this.scrollHandler();

			if((! this.scrollHandlerObj) && (this.state.numInState > (this.buffer)))
				this.scrollHandlerObj = $(window).on('scroll.vendorIndex', _.throttle(this.scrollHandler.bind(this), this.scrollDelay));
				
		})
		.catch(e =>
		{
			return console.log(e.message || "An error occurred when counting number of properties in state");
		});
	}

	componentDidMount()
	{
		this.stateNum = this.context.GA_CONFIG.vp.status[this.props.status];
		
		this.doLoad();

		// reload every now and then
		this.reloadInterval = setInterval(this.doLoad.bind(this), (this.reloadTimes[this.props.status] * 60000) + (Math.random() * 10000));

		this.ulOffset = $(this.ulEl).offset().top;
	}

	componentWillUnmount()
	{

		$(window).off("scroll.vendorIndex");

		if(this.reloadInterval)
			clearInterval(this.reloadInterval);
	}

	scrollHandler(e)
	{
		if(this._currentlyLoading)
			return;

		if(this.state.numInState === 0)
		{
			return this.setState({
				visibleVendorProps: [],
				startView: 0,
				endView: 0,
				initialLoading: false
			});
		}

		// get current scroll bound & window height
		const scrollTop = $(window).scrollTop(),
			windowHeight = $(window).outerHeight();


		const ulStart = Math.max(scrollTop - this.ulOffset, 0),
			ulEnd = Math.max(scrollTop + windowHeight - this.ulOffset, 0);

		// calculate current box top & bottom
		let vpStart = 0, vpEnd = 0;

		if(ulStart > 0)
			vpStart = Math.floor(ulStart/this.boxHeight);

		if(ulEnd > 0)
			vpEnd = Math.ceil(ulEnd/this.boxHeight);

		// if vpStart > number of boxes, then ignore
		if(vpStart > this.state.numInState)
			return;

		vpStart = Math.max(vpStart - this.buffer, 0);
		vpEnd = Math.min(vpEnd + this.buffer, this.state.numInState);

		if((this.state.startView === vpStart) && (this.state.endView === vpEnd))
			return;

		let needToLoad = false;

		// do we need to load more properties?
		for(let i = vpStart; i < vpEnd; i++)
		{
			if(! this.vendorProps[i])
			{
				needToLoad = i;
				break;
			}
		}

		const setVisibleScroll = () =>
		{
			this.setState({
				visibleVendorProps: this.vendorProps.slice(vpStart, vpEnd),
				startView: vpStart,
				endView: vpEnd
			});

			$(window).scrollTop(scrollTop);
		}

		if(needToLoad !== false)
		{
			if(this._currentlyLoading)
				return;

			this._currentlyLoading = true;

			// load set
			return this.loadVendorProps(needToLoad, Math.ceil(vpEnd/this.boxLoadSize) * this.boxLoadSize)
			.then(setVisibleScroll)
			.catch(e =>
			{
				return console.log(e.message || "An error occurred when counting number of properties in state");
			});
		}
		else
		{
			return setVisibleScroll();
		}
	}

	loadVendorProps(start, end)
	{

		const opts = {state: this.stateNum, start: start, end: end};

		if(this.props.unAssigned)
			opts.unassigned = 1;
		else if(this.props.userFilter > 0)
			opts.userfilter = this.props.userFilter;

		return getAPI('/admin/vendor/property/summary', { data: opts })
		.then(data =>
		{
			this._currentlyLoading = false;

			if(! data.success)
			{
				return console.log(data.msg || "An error occurred when loading more properties");
			}

			for(let i = start; i < end; i++)
			{
				if((i - start) > data.vendorProps.length)
					break;

				this.vendorProps[i] = data.vendorProps[i - start];
			}

			this.setState({
				initialLoading: false
			});

		})
		.catch(e =>
		{
			return console.log(e.message || "An error occurred when counting number of properties in state");
		});
	}

	loadCount()
	{
		const opts = {state: this.stateNum};

		if(this.props.unAssigned)
			opts.unassigned = 1;
		else if(this.props.userFilter > 0)
			opts.userfilter = this.props.userFilter;

		return getAPI('/admin/vendor/property/count', { data: opts })
		.then(data =>
		{
			if(! data.success)
			{
				return console.log(data.msg || "An error occurred when counting number of properties in state");
			}

			this.vendorProps = new Array(data.count);

			this.setState({
					numInState: data.count,
					startView: 0,
					endView: 0
				});
		})
		.catch(e =>
		{
			return console.log(e.message || "An error occurred when counting number of properties in state");
		});
	}

	render()
	{
		return (
			<div className={"stage " + (this.props.passive ? "passive" : "")}>
				<h2>{this.props.niceName} {(! this.state.initialLoading) && ("(" + this.state.numInState + ")")}</h2>
				<ul 
					style={{height: (this.state.numInState * this.boxHeight) + "px"}} 
					ref={(ul) => { this.ulEl = ul; }}
					onMouseEnter={this.props.onMouseOver.bind(this, this.props.status)}
					onMouseLeave={this.props.onMouseOut.bind(this, this.props.status)}
					>
					{this.state.initialLoading && (
					<h3>Loading!</h3>
					)}

					{(! this.state.initialLoading) && (this.state.visibleVendorProps.length === 0) && (
						<img style={{width: '100%'}} src="https://media.giphy.com/media/xUStFKHmuFPYk/giphy.gif" />
					)}

					{this.state.visibleVendorProps.map((vp, i) =>
					{
						if(! vp)
							return null;

						return (
							<VendorStateListBox 
								vendorProp={vp} 
								key={vp.id} 
								topOffset={i === 0 ? (this.state.startView * this.boxHeight) : 0}
								maxNoteAge={this.props.maxNoteAge || 99999}
							/>)
					})}
				</ul>
			</div>
		)
	}

}

VendorStateList.defaultProps = {
	numToLoadInitially: 30
}

export default VendorStateList;