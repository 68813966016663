import React from 'react';
import { Card, FormControl, InputGroup, Row, Col, DropdownButton, DropdownItem } from 'react-bootstrap';
import LeadPieCharts from './leadPieChart';
import moment from 'moment';
import { getAPI } from "utils/requestAPI";

class LeadTrackerPage extends React.Component {
    constructor(props) 
    {
        super(props);
        this.state = 
        { 
            fromDate: props.fromDate ? moment(props.fromDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
            toDate: props.toDate ? moment(props.toDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
            leadData: null,
            totalSignUp: 0,
            valData: null,
            totalVals: 0
        };

        this.fetchRefGenChartData = this.fetchRefGenChartData.bind(this);
        this.fetchValRefChartData = this.fetchValRefChartData.bind(this);
        this.onDatePreset = this.onDatePreset.bind(this)
    }

    componentDidMount()
    {
        this.fetchRefGenChartData();
        this.fetchValRefChartData();
        window.history.pushState(null, null, `?toDate=${this.state.toDate}&fromDate=${this.state.fromDate}`);
    }

    fetchRefGenChartData()
    {
        getAPI(`/admin/vendor/referrerCount.json?toDate=${this.state.toDate}&fromDate=${this.state.fromDate}`)
        .then(res => {
            this.setState({ leadData: res.data});
        })
        .catch(e => alert(e || "An error occurred."))
    }

    fetchValRefChartData()
    {
        
        getAPI(`/admin/vendor/referrerValuationCount.json?toDate=${this.state.toDate}&fromDate=${this.state.fromDate}`)
        .then(res => {
            this.setState({ valData: res.data });
        })
        .catch(e => alert(e || "An error occurred."))
        
    }

    onDateChange = (e) => 
    { 
        if(this.state.fromDate <= this.state.toDate)
        {
            this.setState({ [e.target.name]:  e.target.value}, () => 
            {
                this.fetchRefGenChartData();
                this.fetchValRefChartData();
                window.history.pushState(null, null, `?toDate=${this.state.toDate}&fromDate=${this.state.fromDate}`);
                
            });
        }
        else
        {
            this.setState({ [e.target.name]:  e.target.value}, () => {
                this.fetchRefGenChartData();
                this.fetchValRefChartData();
                window.history.replaceState(null, null, `?toDate=${this.state.toDate}&fromDate=${this.state.fromDate}`);
            });
        }
    }

    onDatePreset(event)
    {
        const type = event.target.innerText;
        if(type == 'Today')
        {
            this.setState({ toDate:moment().format('YYYY-MM-DD'), fromDate:moment().format('YYYY-MM-DD') }, () => 
            {
                this.fetchValRefChartData();
                this.fetchRefGenChartData();
                window.history.pushState(null, null, `?toDate=${this.state.toDate}&fromDate=${this.state.fromDate}`);
                
            });
        }
        else if(type == 'Yesterday')
        {
            this.setState({ toDate:moment().subtract(1, 'day' ).format('YYYY-MM-DD') , fromDate:moment().subtract(1, 'day').format('YYYY-MM-DD')}, () => 
            {
                this.fetchValRefChartData();
                this.fetchRefGenChartData();
                window.history.pushState(null, null, `?toDate=${this.state.toDate}&fromDate=${this.state.fromDate}`);
            });
        }
        else if(type == 'This Week')
        {
            this.setState({ toDate:moment().endOf('week').format('YYYY-MM-DD') , fromDate:moment().startOf('week').format('YYYY-MM-DD') }, () => 
            {
                this.fetchValRefChartData();
                this.fetchRefGenChartData();
                window.history.pushState(null, null, `?toDate=${this.state.toDate}&fromDate=${this.state.fromDate}`);
            });
        }
        else if(type == 'Last Week')
        {
            this.setState({ toDate:moment().startOf('week').subtract(1, 'day').format('YYYY-MM-DD'), fromDate:moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD')}, () => 
            {
                this.fetchValRefChartData();
                this.fetchRefGenChartData();
                window.history.pushState(null, null, `?toDate=${this.state.toDate}&fromDate=${this.state.fromDate}`);
            });
        }
        else if(type == 'Last 2 Weeks')
        {
            this.setState({ toDate:moment().startOf('week').subtract(1, 'day').format('YYYY-MM-DD'), fromDate:moment().subtract(2, 'week').startOf('week').format('YYYY-MM-DD')}, () => 
            {
                this.fetchValRefChartData();
                this.fetchRefGenChartData();
                window.history.pushState(null, null, `?toDate=${this.state.toDate}&fromDate=${this.state.fromDate}`);
            });
        }
        else if(type == 'Last 4 Weeks')
        {
            this.setState({ toDate:moment().startOf('week').subtract(1, 'day').format('YYYY-MM-DD'), fromDate:moment().subtract(4, 'week').startOf('week').format('YYYY-MM-DD') }, () => 
            {
                this.fetchValRefChartData();
                this.fetchRefGenChartData();
                window.history.pushState(null, null, `?toDate=${this.state.toDate}&fromDate=${this.state.fromDate}`);
            });
            
        }
        else
        {
            console.log('Invalid Preset, How did you manage this?')
        }
    }

    render() 
    { 

        

        return (
            <div>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col sm={4}>Lead Analysis</Col>
                            <Col sm={2}>
                                <DropdownButton 
                                    id='date-preselect' 
                                    title='Date Presets' 
                                >
                                    <DropdownItem onClick={this.onDatePreset}>Today</DropdownItem>
                                    <DropdownItem onClick={this.onDatePreset}>Yesterday</DropdownItem>
                                    <DropdownItem onClick={this.onDatePreset}>This Week</DropdownItem>
                                    <DropdownItem onClick={this.onDatePreset}>Last Week</DropdownItem>
                                    <DropdownItem onClick={this.onDatePreset}>Last 2 Weeks</DropdownItem>
                                    <DropdownItem onClick={this.onDatePreset}>Last 4 Weeks</DropdownItem>
                                </DropdownButton>
                            </Col>
                            <Col sm={3}>
                                <InputGroup className="date">
                                    <FormControl type="date" name="fromDate" onChange={this.onDateChange.bind(this)} value={this.state.fromDate}/>
                                    <InputGroup.Prepend>
                                        <i className="glyphicon glyphicon-calendar" />
                                    </InputGroup.Prepend>
                                </InputGroup>
                            </Col>
                            <Col sm={3}>
                                <InputGroup className="date">
                                    <FormControl type="date" name="toDate" onChange={this.onDateChange} value={this.state.toDate}/>
                                    <InputGroup.Prepend>  
                                        <i className="glyphicon glyphicon-calendar" />
                                    </InputGroup.Prepend>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <LeadPieCharts 
                            leadData={this.state.leadData} 
                            valData={this.state.valData}
                        />
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default LeadTrackerPage;