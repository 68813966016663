import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import GAConfigContextProvider from "contexts/gaConfigContext";
import { BdCrmContextProvider } from "contexts/bdCrmContext";
import AdminPanel from "components/adminPanel";
import { setAPIBasepath, setPublicURLBasepath } from "utils/requestAPI";

import "styles/theme.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

if(process.env.REACT_APP_API_BASEPATH)
	setAPIBasepath(process.env.REACT_APP_API_BASEPATH);

if(process.env.REACT_APP_PUBLIC_URL_BASEPATH)
	setPublicURLBasepath(process.env.REACT_APP_PUBLIC_URL_BASEPATH);

function App()
{
	return (
		<GAConfigContextProvider>
			<BdCrmContextProvider>
				<Router>
					<AdminPanel />
				</Router>
			</BdCrmContextProvider>
		</GAConfigContextProvider>
	);
}

export default App;
