
import React from "react";
import { Container, Row, Col, Nav, NavDropdown, Navbar, Button, Form, Spinner } from "react-bootstrap";
import _ from "underscore";
import { MdRefresh } from "react-icons/md";
import DocumentTitle from "../shared/documentTitle";
import MatchListDetails from "./matchListDetails";
import ShortlistEdit from "./shortlistEdit";
import VendorTimeline from "./vendorTimeline";
import {GAConfigContext} from "contexts/gaConfigContext";
import "../../styles/vendorPropSidePanel.css";
import moment from "moment";
import { getAPI } from "utils/requestAPI";
import { adminPanelURL } from "utils/common";
import iconRightmove from "images/icons/icon-rightmove.png";
import iconZoopla from "images/icons/icon-zoopla.png";

class MatchListings extends React.PureComponent
{

	filterOptions = {
		unmatchedWithVals: "Vendors not already matched (with vals)",
		unmatched: "Vendors not already matched (all)",
		matchedWithVals: "All vendors (with vals)",
		matched: "All vendors"
	}

	static contextType = GAConfigContext;

	constructor(props)
	{
		super(props);
		this.state =
		{
			vendorProps: [],
			loading: false,
			vendorIndex: 0,
			filter: this.getUrlParameter("filter") || "unmatchedWithVals"
		};
	}

	componentDidUpdate(prevProps, prevState)
	{
		if(this.state.filter !== prevState.filter)
			this.fetchProperties();
	}

    componentDidMount = () =>
    {
    	this.fetchProperties();
    }

    fetchProperties = () =>
    {
    	this.setState({loading: true});
    	getAPI(`/admin/vendor/matchListings.json?${this.getCurrentUrl()}`)
    		.then(data =>
    		{

    			if(data.success)
    			{
    				this.setState({
					loading: false,
					vendorProps:  data.vendorProps || []
    				});

    				if(data.vendorProps.length)
    				{
    					if(this.state.vendorIndex < data.vendorProps.length )
    						this.getVendorInfo(data.vendorProps[this.state.vendorIndex].id);
    					else
    						this.getVendorInfo(data.vendorProps[data.vendorProps.length-1].id);
    				}

    				// pushs new to window loacation
    				window.history.pushState(null, null,window.location.pathname+"?"+ this.getCurrentUrl());
    			}
			})
			.catch(e =>
			{
				this.setState({
					loading: false,
				});

				console.log(e.message || "Oops! Something bad happened!");
			});
    }

	removeVendor = () =>
	{
		const { vendorProps, selectedVendor } = this.state;

		let newVendorProps = _.filter(vendorProps, vp=> vp.id !== selectedVendor.id);

		this.setState({vendorProps : newVendorProps});

		if(vendorProps.length)
		{
			if(this.state.vendorIndex < newVendorProps.length )
				this.getVendorInfo(newVendorProps[this.state.vendorIndex].id);
			else
				this.getVendorInfo(newVendorProps[newVendorProps.length - 1].id);
		}
	}

	// get url parament from browser window search
	getUrlParameter = (name) =>
	{
		name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
		const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
		const results = regex.exec(window.location.search);

		return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
	};

	// sets urls path
	getCurrentUrl = () =>
	{
		let newUrl= "";
		const { filter } = this.state;

		if(filter)
			newUrl +=  "filter=" + filter + "&";

		return newUrl.slice(0, -1);
	}

	// sets filter when a value and fetches properties from dropdown box is selected
	selectFilterValue = (event) =>
	{
		const {target: { id } } = event;

		this.setState({filter: id});
	}

	// get vendor infor by id
	getVendorInfo = (id) =>
	{
		getAPI("/admin/vendor/property/" + id + "/edit.json")
			.then(vendorData =>
			{
				if(vendorData.success)
				{
					this.setState({
					loading: false,
					selectedVendor: vendorData.vendorProp,
					selectedVendorBranches: vendorData.vendorProp.branches ||[],
					selectedVendorBillings:  vendorData.vendorProp.billings || [],
					selectedVendorInteractions: vendorData.vendorProp.interactions || []
					});
				}
			})
			.catch(e =>
			{
				console.log(e);
				this.setState({ loading: false });
			});
	}

	// switches to vendor when chosen
	switchToVendor(id, index)
	{
		this.setState({
			loading: true,
			vendorIndex: index,
		});
		this.getVendorInfo(id, index);
	}

    render = () =>
    {

    	const { vendorProps, selectedVendor, selectedVendorBranches, selectedVendorBillings, selectedVendorInteractions, loading, filter } = this.state;

    	return (
    		<div>
    			<DocumentTitle title={"Match Listings" + (selectedVendor ? " - " + selectedVendor.user.name : "")} />

    			<div className="vendor-prop-side-view vendor-props">
    				{/* side bar */}


    				<div className="vp-side-list">
    					<div className="stage">
    						<ul>
    							{vendorProps.map((vp, index) =>
    							{
    								let bgdColor = "#ffffff";

    								if(vp.wentForVal)
    									bgdColor = "#d7ecce";

    								if(selectedVendor && vp.id === selectedVendor.id)
    									bgdColor = "#faebcc";

    								return (
    									<li onClick={this.switchToVendor.bind(this, vp.id, index)} style={{"backgroundColor": bgdColor}} key={vp.id} name={vp.id} value={vp.id} id={vp.id}>
    										<h3>{vp.name}</h3>
    										<Row>
    											<Col xs={7}>{(vp.postcode||"").toUpperCase()}</Col>
    											<Col xs={5} className="text-right">&pound;{Math.round(vp.estvalue/1000)}k</Col>
    										</Row>
    										<p className="text-center">{vp.created_at ? moment(vp.created_at).format("Do MMM") : "Unknown"}</p>
    									</li>
    								);
    							})}

    						</ul>
    					</div>
    				</div>


    				<div className="vp-main-panel">
    					<Container>
    						<Navbar bg="light" className="mb-3 mt-5" expand="lg">
    							<Navbar.Brand>{vendorProps.length} Vendors</Navbar.Brand>
    							<Navbar.Toggle aria-controls="match-listings-nav" />
    							<Navbar.Collapse id="match-listings-nav">
    								<Col className="mr-auto">
    								<Nav >
    									<NavDropdown title={ this.filterOptions[filter] || "Filter" }>
    										{_.map(this.filterOptions, (val, key) =>
    										{
    											return <NavDropdown.Item onClick={this.selectFilterValue} key={key} id={key}>{val}</NavDropdown.Item>;
    										})}

    									</NavDropdown>
    								</Nav>
    								</Col>
    								<Col>
    								<Form inline>
    									<Button variant="secondary" type="button" onClick={this.fetchProperties}> Reload  &nbsp; <MdRefresh /></Button>
    								</Form>
    								</Col>
    							</Navbar.Collapse>
    						</Navbar>

    						{this.state.loading && <div className="text-center mt-5">
                							<Spinner size="lg" variant="primary" animation="border"/>
             						   	</div>}

    						{selectedVendor &&

							<>
								<h1 className="my-4">   {selectedVendor.address1}, {selectedVendor.postcode} &nbsp;
									(<a href={adminPanelURL(`/vendor/property/${selectedVendor.id}/edit`)} target="_blank">{selectedVendor.id}</a>) &nbsp;
									<a href={`http://www.rightmove.co.uk/property-for-sale/search.html?searchLocation=${selectedVendor.postcode}&minBedrooms=${selectedVendor.bedrooms}&maxBedrooms=${selectedVendor.bedrooms}&locationIdentifier=&useLocationIdentifier=false&buy=For+sale&includeSSTC=true`} target="_blank"><img src={iconRightmove} width="30" /></a> &nbsp;<a href={`http://www.zoopla.co.uk/house-prices/${selectedVendor.postcode}/`} target="_blank"><img src={iconZoopla} width="30" /></a>
								</h1>

								<Row className="my-4">
									<Col md={3}>
										<Row>
											<Col md={3}>
												<span className="font-weight-bold">Name: </span>
											</Col>
											<Col md={9} >
												<span className="text-center">{selectedVendor && selectedVendor.user.name}</span>
											</Col>
										</Row>
									</Col>
									<Col md={3}>
										<Row>
											<Col md={4}>
												<span className="font-weight-bold">Created: </span>
											</Col>
											<Col md={8}>
												<span className="text-center">{selectedVendor && moment.utc(selectedVendor.created_at).format("Do MMM YYYY")}</span>
											</Col>
										</Row>
									</Col>
									<Col md={2}>
										<Row>
											<Col md={4}>
												<span className="font-weight-bold">Beds: </span>
											</Col>
											<Col md={8}>
												<span className="text-center">{selectedVendor && selectedVendor.bedrooms}</span>
											</Col>
										</Row>
									</Col>
									<Col md={2} >
										<Row>
											<Col md={4}  >
												<span className="font-weight-bold">Value: </span>
											</Col>
											<Col md={8}>
												<span className="text-center">{selectedVendor && "£"+Math.round(selectedVendor.estvalue/1000)+"k"}</span>
											</Col>
										</Row>
									</Col>
									{selectedVendor && selectedVendor.archive &&
									<Col md={2}>
										<Row>
											<Col md={4}>
												<span className="font-weight-bold">Archived: </span>
											</Col>
											<Col md={8}>
												<span className="text-center">{this.context.GA_CONFIG.vp.archiveStatus[selectedVendor.archive]}</span>
											</Col>
										</Row>
									</Col> }
								</Row>
							</>}


    						{selectedVendor && <MatchListDetails selectedVendor={selectedVendor}
    							selectedVendorBranches={selectedVendorBranches}
    							selectedVendorBillings={selectedVendorBillings}
    							selectedVendorInteractions={selectedVendorInteractions}
    							reloadVendor={this.fetchProperties}
    							removeVendor={this.removeVendor} className="mb-3" />}

    						{selectedVendor && <ShortlistEdit vendorProp={selectedVendor} reloadVendor={this.fetchProperties} className="mb-3" />}

    						{selectedVendor && <VendorTimeline vendorProp={selectedVendor} reloadVendor={this.fetchProperties} className="mb-3" />}

    					</Container>
    				</div>
    			</div>
    		</div>
    	);
    }
}

export default MatchListings;