import React from "react";
import { csCallMapping, callDataCols } from "../../../utils/common";


function FujisanCallsToValuationRate({ csUsers, csUserFilter, show })
{

	const renderGraph = () =>
	{
		const data = new window.google.visualization.DataTable();

		data.addColumn("string", "All Calls");

		if(!csUsers) return;

		const result = [];

		csUsers.map(user =>  csUserFilter.includes(user.csUserId) ? data.addColumn("number", user.name) : null);
		data.addColumn("number", "Average");

		callDataCols.forEach((val, index) =>
		{
			if(val === "Call Backs" || val === "Revisit 1" || val === "Revisit 2")
				return null;

			const row = [];
			const callType = csCallMapping[index+1];

			row.push(val);

			let totalVals = 0;
			let totalCalls = 0;

			csUsers.forEach(user =>
			{
				//Do we need to skip this user?
				if(!csUserFilter.includes(user.csUserId))
					return;

				if(val !== "Total")
				{
					row.push((user[callType].valsByVendor / user[callType].called)*100);

					totalCalls += user[callType].called;
					totalVals += user[callType].valsByVendor;
				}
				else
				{
					row.push((user.totalValsByVendor / user.totalCalls)*100);

					totalCalls += user.totalCalls;
					totalVals += user.totalValsByVendor;
				}
			});

			row.push((totalVals / totalCalls)*100);
			result.push(row);

		});

		data.addRows(result);


		const options =
        {
            height: 350,
            vAxis: { format: "#'%'", minValue: 0 },
            legend: { position: "bottom", alignment: "center" },
            chartArea: {"width": "90%", "height": "70%"},
        };

		const b_chart = new window.google.visualization.ColumnChart(document.getElementById("fujisan-calls-to-valuation-rate"));

		b_chart.draw(data, options);


	};


	window.google.charts.load("current", {packages: ["corechart", "bar", "line"]});
	window.google.charts.setOnLoadCallback(renderGraph);


	return (
		<div id="fujisan-calls-to-valuation-rate" className={!show ? "d-none" : null}> </div>
	);
}

export default FujisanCallsToValuationRate;
