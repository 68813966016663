let gaWebsocket = {};

const returnWebsocket = () =>
{
	if(! gaWebsocket?.readyState)
	{
		const wssUrl = process.env.REACT_APP_GA_WEBSOCKET;

		gaWebsocket = new WebSocket(wssUrl);
	}

	return gaWebsocket;
}

export default returnWebsocket;