import React from 'react'
import { csCallMapping } from '../../utils/common';
import { Row, Col, Card, Badge } from 'react-bootstrap';


function FujisanPieCharts({ csUsers, csUserFilter, call, }) {

    if(!csUsers)
        return null;

    const renderPieCharts = () => 
    {
        let callsArr =['Users', 'No. of Calls'], valsArr =['Users', 'No. of Vals'], ansArr =['Users', 'No. of Call Ansd'];
        let callsArrOfArr =[], valsArrOfArr =[], ansArrOfArr =[];
        if(csUsers) 
        {
            callsArrOfArr.push(callsArr);
            ansArrOfArr.push(ansArr);
            valsArrOfArr.push(valsArr);

            csUsers.forEach((user) => 
            {
                if(user && csUserFilter.includes(user.csUserId)) 
                {                    
                    callsArr = [];
                    valsArr = [];
                    ansArr = [];
                    callsArr[0] = user.name;
                    ansArr[0] = user.name;
                    valsArr[0] = user.name;

                    if(call === 0)  
                    {
                        callsArr[1] = user.totalCalls;
                        ansArr[1] = user.totalCallsAnswered;
                        valsArr[1] = user.totalValsByVendor;
                    }
                    else 
                    {
                        callsArr[1] = user[csCallMapping[call]].called;
                        ansArr[1] = user[csCallMapping[call]].answered;
                        valsArr[1] = user[csCallMapping[call]].valsByVendor;
                    }
                        
                    callsArrOfArr.push(callsArr);   
                    ansArrOfArr.push(ansArr);   
                    valsArrOfArr.push(valsArr);   
                }
            })

            }
        const cData = window.google.visualization.arrayToDataTable(callsArrOfArr),
                ansData = window.google.visualization.arrayToDataTable(ansArrOfArr),
                vData = window.google.visualization.arrayToDataTable(valsArrOfArr);

        const options = {
            chartArea: {
                left: "3%",
                top: "3%",
                height: "94%",
                width: "94%"
            }
        };

        const call_chart = new window.google.visualization.PieChart(document.getElementById('calls_piechart')),
            ans_chart = new window.google.visualization.PieChart(document.getElementById('ans_piechart')),
            vals_chart = new window.google.visualization.PieChart(document.getElementById('vals_piechart'));

        call_chart.draw(cData, options);
        ans_chart.draw(ansData, options);
        vals_chart.draw(vData, options);
    }

    window.google.charts.load('current', {packages: ['corechart']});
    window.google.charts.setOnLoadCallback(renderPieCharts);

    return (
       <Row className='mt-4'>
           
           <Col sm={4} xs={12}>
                <Card>
                    <Card.Body>
                        <div className='text-center'>
                            <small className="text-muted"> Calls Made <Badge pill variant="light">{call> 0 && call}</Badge></small>
                        </div>
                        <div id="calls_piechart"></div>
                    </Card.Body>
                </Card>
           </Col>

           <Col sm={4} xs={12}>
           <Card>
                <Card.Body>
                    <div className='text-center'>
                        <small className="text-muted"> Calls Answered <Badge pill variant="light">{call> 0 && call}</Badge></small>
                    </div>
                    <div id="ans_piechart"></div>
                </Card.Body>
            </Card>
           </Col>

           <Col sm={4} xs={12}>
           <Card>
                <Card.Body>
                    <div className='text-center'>
                        <small className="text-muted"> Vendors With Valuation <Badge pill variant="light">{call> 0 && call}</Badge></small>
                    </div>
                    <div id="vals_piechart"></div>
                 </Card.Body>
            </Card>
           </Col>
       </Row>
    )
}

export default FujisanPieCharts
