import React, { useState, useEffect} from "react";
import ReactMarkdown from "react-markdown";
import { Card, Tabs, Tab } from "react-bootstrap";

const ScriptSection = ({ title, text }) => (
	<div className="mt-4" style={{ maxHeight: "300px", overflowY: "auto" }}>
		<h1>{title}</h1>
		<ReactMarkdown>
			{text}
		</ReactMarkdown>
	</div>
);

const CsCallScripts = ({ scripts = [], callType = "call 1" }) =>
{
	const [selectedScriptIndex, setSelectedScriptIndex] = useState(0);

	useEffect(() =>
	{
		if(!scripts?.length || !callType)
			return;

		// Match the script to the call type. I know the lowercase and trim is kinda gross, but making CS life as easy as possible.
		const currentIndex = scripts.findIndex(object =>
			object?.title?.toLowerCase().trim() === callType?.toLowerCase().trim()
		);

		// If the title isn't valid, set the current script index to the first script, else set to the correct index
		if(currentIndex < 0)
			setSelectedScriptIndex(0);
		else
			setSelectedScriptIndex(currentIndex);
	}, [scripts, callType]);

	const handleSelect = (eventKey) =>
	{
		setSelectedScriptIndex(eventKey);
	};

	return (
		<Card>
			<Card.Body>
				<Tabs
					defaultActiveKey={selectedScriptIndex}
					activeKey={selectedScriptIndex}
					onSelect={(eventKey) => handleSelect(eventKey)}
				>
					{scripts.map((script, index) => (
						<Tab key={index} eventKey={index} title={script.title}>
							<ScriptSection
								title={script?.title}
								text={script?.text}
							/>
						</Tab>
					))}
				</Tabs>
			</Card.Body>
		</Card>
	);
};

export default CsCallScripts;