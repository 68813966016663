import React, { useState } from "react";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import TagBadge from "../shared/tagBadge";

const TagGroup = ({ groupName, tags, showDelete, onDelete }) =>
{
	const [isCollapsed, setIsCollapsed] = useState(true);

	return (
		<>
			<ListGroup.Item>
				<Row>
					<Col md={3}>
						<strong>{groupName}:</strong>
					</Col>
					<Col>
						<Button
							className="p-0"
							variant="link"
							onClick={() => setIsCollapsed(!isCollapsed)}
						>
							{isCollapsed ? "Show" : "Hide"}
							{" "}
							{tags.length} tags
							{" "}
							{isCollapsed ? <FaAngleDown /> : <FaAngleUp />}
						</Button>
					</Col>
				</Row>
			</ListGroup.Item>
			{!isCollapsed ?
				<ListGroup.Item>
					{tags.map((t) =>
						<TagBadge
							key={t.id}
							showDelete={showDelete}
							onDelete={onDelete}
							tagInstance={t}
						/>
					)}
				</ListGroup.Item>
				: null}
		</>
	);
};

export default TagGroup;
