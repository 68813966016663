import React from "react";
import { Card, Table } from "react-bootstrap";
import moment from "moment";
import _ from "underscore";
import { publicURL } from "utils/common";


const VendorSaleUpdates = ({ updates, saleUpdates, saleOutcomes, showUpdateLink, sstcDate, className }) =>
{


	const sortedUpdates = saleUpdates.filter(update => update.outcome)
		.sort((a, b) => moment(a.outcome_updated_at).diff(b.outcome_updated_at) < 1 ? 1 : -1 );


	const tableBody = () =>
	{
		let firstUpdate = null;
		let sstcRow = null;

		if(sstcDate)
			sstcRow = <tr key="sstc"><td>{moment(sstcDate).format("YYYY-MM-DD")}</td><td>Went SSTC</td><td>System</td></tr>;
		else
			firstUpdate = <tr key="noUpdateYet"><td></td><td>No sale updates yet for this vendor..</td></tr>;


		if(! sortedUpdates.length)
			return sstcDate ? sstcRow : firstUpdate;

		const tableUpdates = sortedUpdates.map((u, i) =>
		{
			// limit number of updates
			if(i >= updates)
				return null;


			return (
				<tr key={i}>
					<td>{moment(u.outcome_updated_at).format("YYYY-MM-DD")}</td>
					<td>{_.invert(saleOutcomes)[u.outcome]}</td>
					<td>{u.outcomeUser ? u.outcomeUser.name : "System"}</td>
				</tr>
			);
		});

		return [...tableUpdates, sstcRow];
	};

	const updatesPanel = () =>
	{
		return (
			<Table className="table-sm mb-0">
				<thead>
					<tr>
						<th width="33%">Date</th>
						<th width="33%">Update</th>
						<th width="33%">By</th>
					</tr>
				</thead>
				<tbody>
					{tableBody()}
				</tbody>
			</Table>);
	};

	const linkToBranchDashboard = () => <a href={publicURL(`/branch/${_.first(saleUpdates).branch_id}/vendors`)}>Log new update</a>;

	return (
		<Card className={className}>
			<Card.Header>
				Sale Updates
			</Card.Header>
			{updatesPanel()}
			{(showUpdateLink && saleUpdates.length) ? <Card.Footer>
				{ linkToBranchDashboard() }
			</Card.Footer> : null}
		</Card>
	);
};

export default VendorSaleUpdates;