import React, { useState, useEffect } from "react";
import { Table, Badge, Container, Card, FormControl, Col, Button, Row, FormLabel, Spinner, Collapse, FormGroup } from "react-bootstrap";
import moment from "moment";
import SortIcon from "../shared/sortIcon";
import { Link, useLocation, useHistory } from "react-router-dom";
import { gaNiceFormatPrice } from "../../utils/common";
import { useContext } from "react";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import Pagination from "react-js-pagination";
import { Typeahead } from "react-bootstrap-typeahead";
import { FaFilter } from "react-icons/fa";
import { getAPI } from "utils/requestAPI";

export default function InvoiceOverview()
{
	// Table Pagination State
	const [page, setPage] = useState(1);

	const history = useHistory();
	const location = useLocation();

	// contexts
	const { GA_CONFIG } = useContext(GAConfigContext);


	const urlParams = new URLSearchParams(location.search);

	// API Request State
	const [totalInvoices, setTotalInvoices] = useState(20);
	const [invoiceData, setInvoiceData] = useState(20);
	const [loading, setLoading] = useState(null);

	// Filter State
	const [showFilter, setShowFilter] = useState(false);
	const [filterType, setFilterType] = useState("");

	const [statusFilter, setStatusFilter] = useState(urlParams.has("status") ? urlParams.get("status").split(",") : ["INVOICED", "CHASING", "LBA", "LBC", "MCOL", "COURT"]);
	const [respUserFilter, setRespUserFilter] = useState(urlParams.has("respUsers") ? urlParams.get("respUsers").split(",") : []);
	const [respUserFilterLabels, setRespUserFilterLabels] = useState(urlParams.has("respUsers") ? urlParams.get("respUsers").split(",").map(uId => GA_CONFIG.admins.find(admin => admin.id === Number(uId)).name) : []);

	const [nextActionFromFilter, setNextActionFromFilter] = useState(moment(urlParams.get("nextActionFrom")).isValid() ? urlParams.get("nextActionFrom") : null);
	const [nextActionToFilter, setNextActionToFilter] = useState(moment(urlParams.get("nextActionTo")).isValid() ? urlParams.get("nextActionTo") : null);

	const [lastActionFromFilter, setLastActionFromFilter] = useState(moment(urlParams.get("lastActionFrom")).isValid() ? urlParams.get("lastActionFrom") : null);
	const [lastActionToFilter, setLastActionToFilter] = useState(moment(urlParams.get("lastActionTo")).isValid() ? urlParams.get("lastActionTo") : null);

	const [daysOverdueFilter, setDaysOverdueFilter] = useState(urlParams.has("daysOverdue") ? urlParams.get("daysOverdue") : null);

	//Sort State
	const [sortType, setSortType] = useState("");
	const [sortDirection, setSortDirection] = useState("");

	const sortStates = {
		desc: "asc",
		asc: "none",
		none: "desc"
	};

	const stateToBadgeVariant = {
		"INVOICED": "light",
		"CHASING": "info",
		"LBA": "warning",
		"LBC": "warning",
		"MCOL": "danger",
		"COURT": "danger",
		"VOIDED": "secondary",
		"PAID": "success",
		"DRAFT": "secondary",
		"WRITE_OFF": "secondary"
	};

	const controller = new AbortController();
	const signal = controller.signal;


	useEffect(() =>
	{

		const fetchData = async() =>
		{
			setLoading(true);
			const url = "/admin/invoice/invoices.json";

			const pageParams = `?page=${page}`;
			const statusParam = statusFilter.length > 0 ? "&status=" + statusFilter.join(",") : "";
			const respParam = respUserFilter.length > 0 ? "&respUsers=" + respUserFilter.join(",") : "";

			const nextActionFromParam = nextActionFromFilter && moment(nextActionFromFilter).isValid() ? "&nextActionFrom=" + nextActionFromFilter : "";
			const nextActionToParam = nextActionToFilter && moment(nextActionToFilter).isValid() ? "&nextActionTo=" + nextActionToFilter : "";

			const lastActionFromParam = lastActionFromFilter && moment(lastActionFromFilter).isValid() ? "&lastActionFrom=" + lastActionFromFilter : "";
			const lastActionToParam = lastActionToFilter && moment(lastActionToFilter).isValid() ? "&lastActionTo=" + lastActionToFilter : "";

			const daysOverdueParam = daysOverdueFilter && !isNaN(daysOverdueFilter) ? "&daysOverdue=" + daysOverdueFilter : "";

			const sortParams = sortType && sortDirection !== "none" ? "&sortType=" + sortType + "&sortDirection=" + sortDirection : "";

			const qs = pageParams +
				statusParam +
				nextActionFromParam +
				nextActionToParam +
				lastActionFromParam +
				lastActionToParam +
				respParam +
				daysOverdueParam +
				sortParams;

			history.push({
				pathname: location.pathname,
				search: qs
			});

			try
			{
				const res = await getAPI(url + qs, {signal});

				const { totalInvoices, data } = res;

				setInvoiceData(data);
				setTotalInvoices(totalInvoices);
				setLoading(false);
			}
			catch (e)
			{
				console.error(e);
			}


		};

		fetchData();


	}, [page, statusFilter, nextActionFromFilter, nextActionToFilter, respUserFilter, lastActionFromFilter, lastActionToFilter, daysOverdueFilter, sortType, sortDirection]);


	// Event Handlers
	const handleSort = sortName =>
	{
		controller.abort();
		if(sortName === sortType)
		{
			setSortDirection(sortStates[sortDirection]);
		}
		else
		{
			setSortType(sortName);
			setSortDirection("desc");
		}

		setPage(1);
	};

	const handleShowFilter = (type) =>
	{
		controller.abort();
		setFilterType(type);

		if((filterType === type) && showFilter === false)
			setShowFilter(true);
		else if(filterType === type)
			setShowFilter(false);
		else
			setShowFilter(true);


	};

	const handleRespUser = (selected) =>
	{
		controller.abort();
		const respUsers = selected.map(uName => GA_CONFIG.admins.find(user => user.name == uName).id);

		setRespUserFilter(respUsers);
		setRespUserFilterLabels(selected);
		setPage(1);

	};

	const handleDaysOverdue = event =>
	{
		controller.abort();
		if(event.target.value === "")
			setDaysOverdueFilter(null);

		if(isNaN(event.target.value))
		{
			alert("Yo, I need a number!");
			return;
		}
		else
		{
			setDaysOverdueFilter(event.target.value);
		}

		setPage(1);

	};

	const handleNextActionFilterChange = (fromOrTo, value) =>
	{


		controller.abort();

		if(!value || (value == "") || !moment(value).isValid())
		{
			if(fromOrTo === "from")
				setNextActionFromFilter(null);

			if(fromOrTo === "to")
				setNextActionToFilter(null);

			setPage(1);

			return;
		}


		if(fromOrTo === "from")
		{
			setNextActionFromFilter(moment(value).format("YYYY-MM-DD"));
		}
		else if(fromOrTo === "to")
		{
			if(moment(nextActionFromFilter).isValid())
			{
				if(moment(nextActionFromFilter).isAfter(moment(value)))
				{
					alert("To date is before from... 🧐");
					return;
				}
			}

			setNextActionToFilter(moment(value).format("YYYY-MM-DD"));

		}

		setPage(1);

	};

	const handleStatusChange = selected =>
	{
		controller.abort();
		setStatusFilter(selected);
		setPage(1);

	};

	const handleLastActionFilterChange = (fromOrTo, value) =>
	{


		controller.abort();

		if(!value || (value == "") || !moment(value).isValid())
		{
			if(fromOrTo === "from")
				setLastActionFromFilter(null);

			if(fromOrTo === "to")
				setLastActionToFilter(null);

			setPage(1);

			return;
		}


		if(fromOrTo === "from")
		{
			setLastActionFromFilter(moment(value).format("YYYY-MM-DD"));
		}
		else if(fromOrTo === "to")
		{
			if(moment(lastActionFromFilter).isValid())
			{
				if(moment(lastActionFromFilter).isAfter(moment(value)))
				{
					alert("To date is before from... 🧐");
					return;
				}
			}

			setLastActionToFilter(moment(value).format("YYYY-MM-DD"));

		}

		setPage(1);

	};

	return (
		<>
			<Container>
				<Card className="my-3">
					<Card.Header>

						<Button className="mr-3" variant="primary" onClick={() => handleShowFilter("daysOverdue")}>Days Overdue {daysOverdueFilter ? <FaFilter /> : null}</Button>

						<Button className="mr-3" variant="primary" onClick={() => handleShowFilter("nextAction")}>Next Action Filter {nextActionFromFilter || nextActionToFilter ? <FaFilter /> : null}</Button>

						<Button className="mr-3" variant="primary" onClick={() => handleShowFilter("lastAction")}>Last Action Filter {lastActionFromFilter || lastActionToFilter ? <FaFilter /> : null}</Button>

						<Button className="mr-3" variant="primary" onClick={() => handleShowFilter("status")}>Status Filter {statusFilter && (statusFilter.length > 0)  ? <FaFilter /> : null}</Button>

						<Button className="" variant="primary" onClick={() => handleShowFilter("respUser")}>Responsible User {respUserFilterLabels && (respUserFilterLabels.length > 0)  ? <FaFilter /> : null}</Button>

						{!loading && <h3 className="float-right">{totalInvoices} invoices</h3>}
					</Card.Header>

					<Collapse in={showFilter} >
						<Card.Body>
							{filterType === "nextAction" && (
								<Row>
									<Col xs={12}><h5> Next Action Filter</h5></Col>
									<Col xs={6}>
										<FormGroup>
											<FormLabel>From Date:</FormLabel>
											<FormControl
												type="date"
												name="fromDate"
												onChange={e => handleNextActionFilterChange("from", e.target.value)}
												value={nextActionFromFilter}
											/>
										</FormGroup>
									</Col>
									<Col xs={6}>
										<FormGroup>
											<FormLabel>To Date:</FormLabel>
											<FormControl
												type="date"
												name="ToDate"
												onChange={e => handleNextActionFilterChange("to", e.target.value)}
												value={nextActionToFilter}
											/>
										</FormGroup>
									</Col>
								</Row>
							)}

							{filterType === "daysOverdue" &&
								<Row>
									<Col xs={12}>
										<FormGroup>
											<FormLabel>More than this many days overdue:</FormLabel>
											<FormControl
												type="text"
												name="daysOverdue"
												onChange={handleDaysOverdue}
												value={daysOverdueFilter}
											/>
										</FormGroup>
									</Col>
								</Row>

							}

							{filterType === "lastAction" && (
								<Row>
									<Col xs={12}><h5> Last Action Filter</h5></Col>
									<Col xs={6}>
										<FormGroup>
											<FormLabel>From Date:</FormLabel>
											<FormControl
												type="date"
												name="fromDate"
												onChange={e => handleLastActionFilterChange("from", e.target.value)}
												value={lastActionFromFilter}
											/>
										</FormGroup>
									</Col>
									<Col xs={6}>
										<FormGroup>
											<FormLabel>To Date:</FormLabel>
											<FormControl
												type="date"
												name="ToDate"
												onChange={e => handleLastActionFilterChange("to", e.target.value)}
												value={lastActionToFilter}
											/>
										</FormGroup>
									</Col>
								</Row>
							)}

							{filterType === "status" && (
								<Row>

									<Col>
										<FormLabel>Status Filter :</FormLabel>
										<Typeahead
											id="status-filter"
											options={["DRAFT", "INVOICED", "CHASING", "LBA", "LBC", "MCOL", "COURT", "PAID", "WRITE_OFF", "VOIDED"]}
											onChange={handleStatusChange}
											selected={statusFilter}
											multiple
										/>
									</Col>

								</Row>
							)}
							{filterType === "respUser" && (
								<Row>

									<Col>
										<FormLabel>Responsible User Filter :</FormLabel>
										<Typeahead
											id="respUser-filter"
											options={GA_CONFIG.admins.filter(user => user.getUserAdminOptions.includes(GA_CONFIG.adminOpts["ARE_OPS_TEAM"])).map(u => u.name)}
											onChange={handleRespUser}
											selected={respUserFilterLabels}
											multiple
										/>
									</Col>


								</Row>
							)}


						</Card.Body>
					</Collapse>
				</Card>
			</Container>

			{loading &&
				<Col className="text-center my-5">
					<Spinner animation="border" role="status" variant="primary" >
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			}

			{!loading && invoiceData && invoiceData.length > 0 &&
				<div className="p-4">
					<Table responsive size="sm" striped hover>
						<thead>
							<tr>
								<th onClick={() => handleSort("branch")} style={{cursor:"pointer"}}>
									Branch
									<SortIcon sortType={sortType} sortToWatch="branch" sortDirection={sortDirection} />
								</th>
								<th onClick={() => handleSort("vendor")} style={{cursor:"pointer"}}>
									Vendor ID
									<SortIcon sortType={sortType} sortToWatch="vendor" sortDirection={sortDirection} />
								</th>
								<th onClick={() => handleSort("paymentMade")} style={{cursor:"pointer"}}>
									Payment Made
									<SortIcon sortType={sortType} sortToWatch="paymentMade" sortDirection={sortDirection} />
								</th>
								<th onClick={() => handleSort("dueDays")} style={{cursor:"pointer"}}>
									Days Overdue
									<SortIcon sortType={sortType} sortToWatch="dueDays" sortDirection={sortDirection} />
								</th>
								<th onClick={() => handleSort("status")} style={{cursor:"pointer"}}>
									Status
									<SortIcon sortType={sortType} sortToWatch="status" sortDirection={sortDirection} />
								</th>
								<th onClick={() => handleSort("invoiceVal")} style={{cursor:"pointer"}}>
									Invoice Value
									<SortIcon sortType={sortType} sortToWatch="invoiceVal" sortDirection={sortDirection} />
								</th>
								<th onClick={() => handleSort("lastAction")} style={{cursor:"pointer"}}>
									Last Action
									<SortIcon sortType={sortType} sortToWatch="lastAction" sortDirection={sortDirection} />
								</th>
								<th onClick={() => handleSort("nextAction")} style={{cursor:"pointer"}}>
									Next Action
									<SortIcon sortType={sortType} sortToWatch="nextAction" sortDirection={sortDirection} />
								</th>
								<th onClick={() => handleSort("respUser")} style={{cursor:"pointer"}}>
									Responsible user
									<SortIcon sortType={sortType} sortToWatch="respUser" sortDirection={sortDirection} />
								</th>
							</tr>
						</thead>
						<tbody>
							{
								invoiceData.map((invoice, index) =>
								{
									const respUser = invoice.responsible_user_id && GA_CONFIG ? GA_CONFIG.admins.find(user => user.id === invoice.responsible_user_id) : null;

									return (
										<tr key={index}>
											<td>
												<Link to={`/branch/${invoice.branch_id}/edit`}>{invoice.name}</Link>
											</td>
											<td>
												<Link to={`/vendor/property/${invoice.vendor_prop_id}/edit`}>{invoice.vendor_prop_id}</Link>
											</td>
											<td>
												{invoice.paymentMade ? moment(invoice.paymentMade).format("YYYY-MM-DD") : null}
											</td>
											<td>
												{invoice.dueDays}
											</td>
											<td>
												<Badge pill variant={stateToBadgeVariant[invoice.status]}>{invoice.status}</Badge>
											</td>
											<td>£{gaNiceFormatPrice(invoice.totalDue)}</td>
											<td>{invoice.last_action && moment(invoice.last_action).isValid() ? moment(invoice.last_action).format("YYYY-MM-DD") : null}</td>
											<td>
												{invoice.next_action && moment(invoice.next_action).isValid() ? moment(invoice.next_action).format("YYYY-MM-DD") : null}
											</td>
											<td>
												{respUser && respUser.name}
											</td>
										</tr>
									);
								})
							}
						</tbody>
					</Table>
				</div>}
			{!loading &&
				<Container>
					<Pagination
						activePage={page}
						itemsCountPerPage={100}
						totalItemsCount={totalInvoices}
						pageRangeDisplayed={page > 100 ? 18 : 25}
						disabled
						itemClass="page-item"
						disabledClass="disabled"
						linkClass="page-link"
						onChange={pageNum => setPage(pageNum)}
					/>
				</Container>

			}
		</>
	);
}
