import React from "react";
import { Table } from "react-bootstrap";

const BdCallNote = ({ call }) =>
{
	return (
		<Table size="sm">
			<thead>
				<tr>
					<th>Call Type</th>
					<th>Call Made</th>
					<th>Outcome</th>
					<th>Reason</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{call.call_type}</td>
					<td>{call.call_done ? "Yes" : "No"}</td>
					<td>{call.outcome}</td>
					<td>{call.reason ?? "No reason needed"}</td>
				</tr>
			</tbody>
		</Table>
	);
};

export default BdCallNote;