import React from 'react';
import { FaRegBuilding, FaUser } from 'react-icons/fa';
import { GiHouse } from 'react-icons/gi';
import {GoBriefcase} from 'react-icons/go';
import { LinkContainer } from 'react-router-bootstrap';
import { Dropdown } from 'react-bootstrap';

const OmniSearchItem = ({options}) => 
{

    const {type, name, id, address, vendorName, postcode, email, mobile } = options;

    if(type === 'branch')
        return (
        <LinkContainer to={`/branch/${id}/edit`}>
            <Dropdown.Item className='text-wrap'>
                <small>
                    <b className='text-wrap'><FaRegBuilding /> {name} <br/></b>
                    ID: {id}
                </small>
            </Dropdown.Item>
        </LinkContainer>);

    if(type === 'property')
        return (
            <LinkContainer to={`/vendor/property/${id}/edit`}>
            <Dropdown.Item className='text-wrap'>
            <small>
                <b className='text-wrap'><GiHouse /> {vendorName} <br/></b>
                {address}, {postcode} <br />
                ID: { id }
            </small>
            </Dropdown.Item>
            </LinkContainer>
        )

    if(type === 'user')
        return (
        <LinkContainer to={`/user/${id}/edit`}>
            <Dropdown.Item className='text-wrap'>
            <small>
                <b className='text-wrap'><FaUser /> {name} <br/></b>
                {email}, {mobile} <br />
                ID: { id }
            </small>
            </Dropdown.Item>
        </LinkContainer>)

    if(type === 'corp')
        return (<LinkContainer to={`/corporate/${id}`}>
            <Dropdown.Item>
                <small>
                    <b className='text-wrap'><GoBriefcase /> {name} <br/></b>
                    ID: { id }
                </small>
            </Dropdown.Item>
        </LinkContainer>)

    if(options.paginationOption)
    {
        return <Dropdown.Item><span className="text-danger">Too many results</span></Dropdown.Item>
    }
    
    return null;

}

export default OmniSearchItem;