import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Route, Switch, Redirect } from "react-router-dom";
import InvoiceChasingByBranch from "./invoiceChasingByBranch";
import InvoiceAnalytics from "./invoiceAnalytics";
import FourOhFour from "../shared/fourOhFour";
import InvoiceOverview from "./invoiceOverview";
import { getAPI } from "utils/requestAPI";

class InvoiceChasing extends React.Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			typeStrings: null,
			loading: true
		};

		this.fetchInitialData = this.fetchInitialData.bind(this);
		this.createInvoiceChasingByBranch = this.createInvoiceChasingByBranch.bind(this);
	}

	componentDidMount()
	{
		this.fetchInitialData();
	}

	fetchInitialData()
	{
		// Use this function if we need more data in the future (statuses etc)
		getAPI("/admin/invoice/typeStrings.json")
			.then(resp =>
			{
				this.setState({
					typeStrings: resp.typeStrings,
					loading: false
				});
			})
			.catch(e => alert("Error: failed to load typestrings for the Invoice Chasing View"))
	}

	createInvoiceChasingByBranch()
	{
		const localState = {
			filterBy: null,
			sortBy: null,
			toDate: null,
			fromDate: null,
			dueMoreThan: null,
			dueLessThan: null,
			userFilter: null,
			action: null,
			nextActionType: null,
			typeStrings: null // needs separate fetch
		};

		const searchParams = window.location.search;

		const props = new URLSearchParams(searchParams);

		if(props.has("filterBy"))
			localState.filterBy = props.get("filterBy");

		if(props.has("sortBy"))
			localState.sortBy = props.get("sortBy");

		if(props.has("toDate") && props.has("fromDate"))
		{
			localState.toDate = props.get("toDate");
			localState.fromDate = props.get("fromDate");
		}

		if(props.has("dueMoreThan"))
			localState.dueMoreThan = props.get("dueMoreThan");

		if(props.has("dueLessThan"))
			localState.dueLessThan = props.get("dueLessThan");

		if(props.has("userFilter"))
			localState.userFilter = props.get("userFilter");

		if(props.has("action"))
			localState.action = props.get("action");

		if(props.has("nextActionType"))
			localState.nextActionType = props.get("nextActionType");

		return (
			<InvoiceChasingByBranch
				filterBy={localState.filterBy}
				typeStrings={this.state.typeStrings || {}}
				sortBy={localState.sortBy}
				toDate={localState.toDate}
				fromDate={localState.fromDate}
				dueLessThan={localState.dueLessThan}
				dueMoreThan={localState.dueMoreThan}
				userFilter={localState.userFilter}
				action={localState.action}
				nextActionType={localState.nextActionType}
			/>
		);
	}

	render()
	{
		if(this.state.loading)
			return null;

		return (
			<>
				<div className={window.location.pathname == "/vendor/invoices/overview" ? "container" : null}>

					<Navbar bg="light" className="mb-3" expand="xl">
						<Navbar.Brand>🧮 Invoice Chasing</Navbar.Brand>
						<Navbar.Toggle aria-controls="invoice-navbar" />
            			<Navbar.Collapse id="invoice-navbar">
							<Nav navbar className="ml-3">
								<LinkContainer to="/vendor/invoices/chasing">
									<Nav.Link>Chasing</Nav.Link>
								</LinkContainer>
								<LinkContainer to="/vendor/invoices/analytics">
									<Nav.Link>Analytics</Nav.Link>
								</LinkContainer>
								<LinkContainer to="/vendor/invoices/overview">
									<Nav.Link>Overview</Nav.Link>
								</LinkContainer>
							</Nav>
						</Navbar.Collapse>
					</Navbar>

				</div>
				<Switch>
					<Redirect exact from="/vendor/invoices" to="/vendor/invoices/chasing" />
					<Route path="/vendor/invoices/chasing">
						{this.createInvoiceChasingByBranch()}
					</Route>
					<Route path="/vendor/invoices/overview">
						<InvoiceOverview />
					</Route>
					<Route path="/vendor/invoices/analytics">
						<InvoiceAnalytics />
					</Route>
					<Route component={FourOhFour} />
				</Switch>
			</>
		);
	}
}

export default InvoiceChasing;