import React from "react";
import _ from "underscore";
import moment from "moment";
import { Collapse, Card, Table, Badge, FormControl, Button } from "react-bootstrap";
import { FaPhone } from "react-icons/fa";
import { MdOpenInNew } from "react-icons/md";
import CreditNoteOrDiscountOrVoid from "./creditNoteOrDiscountOrVoid";
import ChasingAdmin from "./chasingAdmin";
import ChasingNotes from "./chasingNotes";
import { getAPI, postAPI } from "utils/requestAPI";
import "../../styles/invoiceChasing.css";
import { adminPanelURL } from "utils/common";
import xeroImage from "images/admin/xero.png";

class VendorsWithInvoices extends React.Component
{

	statusStrings = {
		INVOICED: 100,
		CHASING: 200,
		LBA: 400,
		LBC: 500,
		MCOL: 800,
		COURT: 900,
		PAID: 1000,
		WRITE_OFF: 2000,
		VOIDED: 3000
	};

	constructor(props)
	{
		super(props);
		this.state =
		{
			expand: false,
			invoiceRows: [],
			vendorProp: null,
			whyNotPaid: null,
			nextAction: null,
			editing: false,
			billingVirtuals: [],
			editStatus: false,
			allowedStatusChange: [],
			dropDownStatus: "- new status -"
		};

		this.loadVendorProp = this.loadVendorProp.bind(this);
		this.reloadVendorDetails = this.reloadVendorDetails.bind(this);
		this.saveStatusInDb = this.saveStatusInDb.bind(this);
		this.cancelEditStatus = this.cancelEditStatus.bind(this);
	}

	createLabelFromType(type)
	{
		const typeStrings =
		{
			VALUATION: 10,
			VALUATION_REFUND: 20,
			INVOICE: 90,
			OTHER_INVOICE: 95,
			DISCOUNT: 200,
			BAD_DEBT: 300,
			VOIDED: 400,
			PAYMENT: 100,
			CREDIT: 50,
			OTHER: 70
		};


		if(type === typeStrings.INVOICE)
			return <Badge variant="secondary">Invoice</Badge>;

		if(type === typeStrings.OTHER_INVOICE)
			return <Badge variant="dark">Other</Badge>;

		if(type === typeStrings.DISCOUNT)
			return <Badge variant="warning">Discount</Badge>;

		if(type === typeStrings.BAD_DEBT)
			return <Badge variant="danger">Bad Debt</Badge>;

		if(type === typeStrings.VOIDED)
			return <Badge variant="info">Voided</Badge>;

		if(type === typeStrings.PAYMENT)
			return <Badge variant="success">Payment</Badge>;
	}

	createLabelFromStatus(status)
	{

		if(status === this.statusStrings.INVOICED)
			return <Badge variant="secondary" onClick={this.onEditStatus.bind(this, status)}>Issued</Badge>;

		if(status === this.statusStrings.CHASING)
			return <Badge variant="primary" onClick={this.onEditStatus.bind(this, status)}>Chasing</Badge>;

		if(status === this.statusStrings.LBA)
			return <Badge variant="warning" onClick={this.onEditStatus.bind(this, status)}>LBA</Badge>;

		if(status === this.statusStrings.LBC)
			return <Badge variant="warning" onClick={this.onEditStatus.bind(this, status)}>LBC</Badge>;

		if(status === this.statusStrings.MCOL)
			return <Badge variant="warning" onClick={this.onEditStatus.bind(this, status)}>MCOL</Badge>;

		if(status === this.statusStrings.COURT)
			return <Badge variant="danger">Court</Badge>;

		if(status === this.statusStrings.PAID)
			return <Badge variant="success">Paid</Badge>;

		if(status === this.statusStrings.WRITE_OFF)
			return <Badge variant="info">Write Off</Badge>;

		if(status === this.statusStrings.VOIDED)
			return <Badge variant="dark">Voided</Badge>;
	}


	onEditStatus(status)
	{
		const invoiceStatusString = _.invert(this.statusStrings)[status];

		const allowedStateChanges = {
			INVOICED: ["- new status -", "LBA", "LBC"],
			CHASING: ["- new status -", "LBA", "LBC"],
			LBA: ["- new status -", "MCOL"],
			LBC: ["- new status -", "MCOL"],
			MCOL: ["- new status -", "COURT"]
		};

		this.setState({
			editStatus: true,
			allowedStatusChange: allowedStateChanges[invoiceStatusString]
		});
	}

	updateStateWithStatus(e)
	{
		this.setState({dropDownStatus: e.target.value});
	}

	saveStatusInDb(xeroInvoiceId)
	{
		this.setState({editStatus: false});

		const status = {};

		status.newStatus = this.state.dropDownStatus;

		// make the request to update status
		postAPI(`/admin/invoice/${xeroInvoiceId}/updateStatus`, status)
			.then(resp =>
			{
				alert("Updated status!");

				this.loadVendorProp();
			})
			.catch(e => alert("Could not update status. Please try again."));
	}

	cancelEditStatus()
	{
		this.setState({editStatus: false});
	}

	toggleExpand()
	{
		this.setState({expand: !this.state.expand}, () =>
		{
			if(this.state.expand)

				this.loadVendorProp();

		});
	}

	reloadVendorDetails(vpId)
	{
		const vendorDetails = {vendors: []};

		vendorDetails.vendors.push(vpId);

		getAPI(`/admin/invoice/getVendorDetails.json?vendors=${JSON.stringify(vendorDetails.vendors)}`)
			.then(resp =>
			{

				if(resp.vendors)
				{
					this.setState({
					whyNotPaid: resp.vendors[vpId].whyNotPaid,
					nextAction: resp.vendors[vpId].nextAction
					});
				}

			});
	}

	loadVendorProp()
	{
		getAPI("/admin/vendor/property/" + this.props.vendorId + "/edit.json")
			.then(data =>
			{
			// We use these billing virtuals to get stats about dueDays etc and create a nice log of history rows

				const sortedBillings = data.vendorProp.billings.sort((a,b) => a.id - b.id);

				sortedBillings.forEach(row =>
				{

					if(row.status)
					{
						if((row.status == 1000) || (row.status == 3000)) // paid or voided? skip
							return;

						let dueDays = null;

						if(row.typeString == "OTHER_INVOICE")


							dueDays = moment().diff(moment(row.due_date), "days");

						else

							dueDays = _.first(this.props.vendorInvoices.filter(inv => inv.xero_invoice_id == row.xero_invoice_id)).dueDays;


						row.dueDays = dueDays;
					}
				});

				this.setState({
				vendorProp: data.vendorProp,
				billingVirtuals: sortedBillings
				});
			})
			.catch(e =>
			{
				throw new Error(e || "An error occurred when loading vendor");
			});
	}

	deleteInvoiceInXero(xeroInvoiceId)
	{
		const action = {};

		action.delete = 1;


		postAPI(`/admin/invoice/${xeroInvoiceId}/update`, action)
			.then(resp =>
			{

				alert("Success! Deleted invoice in Xero.");

				// reloads UI
				this.loadVendorProp();
			})
			.catch(e => alert("Looks like we can no longer delete this invoice"));

	}

	approveInvoiceInXero(xeroInvoiceId)
	{
		const action = {};

		action.approve = 1;

		postAPI(`/admin/invoice/${xeroInvoiceId}/update`, action)
			.then(resp =>
			{

				alert("Success! Approved invoice in Xero.");

				// reloads UI
				this.loadVendorProp();
			})
			.catch(e => alert("Looks like we can no longer approve this invoice"));
	}

	openCallWindow(vpId, brId, e)
	{
		e.preventDefault();
		return window.open(adminPanelURL(`/vendor/property/${vpId}/phone/${brId}`));
	}

	render()
	{


		const { vendorInvoices } = this.props;

		const vendorId = <p style={{marginRight: "5px", fontSize:"12px", display: "inline"}}>Vendor: {_.first(vendorInvoices).vendor_prop_id}</p>;

		const branchId = _.first(vendorInvoices).id;

		const getVendorAddress = (invoice) =>
		{
			const { address1, postcode } = invoice;
			let addressString = "";

			if(address1)
				addressString += address1 + ", ";

			if(postcode)
				addressString += postcode;

			return addressString;
		};

		const vendorAddress = <p style={{marginRight:"5px", fontSize: "12px", display: "inline"}}>Address: {getVendorAddress(_.first(vendorInvoices))}</p>;

		const vendorCRMLink = <a style={{marginRight: "5px", float:"right"}} href={adminPanelURL("/vendor/property/" + _.first(vendorInvoices).vendor_prop_id + "/edit")} target="_blank">CRM <MdOpenInNew /></a>;

		// Trying to use the Billing virtuals instead
		const InvoiceRow = ({ invoiceData }) =>
		{
			if(invoiceData)
			{

				const makeStatusDropDown = options => (
					<>
						<FormControl style={{width:"80%"}} as="select" placeholder="select" onChange={this.updateStateWithStatus.bind(this)} value={this.state.dropDownStatus}>
							{_.map(options, (status, index) => <option value={status} key={index}>{status}</option> )}
						</FormControl>
						<Button
							variant="success"
							size="sm"
							className="text-center margin-2 width-100"
							onClick={() => this.saveStatusInDb(invoiceData.xero_invoice_id)}
						>
							Save
						</Button>
						<Button
							variant="danger"
							size="sm"
							className="text-center margin-2 width-100"
							onClick={this.cancelEditStatus}
						>
							Cancel
						</Button>
					</>
				);


				const conditionToApproveOrDelete = (invoiceData.typeString == "OTHER_INVOICE") && (invoiceData.status === 50);
				const deleteLink = () => <span style={{cursor: "pointer"}} onClick={this.deleteInvoiceInXero.bind(this, invoiceData.xero_invoice_id)}>❌</span>;
				const approveLink = () => <span style={{cursor: "pointer"}} onClick={this.approveInvoiceInXero.bind(this, invoiceData.xero_invoice_id)}>✅</span>;

				const invoiceLink = <a href={"https://go.xero.com/AccountsReceivable/View.aspx?invoiceid=" + invoiceData.xero_invoice_id} target="_blank"><img src={xeroImage} style={{height: "25px"}} /></a>;

				return (
					<tr style={{backgroundColor: invoiceData.status ? "#f7f7f7" : null}} >
						<td>{invoiceData.status ? invoiceLink : null}</td>
						<td>{(invoiceData.amount + invoiceData.tax_amount) < 0 ? "-£" + Math.abs((Math.round((invoiceData.amount + invoiceData.tax_amount)*100)/100)) : "£" + (Math.round((invoiceData.amount + invoiceData.tax_amount)*100)/100)}</td>
						<td>{this.state.editStatus ? makeStatusDropDown(this.state.allowedStatusChange) : this.createLabelFromStatus(invoiceData.status)}{conditionToApproveOrDelete ? deleteLink() : ""}{conditionToApproveOrDelete ? approveLink() : ""}</td>
						<td>{this.createLabelFromType(invoiceData.type)}</td>
						<td>{moment(invoiceData.trans_date).format("YYYY-MM-DD")}</td>
						<td>{invoiceData.status ? moment(invoiceData.due_date).format("YYYY-MM-DD") : ""}</td>
						<td>{invoiceData.status &&  invoiceData.status < this.statusStrings.PAID ? <Badge variant={moment(invoiceData.due_date).isAfter(moment()) ? "success" : "danger"}>{invoiceData.dueDays < 0 ? "due in " + Math.abs(invoiceData.dueDays) + " days" : invoiceData.dueDays + " days"}</Badge>  : ""}</td>
					</tr>
				);
			}
			else
			{
				return (<div>No data to show yet.</div>);
			}
		};

		const callBranchWithVendor = <a onClick={this.openCallWindow.bind(this, _.first(vendorInvoices).vendor_prop_id, branchId)} style={{marginRight: "5px", float: "right"}} href={"#"}>
			<FaPhone />
		</a>;


		const labelsForInvoice = (
			<div style={{display: "inline"}}>
				<Badge style={{marginRight: "5px", float:"right"}} variant="info">{this.state.whyNotPaid ? this.state.whyNotPaid : this.props.whyNotPaid ? this.props.whyNotPaid : ""}</Badge>
				<Badge style={{marginRight: "5px", float:"right"}} variant="warning">{this.state.nextAction ? this.state.nextAction : this.props.nextAction ? this.props.nextAction : ""}</Badge>
			</div>
		);

		const invoicePanel = (
			<Card className={!this.state.expand ? "invoices style-vendor-heading my-2" : "my-2"}>
				<Card.Header>
					<>
						<span style={{cursor:"pointer"}} onClick={this.toggleExpand.bind(this)}>{this.state.expand? "➖" : "➕"}</span>
						{vendorId}
						{vendorAddress}
						{callBranchWithVendor}
						{vendorCRMLink}
						{labelsForInvoice}
					</>
				</Card.Header>
				<Collapse in={this.state.expand}>
					<Card.Body className="invoices vendor-invoice">
						<Table size="sm" hover>
							<colgroup align="center">
								<col width="8%"/>
								<col width="8%"/>
								<col width="9%"/>
								<col width="8%"/>
								<col width="9%"/>
								<col width="8%"/>
								<col width="8%"/>


							</colgroup>
							<thead >
								<tr>
									<th className="text-center border-top-0 border-bottom-0">Xero Link</th>
									<th className="text-center border-top-0 border-bottom-0">Total</th>
									<th className="text-center border-top-0 border-bottom-0">Status</th>
									<th className="text-center border-top-0 border-bottom-0">Type</th>
									<th className="text-center border-top-0 border-bottom-0">Created</th>
									<th className="text-center border-top-0 border-bottom-0">Due</th>
									<th style={{display:"none"}}></th>

								</tr>
							</thead>
							<tbody align="center">
								{(this.state.billingVirtuals.length > 0) && this.state.billingVirtuals.map(
									(lineItem, index) =>
									{
										// Take out PPI rows from the view
										// Everything less than 90 is PPI
										if(lineItem.type < 90)
											return;

										// This is the only overlap (100 = payment, luckily PPI payments don't have vendor_prop_ids)
										if(lineItem.type == 100 && !lineItem.vendor_prop_id)
											return;

										return <InvoiceRow key={index} invoiceData={lineItem}/>;
									}
								)}
							</tbody>
						</Table>
						{this.state.vendorProp ? <CreditNoteOrDiscountOrVoid vendorProp={this.state.vendorProp} vendorInvoices={this.props.vendorInvoices} reloadVendor={this.loadVendorProp.bind(this)} /> : null}
						{this.state.vendorProp ? <ChasingAdmin whyNotPaid={this.props.whyNotPaid} nextAction={this.props.nextAction} branchId={this.props.branchId} vendorProp={this.state.vendorProp} reloadVendor={this.loadVendorProp.bind(this)} reloadVendorDetails={this.reloadVendorDetails.bind(this)} /> : null}
						{this.state.vendorProp ? <ChasingNotes vendorProp={this.state.vendorProp} enableOverflow={true} reloadVendor={this.loadVendorProp.bind(this)} /> : null}
					</Card.Body>
				</Collapse>
			</Card>
		);

		return (
			<div>
				{invoicePanel}
			</div>
		);
	}
}

export default VendorsWithInvoices;