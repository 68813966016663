import React from "react";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";

const PriorAgents = ({ name }) =>
{
	return (
		<Card>
			<Card.Header
				className="bg-warning fw-bold"
				style={{ fontWeight: 600 }}
			>
				Vendor was referred through GetAgent Exchange by
			</Card.Header>
			<ListGroup>
				<ListGroupItem>
					<a className="text-primary">
						{name}
					</a>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

export default PriorAgents;
