import React, { useState, useEffect, useRef } from "react";
import DocumentTitle from "components/shared/documentTitle";
import gaWebsocket from "utils/gaWebsocket";
import { Spinner } from "react-bootstrap";
import { getAPI } from "utils/requestAPI";
import { StarBanner } from "./components/StarBanner";
import { LeaderboardCard } from "./components/LeaderboardCard";
import { TargetBanner } from "./components/TargetBanner";
import { ValBookedToast } from "./components/ValBookedToast";
import { TeamLeaderboard } from "./components/TeamLeaderboard";
import { PowerHour } from "./components/PowerHour";
import { checkPowerHour, calculateStats } from "./utils";

// Our overall theme adds margin bottom to all p tags... Using this file to remove it.
import "./wallboard.css";

const CsWallBoard = () =>
{
	// When we open the websocket, it gets past, and keeps track of, the initial state. But, when a new message occurs, we need the current updated state. So we need to us ref.
	const [wallboardData, setWallboardData] = useState({});
	const wallboardRef = useRef(wallboardData);

	const [settings, setSettings] = useState({});
	const settingsRef = useRef(settings);

	const [newValBooked, setNewValBooked] = useState([]);
	const loading = useRef(false);
	const [timeAndDate, setTimeAndDate] = useState(null);

	const getSettings = async() =>
	{
		try
		{
			const res = await getAPI("/admin/csWallboard/settings");

			if(!res.success || !res.data)
			{
				console.log("Cannot get settings...");
				return;
			}

			const settingData = JSON.parse(res.data.value);

			settingsRef.current = settingData;
			setSettings(settingData);
		}
		catch (error)
		{
			console.log("No settings set", error);
		}
	};

	const getCurrentData = async() =>
	{
		// Get the settings if they are missing
		if(!settingsRef.current.teams)
			await getSettings();

		// We may receive a lot of messages at once and create a huge stack of network requests. If we are already loading one, do not load more.
		if(loading.current)
			return;

		loading.current = true;

		const csCallData = await getAPI("/admin/csWallboard/wallboardData.json");
		const callBacklog = await getAPI("/admin/vendor/customerService/backlog.json");

		loading.current = false;

		if(!csCallData.success)
		{
			console.log("hmm, cannot get the data...");
			return;
		}

		const { csUsers, totalValsArranged } = csCallData.data;

		const stats = calculateStats(csUsers, totalValsArranged, settingsRef);

		if(callBacklog.callsOneNotDone) stats.callsOneNotDone = callBacklog.callsOneNotDone;

		wallboardRef.current = { csUsers: csUsers, stats: stats };
		setWallboardData({ csUsers: csUsers, stats: stats });
	};

	const handleNewVal = (userId) =>
	{
		const user = wallboardRef.current.csUsers.find((user) => user.csUserId === userId);

		if(user)
		{
			setNewValBooked((state) => [...state, user]);

			setTimeout(() => removeToast(user.csUserId), 5000);
		}
	};

	const removeToast = (userId) =>
	{
		setNewValBooked((state) => state?.filter((u) => u.csUserId !== userId));
	};

	const handleNewMessage = (data) =>
	{
		if(data.valBooked) handleNewVal(data.csUserId);

		getCurrentData();
	};

	useEffect(() =>
	{
		getCurrentData();

		gaWebsocket().onmessage = (event) =>
		{
			if(event.data)
			{
				const message = JSON.parse(event.data);

				if(message.type === "CS_WALLBOARD") handleNewMessage(message.data);
			}
		};
	}, []);

	const setUpdateClock = () =>
	{
		setTimeout(updateClock, 1000);
	};

	const updateClock = () =>
	{
		const clockTime = checkPowerHour();

		setTimeAndDate(clockTime);
	};

	useEffect(() =>
	{
		setUpdateClock();
	}, [updateClock]);

	return (
		<div style={{
			display: "flex",
			padding: "32px",
			backgroundColor: "#002664",
			color: "white",
			width: "100vw",
			height: "100vh",
		}}>
			<DocumentTitle title="CS Wallboard" />
			{wallboardData?.csUsers?.length && wallboardData?.stats ? (
				<div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "16px", width: "100%" }}>
					<div className="column" style={{ gridTemplateRows: "15% repeat(3, 28%)" }}>
						<StarBanner title="Star of the week!" name={settings.starOfTheWeek} />
						<LeaderboardCard title="☎️ Calls" leaderboard={wallboardData?.stats?.callLeaderboard} stat="calls" />
						<LeaderboardCard
							title="🤙 Conversations"
							leaderboard={wallboardData.stats.conversationsLeaderboard}
							stat="conversations"
						/>
						<LeaderboardCard
							title="✅ Vendors with valuations"
							leaderboard={wallboardData.stats.valsLeaderboard}
							stat="vals"
						/>
					</div>
					<div className="column" style={{ gridTemplateRows: "17% 25% 17% 21% 17%" }}>
						<div style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							background: "var(--complementary)",
							borderRadius: "8px",
							padding: "8px"
						}}>
							<p className="wallboard-heading">
								{timeAndDate.formattedTimeAndDate}
							</p>
						</div>
						<TargetBanner
							title="Todays Valuations"
							current={wallboardData.stats.valsArranged}
							target={settings.dailyVals}
							targetwallboard-text={"Target: " + settings.dailyVals}
						/>
						<div className="wallboard-card">
							<p className="wallboard-heading" style={{ fontWeight: 600 }}>
								CS vs Auto
							</p>
							<p className="wallboard-heading" style={{ fontWeight: 600 }}>
								📞 {wallboardData.stats.valsArranged - wallboardData.stats.csUpSells} vs {wallboardData.stats.autoVals + wallboardData.stats.csUpSells} 💻
							</p>
						</div>
						<TargetBanner title="Call One backlog" current={wallboardData.stats.callsOneNotDone} target={0} />
						<PowerHour timeAndDate={timeAndDate} />
					</div>
					<div className="column" style={{ gridTemplateRows: "15% repeat(3, 28%)" }}>
						<StarBanner title="Call conversion superstar!" name={wallboardData?.stats?.conversionSuperstar?.name} />
						<TeamLeaderboard title="Team Calls" teamStats={wallboardData.stats.teamStats} metric="calls" />
						<TeamLeaderboard title="Team Conversations" teamStats={wallboardData.stats.teamStats} metric="conversations" />
						<TeamLeaderboard title="Team Vals" teamStats={wallboardData.stats.teamStats} metric="vals" />
					</div>
				</div>
			) : (
				<Spinner size="lg" variant="var(--primary)" animation="border" />
			)}
			{newValBooked?.length ? <ValBookedToast newValBooked={newValBooked} /> : null}
		</div>
	);
};

export default CsWallBoard;
