import React from 'react';
import moment from 'moment';
import { gaNiceFormatPrice } from '../../utils/common';
import { Alert, Card, Row, Col, DropdownButton, DropdownItem, InputGroup, FormControl, Table } from 'react-bootstrap';
import { FaCalendarAlt } from 'react-icons/fa';
import InvoiceCohortTable from './invoiceCohortTable';
import InvoiceChartPercentPaidOverTime from './invoiceChartPercentPaidOverTime';
import DocumentTitle from '../shared/documentTitle';
import { getAPI } from "utils/requestAPI";

class InvoiceAnalytics extends React.Component {
	constructor(props)
	{
		super(props);
		this.state = {
			loading: true,
			invoicesIssued: null,
			fullyPaid: null,
			// loading a default date
			toDate: props.toDate ? moment(props.toDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
			fromDate: props.fromDate ? moment(props.fromDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
		};

		this._chasingStatuses = [
			"Chase again",
			"LBA",
			"LBC",
			"MCOL post TH",
			"MCOL post LBC",
			"Court post LBC",
			"Write off",
			"Don't Chase",
			"Court post TH"
		];
	
		this.parseUrl = this.parseUrl.bind(this);
		this.onDatePreset = this.onDatePreset.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.fetchInvoiceDataNonCohort = this.fetchInvoiceDataNonCohort.bind(this);
	}

	componentDidMount()
	{
		// parse url and make initial request for data
		this.parseUrl();
	}

	fetchInvoiceDataNonCohort()
	{
		getAPI(`/admin/invoice/invoiceDataNonCohort.json?toDate=${this.state.toDate}&fromDate=${this.state.fromDate}`)
        .then(res => {			
			const { invoicesIssued, fullyPaid, partiallyPaid, chaseAgains, statusNotes } = res;

            this.setState({ invoicesIssued, fullyPaid, partiallyPaid, chaseAgains, statusNotes, loading: false });
		})
		.catch(e => alert(e || 'Failed to load invoice analytics data.'));
	}

	cb() // callback for setState
	{
		this.fetchInvoiceDataNonCohort();
		window.history.pushState(null, null, `?toDate=${this.state.toDate}&fromDate=${this.state.fromDate}`);
	}

	onDatePreset(event)
	{
		const type = event.target.innerText;

		if(type === 'Today')
            this.setState({ toDate:moment().format('YYYY-MM-DD'), fromDate:moment().format('YYYY-MM-DD')}, this.cb);
        else if(type === 'Yesterday')
            this.setState({ toDate:moment().subtract(1, 'day' ).format('YYYY-MM-DD') , fromDate:moment().subtract(1, 'day').format('YYYY-MM-DD')}, this.cb);
        else if(type === 'This Week')
            this.setState({ toDate:moment().endOf('week').format('YYYY-MM-DD') , fromDate:moment().startOf('week').format('YYYY-MM-DD')}, this.cb);
        else if(type === 'Last Week')
            this.setState({ toDate:moment().startOf('week').subtract(1, 'day').format('YYYY-MM-DD'), fromDate:moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD')}, this.cb);
        else if(type === 'Last 2 Weeks')
            this.setState({ toDate:moment().startOf('week').subtract(1, 'day').format('YYYY-MM-DD'), fromDate:moment().subtract(2, 'week').startOf('week').format('YYYY-MM-DD')}, this.cb);
        else if(type === 'Last 4 Weeks')
            this.setState({ toDate:moment().startOf('week').subtract(1, 'day').format('YYYY-MM-DD'), fromDate:moment().subtract(4, 'week').startOf('week').format('YYYY-MM-DD')}, this.cb);
        else
            console.log('Invalid Preset, How did you manage this?')
	}

	onDateChange(e)
	{
		if(this.state.fromDate <= this.state.toDate)
            this.setState({ [e.target.name]:  e.target.value}, this.cb);
        else
            this.setState({ [e.target.name]:  e.target.value}, this.cb);
	}

	// We do this as an alternative to the Twig state being sent from BE 
	// for initially booting component
	parseUrl()
	{
		const searchParams = window.location.search;
		
		if(! searchParams)
		{
			this.fetchInvoiceDataNonCohort();
			return;
		}
		
		const parsedSearch = new URLSearchParams(searchParams);
		
		// Update state and URL 
		this.setState({
			toDate: parsedSearch.has('toDate') ? parsedSearch.get('toDate') : this.state.toDate,
			fromDate: parsedSearch.has('fromDate') ? parsedSearch.get('fromDate') : this.state.fromDate
		}, () => 
		{
			window.history.pushState(null, null, `?toDate=${this.state.toDate}&fromDate=${this.state.fromDate}`)
			
			this.fetchInvoiceDataNonCohort();
		});
	}

	render()
	{
		
		if(this.state.loading)
			return <Alert><strong>Loading...</strong></Alert >

		const header = () =>
        {
            return <Row>
                    <Col sm={4} className="d-flex align-items-center">
						<h4 className="mb-0" style={{textAlign: "left", fontSize: "16px"}}>Non-cohort data</h4>
					</Col>
                    <Col sm={2}>
                        <DropdownButton 
                            id='date-preselect' 
                            title='Date Presets' 
                            variant='link'
                        >
                            <DropdownItem onClick={this.onDatePreset}>Today</DropdownItem>
                            <DropdownItem onClick={this.onDatePreset}>Yesterday</DropdownItem>
                            <DropdownItem onClick={this.onDatePreset}>This Week</DropdownItem>
                            <DropdownItem onClick={this.onDatePreset}>Last Week</DropdownItem>
                            <DropdownItem onClick={this.onDatePreset}>Last 2 Weeks</DropdownItem>
                            <DropdownItem onClick={this.onDatePreset}>Last 4 Weeks</DropdownItem>
                        </DropdownButton>
                    </Col>
                    <Col sm={3}>
                        <InputGroup className="date">
                            <FormControl type="date" name="fromDate" onChange={this.onDateChange.bind(this)} value={this.state.fromDate}/>
                            <InputGroup.Text>
								<InputGroup.Append>
                                	<FaCalendarAlt />
								</InputGroup.Append>
                            </InputGroup.Text>
                        </InputGroup>
                    </Col>
                    <Col sm={3}>
                        <InputGroup className="date">
                            <FormControl type="date" name="toDate" onChange={this.onDateChange} value={this.state.toDate}/>
                            <InputGroup.Text>
								<InputGroup.Append>
                                	<FaCalendarAlt />
								</InputGroup.Append>
                            </InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
		}
		
		const invoicesIssued = (
			<Col md={4}>
				<Card className="text-center">
					<Card.Header className="bg-primary text-white">Invoices Issued</Card.Header>					
					<Card.Body>
						<h2>£{gaNiceFormatPrice((this.state.invoicesIssued.amount && this.state.invoicesIssued.amount.toFixed(2)) || 0)}</h2>
						<p className="text-muted">{this.state.invoicesIssued.cnt} invoices</p>
					</Card.Body>
				</Card>
			</Col>
		)

		const invoicesFullyPaid = (
			<Col md={4}>
				<Card className="text-center">
					<Card.Header className="bg-primary text-white">Fully Paid</Card.Header>
					<Card.Body>
						<h2>£{gaNiceFormatPrice((this.state.fullyPaid.amount && this.state.fullyPaid.amount.toFixed(2)) || 0)}</h2>
						<p className="text-muted">{this.state.fullyPaid.cnt} invoices</p>
					</Card.Body>
				</Card>
			</Col>
		)

		const invoicesPartiallyPaid = (
			<Col md={4}>
				<Card className="text-center">
					<Card.Header className="bg-primary text-white">Partially Paid</Card.Header>
					<Card.Body>
						<h2>£{gaNiceFormatPrice((this.state.partiallyPaid.amount && this.state.partiallyPaid.amount.toFixed(2)) || 0)}</h2>
						<p className="text-muted">{this.state.partiallyPaid.cnt} invoices</p>
					</Card.Body>
				</Card>
			</Col>
		)

		const averageTimesChased = (
			<Card className="text-center mb-3">
				<Card.Header>Average Times Chased</Card.Header>
				<Card.Body>
					<h2>{(Math.round((this.state.chaseAgains.count / this.state.chaseAgains.total) * 100) / 100) || 0}</h2>
					<p className="text-muted">Status: "Chase Again"</p>
				</Card.Body>
			</Card>
		)

		const notChasedButPaid = (
			<Card className="text-center">
				<Card.Header>Paid without chasing</Card.Header>
				<Card.Body>
					<h2>{ (Math.round(((this.state.fullyPaid.cnt - this.state.statusNotes.totalInvoicesChased) / this.state.fullyPaid.cnt)*100 * 100)/100) || 0 }%</h2>
					<p className="text-muted">fully paid</p>
				</Card.Body>					
			</Card>
		)

		return(
			<>
				<DocumentTitle title={"Invoice Analytics"} />
			
				<Card className="mb-3">
					<Card.Header>
						{header()}
					</Card.Header>
					<Card.Body>
						<Row className="mb-3">
							{invoicesIssued}
							{invoicesFullyPaid}
							{invoicesPartiallyPaid}
						</Row>
						<Row>
							{/* table for next action when paid */}
							<Col md={8}>
								<Table bordered striped hover size="sm">
									<colgroup align="left">
										<col width="55%"/>
										<col width="25%"/>
										<col width="20%"/>
									</colgroup>
									<thead >
										<tr>
											<th>Status when paid</th>
											<th>#</th>
											<th>%</th>
										</tr>
									</thead>
									<tbody align="left">
										{this._chasingStatuses.map((row, index) => 
										{
											const percentChased = Math.round((this.state.statusNotes.nextActionsCounter[row] / this.state.statusNotes.totalInvoicesChased) * 100);

											return (
												<tr key={index}>
													<td>{row}</td>
													<td>{this.state.statusNotes.nextActionsCounter[row]}</td>
													<td>{percentChased || 0}%</td>
												</tr>
											)
										})}
									</tbody>
								</Table>
							</Col>
							<Col md={4}>
								{averageTimesChased}
								{notChasedButPaid}
							</Col>
						</Row>
					</Card.Body>
				</Card>

				<Card className="mb-3">
					<Card.Header>
						<h4 className="mb-0" style={{textAlign: "left", fontSize: "16px"}}>Cohort Analysis</h4>
					</Card.Header>
					<Card.Body>
						<Row>
							<Col md={12}>
								<InvoiceCohortTable
									date={moment()}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<InvoiceChartPercentPaidOverTime
									date={moment()} 
								/>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</>
		)
	}
}

export default InvoiceAnalytics;