import React from "react";

const ValBookedToast = ({ newValBooked }) =>
{
	return (
		<div className="wallboard-toast-container">
			{newValBooked.map((user, index) => (
				<div key={index} className="wallboard-toast">
					<img
						src="https://emojis.slackmojis.com/emojis/images/1643514389/3643/cool-doge.gif?1643514389"
						alt=""
					/>
					<p className="wallboard-text" style={{ display: "flex", alignItems: "center", fontWeight: 600 }}>{user.name} just booked a valuation!</p>
				</div>
			))}
		</div>
	);
};

export { ValBookedToast };
