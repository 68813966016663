import React, { useState, useRef } from "react";
import { InputGroup, Button, Form } from "react-bootstrap";
import { AsyncTypeahead, Menu } from "react-bootstrap-typeahead";
import OmniSearchItem from "./omniSearchItem";
import { withRouter } from "react-router-dom";
import { getAPI } from "utils/requestAPI";

const userSearchUrl = (searchTerm) => `/admin/searchAll/${searchTerm}.json`;

const OmniSearch = (props) =>
{
	const ref = useRef();

	const [loading, setLoading] = useState(false);
	const [searchResults, setSearchResults] = useState({});
	const [query, setQuery] = useState("");
	const [isDropDownOpen, setIsDropDownOpen] = useState(false);

	const searchQuery = () =>
	{
		if(query === "") return;

		setLoading(true);

		getAPI(userSearchUrl(query))
			.then((data) =>
			{
				setSearchResults(data);
				setLoading(false);

				setIsDropDownOpen(true);
			})
			.catch((e) =>
			{
				setLoading(false);
				console.log(e);

				setIsDropDownOpen(true);
			});
	};

	const handleInputChange = (newValue) =>
	{


		setSearchResults({});
		setIsDropDownOpen(false);


		setQuery(newValue);
	};

	const handleOnKeyDown = (e) =>
	{
		// If the key pressed is not enter leave.
		if(e.which != 13)
			return;

		searchQuery();
	};

	const {
    corporates = [],
    users = [],
    branches = [],
    properties = [],
	} = searchResults;

	return (
		<>
			<Form.Group>
				<InputGroup>
					<AsyncTypeahead
						ref={ref}
						id="omni"
						isLoading={loading}
						placeholder={"Omni Search"}
						filterBy={() => true}
			      		options={[...corporates, ...users, ...branches, ...properties]}
						onSearch={() =>
						{}}
						onKeyDown={handleOnKeyDown}
						open={isDropDownOpen}
						labelKey="name"
						useCache={false}
						onInputChange={(query) => handleInputChange(query)}
						maxResults={200}
						minLength={2}
						renderMenu={(results, menuProps) => (
							<Menu {...menuProps}>
								{results.map((v, index) => (
									<div key={index} className="text-wrap" style={{overflow: "hidden"}} onClick={() =>
									{
										setQuery(null);
										setSearchResults([]);
										setIsDropDownOpen(false);
										ref.current.clear();
									}}>
										<OmniSearchItem options={v} />
									</div>
								))}
							</Menu>
						)}
					/>
					<InputGroup.Append>
						<Button onClick={searchQuery} disabled={loading} variant="outline-secondary">
              Search
						</Button>
					</InputGroup.Append>
				</InputGroup>
			</Form.Group>
		</>
	);
};

export default withRouter(OmniSearch);
