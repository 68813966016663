import React, { useContext } from "react";
import { Nav, Navbar, Col, Form, InputGroup, NavDropdown } from "react-bootstrap";
import moment from "moment";
import { useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router";
import { GAConfigContext } from "contexts/gaConfigContext";

function FujisanNav({ setFromDate, fromDate, setToDate, toDate, call, setCall })
{
	const callNumberToName = (num) =>
    	{
    		const callTypeMapping = {
                1: "Call 1",
                2: "Call 2",
                3: "Call 3",
                4: "Call 4",
								5: "Prospect Call 1",
								6: "Prospect Call 2",
                7: "Call Backs",
                8: "Auto Upsell 1",
								9: "Auto Upsell 2",
                10: "LL 8 Weeks",
                11: "Revisit 1",
                12: "Revisit 2",
    		};

    		return callTypeMapping[num];
	};

	let dateRangeText = "";

	const location = useLocation();
	const navDateOptions = ["/vendor/fujisan/dashboard","/vendor/fujisan/calls"];
	const navDropDownOptions = ["/vendor/fujisan/dashboard"];

	if(toDate === moment().format("YYYY-MM-DD") && fromDate === moment().format("YYYY-MM-DD"))
		dateRangeText = "Today";
	else if(toDate === moment().subtract(1, "day").format("YYYY-MM-DD") && fromDate === moment().subtract(1, "day").format("YYYY-MM-DD"))
		dateRangeText = "Yesterday";
	else if(toDate === moment().endOf("week").format("YYYY-MM-DD") && fromDate === moment().startOf("week").format("YYYY-MM-DD"))
		dateRangeText = "This Week";
	else if(toDate === moment().startOf("week").subtract(1, "day")
		.format("YYYY-MM-DD") && fromDate === moment().subtract(1, "week").startOf("week")
		.format("YYYY-MM-DD"))
		dateRangeText = "Last Week";
	else if(toDate === moment().startOf("week").subtract(1, "day")
		.format("YYYY-MM-DD") && fromDate === moment().subtract(2, "week").startOf("week")
		.format("YYYY-MM-DD"))
		dateRangeText = "Last Two Weeks";
	else if(toDate === moment().startOf("week").subtract(1, "day")
		.format("YYYY-MM-DD") && fromDate === moment().subtract(4, "week").startOf("week")
		.format("YYYY-MM-DD"))
		dateRangeText = "Last Four Weeks";
	else
		dateRangeText = "Custom";

	const [dateRange, setDateRange] = useState(dateRangeText);

	const { GA_CONFIG } = useContext(GAConfigContext);

	const handleToday = () =>
	{
		setFromDate(moment().format("YYYY-MM-DD"));
		setToDate(moment().format("YYYY-MM-DD"));
		setDateRange("Today");
	};

	const handleYesterday = () =>
	{
		setFromDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
		setToDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
		setDateRange("Yesterday");

	};

	const handleThisWeek = () =>
	{
		setFromDate(moment().startOf("week").format("YYYY-MM-DD"));
		setToDate(moment().endOf("week").format("YYYY-MM-DD"));
		setDateRange("This Week");

	};

	const handleLastWeek = () =>
	{
		setFromDate(moment().subtract(1, "week").startOf("week")
			.format("YYYY-MM-DD"));
		setToDate(moment().startOf("week").subtract(1, "day")
			.format("YYYY-MM-DD"));
		setDateRange("Last Week");

	};

	const handleTwoWeeks = () =>
	{
		setFromDate(moment().subtract(2, "week").startOf("week")
			.format("YYYY-MM-DD"));
		setToDate(moment().startOf("week").subtract(1, "day")
			.format("YYYY-MM-DD"));
		setDateRange("Last Two Weeks");

	};


	const handleFourWeeks = () =>
	{
		setFromDate(moment().subtract(4, "week").startOf("week")
			.format("YYYY-MM-DD"));
		setToDate(moment().startOf("week").subtract(1, "day")
			.format("YYYY-MM-DD"));
		setDateRange("Last Four Weeks");
	};


	return (
		<Navbar bg="light" expand="xl" className="mb-3">
			<Navbar.Brand>
                ⛰️ Fujisan
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="fujisan-navbar" />
			<Navbar.Collapse id="fujisan-navbar" style={{width: "100%"}}>
				<Nav navbar className="ml-4 mr-auto">

					<LinkContainer to="/vendor/fujisan/dashboard">
						<Nav.Link>Dashboard</Nav.Link>
					</LinkContainer>
					<LinkContainer to="/vendor/fujisan/backlog">
						<Nav.Link>First Call Backlog</Nav.Link>
					</LinkContainer>
					<LinkContainer to="/vendor/fujisan/calls">
						<Nav.Link>Calls</Nav.Link>
					</LinkContainer>

					{GA_CONFIG.user.adminOptions[GA_CONFIG.adminOpts.ARE_EXTRA_PRIV] ?
					<LinkContainer to="/vendor/fujisan/settings">
						<Nav.Link>Settings</Nav.Link>
					</LinkContainer>
					: null}

				</Nav>

				{navDateOptions.includes(location.pathname) && (
					<Nav navbar className="mr-auto">
						<NavDropdown title={call === 0 ? "All Calls" : callNumberToName(call)}>
							<NavDropdown.Item onClick={() => setCall(0)}>All Calls</NavDropdown.Item>
							<NavDropdown.Item onClick={() => setCall(1)}>Call 1</NavDropdown.Item>
							<NavDropdown.Item onClick={() => setCall(2)}>Call 2</NavDropdown.Item>
							<NavDropdown.Item onClick={() => setCall(3)}>Call 3</NavDropdown.Item>
							<NavDropdown.Item onClick={() => setCall(4)}>Call 4</NavDropdown.Item>
							<NavDropdown.Item onClick={() => setCall(5)}>Prospect Call 1</NavDropdown.Item>
							<NavDropdown.Item onClick={() => setCall(6)}>Prospect Call 2</NavDropdown.Item>
							<NavDropdown.Item onClick={() => setCall(7)}>Call Backs</NavDropdown.Item>
							<NavDropdown.Item onClick={() => setCall(8)}>Auto Upsell 1</NavDropdown.Item>
							<NavDropdown.Item onClick={() => setCall(9)}>Auto Upsell 2</NavDropdown.Item>
							<NavDropdown.Item onClick={() => setCall(10)}>LL 8 Weeks</NavDropdown.Item>
							<NavDropdown.Item onClick={() => setCall(11)}>Revisit 1</NavDropdown.Item>
							<NavDropdown.Item onClick={() => setCall(12)}>Revisit 2</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown  title={dateRange}>
							<NavDropdown.Item  onClick={handleToday}>Today</NavDropdown.Item>
							<NavDropdown.Item  onClick={handleYesterday}>Yesterday</NavDropdown.Item>
							<NavDropdown.Item  onClick={handleThisWeek}>This Week</NavDropdown.Item>
							<NavDropdown.Item  onClick={handleLastWeek}>Last Week</NavDropdown.Item>
							<NavDropdown.Item  onClick={handleTwoWeeks}>Last 2 Weeks</NavDropdown.Item>
							<NavDropdown.Item  onClick={handleFourWeeks}>Last 4 Weeks</NavDropdown.Item>
						</NavDropdown>

					</Nav>
				)}

				{navDateOptions.includes(location.pathname) && (
					<Form inline>
						<Form.Row>
							<Col>
								<InputGroup size="sm">
									<InputGroup.Prepend>
										<InputGroup.Text >from</InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control
										type="date"
										onChange={(e) => setFromDate(e.target.value)}
										value={fromDate}
										size="sm"
									/>

								</InputGroup>
							</Col>
							<Col>
								<InputGroup size="sm">
									<InputGroup.Prepend>
										<InputGroup.Text >to</InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control
										type="date"
										onChange={(e) => setToDate(e.target.value)}
										value={toDate}
										size="sm"
									/>

								</InputGroup>
							</Col>
						</Form.Row>
					</Form>
				)}

			</Navbar.Collapse>
		</Navbar>
	);
}

export default FujisanNav;
