import React from "react";
import moment from "moment";
import { Table, Spinner } from "react-bootstrap";
import _ from "underscore";
import { GAConfigContext } from "../../contexts/gaConfigContext";

class CohortChart extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
            cohortData: null,
            tableStatusOrder: [0, 550, 650, 750, 800, 850, 900, 1000, 1100, 1200]
		};

		this.createAndRenderPieChart = this.createAndRenderPieChart.bind(this);
	}


    static contextType = GAConfigContext;

    //Process the Cohort Valuation data
    processCohortValData(cohortData, view, date)
    {
    	if(view === "months")
    	{
    		const startMonth = moment(date).startOf("month").subtract(13, "month");
    		const dataTable = [];

    		while (moment(date).startOf("month").isSameOrAfter(startMonth, "month"))
    		{
    			const key = startMonth.format("YYYY-M");

    			if(cohortData[key])
    			{
    				dataTable.push([
    					startMonth.format("MMM YYYY"),
    					100 * cohortData[key].autoVals/cohortData[key].total,
    					100 * (cohortData[key].totalVals - cohortData[key].autoVals)/cohortData[key].total
    				]);
    			}
    			else
    			{
    				dataTable.push([startMonth.format("MMM YYYY"), 0, 0]);
    			}

    			startMonth.add(1, "month");
    		}

    		return [["Month / Year", "Auto Valuations", "CS Valuations"], ...dataTable];

    	}
    	else if(view == "weeks")
    	{
    		const dataTable = this.loopy(cohortData, false, false, true); // last bool is for Google Chart and not tables

    		return [["Month / Year", "Auto Valuations", "CS Valuations"], ...dataTable];
    	}
    	else if(view == "days")
    	{
    		const startMonth = moment(date).startOf("day").subtract(14, "day");
    		const dataTable = [];

    		while (moment(date).startOf("day").isSameOrAfter(startMonth, "day"))
    		{
    			const key = startMonth.format("YYYY-MM-DD");

    			if(cohortData[key])
    			{
    				dataTable.push([
    					key,
    					100 * cohortData[key].autoVals/cohortData[key].total,
    					100 * (cohortData[key].totalVals - cohortData[key].autoVals)/cohortData[key].total
    				]);
    			}
    			else
    			{
    				dataTable.push([startMonth.format("YYYY-MM-DD"), 0, 0]);
    			}

    			startMonth.add(1, "day");
    		}

    		return [["Month / Year", "Auto Valuations", "CS Valuations"], ...dataTable];
    	}
    }

    createAndRenderPieChart()
    {
    	if(this.props.cohortData)
    	{
    		let titleForX;

    		if(this.props.view === "months")
    			titleForX = "Month";

    		if(this.props.view === "weeks")
    			titleForX = "Week Beginning";

    		if(this.props.view === "days")
    			titleForX = "Day";

    		let cohortOptions = {
                hAxis: {
                    title: titleForX,
                    titleTextStyle: {color: "#333"},
                    slantedText: true,
                    slantedTextAngle: 45,
                    showTextEvery: 1
                },
                vAxis: {
                    minValue: 0,
                    title: "Percentage",
                    titleTextStyle: {color: "#333"},
                },
                isStacked: true,
                height: "500",
                width: "100%",
                chartArea: { width: "95%", top:10, right: 10},
                legend: {position : "bottom"}
    		};
    		const cohortData = window.google.visualization.arrayToDataTable(this.processCohortValData(this.props.cohortData, this.props.view, this.props.date));


    		const cohortChart = new window.google.visualization.AreaChart(document.getElementById("lead-cohort-valuations"));

    		cohortChart.draw(cohortData, cohortOptions);
    	}
    }

    tableHeader(date, view)
    {
    	if(view === "months")
    	{
    		const startMonth = moment(date).startOf("month").subtract(13, "months");

    		const monthHeaders = [<th>State</th>];

    		while (moment(date).startOf("month").isSameOrAfter(startMonth, "month"))
    		{
    			monthHeaders.push(<th>{startMonth.format("MMM YYYY")}</th>);

    			startMonth.add(1, "month");
    		}

    		return (
    			<tr>
    				{monthHeaders}
    			</tr>
    		);
    	}
    	else if(view === "weeks")
    	{
    		const startMonth = moment(date).startOf("week").subtract(14, "week");

    		const monthHeaders = [<th>State</th>];

    		while (moment(date).startOf("week").isAfter(startMonth, "week"))
    		{
    			startMonth.add(1, "week");
    			monthHeaders.push(<th>WB: {startMonth.startOf("week").format("YYYY-MM-DD")}</th>);
    		}

    		return (
    			<tr>
    				{monthHeaders}
    			</tr>
    		);
    	}
    	else if(view === "days")
    	{
    		const startMonth = moment(date).startOf("day").subtract(14, "day");

    		const monthHeaders = [<th>State</th>];

    		while (moment(date).startOf("day").isSameOrAfter(startMonth, "day"))
    		{
    			monthHeaders.push(<th>{startMonth.format("YYYY-MM-DD")}</th>);

    			startMonth.add(1, "day");
    		}

    		return (
    			<tr>
    				{monthHeaders}
    			</tr>
    		);
    	}
    }

    loopy(dataObj, includeInvertedStatus = false, status = "", isGoogleChart)
    {
    	// utility function to construct tables or charts data
    	// only to be used in the "weekly view"
    	const stack = isGoogleChart ? [] :
    		includeInvertedStatus ? [<td key={-1}>{_.invert(this.context.GA_CONFIG.vp.status)[status]}</td>] : [<td key={-1}></td>];

    	// this date will be used in a loop if GoogleChart to make sure we compute the chart week labels same way we do for the cohort table thus keeping in sync
    	let startDate = moment(this.props.date).startOf("week").subtract(14, "week");

    	_.keys(dataObj).forEach(week =>
    	{
    		if(isGoogleChart)
    		{
    			startDate.add(1, "week");

    			let legendDate = startDate.startOf("week").format("YYYY-MM-DD");

    			if(dataObj[week])
    			{
    				stack.push([
    					"WB:"+legendDate,
    					100 * dataObj[week].autoVals/dataObj[week].total,
    					100 * (dataObj[week].totalVals - dataObj[week].autoVals)/dataObj[week].total
    				]);
    			}
    			else
    			{
    				stack.push(["WB:"+legendDate, 0, 0]);
    			}
    		}
    		else if(!includeInvertedStatus)
    		{
    			if(dataObj[week] && (dataObj[week].total > 0))
    				stack.push(<td>{dataObj[week].total}</td>);
    			else
    				stack.push(<td>0</td>);
    		}
    		else
    		{
    			if(dataObj[week] && (dataObj[week].total > 0))
    			{
    				const percent = (100 * dataObj[week].cohort[status]/dataObj[week].total);

    				stack.push(
    					<td style={{backgroundColor: "hsl(" + (Math.round((percent/100) * 120)) + ", 100%, 80%)"}}>
    						{percent.toFixed(1)}%
    					</td>
    				);
    			}
    			else
    			{
    				stack.push(<td></td>);
    			}
    		}
    	});

    	return stack;
    }

    cohortTotalValueRow(cohortData, view, date)
    {
    	if(view === "months")
    	{
    		if(! cohortData)
    			return null;

    		const startMonth = moment(date).startOf("month").subtract(13, "months");

    		const totalCells = [<td></td>];

    		while (moment(date).startOf("month").isSameOrAfter(startMonth, "month"))
    		{
    			const key = startMonth.format("YYYY-M");

    			if(cohortData[key] && (cohortData[key].total > 0))

    				totalCells.push(<td>{cohortData[key].total}</td>);

    			else

    				totalCells.push(<td>0</td>);


    			startMonth.add(1, "month");
    		}

    		return (
    			<tr style={{fontWeight: "bold"}}>
    				{totalCells}
    			</tr>
    		);
    	}
    	else if(view === "weeks")
    	{
    		if(! cohortData)
    			return null;

    		const totalCells = this.loopy(cohortData);

    		return (
    			<tr style={{fontWeight: "bold"}}>
    				{totalCells}
    			</tr>
    		);
    	}
    	else if(view === "days")
    	{
    		if(!cohortData)
    			return null;

    		const startMonth = moment(date).startOf("day").subtract(14, "day");

    		const totalCells = [<td></td>];

    		while (moment(date).startOf("day").isSameOrAfter(startMonth, "day"))
    		{
    			const key = startMonth.format("YYYY-MM-DD");

    			if(cohortData[key] && (cohortData[key].total > 0))

    				totalCells.push(<td>{cohortData[key].total}</td>);

    			else

    				totalCells.push(<td>0</td>);


    			startMonth.add(1, "day");
    		}

    		return (
    			<tr style={{fontWeight: "bold"}}>
    				{totalCells}
    			</tr>
    		);
    	}
    }


    cohortTableContents(cohortData, view, date, tableStatusOrder)
    {
    	if(view === "months")
    	{
    		if(! cohortData)
    			return null;

    		const tableRows = _.map(tableStatusOrder, status =>
    		{
    			const startMonth = moment(date).startOf("month").subtract(13, "months");
    			const cells = [<td>{_.invert(this.context.GA_CONFIG.vp.status)[status]}</td>];

    			while (moment(date).startOf("month").isSameOrAfter(startMonth, "month"))
    			{
    				const key = startMonth.format("YYYY-M");

    				if(cohortData[key] && (cohortData[key].total > 0))
    				{
    					const percent = (100 * cohortData[key].cohort[status]/cohortData[key].total);

    					cells.push(
    						<td style={{backgroundColor: "hsl(" + (Math.round((percent/100) * 120)) + ", 100%, 80%)"}}>
    							{percent.toFixed(1)}%
    						</td>
    					);
    				}
    				else
    				{
    					cells.push(<td></td>);
    				}

    				startMonth.add(1, "month");
    			}

    			return (
    				<tr key={status+tableStatusOrder}>
    					{cells}
    				</tr>
    			);
    		});

    		return tableRows;

    	}
    	else if(view === "weeks")
    	{
    		if(! cohortData)
    			return null;

    		const tableRows = _.map(tableStatusOrder, status =>
    		{
    			const cells = this.loopy(cohortData, true, status);

    			return (
    				<tr key={status+tableStatusOrder}>
    					{cells}
    				</tr>
    			);
    		});

    		return tableRows;
    	}
    	else if(view === "days")
    	{
    		if(! cohortData)
    			return null;

    		const tableRows = _.map(tableStatusOrder, status =>
    		{
    			const startMonth = moment(date).startOf("day").subtract(14, "day");
    			const cells = [<td>{_.invert(this.context.GA_CONFIG.vp.status)[status]}</td>];

    			while (moment(date).startOf("day").isSameOrAfter(startMonth, "day"))
    			{
    				const key = startMonth.format("YYYY-MM-DD");

    				if(cohortData[key] && (cohortData[key].total > 0))
    				{
    					const percent = (100 * cohortData[key].cohort[status]/cohortData[key].total);

    					cells.push(
    						<td style={{backgroundColor: "hsl(" + (Math.round((percent/100) * 120)) + ", 100%, 80%)"}}>
    							{percent.toFixed(1)}%
    						</td>
    					);
    				}
    				else
    				{
    					cells.push(<td></td>);
    				}

    				startMonth.add(1, "day");
    			}

    			return (
    				<tr key={status+tableStatusOrder}>
    					{cells}
    				</tr>
    			);
    		});

    		return tableRows;
    	}

    }

    render()
    {
    	const { date, cohortData, view } = this.props;
    	const { tableStatusOrder } = this.state;
    	//Get Data Table elements
    	const tableHeaderRow = this.tableHeader(date, view);
    	const tableTotalLeadsRow = this.cohortTotalValueRow(cohortData, view, date);
    	const tableContents = this.cohortTableContents(cohortData, view, date, tableStatusOrder);

    	//Set up window.google charts to draw val Area chart
    	window.google.charts.load("current", {packages: ["corechart"]});
    	window.google.charts.setOnLoadCallback(this.createAndRenderPieChart);
    	return (
    		<div style={{padding:"10px"}}>
    			<p style={{textAlign: "center"}}>Cohort Status</p>
    			{cohortData ? (
    				<Table striped bordered hover responsive size="sm" style={{textAlign: "center"}}>
    					<thead>
    						{tableHeaderRow}
    					</thead>
    					<tbody>
    						{tableTotalLeadsRow}
    						{tableContents}
    					</tbody>
    				</Table>
    			): (<div className="w-100 text-center"><Spinner variant="primary" animation="border"/></div>)}
    			{cohortData ? <p className="mt-6 text-center">Cohort Valuations (CS/Auto)</p> : null}
    			<div id="lead-cohort-valuations" className="w-100 h-100">
    			</div>
    		</div>
    	);
    }
}

export default CohortChart;