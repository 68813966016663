import React from "react";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { adminPanelURL } from "utils/common";

const PriorAgents = ({ agents, shortlist }) =>
{
	// Unlikely to be null (very likely to be length of 0) but good to be safe
	if(!agents?.length)
		return null;

	return (
		<Card>
			<Card.Header
				className="bg-warning fw-bold"
				style={{ fontWeight: 600 }}
			>
				Vendor has stated they've been in contact with
			</Card.Header>
			<ListGroup>
				{agents.map(a =>
					<ListGroupItem key={a}>
						<a
							href={adminPanelURL("/branch/" + a.id + "/edit")}
							target="_blank"
							rel="noreferrer"
						>
							{a.name}
						</a>
						{shortlist.some(b => b.id === a.id) ?
								 <span className="text-success"> - on shortlist</span>
							: null}
					</ListGroupItem>
				)}
			</ListGroup>
		</Card>
	);
};

export default PriorAgents;
