import React from "react";
import { Nav, Navbar, Col, Form, InputGroup, NavDropdown } from "react-bootstrap";
import moment from "moment";
import { LinkContainer } from "react-router-bootstrap";

class WagyuNav extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {dateRange: "Today"};
	}

	render()
	{
		const {dateRange} = this.state;

		const {
			fromDate,
			toDate,
			setFromDate,
			setToDate
		} = this.props;

		const handleToday = () =>
		{
			setFromDate(moment().format("YYYY-MM-DD"));
			setToDate(moment().format("YYYY-MM-DD"));
			this.setState({dateRange: "Today"});
		};

		const handleYesterday = () =>
		{
			setFromDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
			setToDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
			this.setState({dateRange: "Yesterday"});
		};

		const handleThisWeek = () =>
		{
			setFromDate(moment().startOf("week").format("YYYY-MM-DD"));
			setToDate(moment().endOf("week").format("YYYY-MM-DD"));
			this.setState({dateRange: "This Week"});
		};

		const handleLastWeek = () =>
		{
			setFromDate(moment().subtract(1, "week").startOf("week")
				.format("YYYY-MM-DD"));
			setToDate(moment().startOf("week").subtract(1, "day")
				.format("YYYY-MM-DD"));
			this.setState({dateRange: "Last Week"});
		};

		const handleTwoWeeks = () =>
		{
			setFromDate(moment().subtract(2, "week").startOf("week")
				.format("YYYY-MM-DD"));
			setToDate(moment().startOf("week").subtract(1, "day")
				.format("YYYY-MM-DD"));
			this.setState({dateRange: "Last Two Weeks"});
		};

		const handleFourWeeks = () =>
		{
			setFromDate(moment().subtract(4, "week").startOf("week")
				.format("YYYY-MM-DD"));
			setToDate(moment().startOf("week").subtract(1, "day")
				.format("YYYY-MM-DD"));
			this.setState({dateRange: "Last Four Weeks"});
		};

		return (
			<Navbar bg="light" expand="xl" className="mb-3">
				<Navbar.Brand className="ml-4">
					Wagyu
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="wagyu-navbar" />
				<Navbar.Collapse id="wagyu-navbar">
					<Nav navbar className="ml-4 mr-auto">
						<LinkContainer to="/vendor/wagyu/dashboard">
							<Nav.Link>Dashboard</Nav.Link>
						</LinkContainer>
						<LinkContainer to="/vendor/wagyu/calls">
							<Nav.Link>Completed calls</Nav.Link>
						</LinkContainer>
						<LinkContainer to="/vendor/wagyu/outstanding">
							<Nav.Link>Outstanding calls</Nav.Link>
						</LinkContainer>
						<LinkContainer to="/vendor/wagyu/backlog">
							<Nav.Link>Vendors waiting for contact</Nav.Link>
						</LinkContainer>
					</Nav>

					<Nav navbar className="ml-4 mr-auto">
						<NavDropdown title={dateRange}>
							<NavDropdown.Item onClick={handleToday}>Today</NavDropdown.Item>
							<NavDropdown.Item onClick={handleYesterday}>Yesterday</NavDropdown.Item>
							<NavDropdown.Item onClick={handleThisWeek}>This Week</NavDropdown.Item>
							<NavDropdown.Item onClick={handleLastWeek}>Last Week</NavDropdown.Item>
							<NavDropdown.Item onClick={handleTwoWeeks}>Last 2 Weeks</NavDropdown.Item>
							<NavDropdown.Item onClick={handleFourWeeks}>Last 4 Weeks</NavDropdown.Item>
						</NavDropdown>
					</Nav>

					<Nav navbar>
						<Form inline>
							<Form.Row>
								<Col>
									<InputGroup size="sm">
										<InputGroup.Prepend>
											<InputGroup.Text >from</InputGroup.Text>
										</InputGroup.Prepend>
										<Form.Control
											type="date"
											onChange={(e) => setFromDate(e.target.value)}
											value={fromDate}
											size="sm"
										/>
									</InputGroup>
								</Col>
								<Col>
									<InputGroup size="sm">
										<InputGroup.Prepend>
											<InputGroup.Text >to</InputGroup.Text>
										</InputGroup.Prepend>
										<Form.Control
											type="date"
											onChange={(e) => setToDate(e.target.value)}
											value={toDate}
											size="sm"
										/>
									</InputGroup>
								</Col>
							</Form.Row>
						</Form>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	}
}

export default WagyuNav;