import React from "react";
import {Card, Row, Col, FormLabel, FormGroup, Form, Button} from "react-bootstrap";
import _ from "underscore";
import moment from "moment";
import EditableText from "components/shared/editableText";
import { GAConfigContext } from "contexts/gaConfigContext";
import PhoneNumber from "awesome-phonenumber";
import {FaPhone, FaTimes, FaEnvelope} from "react-icons/fa";
import { getAPI, postAPI } from "utils/requestAPI";
import { adminPanelURL } from "utils/common";


class UserEdit extends React.Component
{
	constructor(props)
	{
		super(props);

		this._fields = [
			"email",
			"name",
			"mobile",
			"unsubscribed",
			"id"
		];

		this.state = {
			user: _.pick(props.user, this._fields),
			editing: false
		};

		this._origUser = _.pick(props.user, this._fields);

		this._belongsToVendorGroup = props.user.group == 10;
	}

	static contextType = GAConfigContext;

	componentWillReceiveProps(nextProps)
	{
		this.setState({user: _.pick(nextProps.user, this._fields)});

		this._origUser = _.pick(nextProps.user, this._fields);
	}

	valueChanged(name, e)
	{

		const user = Object.assign({}, this.state.user);

		user[name] = e.target.value;

		this.setState({user: user});
	}

	toggleCheckboxChanged(name, e)
	{
		const user = Object.assign({}, this.state.user);

		user[name] = ! user[name];

		this.setState({user: user});
	}

	startEditing()
	{
		this.setState({editing: true});
	}

	cancelEditing()
	{
		this.setState({
			editing: false,
			user: this._origUser
		});
	}

	checkTps()
	{
		// check tps
		getAPI("/admin/vendor/property/" + this.props.vendorProp.id + "/tpsCheck")
			.then((data) =>
			{

				if(data.tps)
				{
					if(this.props.reloadVendor)
						return this.props.reloadVendor();
					else
						return window.location.reload(true);
				}
				else
				{
					return this.openPhone();
				}
			})
			.catch(error =>
			{
				alert("An unknown error occurred when checking the TPS register: "+ error.message);
			});

	}

	openPhone()
	{
		if(moment(this.props.vendorProp.cs_followup).isAfter(moment()))
		{
			if(! window.confirm("This vendor shouldn't be called until " + moment(this.props.vendorProp.cs_followup).format("Do MMM YYYY") + "!\n\nAre you sure you want to call?"))
				return;
		}

		return window.open(adminPanelURL("/vendor/property/" + this.props.vendorProp.id + "/phone/"), "callwin", "width=500,height=500");
	}

	saveEdit()
	{
		/* Users that aren't in vendor group shouldn't be edited from the vendor page */

		if(! this._belongsToVendorGroup)
		{
			alert("The user you are trying to edit is not a Vendor.");
			return;
		}

		// check for changes
		const changed = {};

		_.each(this._fields, (f) =>
		{
			if(this.state.user[f] !== this._origUser[f])
				changed[f] = this.state.user[f];
		});

		if(Object.keys(changed).length === 0)
		{
			alert("Nothing has changed!");
			return;
		}

		if(typeof changed.email !== "undefined")
		{
			const isEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			if(!isEmail.test(changed.email.trim()))
			{
				alert("Email address is not valid");
				return;
			}
		}

		if((typeof changed.unsubscribed !== "undefined") && (! changed.unsubscribed))
			changed.unsubscribed = null;

		// save to server
		postAPI("/admin/user/" + this.state.user.id + "/edit?patch=1", changed)
			.then((resp) =>
			{

				this._origUser = Object.assign({}, this.state.user);

				this.setState({editing: false});
			})
			.catch(err =>
			{
				alert(err.message || "An unknown error occurred");
			});
	}

	async removeDetails()
	{
		try
		{
			const confirmText = prompt("Are you absolutely sure you want to permanently remove the details of this user? If so, please type \"Confirm\"");

			if(!confirmText)
				return;

			if(confirmText === "Confirm")
			{
				await postAPI(`/admin/vendor/${this.props.vendorProp.id}/remove`);
				window.location.reload();
			}
			else
			{
				alert("Please try again and make sure to type \"Confirm\" exactly");
			}
		}
		catch (err)
		{
			alert((err.message || "Details couldn't be removed.") + "\nPlease try again or email bugs@getagent.co.uk");
		}
	}

	render()
	{

		const isNumberValid = () =>
		{
			if(! this.state.user)
				return false;

			try
			{
				const phoneNumber = new PhoneNumber(this.state.user.mobile, "GB");

				return phoneNumber.isValid();
			}
			catch (err)
			{
				return false;
			}

		};

		let panelFooter = null;

		if(this.state.editing === true)
		{
			panelFooter = (
				<Row>
					<Col>
						<Button onClick={this.cancelEditing.bind(this)}>Cancel</Button>
					</Col>
					<Col className="text-center">
						<Button onClick={() => this.removeDetails()} variant="danger">Remove details</Button>
					</Col>
					<Col>
						<Button onClick={this.saveEdit.bind(this)} className="float-right">Save</Button>
					</Col>
				</Row>
			);
		}
		else
		{
			panelFooter = (
				<Button onClick={this.startEditing.bind(this)}>Edit</Button>
			);
		}


		return (
			<Card className={this.props.className}>
				<Card.Header>
					{"User details - " + this.state.user.id}
				</Card.Header>
				<Card.Body>
					<Form>
						<Row>
							<Col xs={12} md={6}>
								<FormGroup>
									<Col sm={4}>
										<FormLabel>Name:</FormLabel>
									</Col>
									<Col sm={8}>
										<EditableText
											value={this.state.user.name}
											editing={this.state.editing}
											onValueChanged={this.valueChanged.bind(this, "name")}
										/>
									</Col>
								</FormGroup>
							</Col>
							<Col xs={12} md={6}>
								<FormGroup>
									<Col sm={4}>
										<FormLabel>Email:</FormLabel>
									</Col>
									<Col sm={8}>
										<EditableText
											value={this.state.user.email}
											type="email"
											editing={this.state.editing}
											onValueChanged={this.valueChanged.bind(this, "email")}
											icon={<FaEnvelope />}
											href={"mailto:" + this.state.user.email}
										/>
									</Col>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col xs={12} md={6}>
								<FormGroup className={isNumberValid() ? "" : "has-error"}>
									<Col sm={4}>
										<FormLabel>Phone:</FormLabel>
									</Col>
									<Col sm={8}>
										{this.state.editing ? (
											<EditableText
												value={this.state.user.mobile}
												type="text"
												editing={this.state.editing}
												onValueChanged={this.valueChanged.bind(this, "mobile")}
												icon="phone"
												href={adminPanelURL("/vendor/property/" + this.props.vendorProp.id + "/phone")}
												openLinkInWindow={{name: "callWin", size:"width=500,height=500"}}
												validationState={isNumberValid() ? null : "error"}
											/>):(

											<span className={isNumberValid() ? null : "text-danger"}>
												{this.state.user.mobile} &nbsp;
												{((! this.props.vendorProp.cs_followup) || moment(this.props.vendorProp.cs_followup).isBefore(moment())) && <Button onClick={this.checkTps.bind(this)}>TPS</Button>} &nbsp;
												<Button variant={(moment(this.props.vendorProp.cs_followup).isAfter(moment()) ? "danger" : "primary")} onClick={this.openPhone.bind(this)} disabled={(this.props.vendorProp.referrer == "which") && (this.props.vendorProp.status < this.context.GA_CONFIG.vp.status.VALUATION_REQ)}>
													{moment(this.props.vendorProp.cs_followup).isAfter(moment()) ? <FaTimes></FaTimes> : <FaPhone></FaPhone>}

												</Button>
											</span>
										)}

									</Col>
								</FormGroup>
							</Col>

							<Col xs={12} md={6}>
								<FormGroup>

									<Col sm={8}>


										<Form.Check
											type="checkbox"
											checked ={!! this.state.user.unsubscribed}
											disabled = {! this.state.editing}
											onChange= {this.toggleCheckboxChanged.bind(this, "unsubscribed")}
											label="Unsubscribe from emails"
										/>


									</Col>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col xs={12} md={6}>
								<FormGroup>
									<Col sm={4}>
										<FormLabel>Selling:</FormLabel>
									</Col>
									<Col sm={8}>
										{this.props.vendorProp.eta}
									</Col>
								</FormGroup>
							</Col>

							<Col xs={12} md={6}>
								<FormGroup>
									<Col sm={4}>
										<FormLabel>Signed Up:</FormLabel>
									</Col>
									<Col sm={8}>
										{moment(this.props.vendorProp.created_at).format("ddd Do MMM YYYY, HH:mm")}
									</Col>
								</FormGroup>
							</Col>
						</Row>


					</Form>
				</Card.Body>
				<Card.Footer>
					{panelFooter}
				</Card.Footer>
			</Card>
		);
	}
}

export default UserEdit;