import React, { useEffect, useState } from "react";
import { Row, Card, FormControl, Col, Button, Table, Spinner } from "react-bootstrap";
import _ from "underscore";
import { getAPI } from "../../utils/requestAPI";
import moment from "moment";


const StatCard = ( { title, stat } ) => (
	<Col md={2}>
		<Card className="text-center">
			<Card.Header className="bg-primary text-white text-center">{title}</Card.Header>
			<Card.Body>
				<h2>{stat}</h2>
			</Card.Body>
		</Card>
	</Col>);

const OpsTable = ({stats}) => (
	<Table bordered striped hover size="sm">
		<colgroup align="left">
			<col width="40%" />
			<col width="20%" />
			<col width="20%" />
			<col width="20%" />
		</colgroup>
		<thead>
			<tr className="text-left">
				<th>Name</th>
				<th>Calls made</th>
				<th>Calls with update</th>
				<th>Conversion Rate</th>
			</tr>
		</thead>
		<tbody align="left">
			{stats.map((member, index) =>
			{
				return (
					<tr key={index}>
						<td>{member.name}</td>
						<td>{member.numCalls}</td>
						<td>{member.numUpdates}</td>
						<td>{member.convRate}%</td>
					</tr>
				);
			})}
		</tbody>
	</Table>
);

const OpsTeamConversionRateChart = ({ opsMembersConversionRate }) =>
{

	const renderGraph = () =>
	{
		const data = new window.google.visualization.DataTable();

		data.addColumn("string", "Name");
		data.addColumn("number", "Conversion Rate");

		const result = [];

		const dataCols = ["convRate"];

		dataCols.forEach((data, index) =>
		{
			opsMembersConversionRate.forEach(user =>
			{
				const row = [];

				row.push(user.name);
				row.push(user[data]);

				result.push(row);
			});

		});

		data.addRows(result);

		const options =
		{
		    height: 350,
		    vAxis: { format: "#'%'", minValue: 0, maxValue: 100 },
		    legend: { position: "bottom", alignment: "center" },
		    chartArea: {"width": "85%", "height": "80%"},
		};

		const b_chart = new window.google.visualization.ColumnChart(document.getElementById("ops-conversion-rate"));

		b_chart.draw(data, options);
	};

	window.google.charts.load("current", {packages: ["corechart", "bar", "line"]});
	window.google.charts.setOnLoadCallback(renderGraph);

	return (
		<div id="ops-conversion-rate"></div>
	);
};

const OpsTeamCallsAndUpdatesChart = ({ opsMembersConversionRate }) =>
{

	const renderGraph = () =>
	{
		const data = new window.google.visualization.DataTable();

		data.addColumn("string", "Name");
		data.addColumn("number", "Calls Made");
		data.addColumn("number", "Updates Received");

		const result = [];

		opsMembersConversionRate.forEach(user =>
		{
			const row = [];

			row.push(user.name);
			row.push(user.numCalls);
			row.push(user.numUpdates);

			result.push(row);
		});

		data.addRows(result);

		const options =
		{
		    height: 350,
		    vAxis: { minValue: 0 },
		    legend: { position: "bottom", alignment: "center" },
		    chartArea: {"width": "85%", "height": "80%"},
		};

		const b_chart = new window.google.visualization.ColumnChart(document.getElementById("ops-numCalls"));

		b_chart.draw(data, options);
	};

	window.google.charts.load("current", {packages: ["corechart", "bar", "line"]});
	window.google.charts.setOnLoadCallback(renderGraph);

	return (
		<div id="ops-numCalls"></div>
	);
};


const RevChasingConversionRate = () =>
{
	const [opsStats, setOpsStats] = useState({});
	const [queryDate, setQueryDate] = useState({
		toDate: moment().format("YYYY-MM-DD"),
		fromDate: moment().format("YYYY-MM-DD")
	});
	const [loading, setLoading] = useState(false);

	const reduceStats = (list, key) => list.reduce((a,c) =>  a += c[key], 0);

	const fetchCRStats = async() =>
	{
		setLoading(true);

		try
		{
			const { opsMembersConversionRate } = await getAPI(`/admin/vendor/revChasingConversionRate.json?toDate=${queryDate.toDate}&fromDate=${queryDate.fromDate}`);

			const totalCalls = reduceStats(opsMembersConversionRate, "numCalls");
			const totalUpdates = reduceStats(opsMembersConversionRate, "numUpdates");
			const overallCR = totalCalls && Math.round(((totalUpdates / totalCalls)*100)*10)/10;

			setOpsStats({
				rawData: opsMembersConversionRate, // used for Chart
				totalCalls,
				totalUpdates,
				overallCR
			});

			setLoading(false);
		}
		catch (err)
		{
			alert(err.message || "Request did not work, please try again or tell tech if this happens again.");

			setLoading(false);
		}
	};

	const onDateChange = (e) =>
	{
		setQueryDate({
			...queryDate,
			[e.target.name]: e.target.value
		});
	};

	useEffect(() =>
	{
		fetchCRStats();

	}, []);


	const {
		rawData,
		totalCalls,
		totalUpdates,
		overallCR
	} = opsStats;

	return (
		<Card className="my-5">
			<Card.Header>
				Calls Analytics
				<Col>
				From:
				</Col>
				<Col sm={3}>
					<FormControl type="date" name="fromDate" onChange={onDateChange} value={queryDate.fromDate}/>
				</Col>
				<Col>
				To:
				</Col>
				<Col sm={3}>
					<FormControl type="date" name="toDate" onChange={onDateChange} value={queryDate.toDate}/>
				</Col>
				<Col>
					<Button disabled={loading} onClick={() => fetchCRStats()}>Crunch!</Button>
				</Col>
			</Card.Header>
			<Card.Body>
				{ loading && (<Spinner animation="grow" />)}
				{ (rawData && rawData.length) ?
					<>
						<Row>
							<StatCard title="Total calls made" stat={totalCalls} />
							<StatCard title="With update" stat={totalUpdates} />
							<StatCard title="Team CR %" stat={overallCR + "%"} />
						</Row>
						<Row>
							<Col>
								<OpsTeamCallsAndUpdatesChart opsMembersConversionRate={rawData} />
							</Col>
							<Col>
								<OpsTeamConversionRateChart opsMembersConversionRate={rawData} />
							</Col>
						</Row>
						<Row>
							<OpsTable stats={rawData} />
						</Row>
					</>
					: !loading && ("Please select a timeframe where calls were made. Try to keep timeframe up to 1 month maximum.")
				}
			</Card.Body>
		</Card>
	);
};

export default RevChasingConversionRate;