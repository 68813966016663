import React from 'react';
import { Jumbotron, Col, Row } from 'react-bootstrap';
import _ from 'underscore';
import { FaArrowUp } from 'react-icons/fa';
import DocumentTitle from './documentTitle';

const FourOhFour = (props) => 
{
	const factoids = [	"When GetAgent launched they had to buy the GetAgent.co.uk domain name from a male prostitute service... (that is also where Peter & Colby met).",
						"Colby is named after Colbius Maximus, a Roman General famed for his ginger beard and prowess at closing deals via a wax tablet.",
						"Peter's real name is Anthony, but he only lets people he likes call him that.",
						"Michele claims to have driven for Ferrari, but came 3rd at GetAgent go-karting #awks.",
						"Chad's great great grandfather was the Dutch tug-of-war champion, which is where his surname comes from."]


	return (

		<Jumbotron className="position-relative">
			
			<DocumentTitle title={"Can't find what you're looking for..."} />
			
			<h1 className="display-1">{props.title || "Oh Noes!"}</h1>
			
			{! props.hideSearchPrompt && <p className="position-absolute" style={{right:20, top: 10}}>Try searching here <FaArrowUp /></p> }
			
			<Row>
				<Col sm={12} md={9}>
					<p>{props.subtitle || "We can't find what you're looking for..."}</p>
				</Col>
				

				{! props.hideFacts && 
				<Col sm={12} md={9} className="mt-5">
					<h5>Did you know:</h5>
					<p>{_.shuffle(factoids)[0]}</p>
					
				</Col>
				}
			</Row>

		</Jumbotron>

	);

}


export default FourOhFour;