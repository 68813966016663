import React from "react";
import { Alert, Row, Col, ListGroup, ListGroupItem, FormControl, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "underscore";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import DocumentTitle from "../shared/documentTitle";
import { getAPI, postAPI } from "utils/requestAPI";

class ValReqEmail extends React.Component
{
	constructor(props)
	{

		super(props);

		this.state = {
			loading: true,
			error: false,
			errorMsg: "",
			disabled: false,
			ppi: false,
			// fields from the json request
			contactAdmins: null,
			vendorProp: null,
			valMsg: "",
			valTime: "",
			branchQuote: null,
			vendorPhoneNumberDisabled: false
		};

		this.fetchData = this.fetchData.bind(this);
	}

	static contextType = GAConfigContext;

	componentDidMount()
	{
		this.fetchData();
	}

	fetchData()
	{
		getAPI(`/admin/vendor/property/${this.props.match.params.vpId}/edit.json`)
			.then(({ vendorProp }) =>
			{
			// Check branch exists on vendor shortlist
				const valReqBranch = _.find(vendorProp.branches, br => br.id == this.props.match.params.branchId);

				if(! valReqBranch)
				{
					this.setState({
					loading: false,
					error: true,
					errorMsg: "This branch is not on the property shortlist."
					});

					return true;
				}

				// Add the extra validation

				if((! valReqBranch.admins) || (valReqBranch.admins.length == 0))
				{
					this.setState({
					loading: false,
					error: true,
					errorMsg: "This branch hasn't signed up yet, so an automated email can't be sent."
					});

					return true;
				}

				const contactAdmins = valReqBranch.admins.filter(admin => admin["_pivot_send_val_req_email"] != 0);

				if(! contactAdmins || contactAdmins.length == 0)
				{
					this.setState({
					loading: false,
					error: true,
					errorMsg: "None of the branch admins are signed up to receive valuation emails, so an automated email can't be sent"
					});

					return true;
				}

				if(vendorProp.status >= this.context.GA_CONFIG.vp.status.SALE_COMPLETE)
				{
					this.setState({
					loading: false,
					error: true,
					errorMsg: "The vendor status seems dodgy (***do not send valuations to spammed vendors***)."
					});

					return true;
				}


				if(valReqBranch["_pivot_status"] != this.context.GA_CONFIG.vp.branchStatus.VALUATION_REQ)
				{
					this.setState({
					loading: false,
					error: true,
					errorMsg: "This branch does not have a status of valuation requested."
					});

					return true;
				}

				if(vendorProp.archive !== null)
				{
					this.setState({
					loading: false,
					error: true,
					errorMsg: "This vendor is archived - please unarchive before sending vals."
					});

					return true;
				}

				// Set everything in state
				this.setState({
				contactAdmins,
				vendorProp,
				branch: valReqBranch,
				branchQuote: valReqBranch["_pivot_quote"],
				ppi: !! valReqBranch.pay_per_val,
				valMsg: "",
				loading: false,
				vendorPhoneNumberDisabled: !!valReqBranch._pivot_vendor_phone_disabled
				});

			})
			.catch(e => alert("Request failed to load the necessary data. Please try again."));
	}


	getFormInput(e)
	{
		this.setState({valMsg: e.target.value});
	}

	dateChange(e)
	{
		this.setState({valTime: e.target.value});
	}

	sendValReqEmail()
	{

		const { valMsg, valTime, vendorProp, branch, vendorPhoneNumberDisabled } = this.state;
		const dat = {};

		if(valMsg)
			dat.msg = valMsg;

		if(valTime)
			dat.time = valTime;

		dat.vendorPhoneNumberDisabled = vendorPhoneNumberDisabled ? 1 : 0;

		postAPI(`/admin/vendor/property/${vendorProp.id}/valReqEmail/${branch.id}`, dat)
			.then(data =>
			{
				this.props.history.push("/vendor/property/" + vendorProp.id + "/edit");
			})
			.catch(e =>
			{
				alert((e || "Something went wrong! Not sure what, soz") + "\n\nThis is an error. Ask Peter if you are unsure what's what.");
				this.setState({ disabled: false });
			});
	}

	onhideVendorPhoneChange(e)
	{
		const { vendorProp } = this.state;
		const vendor_phone_disabled = e.target.checked ? 1 : 0;
		const branchIds = {};

		vendorProp.branches = _.map(vendorProp.branches, br =>
		{
			if(br.id == this.props.match.params.branchId)

				br._pivot_vendor_phone_disabled = vendor_phone_disabled;

			branchIds["branch-" + br.id] = "on";

			return br;
		});

		this.setState({ vendorProp: vendorProp, vendorPhoneNumberDisabled: vendor_phone_disabled });
	}

	render()
	{

		const alertComponent = errMsg =>
		{
			return (
				<Alert variant="danger">
					<strong>{errMsg ? "Sorry, but the email can't be sent:" : ""}</strong> {errMsg ? errMsg : "This branch is on the PPI model. Make sure you've got a time & date for the valuation!"}
					<p>
						<Link to={"/vendor/property"}>Back to Vendor CRM</Link> | &nbsp;
						{this.state.vendorProp && <Link to={`/vendor/property/${this.state.vendorProp.id}/edit`}>Edit Vendor</Link>}
					</p>
				</Alert>
			);
		};

		if(this.state.loading)
			return <Alert><strong>Loading...</strong></Alert>;

		if(this.state.error)
			return alertComponent(this.state.errorMsg);

		const { vendorProp } = this.state;
		const valReqBranch = _.find(vendorProp.branches, br => br.id == this.props.match.params.branchId);

		return (
			<>
				{this.state.ppi && alertComponent(false)}

				<DocumentTitle title={"Send intro from " + this.state.vendorProp.user.name + " to " + this.state.branch.name} />

				<h1 className="mb-5 mt-3">Send Valuation Request</h1>

				<Row className="mb-3">
					<Col md={6}>
						<h4 className="mb-3">
							Vendor - <Link to={`/vendor/property/${this.state.vendorProp.id}/edit`}>edit</Link>:
						</h4>
						<ListGroup>
							<ListGroupItem>Vendor name: <strong>{this.state.vendorProp.user.name}</strong></ListGroupItem>
							<ListGroupItem>
								Vendor phone: <strong>{this.state.vendorPhoneNumberDisabled ? "N/A" : this.state.vendorProp.user.mobile}</strong>
								<Form.Group controlId="hideVendorPhoneNumber" className="mb-0 mt-1">
									<Form.Check type="checkbox"
										checked={this.state.vendorPhoneNumberDisabled}
										onChange={this.onhideVendorPhoneChange.bind(this)}
										label={"Don't allow agent to call vendor"} />
								</Form.Group>
							</ListGroupItem>
							<ListGroupItem>Vendor email: <strong>{this.state.vendorProp.user.email}</strong></ListGroupItem>
							<ListGroupItem>Address: <strong>{this.state.vendorProp.address1}</strong></ListGroupItem>
							<ListGroupItem>
								<p>
									Message from GetAgent <strong>(do not start with "Message from GetAgent")</strong>:
								</p>
								<FormControl
									as="textarea"
									rows="10"
									value={this.state.valMsg}
									onChange={this.getFormInput.bind(this)}
								/>
							</ListGroupItem>
							<ListGroupItem>
								<p>
									Don't nag agent until:
								</p>
								<FormControl
									type="date"
									onChange={this.dateChange.bind(this)}
									value={this.state.valTime}
								/>
							</ListGroupItem>
						</ListGroup>
					</Col>
					<Col md={6}>
						<h4 className="mb-3">{this.state.branch.name} - <Link to={`/branch/${this.state.branch.id}/edit`}>edit</Link>:</h4>
						<ListGroup>
							{this.state.contactAdmins.map((contactAdmin, index) =>
								<React.Fragment key={index}>
									{ index == 0 && <ListGroupItem>Branch admin name: <strong>{contactAdmin.name}</strong> - <Link to={`/user/${contactAdmin.id}/edit`}>edit</Link></ListGroupItem>}
									{index == 0 && <ListGroupItem>Branch admin email: <strong>{contactAdmin.email}</strong> - <Link to={`/user/${contactAdmin.id}/edit`}>edit</Link></ListGroupItem>}
									{index > 0 && <ListGroupItem>Will also email: <strong>{contactAdmin.email}</strong> - <Link to={`/user/${contactAdmin.id}/edit`}>edit</Link></ListGroupItem>}
								</React.Fragment>
							)}
							{! this.state.branchQuote && <ListGroupItem variant="danger">Watch out - this branch hasn't quoted. Everything <i>should</i> still work, but if you meant to put in a quote, do so before sending the valuation.</ListGroupItem>}
						</ListGroup>
					</Col>
				</Row>
				<Row className="mb-3">
					<Col md={3}>
						<Link to={"/"}>Back to homepage</Link>
					</Col>
					<Col md={3}>
						<Button
							disabled={this.state.disabled}
							className="float-right"
							variant="success"
							onClick={this.sendValReqEmail.bind(this)}
						>
							Send email
						</Button>
					</Col>
				</Row>
			</>
		);
	}
}

export default ValReqEmail;