import React, { useState } from "react";
import { Button, Modal, Badge, Table, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { CallTranscription } from "../shared/callTranscription";
import moment from "moment";
import { FaHeadphonesAlt, FaRegCopy, FaStar, FaRegStar } from "react-icons/fa";

const SCORE_CONFIG = [
	{
    key: "introduction",
    title: "Introduction",
    scores: [
    	{
          key: "clear_introduction",
          label: "Did agent introduce the call and GetAgent clearly?",
          availableScore: 1,
    	},
    	{
          key: "confirmed_address",
          label: "Did agent confirm the vendors correct address?",
          availableScore: 3,
    	},
    ]
	},
	{
    key: "pitch",
    title: "Pitch",
    scores: [
    	{
          key: "rule_of_3",
          label: "Did agent discuss the rule of three and why comparing agents is advised?",
          availableScore: 1,
    	},
    	{
          key: "good_rapport",
          label: "Did agent build good rapport with the customer and add value?",
          availableScore: 2,
    	},
    	{
          key: "showed_empathy",
          label: "Did they give reassurance and if needed give empathy towards the customer?",
          availableScore: 2,
    	},
    	{
          key: "confirmed_sell_date",
          label: "Did agent ask when they want to be on the market/sold by?",
          availableScore: 1,
    	},
    	{
          key: "found_selling_reason",
          label: "Did the CS agent find out the reason for selling/potentially selling?",
          availableScore: 2,
    	},
    	{
          key: "explained_data_source",
          label: "Did agent explain the source of data to add credibility to GA?",
          availableScore: 2,
    	},
    	{
          key: "confirmed_chain_status",
          label: "Did agent ask if they are purchasing onwards or selling chain free?",
          availableScore: 1,
    	},
    	{
          key: "justified_recommendations",
          label: "Did they justify why they recommended the agents on the shortlist?",
          availableScore: 2,
    	},
    	{
          key: "confirmed_if_had_valuations",
          label: "Did agent ask if they have had valuations and when?",
          availableScore: 1,
    	},
    	{
          key: "discussed_market_conditions",
          label: "Did agent discuss the current market conditions and elaborate?",
          availableScore: 1,
    	},
    	{
          key: "confirmed_if_spoken_to_agents",
          label: "Did agent ask if the vendor had spoken to any agents yet?",
          availableScore: 3,
    	},
    ]
	},
	{
    key: "close",
    title: "Close",
    scores: [
    	{
          key: "double_close",
          label: "Was there a clear double close confirming that the agents will be contacting the vendor?",
          availableScore: 3,
    	},
    	{
          key: "vendor_onboard",
          label: "Was the vendor fully onboard and aware that the agents are going contact them to book a valuation with the view to sell in a realistic time frame?",
          availableScore: 3,
    	},
    	{
          key: "vendor_understood",
          label: "Did the vendor fully understand the next steps?",
          availableScore: 2,
    	},
    	{
          key: "six_steps_followed",
          label: "Were the six steps of the structure followed?",
          availableScore: 1,
    	},
    	{
          key: "non_ambiguious_close",
          label: "Was there an unambiguious close?",
          availableScore: 2,
    	},
    	{
          key: "minimum_call_time_met",
          label: "Was the call longer than the minimum time of three minutes?",
          availableScore: 2,
    	}
    ]
	},
];

const AVAILABLE_SCORE = SCORE_CONFIG.reduce((acc, section) =>
	acc + section.scores.reduce((acc, score) => acc + score.availableScore, 0), 0);

const getScoreColour = (score) =>
{
	if(score >= (AVAILABLE_SCORE * 0.85))
		return "#00d97e";

	if(score >= (AVAILABLE_SCORE * 0.55))
		return "#f6c343";


	if(score < (AVAILABLE_SCORE * 0.55))
		return "#e63757";
};

const getScorePercentage = (score) => Math.round(score / AVAILABLE_SCORE * 100) + "%";

const getScoreMarkText = (score) => " (" + (score.availableScore > 1 ? score.availableScore + " marks" : score.availableScore + " mark") + ")";

const CallDetails = ({ call }) =>
{
	const minutes = Math.floor(call.duration / 60);
	const seconds = call.duration - minutes * 60;

	const renderTooltip = (props) => (
		<Tooltip {...props}>
			{call.address1}
		</Tooltip>
	);

	return (
		<div className="mb-4">
			<span className="position-relative pr-3">
				<OverlayTrigger
					placement="top"
					overlay={renderTooltip}
				>
					<span style={{ cursor: "pointer" }}>
						{call.vendor_name}
					</span>
				</OverlayTrigger>
				{" "}
				{/* eslint-disable-next-line react/jsx-no-target-blank */}
					(<a href={`/vendor/property/${call.vendor_prop_id}/edit`} target="_blank">
					{call.vendor_prop_id}
				</a>)
			</span>
      |
			<span className="px-3">
          CS: {call.name}
			</span>
      |
			<span className="px-3">
				{moment(call.called_at).format("ddd Do MMM YYYY, HH:mm")}
			</span>
      |
			<span className="px-3">
          Duration: {minutes + ":" + seconds}
			</span>
		</div>
	);
};

const Scorecard = ({ call }) =>
{
	const renderTooltip = (props, section, score) => (
		<Popover style={{ width: "100%" }} {...props}>
			<Popover.Title as="h3">{score.key + "_justification"}</Popover.Title>
			<Popover.Content>
				{call.qa[section.key][score.key + "_justification"]}
			</Popover.Content>
		</Popover>
	);

	return SCORE_CONFIG.map((section) => (
		<>
			<h3 className="mb-3">{section.title}</h3>
			<Table striped bordered hover>
				<tbody>
					{section.scores.map((score, index) => (
						<tr key={index}>
							<td>{score.label + getScoreMarkText(score)}</td>
							<td className="text-center" style={{
                  width: "10%",
                  backgroundColor: call.qa[section.key][score.key] == 1 ? "lightgreen" : "#e36666",
							}}>
								 <OverlayTrigger
									placement="right"
									overlay={(props) => renderTooltip(props, section, score)}
								>
									<div style={{ cursor: "pointer" }}>
										{(call.qa[section.key][score.key] == 1 ? "Yes" : "No")}
									</div>
								</OverlayTrigger>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	));
};

const Feedback = ({ call }) => (
	<div>
		<div className="mb-4">
			<h3 className="mb-3">Good points</h3>
			<ul className="list-group">
				{call.qa.good_points.split("\n").map((line, index) => (
					<li key={index} className="list-group-item">{line}</li>
				))}
			</ul>
		</div>
		<div className="mb-4">
			<h3 className="mb-3">Constructive points</h3>
			<ul className="list-group">
				{call.qa.constructive_points.split("\n").map((line, index) => (
					<li key={index} className="list-group-item">{line}</li>
				))}
			</ul>
		</div>
		<div className="mb-4">
			<h3 className="mb-3">Summary</h3>
			<div className="bg-light p-3 rounded">
				{call.qa.summary.split("\n").map((line, index) => (
					<p key={index} className="mb-2">{line}</p>
				))}
			</div>
		</div>
	</div>
);


const CallRecording = ({ call }) =>
{
	const [isShown, setIsShown] = useState(false);

	const renderPopover = (props) => (
		<Popover style={{ width: "100%"}} {...props} id="button-tooltip">
			<Popover.Title as="h3">transcription_score_justification</Popover.Title>
			<Popover.Content>
				{call.qa.transcription_score_justification}
			</Popover.Content>
		</Popover>
	);

	return (
		<div className="position-sticky bg-white w-100" style={{ bottom: 0 }}>
			<div
				className="position-relative flex justify-content-center align-items-center p-3 w-100 border-top"
				style={{ cursor: "pointer" }}
				onClick={() => setIsShown(!isShown)}
			>
				<div className="text-center">
            Call recording & transcript
					<Button className="ml-3" variant="secondary">{isShown ? "Hide" : "Show"}</Button>
				</div>
				{call.qa.transcription_score ?
					<div className="position-absolute" style={{ top: 10, right: 10 }}>
						<OverlayTrigger
							placement="right"
							overlay={renderPopover}
						>
							<div>
								<div className="text-center">
									{[1, 2, 3, 4, 5]
										.map((score) =>
											call.qa.transcription_score >= score ? <FaStar style={{ color: "#f6c343" }} /> : <FaRegStar />
										)}
								</div>
                    Transcription quality
							</div>
						</OverlayTrigger>
					</div>
					: null}
			</div>
			{isShown ?
				<div className="position-relative w-100 overflow-auto" style={{ maxHeight: "300px" }}>
    					{call.recording && (
    						<div call="mb-4">
    							<audio className="audio-css w-100" controls preload="none" volume="1.0">
								Your browser does not support the <code>audio</code> element.
    								<source src={call.recording} type="audio/mp3" />
    							</audio>
    						</div>
    					)}
					{call.transcription && (
						<CallTranscription call={call} />
					)}
				</div>
				: null}
		</div>
	);
};

const FujisanCallQAModal = ({ call, onClose }) =>
{
	const copyScorecardToClipboard = async() =>
	{
		const clipboardText = SCORE_CONFIG.map((section) =>
		{
			return section.title + "\n" +
        section.scores.map((score) =>
        	(score.label + getScoreMarkText(score) + "\t") + (call.qa[section.key][score.key] == 1 ? "Yes" : "No")
        )
        	.join("\n");
		}).join("\n\n");

		await navigator.clipboard.writeText(clipboardText);

		alert("Scorecard copied to clipboard! You can now paste it into Google Sheets");
	};

	return (
		<Modal show={true} onHide={onClose} size="lg" centered>
			<Modal.Header closeButton className="pb-0">
				<Modal.Title className="w-100 d-flex justify-content-between">
					<div className="flex-grow-1">
        AI QA Summary <Badge variant="primary" ><FaHeadphonesAlt style={{ marginRight: "5px" }} />{call.call_type}</Badge>
						<div className="mt-2 text-secondary" style={{ fontSize: "10px" }}>
              This report is AI generated and may not be fully correct
						</div>
					</div>
					<div style={{ fontSize: "20px" }}>
						<Badge style={{ color: "white", backgroundColor: getScoreColour(call.total_score) }}>
							{getScorePercentage(call.total_score)}
						</Badge>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="px-4 pt-3 pb-0">
				<CallDetails call={call}/>
				<Scorecard call={call} />
				<Feedback call={call} />
			</Modal.Body>
			<CallRecording call={call}/>
			<Modal.Footer>
				<Button variant="success" onClick={copyScorecardToClipboard}>
					<FaRegCopy /> Copy scorecard
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const FujisanCallQAModalButton = ({ call, onClick }) => (
	<td
		onClick={onClick}
		style={{
      backgroundColor: getScoreColour(call.total_score),
      cursor: "pointer"
		}}
	>
		{getScorePercentage(call.total_score)}
	</td>
);

export { FujisanCallQAModal, FujisanCallQAModalButton };
