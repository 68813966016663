import React from "react";
import _ from "underscore";
import EditableText from "../shared/editableText";
import { gaNiceFormatPrice } from "../../utils/common";
import { Row, Col, FormControl, Button, Form, InputGroup } from "react-bootstrap";
import { postAPI } from "utils/requestAPI";
import { GAConfigContext } from "../../contexts/gaConfigContext";

class CreditNoteOrDiscountOrVoid extends React.Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			editing: false,
			invoiceAmount: 0,
			invoiceDescription: "",
			activeInvoice: null,
			dropDownValue: "",
			writeOffActive: false,
			discountActive: false,
			voidActive: false,
			hasMultipleInvoices: false,
			staticEditing: false,
			loading: false,
			// the below, used for Extra Fees Journey
			dueDays: null,
			barclaysRate: 0.5,
			liborRate: 4,
			interestIncome: 0,
			shouldAddInterestFee: false,
			shouldAddNonNotificationFee: false,
			shouldAddMCOL: false,
			shouldAddCourtFee: false,
			feeMCOL: 0,
			feeCourt: 0,
			nonNotificationFee: 95,
		};

		// Renders the options Write Off, Discount, Void
		this.renderInvoiceEditOptions = this.renderInvoiceEditOptions.bind(this);

		// If multiple invoices attached to a Vendor, set the selected one to state
		this.setActiveInvoice = this.setActiveInvoice.bind(this);

		// When amount is edited on an invoice, regardless of update type
		this.onInvoiceAmountChange = this.onInvoiceAmountChange.bind(this);

		// When description is updated, regardless of update type
		this.onInvoiceDescriptionChange = this.onInvoiceDescriptionChange.bind(this);

		// Takes everything in an sends to BE where allocation begins and finally synced with Xero
		this.sendUpdatedInvoiceToXero = this.sendUpdatedInvoiceToXero.bind(this);

		// Separate the discount logic as we do not need a big post object
		this.onVoidInvoice = this.onVoidInvoice.bind(this);

		// Helper for manually overwriting dueDays
		this.onDueDaysChange = this.onDueDaysChange.bind(this);

		// Plugs in formula for calculating IR
		this.calcInterestIncome = this.calcInterestIncome.bind(this);

		// Toggles adding MCOL fee and amount
		this.onMCOLChange = this.onMCOLChange.bind(this);

		// Same
		this.onNonNotificationFeeChange = this.onNonNotificationFeeChange.bind(this);

		// Posts a request to API to create EFI. This should always map 1:1 with an existing revenue invoice
		this.createExtraFeesInvoice = this.createExtraFeesInvoice.bind(this);

		this.onCourtFeeChange = this.onCourtFeeChange.bind(this);
	}

	static contextType = GAConfigContext;

	sendUpdatedInvoiceToXero(e)
	{


		if(!this.state.dropDownValue)
		{
			alert("Please select an invoice id from the dropdown.");
			return;
		}

		if(!this.state.invoiceAmount)
		{
			alert("Please supply an amount to credit.");
			return;
		}

		if(!this.state.invoiceDescription)
		{
			alert("Please supply a description");
			return;
		}

		this.setState({loading: true});

		const postObj = {};

		postObj.invoiceDescription = this.state.invoiceDescription;
		postObj.invoiceAmount = parseFloat(this.state.invoiceAmount);

		const invoiceIdForPost = this.state.dropDownValue;

		if(this.state.writeOffActive)
		{

			postAPI(`/admin/invoice/${invoiceIdForPost}/writeoff`, postObj)
				.then(resp =>
				{
					alert("Successfully synced Database.");

					if(this.props.reloadVendor)

						this.props.reloadVendor();


					this.setState({loading: false});
				})
				.catch(e =>
				{
					alert((e) || "An unknown error occured.");

					this.setState({ loading: false });
				});

		}

		if(this.state.discountActive)
		{
			postAPI(`/admin/invoice/${invoiceIdForPost}/discount`, postObj)
				.then(resp =>
				{
					if(resp.success)
					{
						alert("Successfully synced Database.");

						if(this.props.reloadVendor)

							this.props.reloadVendor();


						this.setState({loading: false});
					}
					else
					{
						alert(resp.msg || "An unknown error occured.");

						this.setState({ loading: false });
					}
				});

		}

		// wrap up & reset state
		this.cancelEditInvoice();
	}

	createExtraFeesInvoice()
	{

		this.setState({loading: true});

		if(!this.state.extraFees)
			return alert("Looks like you are trying to create an extra fees invoice but you are not in the correct state.");

		if(!this.state.dropDownValue)
		{
			alert("Please first select an invoice to apply extra fees to.");
			return;
		}

		const invoiceIdForPost = this.state.dropDownValue;

		const postObj = {};

		if(this.state.shouldAddInterestFee)
			postObj.interestIncome = this.state.interestIncome;

		if(this.state.shouldAddNonNotificationFee)
			postObj.nonNotificationFee = this.state.nonNotificationFee;

		if(this.state.feeMCOL)
			postObj.feeMCOL = this.state.feeMCOL;

		if(this.state.feeCourt)
			postObj.feeCourt = this.state.feeCourt;

		// the post
		postAPI(`/admin/invoice/${invoiceIdForPost}/extraFees`, postObj)
			.then(resp =>
			{

				alert("Create extra fees invoice as a draft success!");

				if(this.props.reloadVendor)

					this.props.reloadVendor();


				this.setState({loading: false});

			})
			.catch(e =>
			{
				alert(e.message || "An unknown error occured.");
				this.setState({ loading: false });
			});

		this.cancelEditInvoice();
	}

	onVoidInvoice(e)
	{
		this.setState({loading: true});

		if(!this.state.dropDownValue)
		{
			alert("Please select an invoice id from the dropdown.");
			return;
		}

		const invoiceIdForPost = this.state.dropDownValue;

		if(this.state.voidActive)
		{
			postAPI(`/admin/invoice/${invoiceIdForPost}/void`)
				.then(resp =>
				{

					alert("Successfully synced Database.");

					if(this.props.reloadVendor)

						this.props.reloadVendor();


					this.setState({loading: false});

				})
				.catch(e =>
				{
					alert(e.message || "An unknown error occured.");
					this.cancelEditInvoice();
				});
		}

		this.cancelEditInvoice();

	}

	onInvoiceAmountChange(e)
	{
		this.setState({invoiceAmount: e.target.value}, () => this.calcInterestIncome());
	}

	onMCOLChange(e)
	{
		this.setState({feeMCOL: parseFloat(e.target.value)});
	}

	onCourtFeeChange(e)
	{
		this.setState({feeCourt: parseFloat(e.target.value)});
	}

	onNonNotificationFeeChange(e)
	{
		this.setState({nonNotificationFee: parseInt(e.target.value)});
	}

	onInvoiceDescriptionChange(e)
	{
		this.setState({invoiceDescription: e.target.value});
	}

	setActiveInvoice(e)
	{

		// we loop through invoices and narrow it to the dropdown selection to pass a pre-filled default amount to writeOff

		// Sum up all DB rows to see exactly what's left to writeoff

		let remainingAmount = 0;
		let remainingTax = 0;
		let dueDays = 0;

		this.props.vendorProp.billings.forEach(billingTransRow =>
		{
			// you might have 2 invoices that are type 100 or two invoices 1 is type 100 and one is 95 (other invoice)

			// Making sure we're looping through correct invoice

			if(billingTransRow.xero_invoice_id == e.target.value)
			{
				// main invoice
				if(billingTransRow.typeString === "INVOICE")
				{
					dueDays = billingTransRow.dueDays;

					this.setState({dueDays}, () => this.calcInterestIncome());
				}

				remainingAmount += billingTransRow.amount;
				remainingTax += billingTransRow.tax_amount;
			}
		});

		const defaultWriteOff = remainingAmount + remainingTax;

		// We need to do this complicated logic because the £ component expects editable dichotomy

		if(!this.state.staticEditing)

			this.setState({staticEditing: true});


		else if(this.state.staticEditing)

			this.setState({staticEditing: false});


		// Extra fees for interest calculation works out similarly to write off i.e. you only add extra fees on whatever there's left to pay
		if(this.state.writeOffActive || this.state.extraFees)
		{
			this.setState({
				dropDownValue: e.target.value,
				invoiceAmount: defaultWriteOff,
			}, () =>
			{

				this.setState({staticEditing: true});

				this.calcInterestIncome();

			});
		}
		else
		{
			this.setState({dropDownValue: e.target.value,});
		}
	}

	cancelEditInvoice()
	{
		this.setState({
			editing: false,
			writeOffActive: false,
			discountActive: false,
			voidActive: false,
			staticEditing: false,
			invoiceAmount: 0,
			invoiceDescription: "",
			dropDownValue: "",
			isNotFirstDiscount: false,
			extraFees: false,
			interestIncome: 0,
			dueDays: null,
			shouldAddInterestFee: false,
			shouldAddNonNotificationFee: false,
			shouldAddMCOL: false,
			shouldAddCourtFee: false,
			feeMCOL: 0,
			feeCourt: 0
		});
	}

	onDueDaysChange(e)
	{
		this.setState({dueDays: parseInt(e.target.value)}, () => this.calcInterestIncome());
	}

	onRateChange(name, e)
	{
		if(name === "barclaysRate")
		{
			console.log(e.target.value);
			console.log(typeof e.target.value);
			this.setState({barclaysRate: parseFloat(e.target.value)}, () => this.calcInterestIncome());
		}
		else if(name === "liborRate")
		{
			this.setState({liborRate: parseFloat(e.target.value)}, () => this.calcInterestIncome());
		}
	}

	calcInterestIncome()
	{
		// plugs in formula for interest rate calculation
		const interestIncomeToAdd = (this.state.barclaysRate + this.state.liborRate) / 100 * this.state.invoiceAmount / 365 * this.state.dueDays;

		this.setState({interestIncome: Math.floor(interestIncomeToAdd)});
	}

	onEditInvoice(name, e)
	{
		// Start with reseting state so you can only have 1 of the journeys on screen at any point in time
		// This should in theory prevent side effects and unwanted mismatches

		this.cancelEditInvoice();

		const { vendorInvoices, vendorProp } = this.props;

		const hasMultipleInvoices = vendorInvoices.length > 1;

		// Check row types from virtuals to see if we have any discount applied and if true, to see the total of the discount as percentage (and or sum)

		let discountUnitAmount = 0;
		let discountTaxAmount = 0;

		vendorProp.billings.forEach(billingTransRow =>
		{
			if(billingTransRow.typeString === "DISCOUNT")
			{
				discountUnitAmount += billingTransRow.amount;
				discountTaxAmount += billingTransRow.tax_amount;
			}
		});

		const totalDiscount = discountUnitAmount + discountTaxAmount;

		const originalInvoice = _.first(this.props.vendorInvoices).amount +  _.first(this.props.vendorInvoices).tax_amount;

		const discountRateAsPercentage = Math.abs((totalDiscount / originalInvoice)*100); // like 10 for 10%

		// We want to have a flag to display a warning later in the view
		if(discountRateAsPercentage)

			this.setState({isNotFirstDiscount: true});


		if(name === "writeOff")
		{
			this.setState({
				writeOffActive: true,
				editing: true,
			});

		}

		if(name === "discount")
		{
			this.setState({
				discountActive: true,
				editing: true,
				invoiceAmount: discountRateAsPercentage
			});
		}

		if(name === "void")
		{
			this.setState({
				voidActive: true,
				editing: true
			});
		}

		if(name === "extraFees")
		{
			this.setState({
				extraFees: true,
				editing: true,
			});
		}

		if(hasMultipleInvoices)
		{
			this.setState({hasMultipleInvoices: true,});
		}
		else
		{
			if((name === "writeOff") || (name === "extraFees"))
			{
				let remainingAmount = 0;
				let remainingTax = 0;
				let dueDays = 0;

				this.props.vendorProp.billings.forEach(billingTransRow =>
				{

					if(billingTransRow.typeString === "INVOICE")
					{
						dueDays = billingTransRow.dueDays;

						this.setState({dueDays}, () => this.calcInterestIncome());
					}

					// We do not want write off sum to be inflated by extra fees invoices
					if(billingTransRow.typeString === "OTHER_INVOICE")
						return;


					if(billingTransRow.type == 10)
						return;

					remainingAmount += billingTransRow.amount;
					remainingTax += billingTransRow.tax_amount;
				});

				const defaultWriteOff = remainingAmount + remainingTax;

				this.setState({
					dropDownValue: _.first(this.props.vendorInvoices).xero_invoice_id,
					invoiceAmount: defaultWriteOff,
					staticEditing: true
				}, () => this.calcInterestIncome());
			}
			else
			{
				this.setState({
					dropDownValue: _.first(this.props.vendorInvoices).xero_invoice_id,
					staticEditing: true
				});
			}
		}
	}

	renderInvoiceEditOptions(name)
	{
		if(name === "writeOff")
		{
			const multipleInvoices = (
				<Col md={3}>
					<Form.Label>Select invoice to write off:</Form.Label>
					{console.log(this.state.dropDownValue)}
					<FormControl
						as="select"
						placeholder="---- SELECT AN INVOICE-----"
						onChange={this.setActiveInvoice}
						value={this.state.dropDownValue}
					>
						<option
							value={"---- SELECT AN INVOICE-----v"}
						>
								---- SELECT AN INVOICE-----
						</option>

						{_.map(this.props.vendorInvoices, (invoice, index) => <option  value={invoice.xero_invoice_id} key={index}>{invoice.xero_invoice_id}</option> )}

					</FormControl>
				</Col>
			);

			return (
				<Row>
					{this.state.hasMultipleInvoices ? multipleInvoices : null}
					<Col md={3}>
						<Form.Label>
								Amount to write-off:
						</Form.Label>
						<EditableText
							value={(Math.ceil(this.state.invoiceAmount * 100) / 100).toFixed(2)}
							onChange ={this.onInvoiceAmountChange}
							onValueChanged={this.onInvoiceAmountChange}
							editing={this.state.staticEditing}
							formatter={(val) => "£" +gaNiceFormatPrice(val, 2)}
							currency={true}
						>
						</EditableText>

					</Col>
					<Col md={this.state.hasMultipleInvoices ? 4 : 7}>
						<Form.Label>Description:</Form.Label>
						<FormControl
							type="text"
							key="creditNoteDescription"
							placeholder="description"
							value={this.state.invoiceDescription}
							onChange={this.onInvoiceDescriptionChange}
						/>
					</Col>
					<Col md={1}>
						<Button
							style={{marginTop:"32px"}}
							variant="success"
							className="text-center margin-2 width-100"
							onClick={this.sendUpdatedInvoiceToXero}
						>
								Save
						</Button>
					</Col>
					<Col className="pl-0" md={1}>
						<Button
							style={{marginTop:"32px"}}
							variant="danger"
							className="text-center margin-2 width-100"
							onClick={this.cancelEditInvoice.bind(this)}
						>
								Cancel
						</Button>
					</Col>
				</Row>
			);
		}
		else if(name === "discount")
		{

			const multipleInvoices = (
				<Col md={3}>
					<Form.Label>Select invoice to discount:</Form.Label>
					<FormControl as="select" placeholder="---- SELECT AN INVOICE-----" onChange={this.setActiveInvoice} value={this.state.dropDownValue}>
						<option value="---- SELECT AN INVOICE-----">---- SELECT AN INVOICE-----</option>
						{_.map(this.props.vendorInvoices, (invoice, index) => <option value={invoice.xero_invoice_id} key={index}>{invoice.xero_invoice_id}</option> )}
					</FormControl>
				</Col>
			);

			const warningMessage = `Warning, this invoice already has a discount applied. If you want to further discount it, make sure the new discount is greater than ${this.state.invoiceAmount}%`;

			return (
				<Row>
					{this.state.hasMultipleInvoices ? multipleInvoices : null}
					<Col md={3}>
						<Form.Label>
							Percent to discount:
						</Form.Label>
						<EditableText
							value={this.state.invoiceAmount}
							onChange ={this.onInvoiceAmountChange}
							onValueChanged={this.onInvoiceAmountChange}
							editing={this.state.staticEditing}
							percent={true}
						>
						</EditableText>
						{this.state.isNotFirstDiscount ? warningMessage : null}

					</Col>
					<Col md={this.state.hasMultipleInvoices ? 4 : 7}>
						<Form.Label>Description:</Form.Label>
						<FormControl
							type="text"
							placeholder="description"
							value={this.state.invoiceDescription}
							onChange={this.onInvoiceDescriptionChange}
						/>
					</Col>
					<Col md={1}>
						<Button
							style={{marginTop:"32px"}}
							variant="success"
							className="text-center margin-2 width-100"
							onClick={this.sendUpdatedInvoiceToXero}
						>
							Save
						</Button>
					</Col>
					<Col className="pl-0" md={1}>
						<Button
							style={{marginTop:"32px"}}
							variant="danger"
							className="text-center margin-2 width-100"
							onClick={this.cancelEditInvoice.bind(this)}
						>
							Cancel
						</Button>
					</Col>
				</Row>
			);
		}
		else if(name === "void")
		{
			const multipleInvoices = (
				<Col md={3}>
					<Form.Label>Select invoice to void:</Form.Label>
					<FormControl as="select" placeholder="select" onChange={this.setActiveInvoice} value={this.state.dropDownValue}>
						<option value="---- SELECT AN INVOICE-----">---- SELECT AN INVOICE-----</option>
						{_.map(this.props.vendorInvoices, (invoice, index) => <option value={invoice.xero_invoice_id} key={index}>{invoice.xero_invoice_id}</option> )}
					</FormControl>
				</Col>
			);

			return (
				<Row>
					{this.state.hasMultipleInvoices ? multipleInvoices : null}
					<Col md={3}>
						<p>Are you really sure you want to void this invoice?</p>
					</Col>

					<Col md={2}>
						<Button

							variant="success"
							className="text-center margin-2 width-100"
							onClick={this.onVoidInvoice}
						>
							Yes, Void!
						</Button>
					</Col>
					<Col md={2}>
						<Button

							variant="danger"
							className="text-center margin-2 width-100"
							onClick={this.cancelEditInvoice.bind(this)}
						>
							Cancel
						</Button>
					</Col>
				</Row>
			);


		}
		else if(name === "extraFees")
		{
			const rowStyle = {
				paddingTop: "15px",
				paddingBottom: "15px",
				backgroundColor: "gold"
			};


			const multipleInvoices = (
				<Col md={2}>
					<Form.Label>Invoice:</Form.Label>
					<FormControl as="select" placeholder="select" onChange={this.setActiveInvoice} value={this.state.dropDownValue}>
						<option value="SELECT">SELECT</option>
						{_.map(this.props.vendorInvoices, (invoice, index) => <option value={invoice.xero_invoice_id} key={index}>{invoice.xero_invoice_id}</option> )}
					</FormControl>
				</Col>
			);

			return (
				<>
					<Row style={rowStyle}>
						<Col md={2}>
							<Form.Label>
								Add Interest?
							</Form.Label>
							<Form.Check label="Yes" onChange={() => this.setState({shouldAddInterestFee: !this.state.shouldAddInterestFee})}></Form.Check>
						</Col>


						{this.state.hasMultipleInvoices ? multipleInvoices : null}

						<Col md={3}>
							<Form.Label>
								Invoice Amount
							</Form.Label>
							<EditableText
								value={this.state.invoiceAmount}
								onChange ={this.onInvoiceAmountChange}
								onValueChanged={this.onInvoiceAmountChange}
								editing={this.state.staticEditing}
								formatter={(val) => "£" +gaNiceFormatPrice(val)}
								currency={true}
							>
							</EditableText>
						</Col>

						<Col md={2}>
							<Form.Label>
								Due Days
							</Form.Label>
							<EditableText
								value={this.state.dueDays}
								onChange ={this.onDueDaysChange}
								onValueChanged={this.onDueDaysChange}
								editing={this.state.staticEditing}
							>
							</EditableText>
						</Col>

						<Col md={2}>
							<Form.Label>
								Barclay's
							</Form.Label>
							<EditableText
								value={this.state.barclaysRate}
								onChange={this.onRateChange.bind(this, "barclaysRate")}
								onValueChanged={this.onRateChange.bind(this, "barclaysRate")}
								editing={this.state.staticEditing}
							>
							</EditableText>
						</Col>

						<Col md={1}>
							<Form.Label>
								Libor
							</Form.Label>
							<EditableText
								value={this.state.liborRate}
								onChange ={this.onRateChange.bind(this,"liborRate")}
								onValueChanged={this.onRateChange.bind(this, "liborRate")}
								editing={this.state.staticEditing}
							>
							</EditableText>
						</Col>

						<Col md={2}>
							<Form.Label>
								Interest
							</Form.Label>

							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>&pound;</InputGroup.Text>
								</InputGroup.Prepend>
								<FormControl
									disabled
									value={this.state.interestIncome}
								/>
							</InputGroup>
						</Col>
					</Row>

					<Row style={rowStyle}>
						<Col md={2}>
							<Form.Label>
								Add NNF?
							</Form.Label>
							<Form.Check label="Yes" onChange={() => this.setState({shouldAddNonNotificationFee: !this.state.shouldAddNonNotificationFee})}></Form.Check>
						</Col>

						<Col md={3}>
							<Form.Label>
								Non-Notification Fee
							</Form.Label>
							<EditableText
								value={this.state.nonNotificationFee}
								onChange ={this.onNonNotificationFeeChange}
								onValueChanged={this.onNonNotificationFeeChange}
								editing={this.state.staticEditing}
								formatter={(val) => "£" +gaNiceFormatPrice(val)}
								currency={true}
							>
							</EditableText>
						</Col>
					</Row>

					<Row style={rowStyle}>

						<Col md={2}>
							<Form.Label>
								MCOL Fee
							</Form.Label>
							<EditableText
								value={this.state.feeMCOL}
								onChange ={this.onMCOLChange}
								onValueChanged={this.onMCOLChange}
								editing={this.state.staticEditing}
								formatter={(val) => "£" +gaNiceFormatPrice(val)}
								currency={true}
							>
							</EditableText>
						</Col>


						<Col md={3}>
							<Form.Label>
								Court Fee
							</Form.Label>
							<EditableText
								value={this.state.feeCourt}
								onChange ={this.onCourtFeeChange}
								onValueChanged={this.onCourtFeeChange}
								editing={this.state.staticEditing}
								formatter={(val) => "£" +gaNiceFormatPrice(val)}
								currency={true}
							>
							</EditableText>
						</Col>


					</Row>

					<Row style={rowStyle}>
						<Col md={5}>
							<Button
								style={{display: "inline-grid", marginRight: "10px"}}
								variant="success"
								className="text-center margin-2 width-100"
								onClick={this.createExtraFeesInvoice}
							>
								Create Draft Invoice
							</Button>
							<Button
								style={{display: "inline-grid"}}
								variant="danger"
								className="text-center margin-2 width-100"
								onClick={this.cancelEditInvoice.bind(this)}
							>
								Cancel
							</Button>
						</Col>


					</Row>
				</>
			);
		}
	}

	render()
	{
		const loadingOverlay = <div>Loading your request, please wait</div>;
		const { user, adminOpts } = this.context.GA_CONFIG;
		const canEditInvoice = user.adminOptions[adminOpts.CAN_CHANGE_INVOICE];

		if(! canEditInvoice)
			return null;

		const editInvoiceOptions = (
			<Row style={{marginBottom: "15px"}}>
				<a style={{cursor: "pointer", fontSize:"17px", marginLeft:"15px", color: "#007bff"}} onClick={this.onEditInvoice.bind(this, "writeOff")}>Write Off</a>
				&nbsp;&nbsp;|
				<a style={{cursor: "pointer", fontSize:"17px", marginLeft:"15px", color: "#007bff"}} onClick={this.onEditInvoice.bind(this, "discount")}>Discount</a>
				&nbsp;&nbsp;|
				<a style={{cursor: "pointer", fontSize:"17px", marginLeft:"15px", color: "#007bff"}} onClick={this.onEditInvoice.bind(this, "void")}>Void</a>
				&nbsp;&nbsp;|
				<a style={{cursor: "pointer", fontSize:"17px", marginLeft:"15px", color: "#007bff"}} onClick={this.onEditInvoice.bind(this, "extraFees")}>Add Extra Fees</a>
			</Row>
		);


		return (
			<div>
				<Row style={{marginBottom: "15px"}}>
					<Col md={12}>
						{ editInvoiceOptions }
						{this.state.loading ? loadingOverlay : null}
						{ this.state.writeOffActive && this.state.editing && this.renderInvoiceEditOptions("writeOff") }
						{ this.state.discountActive && this.state.editing && this.renderInvoiceEditOptions("discount") }
						{ this.state.voidActive && this.state.editing && this.renderInvoiceEditOptions("void") }
						{ this.state.extraFees && this.state.editing && this.renderInvoiceEditOptions("extraFees") }
					</Col>
				</Row>
			</div>
		);
	}
}

export default CreditNoteOrDiscountOrVoid;