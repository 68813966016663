import React from "react";
import { Card, Col, Row, FormGroup, FormControl, Button } from "react-bootstrap";
import _ from "underscore";
import { GAConfigContext } from "contexts/gaConfigContext";
import { postAPI } from "utils/requestAPI";

class VendorEmail extends React.Component
{

	state = {
		loading: false,
		hidden: true,
		userChanged: false,
		email: ""
	}

	static contextType = GAConfigContext;

	templates = {
		valcannotdo:
		{
			generateEmail: (props) =>
			{
				// loop through agents and get ones that are val requested
				const branches = _.filter(props.vendorProp.branches, br => (br["_pivot_status"] == this.context.GA_CONFIG.vp.branchStatus.VALUATION_REQ));

				if(branches.length === 0)
				{
					// no branches outstanding, therefore this template shouldn't be user
					return this.setState({hidden: true});
				}

				let niceFormattedBranches = "";

				if(branches.length === 1)
				{
					niceFormattedBranches = branches[0].name;
				}
				else if(branches.length === 2)
				{
					niceFormattedBranches = branches[0].name + " and " + branches[1].name;
				}
				else
				{
					niceFormattedBranches = branches.slice(0, branches.length - 1).map(br => br.name).join(", ");
					niceFormattedBranches += " and " + branches[branches.length - 1].name;
				}

				const email = `Dear ${props.vendorProp.user.name.split(" ")[0]},

I hope you are well and thank you for using our service to find the best estate agents!

You requested a valuation with ${niceFormattedBranches}. 

We have been speaking with the ${branches.length > 1 ? "agents" : "agent"} to request this, however, they are not able to work with GetAgent and receive your valuation request.

Please feel free to book valuations with other agents by simply re-visiting your shortlist. If you have any questions, don't hesitate to get in touch on 0203 608 6556.

When you select an agent through our site you can also use our free monitoring service, to see how your sale is progressing compared to the rest of the market. Simply revisit your shortlist when your house is on the market or ask us for more details.

Best of luck with your sale!

${this.context.GA_CONFIG.user.name}
`;

				this.setState({
					email: email,
					hidden: false,
					loading: false
				});
			},

			onSave: () =>
			{
				// either move vendor to intros done (if any intros done) or archive
				const branchWithIntroDone = _.find(this.props.vendorProp.branches, br => br["_pivot_val_req_at"] && (br["_pivot_status"] >= (this.context.GA_CONFIG.vp.branchStatus.VAL_INTRO_DONE) && (br["_pivot_status"] <= this.context.GA_CONFIG.vp.branchStatus.AGENT_CHOSEN)) || br["_pivot_status"] == this.context.GA_CONFIG.vp.branchStatus.AGENT_WILL_NOT_CONTACT);

				let prom = null;

				if(branchWithIntroDone)
				{
					// move to intros done
					prom = postAPI("/admin/vendor/property/" + this.props.vendorProp.id + "/edit?patch=1", {status: this.context.GA_CONFIG.vp.status.INTROS_DONE});
				}
				else
				{
					// archive
					prom = postAPI("/admin/vendor/property/" + this.props.vendorProp.id + "/archive",
						{
							archive: this.context.GA_CONFIG.vp.archiveStatus.VAL_REQ_BUT_NO_INTRO_DONE,
							reason: "Couldn't do any of the intros for the requested valuations. Vendor emailed."
						});
				}

				return prom.then(resp =>
				{
					this.setState({hidden: true});

					if(this.props.reloadVendor)
						this.props.reloadVendor();
				})
					.catch(e =>
					{
						alert(e|| "An unknown error occurred");
						this.setState({loading: false});
					});

			}
		}
	}

	save()
	{
		this.setState({loading: true});

		// post to email endpoint
		postAPI("/admin/vendor/property/" + this.props.vendorProp.id + "/email", {email: this.state.email})
			.then(resp =>
			{
				if((! this.templates[this.props.template]) || (! this.templates[this.props.template].onSave))
				{
					this.setState({hidden: true});

					if(this.props.reloadVendor)
						this.props.reloadVendor();
				}
				else
				{
					this.templates[this.props.template].onSave();
				}

			})
			.catch(e =>
			{
				alert(e || "Something went wrong when trying to send email");
				this.setState({loading: false});
			});

	}

	init(props)
	{
		if((! this.templates[props.template]) || (! this.templates[props.template].generateEmail))
			return;

		this.templates[props.template].generateEmail(props);
	}

	emailChange(e)
	{
		this.setState({
			email: e.target.value,
			userChanged: true
		});
	}

	componentDidMount()
	{
		return this.init(this.props);
	}

	componentWillReceiveProps(nextProps)
	{
		if(this.state.userChanged === false)
			return this.init(nextProps);
	}


	render()
	{


		if(this.state.hidden)
			return null;

		return (
			<Card className={this.props.className}>
				<Card.Header>Email Vendor</Card.Header>
				<Card.Body>
					<Row>
						<Col md="9">
							<FormGroup>
								<FormControl
									as="textarea"
									placeholder="Your email goes here"
									rows="20"
									value={this.state.email || ""}
									onChange={this.emailChange.bind(this)}
								/>
							</FormGroup>
						</Col>

						<Col md="3">
							<p>Tips:</p>
							<ul>
								<li>"your shortlist" will be replaced with a link to the vendor's shortlist</li>
							</ul>
							<Button
								variant="danger"
								size="large"
								block
								onClick={this.save.bind(this)}
								disabled={this.state.loading || (this.state.email.length < 30)}>Send Email</Button>

						</Col>
					</Row>
				</Card.Body>

			</Card>
		);
	}

}

export default VendorEmail;