import React from 'react';
import { Card, Form, Col, Button } from 'react-bootstrap';
import { postAPI } from "utils/requestAPI";

class OneClickSignUpForm extends React.Component 
{
    constructor(props)
    {
        super(props);
        this.state = 
        { 
            name: null,
            phoneNum: null,
            email: null,
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.submitSignUpForm = this.submitSignUpForm.bind(this);
    
    }


    handleFormChange(e)
    {
        this.setState({ [e.target.name]: e.target.value });
    }

    submitSignUpForm()
    {

        if(!this.state.email || !this.state.name || !this.state.phoneNum)
        {
            alert('Missing some information!')
            return;
        }


        const body = {
            vpid: this.props.vendorProp ? this.props.vendorProp.id : null,
            bid:  this.props.branch ? this.props.branch.id : null,
            name: this.state.name,
            email: this.state.email,
            mobile: this.state.phoneNum
        };

        postAPI('/admin/branch/sendSignupEmail', body)
        .then(data => 
        {
            alert('Email sent!');
            this.setState({ name: "", email: "", phoneNum: ""  });
        })
        .catch(e => alert(e))
    }


    render()
    {
    
        return (
            <Card>
                <Card.Header>Send 1-click sign up link</Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Row className="mb-3">
                            <Col xs={6}>
                                
                                <Form.Control 
                                    type='text'
                                    placeholder="Full name"
                                    name='name'
                                    value={this.state.name}
                                    onChange={this.handleFormChange}
                                />
                               
                            </Col>
                            <Col xs={6}>
                                <Form.Control 
                                    type='text'
                                    placeholder='Phone Number'
                                    name='phoneNum'
                                    value={this.state.phoneNum}
                                    onChange={this.handleFormChange}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                            <Col xs={6}>
                                <Form.Control 
                                    type='email'
                                    placeholder='E-mail'
                                    name='email'
                                    value={this.state.email}
                                    onChange={this.handleFormChange}
                                />
                            </Col>
                            <Col xs={6}>
                                <Button 
                                    bsStyle='danger' 
                                    disabled={this.state.submitLoading} 
                                    onClick={this.submitSignUpForm}
                                >
                                Send email
                                </Button>
                            </Col>
                        </Form.Row>
                        { (this.state.vendorProp || this.props.vendorProp) && <p><small>Email will contain a reference to this vendor property</small></p>}
                    </Form>
                </Card.Body>
            </Card>
        );
    }
}

export default OneClickSignUpForm;