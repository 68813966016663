import React from "react";
import { Table, Button, Card } from "react-bootstrap";
import { Promise } from "bluebird";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import moment from "moment";
import { getAPI, postAPI } from "utils/requestAPI";
import { adminPanelURL } from "utils/common";
import iconRightmove from "images/icons/icon-rightmove.png";
import iconZoopla from "images/icons/icon-zoopla.png";
import iconOTM from "images/icons/icon-otm.png";

class CsPotentialListings extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
            listings: [],
            noneMatchClicked: false
		};
	}

	componentDidMount()
	{
		this.getPotentialListings(this.props.id);
	}

	componentDidUpdate(prevProps)
	{
		if(this.props.id !== prevProps.id)
			this.getPotentialListings(this.props.id);
	}

	getPotentialListings(id)
	{
		return getAPI("/vendor/quotes/" + id + "/potentiallistings.json")
			.then(data =>
			{
				this.setState(
					{
                listings: data.listings.map(ls =>
                {
                	ls.disabled = false;
                	return ls;
                }),
                noneMatchClicked: false
					});
			})
			.catch(e => console.log(e));
	}

    matchListing = (ls, index) =>
    {
    	const listingParams = {
            zoopla_id: ls.z_id || undefined,
            rightmove_id: ls.rm_id || undefined,
            am_id: ls.am_id || undefined,
            is_match: 1
    	};

    	postAPI("/admin/vendor/property/" + this.props.id + "/listing", listingParams)
    		.then(data =>
    		{
    			const newListings = [...this.state.listings];

    			newListings[index].disabled= true;

    			this.setState({listings: newListings});
    		})
    		.catch(e => alert(e || "An unknown error occurred"));
    }

    noneMatch = () =>
    {
    	Promise.mapSeries(this.state.listings, ls =>
    	{
    		const params = {zoopla_id: ls.z_id || 0, rightmove_id: ls.rm_id || 0, am_id: ls.am_id || 0, is_match: 0};

    		return postAPI("/admin/vendor/property/" + this.props.id + "/listing", params)
    			.then(data =>
    			{

    			})
    			.catch(e => alert(e || "An unknown error occurred."));

    	}).then(done =>
    	{
    		this.setState({
                noneMatchClicked: true,
                listings: []
    		});
    	});
    }

    render()
    {
    	const { listings, noneMatchClicked } = this.state;

    	const matchListBtn = (listing, i) => <Button variant="primary" className="float-right" onClick={this.matchListing.bind(this, listing, i)} >Match</Button>;

    	if(listings.length === 0)
    		return null;

    	return (

    		<Card className={this.props.className}>
    			<Card.Header>
    				{(<div style={{fontSize: 14}}>Potential Listings Matches {!noneMatchClicked ? <Button variant="danger" className="float-right" onClick={this.noneMatch.bind(this)}>None Match <FaThumbsDown /></Button> : <FaThumbsUp className="float-right" />}<div className="clearfix"></div></div>)}
    			</Card.Header>
    			<Card.Body>
    				<Table className="mb-0">
    					<thead>
    						<tr>
    							<th>Address</th>
    							<th>Date Added</th>
    							<th>Branch</th>
    							<th>Portals</th>
    							<th>Match?</th>
    						</tr>
    					</thead>
    					<tbody>
    						{listings.map((listing, i) =>
    						{
    							return (<tr key={i}>
    								<td>
    									{listing.address}
    								</td>
    								<td>
    									{listing.dateAdded && moment.utc(listing.dateAdded).format("Do MMM YYYY")}
    								</td>
    								<td>
    									{listing.branchId && listing.branchName && (<a href={adminPanelURL("/branch/" + listing.branchId + "/edit")} className={!listing.branchSignedUp ? "text-danger" : null} target="_blank">{listing.branchName}</a>)}
    								</td>
    								<td>
    									{listing.z_id && <a href={`http://www.zoopla.co.uk/for-sale/details/${listing.z_id}`} target="_blank"><img src={iconZoopla} width="20" /></a>} &nbsp;
    									{listing.rm_id && <a href={`http://www.rightmove.co.uk/property-for-sale/property-${listing.rm_id}.html`} target="_blank"><img src={iconRightmove} width="20" /></a>} &nbsp;
    									{listing.am_id && <a href={`https://www.onthemarket.com/details/${listing.am_id}/`} target="_blank"><img src={iconOTM} width="20" /></a>  }
    								</td>
    								<td>
    									{listing.disabled ? <FaThumbsUp className="float-right" /> : matchListBtn(listing, i)}
    								</td>
    							</tr>);
    						})}
    					</tbody>
    				</Table>
    			</Card.Body>
    		</Card>
    	);
    }
}

export default CsPotentialListings;