import React from 'react'
import { csCallMapping, callDataCols } from '../../../utils/common';


function FujisanCallsToLiveListings({ csUsers, csUserFilter, show }) {

    const renderGraph = () => 
    {
        const data = new window.google.visualization.DataTable();
        const chartArr = [];

        if(!csUsers) return;

        data.addColumn('string', 'All Calls'); 
        csUsers.map(user =>  csUserFilter.includes(user.csUserId) ? data.addColumn('number', user.name) : null);
        data.addColumn('number', 'Average');
  
        callDataCols.forEach((call, index) => 
        {
            if(call === "Call Backs" || call === "Revisit 1" || call === "Revisit 2")
                return null;

        const callType = csCallMapping[index+1];
        const row = [];
        row.push(call);

  let totalListings = 0;
			let totalCalls = 0;

            csUsers.forEach(user => 
            {
                if(!csUserFilter.includes(user.csUserId))
                    return;
                
                if(call !== 'Total')
                {
                    row.push((user[callType].listings/user[callType].called) * 100)

					totalCalls += user[callType].called;
					totalListings += user[callType].listings;
                }
                else
                {
                    row.push((user.totalListings/user.totalCalls) * 100)

					totalCalls += user.totalCalls;
					totalListings += user.totalListings;
                }
            })

			row.push((totalListings / totalCalls)*100);
            chartArr.push(row);
        })


        data.addRows(chartArr)  

        const options = 
        {
            height: 350,
            vAxis: { title: '% of Calls Listed Per User', format:"#'%'" , minValue: 0 },
            legend: { position: 'bottom'},
            chartArea: {'width': '90%', 'height': '70%'},
        };
        

        const b_chart = new window.google.visualization.ColumnChart(document.getElementById('fujisan-calls-to-live-listing'));
        b_chart.draw(data, options);


    }
    
    
    window.google.charts.load('current', {packages: ['corechart', 'bar', 'line']});
    window.google.charts.setOnLoadCallback(renderGraph);
    






    return (
        <div id='fujisan-calls-to-live-listing' className={!show ? 'd-none' : null}> </div>
    )
}

export default FujisanCallsToLiveListings
