import React from "react";
import { Col, Card, CardGroup, Badge } from "react-bootstrap";
import { csCallMapping } from "../../utils/common";

function FujisanCards({ csUsers, csUserFilter, call, totalValsByDate })
{

	let callMade = 0, callAns = 0, valsArr = 0, valsVenArr = 0;
	let totValArr = 0, totVenValsArr = 0, totTimOnPhone = 0, csTotalVals = 0;

	if(csUsers)
	{

		csUsers.forEach(user =>
		{

			if(user && !csUserFilter.includes(user.csUserId))
				return;

			// For all calls do this
			if(!csCallMapping[call])
			{
				callMade += user.totalCalls;
				callAns += user.totalCallsAnswered;
				valsArr += user.totalVals;
				valsVenArr += user.totalValsByVendor;
			}

			// For specific calls do this
			if(call !== 0 && user[csCallMapping[call]])
			{
				callMade += user[csCallMapping[call]].called;
				callAns += user[csCallMapping[call]].answered;
				valsArr += user[csCallMapping[call]].vals;
				valsVenArr += user[csCallMapping[call]].valsByVendor;
			}
		});


		// Leaderboard result
		if(totalValsByDate)
		{
			totalValsByDate.forEach((date) =>
			{
				totValArr += date.numVals;
				totVenValsArr += date.numVendors;
			});
		}

		//Get total duration
		csUsers.forEach(user =>
		{
			totTimOnPhone += user.totalDuration;
			csTotalVals += user.totalValsByVendor;
		});
	}


	return (
		<>
			<CardGroup className="row">
				<Col lg={2}>
					<Card className="text-center mb-lg-0 mb-3" border="primary">
						<Card.Header className="small bg-primary text-white font-weight-bold">
							{call === 0 ? "Calls Made" : <span>Calls Made <br/><Badge pill variant="light">{call}</Badge></span> }
						</Card.Header>
						<Card.Body>
							<h3 align="center">
                                &nbsp; ☎️ &nbsp;
								<br />
								{callMade}
							</h3>
						</Card.Body>
					</Card>
				</Col>

				<Col lg={2}>
					<Card className="text-center mb-lg-0 mb-3" border="danger">
						<Card.Header className="small bg-danger text-white font-weight-bold">
							{call === 0 ? "Calls Answered" : <span>Calls Answered <Badge pill variant="light">{call}</Badge></span> }
						</Card.Header>
						<Card.Body>
							<h3 align="center">
                                &nbsp;🗣 &nbsp;
								<br />
								{callAns}
							</h3>
						</Card.Body>
					</Card>
				</Col>

				<Col lg={2}>
					<Card className="text-center mb-lg-0 mb-3">
						<Card.Header className="small bg-light font-weight-bold">
							{call === 0 ? "Vals Arranged" : <span>Vals Arranged <Badge pill variant="light">{call}</Badge></span> }
						</Card.Header>
						<Card.Body>
							<h3 align="center">
                                &nbsp; 👍 &nbsp;
								<br />
								{valsArr}
							</h3>
						</Card.Body>
					</Card>
				</Col>

				<Col lg={2}>
					<Card className="text-center mb-lg-0 mb-3" border="info">
						<Card.Header className="small bg-info text-white ">
							{call === 0 ? "Vendor with Vals" : <span>Vendor with Vals <Badge pill variant="light">{call}</Badge></span> }
						</Card.Header>
						<Card.Body>
							<h3 align="center">
                                &nbsp; 🧑 &nbsp;
								<br />
								{valsVenArr}
							</h3>
						</Card.Body>
					</Card>
				</Col>

				<Col lg={2}>
					<Card className="text-center mb-lg-0 mb-3" border="success">
						<Card.Header className="small bg-success text-white font-weight-bold">
							{call === 0 ? "Answer Rate" : <span>Answer Rate <br/><Badge pill variant="light">{call}</Badge></span> }
						</Card.Header>
						<Card.Body>
							<h3 align="center">
                                &nbsp; ⌛ &nbsp;
								<br />
								{Math.round((callAns/callMade) * 100) || 0}%
							</h3>
						</Card.Body>
					</Card>
				</Col>

				<Col lg={2}>
					<Card className="text-center mb-lg-0 mb-3" border="warning">
						<Card.Header className="small bg-warning text-white font-weight-bold">
							{call === 0 ? "Valuation Rate" : <span>Valuation Rate <Badge pill variant="light">{call}</Badge></span> }
						</Card.Header>
						<Card.Body>
							<h3 align="center">
                                &nbsp; 🎉 &nbsp;
								<br />
								{Math.round((valsVenArr/callAns) * 100) || 0}%
							</h3>
						</Card.Body>
					</Card>
				</Col>
			</CardGroup>

			<CardGroup className="row mt-4">
				<Col lg={3}>
					<Card className="text-center mb-lg-0 mb-3">
						<Card.Body>
							<div className="text-center">
								<small className="text-muted"> Total Valuations Arranged</small>
							</div>
							<h2 align="center">
                            &nbsp; 👍 &nbsp;
								<br />
								{totValArr}
							</h2>
						</Card.Body>
					</Card>
				</Col>

				<Col lg={3}>
					<Card className="text-center mb-lg-0 mb-3">
						<Card.Body>
							<div className="text-center">
								<small className="text-muted"> Total Automised Valuations </small>
							</div>
							<h2 align="center">
                            &nbsp; 💻 &nbsp;
								<br />
								{totVenValsArr - csTotalVals}
							</h2>
						</Card.Body>
					</Card>
				</Col>

				<Col lg={3}>
					<Card className="text-center mb-lg-0 mb-3">
						<Card.Body>
							<div className="text-center">
								<small className="text-muted"> Total Vendor With Vals Arranged</small>
							</div>
							<h2 align="center">
                            &nbsp; 🧑 &nbsp;
								<br />
								{totVenValsArr}
							</h2>
						</Card.Body>
					</Card>
				</Col>

				<Col lg={3}>
					<Card className="text-center mb-lg-0 mb-3">
						<Card.Body>
							<div className="text-center">
								<small className="text-muted"> Total Time on Phone</small>
							</div>
							<h2 align="center">
                            &nbsp; 📞 &nbsp;
								<br />
								{Math.round(totTimOnPhone/60)} mins
							</h2>
						</Card.Body>
					</Card>
				</Col>

			</CardGroup>
		</>
	);
}

export default FujisanCards;
