import React from "react";
import { Table, FormCheck, Badge } from "react-bootstrap";
import { csCallMapping } from "../../utils/common";

function FujisanCsTeamTable({csUsers, csUserList, csUserFilter, setCsUserFilter, call})
{

	if(! csUsers) return null;

	const handleAllCheck = () =>
	{
		if(csUserFilter.length === csUserList.length)
			setCsUserFilter([]);
		else
			setCsUserFilter(csUserList);
	};

	const handleCheckBoxClick = (id) =>
	{
		if(csUserFilter.includes(id))
			setCsUserFilter(csUserFilter.filter(u => u !== id));
		else
			setCsUserFilter([...csUserFilter, id]);
	};


	return (
		<>
			<Table size="sm" bordered hover className="mt-5" responsive="lg">
				<colgroup align="center">
					<col width="4%"/>
					<col width="16%"/>
					<col width="17%"/>
					<col width="17%"/>
					<col width="17%"/>
					<col width="17%"/>
					<col width="12%" className="table-info"/>
				</colgroup>
				<thead>
					<tr>
						<th className="text-center">
							<FormCheck
								type="checkbox"
								checked={csUserFilter.length === csUserList.length}
								onClick={handleAllCheck}
							/>
						</th>
						<th className="text-center">Beautiful People</th>
						<th className="text-center" colSpan="1">{call > 0 && <div className="float-left"><Badge pill variant="light">{call}</Badge></div>}Calls Made </th>
						<th className="text-center" colSpan="1">{call > 0 && <div className="float-left"><Badge pill variant="light">{call}</Badge></div>}Calls Answered </th>
						<th className="text-center" colSpan="1">{call > 0 && <div className="float-left"><Badge pill variant="light">{call}</Badge></div>}Vals Arranged</th>
						<th className="text-center" colSpan="1">{call > 0 && <div className="float-left"><Badge pill variant="light">{call}</Badge></div>}Vendor With Vals</th>
						<th className="text-center" >Time on Phone </th>
					</tr>
				</thead>

				<tbody align="center">
					{csUsers && csUsers.map((user, index) =>
					{
						if(call === 0)
						{
							return (
								<tr key={index}>
									<td>
										<FormCheck
											type="checkbox"
											checked={csUserFilter.includes(user.csUserId)}
											onClick={() => handleCheckBoxClick(user.csUserId)}
										/>
									</td>
									<td >{user.name}</td>
									<td >{user.totalCalls} </td>
									<td >{user.totalCallsAnswered} </td>
									<td >{user.totalVals || 0}</td>
									<td >{user.totalValsByVendor||0} </td>
									<td >{Math.round(user.totalDuration/60)|| 0} mins</td>
								</tr>
							);
						}
						else
						{
							return (
								<tr key={index}>
									<td>
										<FormCheck
											type="checkbox"
											checked={csUserFilter.includes(user.csUserId)}
											onClick={() => handleCheckBoxClick(user.csUserId)}
										/>
									</td>
									<td >{user.name}</td>
									<td >{user[csCallMapping[call]].called} </td>
									<td >{user[csCallMapping[call]].answered} </td>
									<td >{user[csCallMapping[call]].vals || 0}</td>
									<td >{user[csCallMapping[call]].valsByVendor||0} </td>
									<td >{Math.round(user[csCallMapping[call]].duration/60)|| 0} mins</td>
								</tr>
							);

						}
					})}
				</tbody>

			</Table>
		</>
	);
}

export default FujisanCsTeamTable;
