import React from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import _ from "underscore";
import { getAPI } from "utils/requestAPI";

class BranchSearchTypeaheadAsync extends React.Component
{

	constructor(props)
	{
		super(props);
		this.state = {
			isLoading: false,
			options: [],
		};

		this.onBranchSearchQuery = this.onBranchSearchQuery.bind(this);
		this.handleBranchAdded = this.handleBranchAdded.bind(this);
	}

	onBranchSearchQuery(query)
	{
		// Letting parent component know that we are currently searching in the child component
		// As such it should re-send the query as prop so it can control the "selected" prop of the input

		if(this.props.onBranchAdded)
			this.props.onBranchAdded(false, query);

		this.setState({ isLoading: true });

		const options = { skipValidate: true };
		
		if(this.props.omitPerf){
			options.data = { omitPerf: true };
		}

		getAPI(`/branch/search/${query}.json`, options)
			.then(brData =>
			{
				if(this.props.branchFilter)
					brData = _.filter(brData, this.props.branchFilter);
	
				this.setState({
				options: brData,
				isLoading: false
				});
			})
			.catch(() => this.setState({ isLoading: false }));
	}

	handleBranchAdded(selection)
	{
		// handle slightly different if search box allows selecting multiple tokens
		if(this.props.multiple)
		{
			this.props.onBranchAdded(selection);
		}
		else
		{
			if(selection.length === 0)
				return null;

			const branchData = _.first(selection);

			if(this.props.onBranchAdded)
				this.props.onBranchAdded(branchData);
		}

	}

	render()
	{
		return (
			<React.Fragment>
				<AsyncTypeahead
					isLoading={this.state.isLoading}
					onSearch={this.onBranchSearchQuery}
					onChange={this.handleBranchAdded}
					placeholder={this.props.placeholder ? this.props.placeholder : "Enter name or id"}
					options={this.state.options}
					labelKey="name"
					filterBy={["name", "id"]}
					selected={this.props && this.props.selectedBranch}
					useCache={false}
					id="branch-search"
					multiple={this.props.multiple || false}
					open={this.props.open}
				/>
			</React.Fragment>
		);
	}
}
export default BranchSearchTypeaheadAsync;