import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AnalyticsDashboard from '../components/analytics-dashboard-pages/analyticsDashboard';
import CsView from '../components/vendors/csView';
import VendorEdit from '../components/vendors/vendorEdit';
import VendorIndex from '../components/vendors/vendorIndex';
import InvoiceChasing from '../components/invoices/invoiceChasing';
import Fujisan from '../components/fujisan/fujisan';
import CallForm from '../components/call-form/callForm';
import ValReqEmail from '../components/vendors/valReqEmail';
import MatchListings from '../components/vendors/matchListings';
import RevChasing from '../components/vendors/revChasing';
import FourOhFour from '../components/shared/fourOhFour';
import CRMTeam from '../components/vendors/CRMTeam';
import Wagyu from '../components/wagyu/wagyu';
import ValReqs from '../components/vendors/valReqs';
import LandRegMatch from '../components/land-reg-match/landRegMatch';
import CSVExport from '../components/vendors/vendorCSVExport';
import CsWallBoard from '../components/csWallboard/CsWallBoard';
import WallboardEdit from '../components/csWallboard/WallboardEdit';

const VendorRouter = () => {
    return (<>
        <Switch>
            <Route exact path='/vendor/analyticsDashboard/*' component={AnalyticsDashboard}/>
            <Route exact path='/vendor/customerService' component={CsView} />
            <Route path='/vendor/fujisan' component={Fujisan} />
            <Route exact path='/vendor/matchListings' component={MatchListings} />
            <Route exact path='/vendor/revChasing' component={RevChasing} />
            <Route exact path='/vendor/export' component={CSVExport} />

            <Route exact path='/vendor/property/:vpId/edit'>
                {props =>  <VendorEdit key={props.match.params.vpId} {...props}/>}
            </Route>
            <Route exact path='/vendor/landregMatch' component={LandRegMatch} />
            <Route exact path='/vendor/property/:vpId/phone' component={CallForm} />
            <Route exact path='/vendor/property/:vpId/phone/:branchId' component={CallForm} />
            <Route exact path='/vendor/property/:vpId/valReqEmail/:branchId' component={ValReqEmail} />

            <Route exact path='/vendor/property' component={VendorIndex} />
            <Route exact path='/vendor/CRMTeam' component={CRMTeam} />
            <Route path='/vendor/wagyu' component={Wagyu} />
            <Route exact path='/vendor/valReqs' component={ValReqs} />
			<Route path='/vendor/invoices' component={InvoiceChasing} />
            <Route exact path="/vendor/customerService/wallboard" component={CsWallBoard}/>
            <Route exact path="/vendor/customerService/wallboard/edit" component={WallboardEdit}/>
            <Route component={FourOhFour} />
        </Switch>
    </>);
}
 
export default VendorRouter;