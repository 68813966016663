import React from 'react';
import {Row, Col, Badge} from 'react-bootstrap';
import { GAConfigContext } from '../../contexts/gaConfigContext';
import _ from 'underscore';
import moment from 'moment';
import { gaNiceFormatPrice } from '../../utils/common';


class VendorStateListBox extends React.Component {
	
	static contextType = GAConfigContext;

	archiveColours =
	{
		"100": "#ffc100",
		"150": "#3dc9b1",
		"200": "#c5b09b",
		"225": "#1174ff",
		"260": "#88408c",
		"276": "#ffc100",
		"277": "#88408c",
		"290": "#912BE1",
		"350": "#1174ff",
		"400": "#ffc100",
		"1200": "#c5b09b",
		"5000": "#ffc100",
		"250": "#00A8DD",
		"275": "#c5b09b"
	}

	vendorBoxClick()
	{
		return window.open('/vendor/property/' + this.props.vendorProp.id + '/edit');
	}

	render()
	{
		let quoteProportion = 0;

		if(this.props.vendorProp.numShortlisted > 0)
			quoteProportion = this.props.vendorProp.numQuotes/this.props.vendorProp.numShortlisted;

		let quoteLabel = 'danger';

		if(quoteProportion > 0.7)
			quoteLabel = 'success';
		else if(quoteProportion > 0.5)
			quoteLabel = 'warning';


		return (
			 <li style={{paddingTop: (this.props.topOffset || 10), backgroundColor: (this.props.maxNoteAge < this.props.vendorProp.lastnote) ? "red" : ""}}
			 	onClick={this.vendorBoxClick.bind(this)}>

				<h3>{this.props.vendorProp.name}</h3>
				<Row>
					<Col sm={6}>{this.props.vendorProp.postcode && this.props.vendorProp.postcode.toUpperCase()}</Col>
					<Col sm={6} className='text-right'>&pound;{gaNiceFormatPrice(Math.round(this.props.vendorProp.estvalue/1000))}k</Col>
				</Row>
				<p className="text-center">
					
					{moment(this.props.vendorProp.created_at).format('Do MMM')}
				</p>
				
				{this.props.vendorProp.archive ?  (
					<Badge variant="default" style={{backgroundColor: (this.archiveColours[this.props.vendorProp.archive] || "#000")}}>
						{_.invert(this.context.GA_CONFIG.vp.archiveStatus)[this.props.vendorProp.archive]}
					</Badge>
				) :(
					<Badge variant={quoteLabel}>{this.props.vendorProp.numQuotes}</Badge> 
				
				)}
			</li>

		);
	}

}

export default VendorStateListBox;