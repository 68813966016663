import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import moment from "moment";
import _ from "underscore";
import { Device } from "@twilio/voice-sdk";
import { CALLCLIENTS, PLIVOS } from "../../utils/common";

class CallBox extends React.Component
{
	constructor(props)
	{
		super(props);

		const { twilioToken } = this.props;

		const callClient = twilioToken ? CALLCLIENTS.Twilio : CALLCLIENTS.Plivo;

		this.state = {
			plivoReady: false,
			calling: false,
			statusText: "Waiting for component to mount",
			muted: false,
			callAnswerTime: null,
			callClient: callClient,
			twilioConnection: null,
		};

		this._plivoWebSdk = null;

		this._callClient = null;

		this.call = this.call.bind(this);

		if(!(callClient in CALLCLIENTS))
			throw new Error("Call client not loaded / specified");
	}

	static contextType = GAConfigContext;

	componentDidMount()
	{
		if(this.state.callClient === CALLCLIENTS.Plivo)
		{
			this._plivoWebSdk = new window.Plivo({clientRegion: "europe", enableTracking: true});
			this._callClient = this._plivoWebSdk.client;

			// add event handlers
			this._callClient.on("onWebrtcNotSupported", this.plivoNotSupported.bind(this));
			this._callClient.on("onLogout", this.plivoLogout.bind(this));
			this._callClient.on("onLoginFailed", this.plivoLoginFail.bind(this));

			// can't be hooked on Twilio :(
			this._callClient.on("onCallRemoteRinging", this.plivoRemoteRinging.bind(this));
			this._callClient.on("onConnectionChange", this.plivoConnectionChange.bind(this));
			this._callClient.on("mediaMetrics", this.plivoMediaMetrics.bind(this));

			this._callClient.on("onLogin", this.genericClientReady.bind(this));
			this._callClient.on("onCalling", this.genericCallStarted.bind(this));
			this._callClient.on("onCallFailed", this.genericCallFail.bind(this));
			this._callClient.on("onCallTerminated", this.genericCallTerminated.bind(this));
			this._callClient.on("onCallAnswered", this.genericCallAnswer.bind(this));

			if(this._callClient.isLoggedIn)
			{
				this.genericClientReady();
			}
			else
			{
				const credentials = PLIVOS[Math.floor(Math.random() * PLIVOS.length)];

				this._callClient.login(credentials.username, credentials.password);
			}
		}
		else if(this.state.callClient === CALLCLIENTS.Twilio)
		{
			const callConfig = { closeProtection: true, enableRingingState: true };

			this._callClient = new Device(this.props.twilioToken, callConfig);

			this._callClient.on("registered", this.genericClientReady.bind(this));
			this._callClient.on("error", (error) => this.genericCallFail.bind(this)(error));

			this._callClient.register();
		}
	}

	componentWillUnmount()
	{
		if(this.state.calling)
		{
			alert("You're still on the phone with someone! When you press OK, the call will be hungup...");
			this.hangup();
		}

		if(this._callClient && this._callClient.destroy)
			this._callClient.destroy();
	}

	async call()
	{
		const { callClient } = this.state;

		if((! this._callClient))
			throw new Error(`The ${callClient} client hasn't been loaded!`);


		if((callClient === CALLCLIENTS.Plivo) && ! this._callClient.isLoggedIn)
			throw new Error("Not logged in to Plivo, so can't call");

		this.setState({
			statusText: "Dialling...",
			calling: true,
			muted: false
		});

		let phonenum = this.props.vendorProp.user.mobile.trim().replace(/\D/g, "");

		if(phonenum.indexOf("0") === 0)
		{
			// replace leading 0 with country code
			phonenum = phonenum.replace("0", "+44");
		}

		if(callClient === CALLCLIENTS.Plivo)
		{
			const params = {
				"X-PH-Phonenumber": phonenum,
				"X-PH-Uid": this.context.GA_CONFIG.user.id,
				"X-PH-PId": this.props.vendorProp.id
			};

			this._callClient.call(phonenum, params);
			this._callClient.unmute();
		}
		else if(callClient === CALLCLIENTS.Twilio)
		{
			const params = {
				To: phonenum,
				uId: this.context.GA_CONFIG.user.id,
				vpId: this.props.vendorProp.id
			};

			// Establish the phone call
			const twilioConnection = await this._callClient.connect({ params });

			twilioConnection.on("accept", this.genericCallAnswer.bind(this));
			twilioConnection.on("ringing", () => this.genericCallStarted.bind(this)(twilioConnection));
			twilioConnection.on("disconnect", this.genericCallTerminated.bind(this));
			twilioConnection.on("cancel", this.genericCallTerminated.bind(this));
			twilioConnection.mute(false);

			// Update the state to the twilio connection
			this.setState({ twilioConnection: twilioConnection });
		}
	}

	toggleMute()
	{
		console.log("Call mute toggled!");

		const {
			muted,
			callClient
		} = this.state;

		if(callClient === CALLCLIENTS.Plivo)
		{
			if(muted)
				this._callClient.unmute();
			else
				this._callClient.mute();

		}
		else if(callClient === CALLCLIENTS.Twilio && this.state.twilioConnection)
		{
			this.state.twilioConnection.mute(!muted);
		}

		this.setState({ muted: !muted });
	}

	dtmfPress(key)
	{
		console.log("key pressed", key);

		const { callClient } = this.state;

		if(callClient === CALLCLIENTS.Plivo)
			this._callClient.sendDtmf(key);
		else if(callClient === CALLCLIENTS.Twilio && this.state.twilioConnection)
			this.state.twilioConnection.sendDigits(key.toString());
	}

	hangup()
	{
		if(this.state.callClient === CALLCLIENTS.Plivo)
			this._callClient.hangup();
		else if(this.state.callClient === CALLCLIENTS.Twilio && this.state.twilioConnection)
			this.state.twilioConnection.disconnect();

		this.setState({
			statusText: "You hungup",
			calling: false,
			twilioConnection: null
		});

		if(this.props.onCallEnd)
			this.props.onCallEnd("hungup");
	}

	genericCallStarted(call)
	{
		// Twilio ClientCallId case;
		if(call)
			this.props.setCallClientId(call.parameters.CallSid);

		this.setState({
			statusText: "Calling...",
			calling: true
		});

		if(this.props.onCallStart)
			this.props.onCallStart();
	}

	genericCallAnswer()
	{
		this.setState({
			statusText: this.state.callClient === CALLCLIENTS.Twilio ? "Call Started" : "Call Answered",
			calling: true,
			callAnswerTime: moment(),
		});
	}

	genericCallTerminated()
	{
		let callTime = null;

		if(this.state.callAnswerTime)
			callTime = moment().diff(this.state.callAnswerTime, "seconds");

		this.setState({
			statusText: "Call Ended",
			calling: false,
			callAnswerTime: null,
			twilioConnection: null,
		});

		if(this.props.onCallEnd)
			this.props.onCallEnd("terminated", null, callTime);
	}

	genericCallFail(error)
	{
		const cause = this.state.callClient === CALLCLIENTS.Twilio ? error.message : error;

		this.setState({
			statusText: "Call Failed:" + cause,
			calling: false
		});

		if(this.props.onCallEnd)
			this.props.onCallEnd("failed", cause);
	}

	genericClientReady()
	{
		console.log("Client is ready to call");

		this.setState({
			plivoReady: true,
			statusText: "Ready to call"
		});

		if(this.props.callImmediately && ! this.state.calling)
			return this.call();
	}

	plivoNotSupported()
	{
		throw new Error("This browser isn't supported by Plivo");
	}

	plivoLogout()
	{
		console.log("logged out");

		this.setState({
			plivoReady: false,
			statusText: "Logged out of Plivo (this is bad)"
		});
	}

	plivoLoginFail(reason)
	{
		console.log("login failed:", reason);

		this.setState({
			plivoReady: false,
			calling: false,
			statusText: "Couldn't login to Plivo"
		});
	}

	plivoRemoteRinging()
	{
		this.setState({
			statusText: "It's ringing!",
			calling: true
		});
	}

	plivoMediaMetrics(mm)
	{
		console.log("media metrics", mm);
	}

	plivoConnectionChange(e)
	{
		console.log("Connection change", e);
	}

	render()
	{
		const callButtons = _.range(1, 10).map(i => (<Col xs={4} className="mt-2" key={i}><Button onClick={this.dtmfPress.bind(this, i)} variant="light" block>{i}</Button></Col>));

		return (
			<div>
				{this.state.statusText} {this.state.muted ? "(Muted!)" : ""}
				{this.state.calling && (
					<Row>
						{callButtons}
						<Col xs={4} className="mt-2">
							<Button onClick={this.dtmfPress.bind(this, "*")} variant="light" block>*</Button>
						</Col>
						<Col xs={4} className="mt-2">
							<Button onClick={this.dtmfPress.bind(this, 0)} variant="light" block>0</Button>
						</Col>
						<Col xs={4} className="mt-2">
							<Button onClick={this.dtmfPress.bind(this, "#")} variant="light" block>#</Button>
						</Col>
						<Col xs={6} className="mt-2">
							<Button onClick={this.toggleMute.bind(this)} variant="warning" block>{this.state.muted ? "Unmute" : "Mute"}</Button>
						</Col>
						<Col xs={6} className="mt-2">
							<Button onClick={this.hangup.bind(this)} variant="danger" block>Hangup</Button>
						</Col>
					</Row>
				)}
				{! this.state.calling && (
					<Button onClick={this.call.bind(this)} disabled={! this.state.plivoReady}>Call</Button>
				)}
				<p className="text-muted">{this.props.twilioToken ? "Twilio" : "Plivo"}</p>
			</div>
		);
	}
}

export default CallBox;