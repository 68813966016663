import React from "react";
import { Table, Nav, Row, Col, NavDropdown, Dropdown, Spinner, Card, Form, CardGroup } from "react-bootstrap";
import { adminPanelURL } from "utils/common";
import { getAPI } from "utils/requestAPI";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import _ from "underscore";
import moment from "moment";

class CallRow extends React.PureComponent
{
	constructor(props)
	{
		super(props);

		this.state =
		{
			crmCall: this.props.crmCall
		};
	}

	render()
	{
		const { crmCall } = this.state;

		return (
			<tr style={{fontSize: "12px", wordBreak: "break-all"}}>
				<td>
					<a href={adminPanelURL("/vendor/property/" + crmCall.vendor_prop_id + "/edit")} rel="noreferrer" target="_blank">{crmCall.vendor_prop_id}</a>
				</td>
				<td>
					<a href={adminPanelURL("/branch/" + crmCall.branch_id + "/edit")} rel="noreferrer" target="_blank">{crmCall.branch_id}</a>
				</td>
				<td>{crmCall.call_type}</td>
				<td>{moment(crmCall.val_req_at).format("DD MMM YYYY (HH:mm)")}</td>
				<td>{moment(crmCall.val_rem_sent).format("DD MMM YYYY (HH:mm)")}</td>
				<td>{moment(crmCall.call_at).format("DD MMM YYYY (HH:mm)")}</td>
				<td>{crmCall.locked_by_name || ""}</td>
				<td style={{color: moment(crmCall.locked_until).isAfter(moment()) ? "red" : "green"}} >
					{crmCall.locked_until ? moment(crmCall.locked_until).format("DD MMM YYYY (HH:mm)") : ""}
				</td>
			</tr>
		);
	}
}

class WagyuOutstandingCalls extends React.PureComponent
{
	constructor(props)
	{
		super(props);

		this.state =
		{
			callsToBeMade: {},
			totalCallsToBeMadeByType: {},
			crmUsers: {},
			userId: "all",
			callType: "all",
			loading: false
		};
	}

	static contextType = GAConfigContext;

	componentDidMount()
	{
		this.fetchCallStats();
	}

	fetchCallStats = async() =>
	{
		this.setState({loading: true});

		try
		{
			const data = await getAPI("/admin/outstandingCrmCalls.json");

			this.setState({
				callsToBeMade: data.callsToBeMade || {},
				totalCallsToBeMadeByType: data.totalCallsToBeMadeByType || {},
				crmUsers: data.crmUsers || {},
				loading: false
			});

		}
		catch (err)
		{
			this.setState({loading: false});
			alert(err.message);
		}
	}

	callDropdownMenu = (title, selectedId, setSelectedId, list) =>
	{
		return (
			<Nav>
				<NavDropdown title={selectedId != "all" ? list[selectedId] : title}>
					<Dropdown.Item style={{fontSize: "10px"}} onClick={setSelectedId} id="all">All</Dropdown.Item>

					{list.map((val, key) => <Dropdown.Item style={{fontSize: "10px"}} onClick={setSelectedId} id={key} key={key}>{val}</Dropdown.Item>)}
				</NavDropdown>
			</Nav>
		);
	}

	cardItem = (title, body) =>
	{
		return (
			<Col key={`total-card-${title}`} lg={3}>
				<Card className="text-center mb-lg-0">
					<Card.Body>
						<div className="text-center">
							<small className="text-muted">{title}</small>
						</div>
						<h2 align="center">
							<br />
							{body}
						</h2>
					</Card.Body>
				</Card>
			</Col>
		);
	};

	selectUser = (event) =>
	{
		const {target: { id } } = event;

		this.setState({userId: id || "all"});
	}

	filterOutRow = (crmCall) =>
	{
		if(this.state.userId !== "all" && crmCall.locked_by != this.state.userId)
			return true;
		
		if(this.state.callType !== "all" && crmCall.call_type !== this.context.GA_CONFIG.crmCalls.callTypes[this.state.callType])
			return true;

		return false;
	}

	render()
	{
		const {
			callsToBeMade,
			totalCallsToBeMadeByType,
			crmUsers,
			callType,
			userId,
			loading
		} = this.state;

		if(loading)
		{
			return <div className="text-center mt-5">
				<p>Loading scheduled calls</p>
				<Spinner size="lg" variant="primary" animation="border"/>
			</div>;
		}

		return (
			<>
				<CardGroup className="row mb-3">
					{this.context.GA_CONFIG.crmCalls.callTypes.map(type => this.cardItem(type, totalCallsToBeMadeByType[type] || 0))}
				</CardGroup>

				<Card>
					<Row>
						<Col className="p-4 flex" style={{textAlign: "center"}}>
							<p style={{marginBottom: 0, height: "fit-content"}}>
								Outstanding scheduled calls from the last 4 days until now.
							</p>
						</Col>
					</Row>
					<Table size="sm" className="mb-0 card-table">
						<thead>
							<tr className="table-info">
								<th><span className="mb-1">Vendor</span></th>
								<th><span className="mb-1">Branch</span></th>
								<th>
									{this.callDropdownMenu(
										"Call type",
										callType,
										(event) => this.setState({callType: event.target.id || "all"}),
										this.context.GA_CONFIG.crmCalls.callTypes
									)}
								</th>
								<th><span className="mb-1">Valuation requested</span></th>
								<th><span className="mb-1">Valuation sent</span></th>
								<th><span className="mb-1">Scheduled call at</span></th>
								<th>
									{crmUsers.length > 0 ? (
										<Nav>
											<NavDropdown title={ userId != "all" ? crmUsers.find(x => x.id == userId).name : "Locked by" }>
												<Dropdown.Item style={{fontSize: "10px"}} onClick={this.selectUser} id="all">All</Dropdown.Item>

												{crmUsers.map((usr) =>
												{
													return (
														<Dropdown.Item style={{fontSize: "10px"}} key={usr.id} onClick={this.selectUser} id={usr.id}>{usr.name}</Dropdown.Item>
													);
												})}
											</NavDropdown>
										</Nav>
									) : <span className="mb-1">Locked by</span>}
								</th>
								<th><span className="mb-1">Locked until</span></th>
							</tr>
						</thead>

						<tbody>
							{Object.keys(callsToBeMade).length > 0 && callsToBeMade.map((crmCall) =>
							{
								if(!this.filterOutRow(crmCall))
									return <CallRow key={crmCall.id} crmCall={crmCall} />
							})}
						</tbody>
					</Table>
				</Card>
			</>
		);
	}
}

export default WagyuOutstandingCalls;