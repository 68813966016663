import React, { useState, useEffect } from "react";
import moment from "moment";
import {Route} from "react-router-dom";
import { useLocation, useHistory } from "react-router";
import FujisanNav from "./fujisanNav";
import FujisanCards from "./fujisanCards";
import FujisanGraphs from "./fujisanGraphs/fujisanGraphs";
import FujisanCsTeamTable from "./fujisanCsTeamTable";
import FujisanPieCharts from "./fujisanPieCharts";
import FujisanBacklogStats from "./fujisanBacklogStats";
import FujisanCalls from "./fujisanCalls";
import DocumentTitle from "../shared/documentTitle";
import { Spinner } from "react-bootstrap";
import { getAPI } from "utils/requestAPI";
import FujisanCallSettings from "./fujisanCallSettings";

const Fujisan = () =>
{

	// check for query Dates
	const location = useLocation();
	const history = useHistory();
	const searchQuery = new URLSearchParams(location.search);

	const [call, setCall] = useState(0);
	const [toDate, setToDate] = useState(searchQuery.get("to") || moment().format("YYYY-MM-DD"));
	const [fromDate, setFromDate] = useState(searchQuery.get("from") || moment().format("YYYY-MM-DD"));
	const [loading, setLoading] = useState(false);
	const [csUsers, setCsUsers] = useState(null);
	const [data, setData] = useState(null);
	const [csUserFilter, setCsUserFilter] = useState([]);
	const [csUserList, setCsUserList] = useState([]);

	const csUsersWithMergedCallBacks = csUsers?.map(user =>
	{
		const callBacks = [user.CALL_BACK_1, user.CALL_BACK_2, user.CALL_BACK_3];
		const mergeCallBacks = key => callBacks.reduce((total, callBack) => total + callBack[key], 0);
		const mergeCallBacksWhyNoVals = key => callBacks.reduce((total, callBack) => total + callBack.whyNoVals[key], 0);

		return {
			...user,
			CALL_BACKS: {
				answered: mergeCallBacks("answered"),
				archive: mergeCallBacks("archive"),
				called: mergeCallBacks("called"),
				duration: mergeCallBacks("duration"),
				listings: mergeCallBacks("listings"),
				vals: mergeCallBacks("vals"),
				valsByVendor: mergeCallBacks("valsByVendor"),
				whyNoVals: {
					ALREADY_CHOSEN: mergeCallBacksWhyNoVals("ALREADY_CHOSEN"),
					ALREADY_CONTACTED: mergeCallBacksWhyNoVals("ALREADY_CONTACTED"),
					ALREADY_LIVE: mergeCallBacksWhyNoVals("ALREADY_LIVE"),
					GO_DIRECT: mergeCallBacksWhyNoVals("GO_DIRECT"),
					NOT_READY: mergeCallBacksWhyNoVals("NOT_READY"),
					NOT_SELLING: mergeCallBacksWhyNoVals("NOT_SELLING"),
					OTHER: mergeCallBacksWhyNoVals("OTHER"),
					PROBATE: mergeCallBacksWhyNoVals("PROBATE"),
					UNKNOWN_REASON: mergeCallBacksWhyNoVals("UNKNOWN_REASON")
				}
			}
		};
	});

	useEffect(() =>
	{
		history.push({ search: `?from=${fromDate}&to=${toDate}`});

		setLoading(true);

		getAPI(`/admin/vendor/customerService/fujisan.json?to=${toDate}&from=${fromDate}`)
			.then(({ success, msg, csUsers}) =>
			{
				setCsUsers(csUsers);

				//inital values are all id of cs users.
				setCsUserList(csUsers.map(u => u.csUserId));
				setCsUserFilter(csUsers.map(u => u.csUserId));

				getAPI(`/admin/valuationLeaderboard.json?to=${toDate}&from=${fromDate}`)
					.then(dataJson =>
					{
						setData({
							numValsByUser: dataJson.numValsByUser,
							callsByDate: dataJson.callsByDate,
							totalValsByDate: dataJson.totalValsByDate
						});

						setLoading(false);
					})
					.catch(e => alert(e));
			})
			.catch(e => alert(e));


	}, [toDate, fromDate, history]);

	return (<>
		<DocumentTitle title="Fujisan"/>
		<FujisanNav
			toDate={toDate}
			fromDate={fromDate}
			setFromDate={setFromDate}
			setToDate={setToDate}
			call={call}
			setCall={setCall}
		/>
		<Route exact path="/vendor/fujisan/dashboard">

			{loading && <div className="text-center mt-5">
				<Spinner size="lg" variant="primary" animation="border"/>
			</div>}

			{/* This hack needed because Google Charts freaks out if the divs don't exist when we're setting up charts */}
			<div style={{display: loading ? "none" : "block"}}>

				<FujisanCards
					csUsers={csUsersWithMergedCallBacks}
					csUserFilter={csUserFilter}
					call={call}
					totalValsByDate={data && data.totalValsByDate}
				/>
				<FujisanGraphs
					csUsers={csUsersWithMergedCallBacks}
					csUserFilter={csUserFilter}
				/>
				<FujisanPieCharts
					csUsers={csUsersWithMergedCallBacks}
					csUserFilter={csUserFilter}
					call={call}
				/>
				<FujisanCsTeamTable
					csUsers={csUsersWithMergedCallBacks}
					csUserFilter={csUserFilter}
					csUserList={csUserList}
					setCsUserFilter={setCsUserFilter}
					call={call}
				/>

			</div>
		</Route>
		<Route exact path="/vendor/fujisan/backlog" component={FujisanBacklogStats} />
		<Route exact path="/vendor/fujisan/calls">
			<FujisanCalls to={toDate} from={fromDate} />
		</Route>
		<Route exact path="/vendor/fujisan/settings">
			<FujisanCallSettings csUsers={csUsersWithMergedCallBacks || []} />
		</Route>
	</>);
};

export default Fujisan;