import React, { useContext, useState } from "react";
import { Navbar, Nav, NavDropdown, Container, Form, Dropdown, Badge } from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";
import logo from "images/logo/admin-logo-white.png";
import { LinkContainer } from "react-router-bootstrap";
import OmniSearch from "./omniSearch";
import { useEffect } from "react";
import { GAConfigContext } from "contexts/gaConfigContext";
import { getAPI } from "utils/requestAPI";

const AdminNav = () =>
{

	const [remindersCount, setRemindersCount] = useState(0);
	const { GA_CONFIG: { user } } = useContext(GAConfigContext);

	useEffect(() =>
	{

		function getTodos()
		{
			getAPI(`/admin/reminders.json?user=${user.id}&countOnly=1`)
				.then(({ numReminders }) =>
				{
					setRemindersCount(numReminders);
				})
				.catch(e =>
				{
					throw Error ("Failed to check reminders");
				});
		}

		getTodos();

		const interval = setInterval(getTodos, 1000 * 60 * 1);

		return () => clearInterval(interval);

	}, []);


	return (<>
		<Navbar bg="dark" variant="dark" className="mb-md-4" expand="lg">
			<Container>
				<Navbar.Toggle aria-controls="main-navbar-nav" />

				<LinkContainer to="/">
					<Navbar.Brand className="mr-auto">
						<img src={logo} width={20} alt="screen-logo" style={{verticalAlign: "text-top"}}/> Admin Panel
					</Navbar.Brand>
				</LinkContainer>


				<Navbar.Collapse id="main-navbar-nav" className="mr-lg-auto">

					<Nav >
    					<LinkContainer to="/reminders">
    						<Nav.Link className="mx-2">Todos {remindersCount > 0 ? <Badge variant="danger" pill size="sm">{remindersCount}</Badge> : null}
    						</Nav.Link>
    					</LinkContainer>
    					<NavDropdown title={"Vendors"} id="vendors-drop-down" className="text-white">
    						<LinkContainer to="/vendor/property">
    							<NavDropdown.Item>CRM</NavDropdown.Item>
    						</LinkContainer>
    						<Dropdown.Divider></Dropdown.Divider>
    						<LinkContainer to="/vendor/customerService">
    							<NavDropdown.Item>Customer Service</NavDropdown.Item>
    						</LinkContainer>
    						<LinkContainer to="/vendor/fujisan/dashboard">
    							<NavDropdown.Item>⛰️ Fujisan</NavDropdown.Item>
    						</LinkContainer>

    						<Dropdown.Divider></Dropdown.Divider>

    						<LinkContainer to="/vendor/valReqs">
    							<NavDropdown.Item>Branches To Signup</NavDropdown.Item>
    						</LinkContainer>
							<Dropdown.Divider></Dropdown.Divider>
    						<LinkContainer to="/vendor/CRMTeam">
    							<NavDropdown.Item>Branches To Make Contact</NavDropdown.Item>
    						</LinkContainer>
							<LinkContainer to="/vendor/wagyu/dashboard">
								<NavDropdown.Item>🐮 Wagyu</NavDropdown.Item>
							</LinkContainer>
    						<Dropdown.Divider></Dropdown.Divider>
    						<LinkContainer to="/vendor/matchListings">
    							<NavDropdown.Item>Match Listings</NavDropdown.Item>
    						</LinkContainer>
    						<LinkContainer to="/vendor/revChasing">
    							<NavDropdown.Item>Revenue Chasing</NavDropdown.Item>
    						</LinkContainer>
							<LinkContainer to="/vendor/landregMatch">
    							<NavDropdown.Item>Land Reg Match</NavDropdown.Item>
    						</LinkContainer>
    						<LinkContainer to="/vendor/invoices">
    							<NavDropdown.Item>🧮 Invoices</NavDropdown.Item>
    						</LinkContainer>

    						<Dropdown.Divider></Dropdown.Divider>
    						<LinkContainer to="/vendor/analyticsDashboard/leads">
    							<NavDropdown.Item>📈 Analytics Dashboard</NavDropdown.Item>
    						</LinkContainer>
    					</NavDropdown>

    					<LinkContainer to="/leaderboard">
    						<Nav.Link className="mx-2" >Leaderboard</Nav.Link>
    					</LinkContainer>

    					<NavDropdown title={"Branches"} id="branches-drop-down" className="text-white">
							<LinkContainer to="/branch/bdCrm/calls">
								<NavDropdown.Item>BD CRM</NavDropdown.Item>
							</LinkContainer>
    						<LinkContainer to="/branch/sponsor">
    							<NavDropdown.Item>Sponsored Outcodes</NavDropdown.Item>
    						</LinkContainer>
    						<LinkContainer to="/salesReport">
								<NavDropdown.Item>Sales Report</NavDropdown.Item>
							</LinkContainer>
							<LinkContainer to="/branch/admin">
    							<NavDropdown.Item href="/">Redphone list & Create Corporate</NavDropdown.Item>
    						</LinkContainer>
							<LinkContainer to="/branch/deregister">
    							<NavDropdown.Item href="/">Branch deregister</NavDropdown.Item>
    						</LinkContainer>
    					</NavDropdown>

    					<LinkContainer to="/user/dashboard">
    						<Nav.Link className="mx-2" href="">Admins</Nav.Link>
    					</LinkContainer>

    					<NavDropdown title={"Tech"} id="branches-drop-down" className="text-white">
							<LinkContainer to="/workerLog">
    							<NavDropdown.Item href="/">Worker Log</NavDropdown.Item>
    						</LinkContainer>
    						<LinkContainer to="/landingPage">
    							<NavDropdown.Item href="/">Landing Pages</NavDropdown.Item>
    						</LinkContainer>
    						<LinkContainer to="/letters">
    							<NavDropdown.Item href="/">Letters Generator</NavDropdown.Item>
    						</LinkContainer>
    						<LinkContainer to="/landreg/search">
    							<NavDropdown.Item href="/">Land Registry Search</NavDropdown.Item>
    						</LinkContainer>
    						<LinkContainer to="/listings/search">
    							<NavDropdown.Item>Listings Search</NavDropdown.Item>
    						</LinkContainer>
							<LinkContainer to="/vendor/export">
								<NavDropdown.Item>Vendors Export</NavDropdown.Item>
							</LinkContainer>
    						<LinkContainer to="/analytics">
    							<NavDropdown.Item>PR Export</NavDropdown.Item>
    						</LinkContainer>
    						<LinkContainer to="/techStatus">
    							<NavDropdown.Item>T' Cloud</NavDropdown.Item>
    						</LinkContainer>
							<LinkContainer to="/calls/settings">
    							<NavDropdown.Item>Incoming Numbers</NavDropdown.Item>
    						</LinkContainer>
    					</NavDropdown>
						<NavDropdown title={<FaQuestionCircle />} id="help-drop-down" className="text-white">
							<NavDropdown.Item href="https://drive.google.com/drive/folders/1jUSgqlqvZDzncZ0z3TlxvFhecJ4Q39xK?usp=sharing">Instructional Videos</NavDropdown.Item>
							<NavDropdown.Item href="https://docs.google.com/presentation/d/1GYgeWWrrkvHHfjxiU7X0b0b7yWnlkRkkAsUa5Vc5ti0/edit?usp=sharing">Where does our data come from?</NavDropdown.Item>
							<NavDropdown.Item href="mailto:bugs@getagent.co.uk">Report a bug</NavDropdown.Item>
						</NavDropdown>
    				</Nav>

					<Form inline className="d-lg-none">
						<OmniSearch />
					</Form>
				</Navbar.Collapse>

				<Form inline className="d-none d-lg-flex">
					<OmniSearch />
				</Form>

			</Container>
		</Navbar>
	</>);

};

export default AdminNav;