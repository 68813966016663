import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { getAPI, postAPI } from "utils/requestAPI";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import { TeamInput } from "./components/TeamInput";

const CURRENT_TEAM_AMOUNT = 4;
const CURRENT_TEAM_MEMBER_AMOUNT = 8;

const WallboardEdit = () =>
{
	const { GA_CONFIG } = useContext(GAConfigContext);
	const [editTeams, setEditTeams] = useState(false);
	const [loading, setLoading] = useState(false);
	const [settings, setSettings] = useState({
		dailyVals: null,
		monthVendorsWithVals: null,
		starOfTheWeek: null,
		teams: new Array(CURRENT_TEAM_AMOUNT).fill([]).map(() => new Array(CURRENT_TEAM_MEMBER_AMOUNT).fill("")),
		teamNames: new Array(CURRENT_TEAM_AMOUNT).fill("")
	});

	const csUsers = GA_CONFIG.admins.filter((user) => user.getUserAdminOptions.includes(GA_CONFIG.adminOpts.ARE_CUSTOMER_SERVICE));

	const handleFormTargetChange = (key, value) =>
	{
		setSettings({ ...settings, [key]: value });
	};

	const handleTeamUpdate = (teamIndex, memberIndex, value) =>
	{
		const teamToUpdate = [...settings.teams[teamIndex]];

		teamToUpdate[memberIndex] = value;

		setSettings({ ...settings, teams: settings.teams.map((team, index) => (index === teamIndex ? teamToUpdate : team)) });
	};

	const handleTeamNameUpdate = (editIndex, value) =>
	{
		setSettings({ ...settings, teamNames: settings.teamNames.map((name, index) => (index === editIndex ? value : name)) });
	};

	const deleteTeam = (index) =>
	{
		const confirm = window.confirm("Are you sure you would like to delete this team?");

		if(confirm)
		{
			const newTeams = settings.teams.toSpliced(index, 1);
			const newTeamNames = settings.teamNames.toSpliced(index, 1);

			setSettings({ ...settings, teams: newTeams, teamNames: newTeamNames });
		}
	};

	const addAnotherTeam = () =>
	{
		const confirm = window.confirm("Are you sure you would like to add another team?");

		if(confirm)
		{
			const newTeams = [...settings.teams, new Array(8).fill("")];
			const newTeamNames = [...settings.teamNames, ""];

			setSettings({ ...settings, teams: newTeams, teamNames: newTeamNames });
		}
	};

	const submitChanges = async() =>
	{
		// Remove empty teams members
		const filteredTeams = [...settings.teams].map((team) => team.filter((member) => member));
		const res = await postAPI("/admin/csWallboard/settings/edit", { ...settings, teams: filteredTeams });

		if(res.success) window.alert("Settings saved successfully!");
		else window.alert("Hmmm, something went wrong...");
	};

	const getSettingsData = async() =>
	{
		setLoading(true);
		const settingsRes = await getAPI("/admin/csWallboard/settings");

		if(settingsRes?.success && settingsRes?.data?.value)
		{
			const settings = JSON.parse(settingsRes.data.value);

			setSettings(settings);
		}

		setLoading(false);
	};

	useEffect(() =>
	{
		getSettingsData();
	}, []);

	return !loading ? (
		<div>
			<h1>Wallboard Edit</h1>
			<div>
				<Form>
					<Form.Row className="mb-4">
						<Col>
							<InputGroup style={{ display: "flex", flexDirection: "column" }}>
								<Form.Label>Daily valuation goal</Form.Label>
								<Form.Control value={settings.dailyVals || ""} onChange={(event) => handleFormTargetChange("dailyVals", event.target.value)} />
							</InputGroup>
						</Col>
					</Form.Row>
					<Form.Row className="mb-4">
						<Col>
							<InputGroup style={{ display: "flex", flexDirection: "column" }}>
								<Form.Label>Monthly vendors with vals</Form.Label>
								<Form.Control value={settings.monthVendorsWithVals || ""} onChange={(event) => handleFormTargetChange("monthVendorsWithVals", event.target.value)} />
							</InputGroup>
						</Col>
					</Form.Row>
					<Form.Row className="mb-4">
						<Col>
							<InputGroup style={{ display: "flex", flexDirection: "column" }}>
								<Form.Label>Star of the week </Form.Label>
								<Form.Control value={settings.starOfTheWeek || ""} onChange={(event) => handleFormTargetChange("starOfTheWeek", event.target.value)} />
							</InputGroup>
						</Col>
					</Form.Row>
					<Button onClick={submitChanges}>Save</Button>
				</Form>
				<div>
					<Button className="mt-4" onClick={() => setEditTeams(!editTeams)}>
						Edit teams
					</Button>
					{editTeams ? (
						<div>
							{settings?.teams?.map((team, index) => (
								<TeamInput
									key={index}
									teamIndex={index}
									teams={settings.teams}
									membersPerTeam={CURRENT_TEAM_MEMBER_AMOUNT}
									csUsers={csUsers}
									handleTeamUpdate={handleTeamUpdate}
									teamName={settings.teamNames[index]}
									handleTeamNameUpdate={handleTeamNameUpdate}
									deleteTeam={deleteTeam}
								/>
							))}
							<Button className="mt-2 mb-4" onClick={addAnotherTeam}>
								Add another team
							</Button>
						</div>
					) : null}
				</div>
			</div>
		</div>
	) : (
		<div>Loading data...</div>
	);
};

export default WallboardEdit;
