import React from "react";

const LeaderboardCard = ({ title, leaderboard, stat }) =>
{
	const leaderboardEmojiLookup = ["👑", "🔥", "🎉"];

	return (
		<div className="wallboard-card">
			<p className="wallboard-heading" style={{ fontWeight: 600, paddingBottom: "4px" }}>{title}</p>
			<div style={{ display: "flex", flexDirection: "column", gap: "12px", justifyContent: "center" }}>
				{leaderboard?.length ?
					leaderboard.map((user, index) => (
						<div key={index} className="wallboard-text"
							style={{
								display: "flex",
								justifyContent: "space-between",
								borderBottom: "1px solid",
								borderColor: "var(--secondary)"
							}}>
							<p>{leaderboardEmojiLookup[index]}  {user.name}</p>
							<p>{user[stat]}</p>
						</div>
					))
					:
					<div className="wallboard-text">Nothing to show yet</div>}
			</div>
		</div>
	);
};

export { LeaderboardCard };