import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import _ from "underscore";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import { PLIVOS } from "../../utils/common";
import { Device } from "@twilio/voice-sdk";

class Caller extends React.Component
{
	static contextType = GAConfigContext;

	constructor(props)
	{
		super(props);

		this.state = {
			callClientToUse: props.twilioToken ? "twilio" : "plivo",
			callClient: null,
			plivo: null,
			twilio: null,
			calling: false,
			callAnswerTime: null,
			statusText: "",
			muted: false,
			callClientReady: false
		};

		//Bind All the methods in one place
		this.plivoConnectionChange = this.plivoConnectionChange.bind(this);
		this.plivoLoginFail = this.plivoLoginFail.bind(this);
		this.plivoLogout = this.plivoLogout.bind(this);
		this.plivoMediaMetrics = this.plivoMediaMetrics.bind(this);
		this.plivoRemoteRinging = this.plivoRemoteRinging.bind(this);

		this.genericCallAnswer = this.genericCallAnswer.bind(this);
		this.genericCallFail = this.genericCallFail.bind(this);
		this.genericCallStarted = this.genericCallStarted.bind(this);
		this.genericCallTerminated = this.genericCallTerminated.bind(this);
		this.genericClientReady = this.genericClientReady.bind(this);
		this.call = this.call.bind(this);
		this.hangup = this.hangup.bind(this);

		// call Clients after setting up incase we run into some weird shit
		this.twilioClient = null;
		this.plivoClient = null;
	}

	componentWillMount()
	{
		this.connectToCallClient();
	}

	componentWillReceiveProps(nextProps)
	{
		if(nextProps.startCall)
			this.call();
	}

	connectToCallClient()
	{
		if(this.state.callClientToUse === "plivo")
		{
			const plivoClient = new window.Plivo({ clientRegion: "europe", enableTracking: true }).client;

			plivoClient.on("onWebrtcNotSupported", this.plivoNotSupported);
			plivoClient.on("onLogout", this.plivoLogout);
			plivoClient.on("onLoginFailed", this.plivoLoginFail);

			plivoClient.on("onCallRemoteRinging", this.plivoRemoteRinging);
			plivoClient.on("onConnectionChange", this.plivoConnectionChange);
			plivoClient.on("mediaMetrics", this.plivoMediaMetrics);

			plivoClient.on("onLogin", this.genericClientReady);
			plivoClient.on("onCalling", this.genericCallStarted);
			plivoClient.on("onCallFailed", this.genericCallFail);
			plivoClient.on("onCallTerminated", this.genericCallTerminated);
			plivoClient.on("onCallAnswered", this.genericCallAnswer);

			this.plivoClient = plivoClient;

			if(plivoClient.isLoggedIn)
			{
				this.setState({ callClient:plivoClient  }, () => this.genericClientReady());
			}
			else
			{
				const credentials = PLIVOS[Math.floor(Math.random() * PLIVOS.length)];

				plivoClient.login(credentials.username, credentials.password);
				this.setState({ callClient: plivoClient }, () => this.genericClientReady());
			}
		}

		if(this.state.callClientToUse === "twilio")
		{
			const callConfig = { closeProtection: true, enableRingingState: true };
			const twilioClient = new Device(this.props.twilioToken, callConfig);

			twilioClient.on("registered", this.genericClientReady);
			twilioClient.on("error", (error) => this.genericCallFail(error));

			twilioClient.register();

			this.twilioClient = twilioClient;

			// Set to state
			this.setState({ callClient: twilioClient, callClientReady: true  });
		}
	}

	plivoNotSupported()
	{
		throw new Error("This browser isn't supported by Plivo");
	}

	plivoLogout()
	{
		console.log("logged out");

		this.setState({
			plivoReady: false,
			statusText: "Logged out of Plivo (this is bad)"
		});
	}

	plivoLoginFail(reason)
	{
		console.log("login failed:", reason);

		this.setState({
			plivoReady: false,
			calling: false,
			statusText: "Couldn't login to Plivo"
		});
	}

	plivoRemoteRinging()
	{
		this.setState({
			statusText: "It's ringing!",
			calling: true
		});
	}

	plivoMediaMetrics(mm)
	{
		console.log("media metrics", mm);
	}

	plivoConnectionChange(e)
	{
		console.log("Connection change", e);
	}

	async call()
	{
		const {
			callClient,
			callClientToUse,
		} = this.state;

		if((! callClient))
			throw new Error(`The ${callClientToUse} client hasn't been loaded!`);

		if((callClientToUse === "plivo") && !callClient.isLoggedIn)
			throw new Error("Not logged in to Plivo, so can't call");

		this.setState({
			statusText: "Dialling...",
			calling: true,
			muted: false
		});

		let phonenum = this.props.phoneNum.trim().replace(/\D/g, "");

		if(phonenum.indexOf("0") === 0)
		{
		// replace leading 0 with country code
			phonenum = phonenum.replace("0", "+44");
		}

		if(callClientToUse === "plivo")
		{
			const params = {
				"X-PH-Phonenumber": phonenum,
				"X-PH-Uid": this.context.GA_CONFIG.user.id
			};

			if(this.props.vendorProp && this.props.vendorProp.id)
				params["X-PH-Pid"] = this.props.vendorProp.id;

			if(this.props.branch && this.props.branch.id)
				params["X-PH-Bid"] = this.props.branch.id;

			this.state.callClient.call(phonenum, params);
			this.state.callClient.unmute();
		}

		if(callClientToUse === "twilio")
		{
			const params = {
				To: phonenum,
				uId: this.context.GA_CONFIG.user.id
			};

			if(this.props.vendorProp && this.props.vendorProp.id)
				params.vpId = this.props.vendorProp.id;

			if(this.props.branch && this.props.branch.id)
				params.bId = this.props.branch.id;

			const callObj = await this.twilioClient.connect({ params });

			//Attach event listeners.
			callObj.on("ringing", () => this.genericCallStarted(callObj));
			callObj.on("accept", this.genericCallAnswer);
			callObj.on("disconnect", this.genericCallTerminated);
			callObj.on("cancel", this.genericCallTerminated);
			callObj.on("reject", this.genericCallTerminated);

			this.setState({ callClient: callObj });
			this.state.callClient.mute(false);
		}
	}

	toggleMute()
	{
		console.log("Call mute toggled!");

		const {
			muted,
			callClientToUse,
			callClient
		} = this.state;

		if(callClientToUse === "plivo")
		{
			if(muted)
				callClient.unmute();
			else
				callClient.mute();
		}

		if((callClientToUse === "twilio") && this.state.callClient)
			this.state.callClient.mute(!muted);

		this.setState({ muted: !muted });
	}

	dtmfPress(key)
	{
		console.log("key pressed", key);

		const {
			callClientToUse,
			callClient
		} = this.state;

		if(callClientToUse === "plivo")
			callClient.sendDtmf(key);

		if(callClientToUse === "twilio")
			callClient.sendDigits(key.toString());
	}

	hangup()
	{
		if(this.state.callClientToUse === "plivo")
			this.state.callClient.hangup();


		if(this.state.callClientToUse === "twilio")
			this.state.callClient.disconnect();

		this.setState({
			statusText: "You hungup",
			calling: false,
		});

		if(this.props.onCallEnd)
			this.props.onCallEnd("hungup");
	}

	genericCallStarted(call)
	{
		// Twilio ClientCallId case;
		if(call && this.props.setCallClientId)
			this.props.setCallClientId(call.parameters.CallSid);

		this.setState({
			statusText: "Calling...",
			calling: true
		});

		if(this.props.onCallStart)
			this.props.onCallStart();
	}

	genericCallAnswer()
	{
		this.setState({
			statusText: this.state.callClientToUse === "twilio" ? "Call Started" : "Call Answered",
			calling: true,
			callAnswerTime: moment(),
		});
	}

	genericCallTerminated()
	{
		let callTime = null;

		if(this.state.callAnswerTime)
			callTime = moment().diff(this.state.callAnswerTime, "seconds");

		this.setState({
			statusText: "Call Ended",
			calling: false,
			callAnswerTime: null,
		});

		if(this.props.onCallEnd)
			this.props.onCallEnd("terminated", null, callTime);
	}

	genericCallFail(error)
	{
		const cause = this.state.callClientToUse === "twilio" ? error.message : error;

		this.setState({
			statusText: "Call Failed:" + cause,
			calling: false
		});

		if(this.props.onCallEnd)
			this.props.onCallEnd("failed", cause);
	}

	genericClientReady()
	{
		console.log("Client is ready to call");

		this.setState({
			statusText: "Ready to call",
			callClientReady: true
		});

		if(this.props.callerReady)
			this.props.callerReady(true);
	}

	render()
	{
		const callButtons = _.range(1, 10).map(i => (<Col xs={4} className="mt-2" key={i}><Button onClick={this.dtmfPress.bind(this, i)} block variant="light">{i}</Button></Col>));

		return (
			<div className="my-3">
				<div>{this.state.statusText} {this.state.muted ? "(Muted!)" : ""}</div>
				{this.state.calling && (
					<Row>
						{callButtons}
						<Col xs={4} className="mt-2">
							<Button onClick={this.dtmfPress.bind(this, "*")} block variant="light">*</Button>
						</Col>
						<Col xs={4} className="mt-2">
							<Button onClick={this.dtmfPress.bind(this, 0)} block variant="light">0</Button>
						</Col>
						<Col xs={4} className="mt-2">
							<Button onClick={this.dtmfPress.bind(this, "#")} block variant="light">#</Button>
						</Col>
						<Col xs={6} className="mt-2">
							<Button onClick={this.toggleMute.bind(this)} block variant="warning">{this.state.muted ? "Unmute" : "Mute"}</Button>
						</Col>
						<Col xs={6} className="mt-2">
							<Button onClick={this.hangup} block variant="danger">Hangup</Button>
						</Col>
					</Row>
				)}
				<p className="text-muted">{this.props.twilioToken ? "Twilio" : "Plivo"}</p>
			</div>
		);
	}
}

export default Caller;