import React from "react";
import { Form, Navbar, FormLabel, FormGroup, FormControl,  Button, Alert, Nav } from "react-bootstrap";
import {FiRefreshCcw} from "react-icons/fi";
import _ from "underscore";
import moment from "moment";
import CRMTeamVendorProp from "./CRMTeamVendorProp";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import DocumentTitle from "../shared/documentTitle";
import { postAPI, getAPI } from "utils/requestAPI";

class CRMTeam extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			loading: true,
			vendorProp: [],
			CRMUsers: []
		};
	}

	static contextType = GAConfigContext;

	componentDidMount()
	{
		this.fetchVendorProp();
		this.fetchAdminData();
	}

	onBlockBranch = (branchId, vpId) =>
	{
		return async() =>
		{
			const confirm = window.confirm("Are you sure you want to dismiss this branch?");

			if(confirm)
			{
				try
				{
					await postAPI(`/admin/vendor/CRMTeam/${vpId}/blockBranch/${branchId}`);

					this.fetchVendorProp();
				}
				catch (e)
				{
					throw new Error("Error while blocking the branch:", e.message);
				}
			}
		};
	}

	onTransferValuation = (callId, CRMUser) =>
	{
		return async() =>
		{
 			const confirm = window.confirm(`Are you sure you want to transfer this valuation to ${this.state.CRMUsers.find(user => user.id == CRMUser).name}?`);

			if(confirm)
			{
				try
				{
					const updatedCallObj = {
						locked_by: CRMUser,
						locked_until: moment().add(1, "days").utc().toDate()
					};

					await postAPI("/admin/vendor/CRMTeam/updateCRMCall", {
						crmCallId: callId,
						updatedCallObj
					});

					this.fetchVendorProp();
				}
				catch (e)
				{
					throw new Error("Error while transferring the valuation:", e.message);
				}
			}
		};
	};

	fetchAdminData = async() =>
	{
		const { adminOpts } = this.context.GA_CONFIG;

		try
		{
			const { adminUsers } = await getAPI("/admin/user/admin.json");
			const CRMUsers = adminUsers.filter(user => user.adminOptions[adminOpts.ARE_VAL_FOLLOWUP]);

			this.setState({ CRMUsers });
		}
		catch (e)
		{
			alert("Something went wrong when trying to fetch the data.");
		}
	}

	fetchVendorProp = async() =>
	{
		this.setState({loading: true,});

		try
		{
			const data = await getAPI("/admin/vendor/CRMTeam.json");

			if(! data.success)
				return alert(data.msg || "An unknown error occurred - please try refreshing the page");

			this.setState({
				vendorProp: data.vendorProp,
				loading: false,
			});
		}
		catch (e)
		{
			this.setState({
				loading: false,
				vendorProp: null
			});
			return alert(e.message || "An unknown error occurred - please try refreshing the page");

		}
	}

	render()
	{
		const {
			loading,
			vendorProp,
			CRMUsers
		} = this.state;

    	const { user, adminOpts } = this.context.GA_CONFIG;
    	const hasAccess = user.adminOptions[adminOpts.ARE_VAL_FOLLOWUP];

		return (
			(hasAccess) ?
				<div>
					<DocumentTitle title="Branches To Make Contact" />

					<div>
						<h1 className="display-4 my-5">Branches To Make Contact <Button onClick={this.fetchVendorProp} className="float-right my-2" disabled={loading}>
							<FiRefreshCcw />
						</Button>
						</h1>
					</div>

					{loading ? (
						<Alert variant="info">Loading!</Alert>
					) : (
						vendorProp ? (
							<CRMTeamVendorProp
								vendorProp={vendorProp}
								branchBlocker={this.onBlockBranch}
								transferValuation={this.onTransferValuation}
								CRMUsers={CRMUsers}
							/>
						) : <Alert variant="success">No valuations to show</Alert>
					)}
				</div>
				:
				<Alert variant="danger"><h4>Sorry, but this functionality isn't available for you.</h4> Need access? Talk to your manager.</Alert>
		);
	}
}

export default CRMTeam;