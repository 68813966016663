import React from 'react';
import { gaNiceFormatPrice } from '../../utils/common';
import moment from 'moment';
import _ from 'underscore';
import { Row, Col, Table } from 'react-bootstrap';
import { getAPI } from "utils/requestAPI";

class InvoiceCohortTable extends React.Component {
	constructor(props)
	{
		super(props);
		this.state = {
			date: props.date ? props.date : moment().format('YYYY-MM-DD'),
			cohortData: null,
			invoiceDataTypes: ['due', 'paid', 'discount', 'badDebt', 'void', 'outstanding']
		};

		this.processTableHeader = this.processTableHeader.bind(this);
		this.cohortTableContents = this.cohortTableContents.bind(this);
		this.fetchCohortData = this.fetchCohortData.bind(this);
	}

	componentDidMount()
	{
		this.fetchCohortData();
	}

	fetchCohortData()
	{
		getAPI('/admin/invoice/invoiceCohortData.json')
		.then(cd =>
		{	
			this.setState({cohortData: cd.data});
		})
		.catch(e => alert('Could not load cohort data for invoices table.'));
	}

	processTableHeader(date)
	{
		const startMonth = moment(date).startOf('month').subtract(12, 'months');

		const monthHeaders = [<th key={"monthHeader"} style={{textAlign:"center"}}>Status</th>];

		while(moment(date).startOf('month').add(1, 'month').isSameOrAfter(startMonth, 'month'))
		{
			monthHeaders.push(<th key={startMonth}>{startMonth.format("MMM YYYY")}</th>);

			startMonth.add(1, 'month');
		}

		return (
			<tr>
				{monthHeaders}
			</tr>
		);
	}

	cohortTableContents(date, cohortData)
    {
		if(! cohortData)
			return null;

		const tableRows = _.map(this.state.invoiceDataTypes, (status, index) =>
		{
			const startMonth = moment(date).startOf('month').subtract(12, 'months');
			const cells = [<td key={status}>{status == 'badDebt' ? 'BAD DEBT' : status.toUpperCase()}</td>];
			while(moment(date).startOf('month').add(1, 'month').isSameOrAfter(startMonth, 'month'))
			{   
				const key = startMonth.format("YYYY-M");
				if(cohortData[key])
				{
					
					const statistic = cohortData[key].cohort[status];

					cells.push(
						<td key={key}>
							{gaNiceFormatPrice(statistic.toFixed(0))}
						</td>
						);
				}
				else
				{
					cells.push(<td key={key}></td>)
				}
				
				startMonth.add(1, 'month');
			}

			return (
				<tr key={index}>
					{cells}
				</tr>
			);
		});
		
		return tableRows;
	}
	
	
	render()
	{
		const { date, cohortData } = this.state;

		const tableHeader = this.processTableHeader(date);
		const tableContent = this.cohortTableContents(date, cohortData);

		return(
			<Row>
				<Col md={12}>
					<Table striped bordered hover style={{textAlign: 'center'}} size="sm" responsive>
                            <thead>
                               {tableHeader}
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
					</Table>
				</Col>
			</Row>
			
		)
	}
}

export default InvoiceCohortTable;