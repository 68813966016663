import React from "react";
import { Table, Nav, Row, Col, NavDropdown, Dropdown, Spinner, Card, Form, CardGroup } from "react-bootstrap";
import { getAPI } from "utils/requestAPI";
import { adminPanelURL } from "utils/common";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import { postAPI } from "utils/requestAPI";
import { FaRegEdit, FaSave, FaTimes } from "react-icons/fa";
import tickBlue from "images/icons/tick-blue.png";
import iconAlert from "images/icons/icon-alert.png";
import _ from "underscore";

class CallRow extends React.PureComponent
{
	constructor(props)
	{
		super(props);

		this.state =
		{
			crmCall: this.props.crmCall,
			updatedOutcome: this.props.crmCall.outcome || "",
			updatedNoContactReason: this.props.crmCall.why_no_contact || "",
			updatedAwnc: this.props.crmCall.awnc_reason || "",
			updatedEmailResent: this.props.crmCall.email_resent || "",
			updatedCCAdded: this.props.crmCall.cc_added || "",
			saving: false,
			editing: false
		};
	}

	static contextType = GAConfigContext;

	onEditClick = () =>
	{
		this.setState({editing: true});
	}

	onSaveClick = async() =>
	{
		this.setState({saving: true});

		try
		{
			const updatedCallObj = {
				outcome: this.state.updatedOutcome || null,
				awnc_reason: this.state.updatedAwnc || null,
				why_no_contact: this.state.updatedNoContactReason || null,
				email_resent: this.state.updatedEmailResent || null,
				cc_added: this.state.updatedCCAdded || null
			};

			await postAPI("/admin/vendor/CRMTeam/updateCRMCall", {
				crmCallId: this.state.crmCall.id,
				updatedCallObj
			});

			this.setState({
				editing: false,
				saving: false
			});
		}
		catch (err)
		{
			this.setState({
				editing: false,
				saving: false
			});

			alert("Error while updating the call details: " + err.message);
		}
	}

	render()
	{
		const {
			crmCall,
			saving,
			editing,
			updatedOutcome,
			updatedNoContactReason,
			updatedAwnc,
			updatedEmailResent,
			updatedCCAdded,
		} = this.state;

		const { user, adminOpts } = this.context.GA_CONFIG;
    
    const canEdit = user.adminOptions[adminOpts.CAN_UPDATE_CRM_PAGE];
    
		const { agentHasContacted } = this.props;

		if(editing)
		{
			return (
				<tr style={{fontSize: "12px", wordBreak: "break-all"}}>
					<td>
						<a href={adminPanelURL("/branch/" + crmCall.branch_id + "/edit")} rel="noreferrer" target="_blank">{crmCall.branch_id}</a> / <br />
						<a href={adminPanelURL("/vendor/property/" + crmCall.vendor_prop_id + "/edit")} rel="noreferrer" target="_blank">{crmCall.vendor_prop_id}</a>
					</td>
					<td>{crmCall.name}</td>
					<td>{crmCall.call_type}</td>
					<td>
						<Form.Control
							as="select"
							onChange={(e) => this.setState({updatedOutcome: e.target.value})}
							value={updatedOutcome}
							style={{fontSize: "12px"}}
						>
							{this.context.GA_CONFIG.crmCalls.outcomes.map((r) =>
							{
								return <option key={`${crmCall.id}-${r}`}>{r}</option>;
							})}
						</Form.Control>
					</td>
					<td style={{textAlign: "center"}}>
						{
							agentHasContacted ?
								<img src={tickBlue} width="24" />
								:
								<img src={iconAlert} width="24" />
						}
					</td>
					<td>
						<Form.Control
							as="select"
							onChange={(e) => this.setState({updatedNoContactReason: e.target.value})}
							value={updatedNoContactReason}
							style={{fontSize: "12px"}}
						>
							{this.context.GA_CONFIG.crmCalls.noContactReasons.map((r) =>
							{
								return <option key={`${crmCall.id}-${r}`}>{r}</option>;
							})}
						</Form.Control>
					</td>
					<td>
						<Form.Control
							as="select"
							onChange={(e) => this.setState({updatedAwnc: e.target.value})}
							value={updatedAwnc}
							style={{fontSize: "12px"}}
						>
							{this.context.GA_CONFIG.crmCalls.awncReasons.map((r) =>
							{
								return <option key={`${crmCall.id}-${r}`}>{r}</option>;
							})}
						</Form.Control>
					</td>
					<td>
						<Form.Control
							as="select"
							onChange={(e) => this.setState({updatedEmailResent: e.target.value})}
							value={updatedEmailResent}
							style={{fontSize: "12px"}}
						>
							{this.context.GA_CONFIG.crmCalls.emailResentOptions.map((r) =>
							{
								return <option key={`${crmCall.id}-${r}`}>{r}</option>;
							})}
						</Form.Control>
					</td>
					<td>
						<Form.Control
							as="select"
							onChange={(e) => this.setState({updatedCCAdded: e.target.value})}
							value={updatedCCAdded}
							style={{fontSize: "12px"}}
						>
							{this.context.GA_CONFIG.crmCalls.ccAddedOptions.map((r) =>
							{
								return <option key={`${crmCall.id}-${r}`}>{r}</option>;
							})}
						</Form.Control>
					</td>
					{saving ? (
						<td>
							<Spinner size="sm" variant="primary" animation="border"/>
						</td>
					) : (
						<td style={{cursor:"pointer"}}>
							<FaSave style={{color: "#2C7BE5"}} onClick={this.onSaveClick}/>
							&nbsp;
							<FaTimes style={{color: "red"}} onClick={() => this.setState({editing: false})}/>
						</td>
					)}
				</tr>
			);
		}
		else
		{
			return (
				<tr style={{fontSize: "12px", wordBreak: "break-all"}}>
					<td>
						<a href={adminPanelURL("/branch/" + crmCall.branch_id + "/edit")} rel="noreferrer" target="_blank">{crmCall.branch_id}</a> /<br />
						<a href={adminPanelURL("/vendor/property/" + crmCall.vendor_prop_id + "/edit")} rel="noreferrer" target="_blank">{crmCall.vendor_prop_id}</a>
					</td>
					<td>{crmCall.name}</td>
					<td>{crmCall.call_type}</td>
					<td>{updatedOutcome || "-" }</td>
					<td style={{textAlign: "center"}}>
						{
							agentHasContacted ?
								<img src={tickBlue} width="24" />
								:
								<img src={iconAlert} width="24" />
						}
					</td>
					<td>{updatedNoContactReason || "-" }</td>
					<td>{updatedAwnc || "-" }</td>
					<td>{updatedEmailResent || "-" }</td>
					<td>{updatedCCAdded || "-" }</td>
					{canEdit ?
						<td onClick={this.onEditClick} style={{cursor:"pointer", color: "#2C7BE5"}}>
							<FaRegEdit />
						</td>
						: <td></td>}
				</tr>
			);
		}
	}
}

class WagyuCalls extends React.PureComponent
{
	constructor(props)
	{
		super(props);

		this.state =
		{
			callsMade: {},
			userId: "all",
			callId: "all",
			crmUsers: "",
			callType: "all",
			outcomeId: "all",
			contactMade: "all",
			whyNoContactId: "all",
			awncReasonId: "all",
			emailResentId: "all",
			ccAddedId: "all",
			loadingCalls: false
		};
	}

	static contextType = GAConfigContext;

	componentDidUpdate = (prevProps) =>
	{
		if(prevProps.to !== this.props.to || prevProps.from !== this.props.from)
			this.fetchCallStats();
	}

	componentDidMount()
	{
		this.fetchCallStats();
	}

	fetchCallStats = async() =>
	{
		const { to, from } = this.props;

		this.setState({loadingCalls: true});

		try
		{
			const data = await getAPI(`/admin/vendor/CRMTeam/callHistory.json?&from=${from}&to=${to}`);

			this.setState({
				callsMade: data.callsMade || {},
				totalCallsMadeByType: _.countBy(data.callsMade, "call_type") || {},
				crmUsers: data.crmUsers || {},
				loadingCalls: false,
			});
		}
		catch (err)
		{
			this.setState({errorMsg: err.message});
		}
	}

	selectUser = (event) =>
	{
		const {target: { id } } = event;

		this.setState({userId: id || "all"});
	}

	filterOutRow = (crmCall, agentHasContacted) =>
	{
		if(this.state.userId !== "all" && crmCall.called_by != this.state.userId)
			return true;

		if(this.state.callType !== "all" && crmCall.call_type !== this.context.GA_CONFIG.crmCalls.callTypes[this.state.callType])
			return true;

		if(this.state.outcomeId !== "all" && crmCall.outcome !== this.context.GA_CONFIG.crmCalls.outcomes[this.state.outcomeId])
			return true;

		if(this.state.contactMade !== "all" && agentHasContacted == this.state.contactMade)
			return true;

		if(this.state.whyNoContactId !== "all" && crmCall.why_no_contact !== this.context.GA_CONFIG.crmCalls.noContactReasons[this.state.whyNoContactId])
			return true;

		if(this.state.awncReasonId !== "all" && crmCall.awnc_reason !== this.context.GA_CONFIG.crmCalls.awncReasons[this.state.awncReasonId])
			return true;

		if(this.state.emailResentId !== "all" && crmCall.email_resent !== this.context.GA_CONFIG.crmCalls.emailResentOptions[this.state.emailResentId])
			return true;

		if(this.state.ccAddedId !== "all" && crmCall.cc_added !== this.context.GA_CONFIG.crmCalls.ccAddedOptions[this.state.ccAddedId])
			return true;

		return false;
	}

	callDropdownMenu = (title, selectedId, setSelectedId, list) =>
	{
		return (
			<Nav>
				<NavDropdown title={selectedId != "all" ? list[selectedId] : title}>
					<Dropdown.Item style={{fontSize: "10px"}} onClick={setSelectedId} id="all">All</Dropdown.Item>

					{list.map((val, key) => <Dropdown.Item style={{fontSize: "10px"}} onClick={setSelectedId} id={key} key={key}>{val}</Dropdown.Item>)}
				</NavDropdown>
			</Nav>
		);
	}

	cardItem = (title, body) =>
	{
		return (
			<Col lg={3}>
				<Card className="text-center mb-lg-0">
					<Card.Body>
						<div className="text-center">
							<small className="text-muted">{title}</small>
						</div>
						<h2 align="center">
							<br />
							{body}
						</h2>
					</Card.Body>
				</Card>
			</Col>
		);
	};

	render()
	{
		const {
			callsMade,
			totalCallsMadeByType,
			crmUsers,
			userId,
			callType,
			outcomeId,
			contactMade,
			whyNoContactId,
			awncReasonId,
			emailResentId,
			ccAddedId,
		} = this.state;

		if(this.state.loadingCalls)
		{
			return <div className="text-center mt-5">
				<Spinner size="lg" variant="primary" animation="border"/>
			</div>;
		}

		return (
			<>
				{_.isEmpty(totalCallsMadeByType) ? null : (
					<CardGroup className="row mb-3">
						{this.context.GA_CONFIG.crmCalls.callTypes.map(type => this.cardItem(type, totalCallsMadeByType[type] || 0))}
					</CardGroup>
				)}

				<Card>
					{!Object.keys(callsMade).length ? (
						<Row>
							<Col className="p-4 flex" style={{textAlign: "center"}}>
								<p style={{marginBottom: 0, height: "fit-content"}}>
									No calls found for the chosen date selection.
								</p>
							</Col>
						</Row>
					) : null}

					<Table size="sm" className="mb-0 card-table">
						<thead>
							<tr className="table-info">
								<th><span className="mb-1">Branch/ Vendor ID</span></th>
								<th>
									<Nav>
										<NavDropdown title={ userId != "all" ? crmUsers && crmUsers.find(x => x.id == userId).name : "Team" }>
											<Dropdown.Item style={{fontSize: "10px"}} onClick={this.selectUser} id="all">All</Dropdown.Item>

											{crmUsers && crmUsers.map((usr) =>
											{
												return (
													<Dropdown.Item style={{fontSize: "10px"}} key={usr.id} onClick={this.selectUser} id={usr.id}>{usr.name}</Dropdown.Item>
												);
											})}
										</NavDropdown>
									</Nav>
								</th>
								<th>
									{this.callDropdownMenu(
										"Type",
										callType,
										(event) => this.setState({callType: event.target.id || "all"}),
										this.context.GA_CONFIG.crmCalls.callTypes
									)}
								</th>
								<th>
									{this.callDropdownMenu(
										"Outcome",
										outcomeId,
										(event) => this.setState({outcomeId: event.target.id || "all"}),
										this.context.GA_CONFIG.crmCalls.outcomes
									)}
								</th>
								<th>
									{this.callDropdownMenu(
										<span>Contact</span>,
										contactMade,
										(event) => this.setState({contactMade: event.target.id || "all"}),
										["Contact made", "Contact not made"]
									)}
								</th>
								<th>
									{this.callDropdownMenu(
										<span>Why No <br/>Contact</span>,
										whyNoContactId,
										(event) => this.setState({whyNoContactId: event.target.id || "all"}),
										this.context.GA_CONFIG.crmCalls.noContactReasons
									)}
								</th>
								<th>
									{this.callDropdownMenu(
										<span>AWNC <br/>Reason</span>,
										awncReasonId,
										(event) => this.setState({awncReasonId: event.target.id || "all"}),
										this.context.GA_CONFIG.crmCalls.awncReasons
									)}
								</th>
								<th>
									{this.callDropdownMenu(
										<span>Email <br/>Resent</span>,
										emailResentId,
										(event) => this.setState({emailResentId: event.target.id || "all"}),
										this.context.GA_CONFIG.crmCalls.emailResentOptions
									)}
								</th>
								<th>
									{this.callDropdownMenu(
										<span>CC <br/>Added</span>,
										ccAddedId,
										(event) => this.setState({ccAddedId: event.target.id || "all"}),
										this.context.GA_CONFIG.crmCalls.ccAddedOptions
									)}
								</th>
								<th></th>
							</tr>
						</thead>

						<tbody>
							{Object.keys(callsMade).length > 0 && (callsMade.map((crmCall) =>
							{
								const agentHasContacted = (crmCall.branchStatus > this.context.GA_CONFIG.vp.branchStatus.VAL_INTRO_DONE) && (crmCall.branchStatus <= this.context.GA_CONFIG.vp.branchStatus.AGENT_CHOSEN);

								if(!this.filterOutRow(crmCall, agentHasContacted))
									return <CallRow key={crmCall.id} crmCall={crmCall} agentHasContacted={agentHasContacted} />;
							}))}
						</tbody>
					</Table>
				</Card>
			</>
		);
	}
}

export default WagyuCalls;