import React from "react";
import { ProgressBar } from "react-bootstrap";

const TargetBanner = ({ title, current, target, text }) =>
{
	let percentage = 100;

	if(target === 0)
		percentage = 100 - current;
	else
		percentage = (current / target) * 100;

	if(percentage <= 0)
		percentage = 1;

	return (
		<div className="wallboard-card">
			<p className="wallboard-heading">{title}</p>
			{current ?
				<>
					<p className="xl-wallboard-heading">{current} {percentage >= 100 ? "🏆" : null}</p>
					<div>
						<ProgressBar
							style={{ height: "12px" }}
							variant={percentage >= 100 ? "success" : "info"}
							striped={percentage <= 100}
							now={percentage}
						/>
						<div className="wallboard-text"  style={{ display: "flex", justifyContent: "space-between" }}>
							<p>{target !== 0 ? parseInt(percentage) + "%" : ""}</p>
							<p>{text}</p>
						</div>
					</div>
				</>
				:
				<div className="wallboard-text">Nothing to show yet</div>}
		</div>
	);
};


export { TargetBanner };