import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	FormControl,
	Button,
	Form
} from "react-bootstrap";
import _ from "underscore";

import { getAPI } from "utils/requestAPI";

const VendorAddressEdit = ({ postcode, address = {}, onPostcodeChange, onAddressChange }) =>
{
	const [ addresses, setAddresses ] = useState(null);

	const [ loading, setLoading ] = useState(false);

	const fetchAddresses = async(isSearching) =>
	{
		try
		{
			setLoading(true);

			const sanitisedPostcode = postcode.trim().replace(" ", "");

			const res = await getAPI(`/address/list?postcode=${sanitisedPostcode}`);

			if(res.result?.length > 0)
			{
				const addresses = res.result.map(a => ({
					address1: _.compact([a.line_1, a.line_2, a.line_3]).join(", "),
					udprn: a.udprn,
				}));

				const withManual = (!isSearching && !address.udprn) ? [...addresses, address] : addresses;

				setAddresses(withManual);

				if(!address.address1)
					onAddressChange(addresses[0]);
			}
			else
			{
				setAddresses([]);

				onAddressChange({ address1: "", udprn: null });
			}
		}
		catch (e)
		{
			alert(e);

			setAddresses(null);

			onAddressChange({ address1: "", udprn: null });
		}

		setLoading(false);
	};

	useEffect(() =>
	{
		fetchAddresses();
	}, []);

	const handlePostcodeChange = (e) =>
	{
		setAddresses(null);

		onPostcodeChange(e);
	};

	const handleAddressChange = (e) =>
	{
		if(e.currentTarget.value == "address-not-listed")
		{
			const newAddress = prompt("Enter the unlisted address (without postcode).\n\nPlease be 100% sure this address does exist and the postcode is correct. Please also send address to bugs@getagent.co.uk!");

			if(newAddress && newAddress.length > 1)
			{
				const address = {address1: newAddress, udprn: ""};

				setAddresses([...addresses, address]);
				onAddressChange(address);
			}

			return;
		}

		const address = e.currentTarget.value.startsWith("manual-")
			? { address1: e.currentTarget.value.replace("manual-", ""), udprn: ""}
			: addresses.find(address => address.udprn === parseInt(e.currentTarget.value));

		if(address)
			onAddressChange(address);
	};

	return (
		<>
			<Row className="mb-3">
				<Col className="d-flex">
					<FormControl
						type="text"
						value={postcode}
						onChange={handlePostcodeChange}
						placeholder="Postcode"
					/>
					<Button
						className="ml-4"
						onClick={() => fetchAddresses(true)}
						disabled={loading}
					>
						{loading ? "Searching" : "Search"}
					</Button>
				</Col>
			</Row>

			{
				(postcode && addresses) ?
					<Row className="mb-3">
						<Col>
							{
								addresses.length > 0 ?
									<Form.Control
										as="select"
										value={address.udprn || "manual-" + address.address1}
										onChange={handleAddressChange}
									>
										{
											addresses.map((address, i) =>
												<option
													key={i}
													value={address.udprn || "manual-" + address.address1}
													style={{backgroundColor: address.udprn ? null : "red"}}
												>
													{address.address1} {address.udprn ? null : "(manual)"}
												</option>
											)
										}
										<option
											value="address-not-listed"
											style={{backgroundColor: "yellow"}}
										>
										Address not listed?
										</option>
									</Form.Control> :
									"Sorry, no addresses found for that postcode"
							}
						</Col>
					</Row>
					: null
			}
		</>
	);
};

export default VendorAddressEdit;