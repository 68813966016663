import React from 'react';
import _ from 'underscore';
import { gaNiceFormatPrice, adminPanelURL } from 'utils/common';
import { Collapse, Row, Col, Badge, Card } from 'react-bootstrap';
import VendorsWithInvoices from './vendorsWithInvoices';
import { FaPhone } from 'react-icons/fa';
import { getAPI } from "utils/requestAPI";

class BranchWithInvoice extends React.Component {
	
	constructor(props) 
	{
		super(props);
		this.state = 
		{
			expand: false,
			vendors: null
		}

		this.getVendorDetails = this.getVendorDetails.bind(this);
		this.openBranchCallBox = this.openBranchCallBox.bind(this);
	}

	toggleExpand()
	{
		this.setState({
			expand: !this.state.expand
		}, () => {
			
			// fetch vendor details
			if(this.state.expand)
			{
				this.getVendorDetails(this.props.vendors);
			}

		});
	}

	openBranchCallBox(e)
	{
		e.preventDefault();
		return window.open(adminPanelURL(`/branch/${this.props.id}/phone/`));
	}

	async getVendorDetails(vendors)
	{
		const slimDownToId = _.map(vendors, v => v.vendorId);

		try {
			const resp = await getAPI(`/admin/invoice/getVendorDetails.json?vendors=${JSON.stringify(slimDownToId)}`);

			this.setState({
				vendors: resp.vendors
			});
		}
		catch(e)
		{
			throw new Error('Could not load vendors.');
		}

	}
	
	render()
	{
		const { vendors, branchName, countInvoices, totalDue, id } = this.props;

		const branchHeader = () => {
			
			let dynamicLabel = null;

			const isActiveFilter = this.props.filterBy;

			if(isActiveFilter === 'voided')
			{
				dynamicLabel = <Badge variant="success">Total Voided: {'£' + gaNiceFormatPrice(Math.abs(totalDue))}</Badge>
				
			}
			else if(isActiveFilter == 'writeOff')
			{
				dynamicLabel = <Badge variant="success">Total Writeoff: {'£' + gaNiceFormatPrice(Math.abs(totalDue))}</Badge>
			}
			else
				dynamicLabel = <Badge variant="danger">Total Due: {'£' + gaNiceFormatPrice(totalDue)}</Badge>
	
	
			
			return 	<Row>
						<Col md={6}>
							<span style={{cursor:'pointer'}} onClick={this.toggleExpand.bind(this)}>{this.state.expand? '➖' : '➕'}</span>
							{'  '}
							{branchName}
						</Col>
						<Col  md={6}>
							<div style={{float:'right'}}>
								{countInvoices > 1 ? <Badge>Invoices: {countInvoices}</Badge> : null}
								{dynamicLabel}
								{' '}
								{countInvoices > 1 ? <a href={'#'} onClick={this.openBranchCallBox}><FaPhone /></a> : null}
							</div>
						</Col>
					</Row>
		}
		

		const branchPanel = (
			<Card className="my-3">
				<Card.Header>{branchHeader()}</Card.Header>
					<Collapse in={this.state.expand}>
						<Card.Body>
							{vendors.map(vendor => 
							{
								return (
									<VendorsWithInvoices
										key={vendor.vendorId} 
										vendorInvoices={vendor.invoices}
										vendorId={vendor.vendorId}
										whyNotPaid={this.state.vendors && this.state.vendors[vendor.vendorId] && this.state.vendors[vendor.vendorId].whyNotPaid}
										nextAction={this.state.vendors && this.state.vendors[vendor.vendorId] && this.state.vendors[vendor.vendorId].nextAction}
										branchId={id}
									/>
								)
							})}
						
						</Card.Body>
					</Collapse>
			</Card>
		)
		
		return (
			<div>
				{branchPanel}
			</div>
		)
	}
}

export default BranchWithInvoice;