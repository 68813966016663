import moment from "moment";
import _ from "underscore";

const checkPowerHour = () =>
{
	// Get the current time
	const now = moment();

	// Define power hour ranges
	const powerHourMorningStart = moment().hour(11).minute(30)
		.second(0);
	const powerHourMorningEnd = moment().hour(12).minute(30)
		.second(0);
	const powerHourAfternoonStart = moment().hour(15).minute(0)
		.second(0);
	const powerHourAfternoonEnd = moment().hour(16).minute(0)
		.second(0);

	// Check if current time is within power hour ranges and calculate remaining time
	if(now.isSameOrAfter(powerHourMorningStart) && now.isBefore(powerHourMorningEnd))
	{
		const duration = moment.duration(powerHourMorningEnd.diff(now));

		return {
			formattedTimeAndDate: moment(now).format("ddd, DD MMMM HH:mm"),
			isPowerHour: true,
			minutes: Math.floor(duration.asMinutes()),
			seconds: duration.seconds(),
		};
	}
	else if(now.isSameOrAfter(powerHourAfternoonStart) && now.isBefore(powerHourAfternoonEnd))
	{
		const duration = moment.duration(powerHourAfternoonEnd.diff(now));

		return {
			formattedTimeAndDate: moment(now).format("ddd, DD MMMM HH:mm"),
			isPowerHour: true,
			minutes: Math.floor(duration.asMinutes()),
			seconds: duration.seconds(),
		};
	}

	return {
		formattedTimeAndDate: moment(now).format("ddd, DD MMMM HH:mm"),
		isPowerHour: false,
		minutes: 0,
		seconds: 0,
	};
};

const calculateStats = (csUsers, totalValsArranged, settingsRef) =>
{
	const callSuperstarLeaderboard = _.sortBy(csUsers.filter(user => user.conversionScore), "conversionScore");
	const conversionSuperstar = _.last(callSuperstarLeaderboard);
	const csValsArranged = csUsers.reduce((acc, user) => (user.vals ? acc + user.vals : acc), 0);
	const csUpSells = csUsers.reduce((acc, user) => (user.upSells ? acc += user.upSells : acc), 0);
	const teamStats = settingsRef?.current?.teams?.map((team, index) =>
	{
		const teamStats = {
			teamName: settingsRef?.current.teamNames[index],
			calls: 0,
			conversations: 0,
			vals: 0,
		};

		team.map((memberId) =>
		{
			csUsers.map((user) =>
			{
				if(memberId === user.csUserId)
				{
					teamStats.calls += user.calls ? user.calls : 0;
					teamStats.conversations += user.conversations ? user.conversations : 0;
					teamStats.vals += user.vals ? user.vals : 0;
				}
			});
		});

		return teamStats;
	});

	const stats = {
		callLeaderboard: _.sortBy(csUsers.filter((user) => user.calls),"calls").reverse().slice(0, 3),
		conversationsLeaderboard: _.sortBy(csUsers.filter((user) => user.conversations),"conversations").reverse().slice(0, 3),
		valsLeaderboard: _.sortBy(csUsers.filter((user) => user.vals),"vals").reverse().slice(0, 3),
		conversionSuperstar: conversionSuperstar ? conversionSuperstar : "",
		valsArranged: csValsArranged,
		teamStats: teamStats,
		autoVals: totalValsArranged.vals - csValsArranged,
		csUpSells: csUpSells,
	};

	return stats;
};

export { checkPowerHour, calculateStats };
