import React, { useEffect, useContext } from "react";
import { Row } from "react-bootstrap";
import $ from "jquery";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import _ from "underscore";
import { adminPanelURL } from "utils/common";

const RevChasingStats =  ({ updateStats }) =>
{

	const { GA_CONFIG } = useContext(GAConfigContext);

	const groupByAdminUser = _.groupBy(GA_CONFIG.admins, "id");

	const darkGreen = "#006400";
	const lightGrey = "#D3D3D3";
	const lightBrown = "#d0b28b";
	const darkBlue = "#1d2951";
	const lightRed = "#ff6961";

	const legend = () =>
	{
		return (
			<Row className="justify-content-center align-items-center mt-5 mb-2 flex-wrap">
				<div style={{height: "15px", width: "15px", backgroundColor: darkGreen, marginRight: "7px", marginLeft: "5px"}}></div>
				Updated by agent
				<div style={{height: "15px", width: "15px", backgroundColor: darkBlue, marginRight: "7px", marginLeft: "5px"}}></div>
				Updated by GA
				<div style={{height: "15px", width: "15px", backgroundColor: lightBrown, marginRight: "7px", marginLeft: "5px"}}></div>
				Archived
				<div style={{height: "15px", width: "15px", backgroundColor: lightGrey, marginRight: "7px", marginLeft: "5px"}}></div>
				No update
				<div style={{height: "15px", width: "15px", backgroundColor: lightRed, marginRight: "7px", marginLeft: "5px"}}></div>
				No Agent Chosen
			</Row>
		);
	};

	const drawGitHubLike = (lastUpdateRows, archived, inBadState) =>
	{
		if(! lastUpdateRows || ! archived)
			return null;

		const squareSide = 10, // this size INCLUDES the padding
			maxWidth = 600,
			padding = 1;

		const squaresPerRow = Math.floor(maxWidth / squareSide);
		const numberOfProps = lastUpdateRows.length;

		const numberOfRows = Math.ceil(numberOfProps / squaresPerRow);

		const maxHeight = numberOfRows * squareSide;

		const canvas = $("#revChaseStats").get(0);

		canvas.setAttribute("width", maxWidth);
		canvas.setAttribute("height", maxHeight);

		const ctx = canvas.getContext("2d");

		lastUpdateRows.forEach((update, i) =>
		{
			const col = Math.floor(i % squaresPerRow);
			const row = Math.floor(i / squaresPerRow);
			const x = col * squareSide;
			const y = row * squareSide;

			const updateArchived = !! archived[update.vendor_prop_id];
			const outcomeUpdatedByAgent = update.outcome_updated_by ? (groupByAdminUser[update.outcome_updated_by] ? false : true) : false;
			const outcomeUpdatedByGA = update.outcome_updated_by ? (groupByAdminUser[update.outcome_updated_by] ? true : false) : false;
			const leftInBadState = !! inBadState[update.vendor_prop_id];

			if(updateArchived)
				ctx.fillStyle = lightBrown;
			else if(leftInBadState)
				ctx.fillStyle = lightRed;
			else if(outcomeUpdatedByAgent)
				ctx.fillStyle = darkGreen;
			else if(outcomeUpdatedByGA)
				ctx.fillStyle = darkBlue;
			else
				ctx.fillStyle = lightGrey;

			ctx.fillRect(x + padding/2, y + padding/2, squareSide - padding, squareSide - padding);

		});

		// remove click handler first just to make sure we don't have multiple handlers (if this mounts multiple times)
		$("#revChaseStats").off("click").on("click", (ev) =>
		{
			const posX = $("#revChaseStats").position().left,
            	posY = $("#revChaseStats").position().top;

			const canvasX = ev.pageX - posX,
            	canvasY = ev.pageY - posY;

			// calculate which element we're clicking on
			const elIndex = (Math.floor(canvasY/squareSide) * squaresPerRow) + Math.floor(canvasX/squareSide);

			window.open(adminPanelURL("/vendor/property/" + lastUpdateRows[elIndex].vendor_prop_id + "/edit"));

		});

	};

	useEffect(() =>
	{

		if(! updateStats)
			return null;

		const { lastUpdateRows, archived, inBadState } = updateStats;

		// only draw after initial render
		drawGitHubLike(lastUpdateRows, archived, inBadState);

	}, [updateStats]);


	return (
		<>

			{legend()}

			<canvas id="revChaseStats" width="1000" height="100" style={{cursor: "pointer"}} />

		</>
	);

};

export default RevChasingStats;