import React, { useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { getAPI } from "utils/requestAPI";

const TagValueInput = ({ tagValue, onValueChange, tagId, tagType, tagListValues }) =>
{

	const [tagAutoValues, setTagAutoValues] = useState(null);

	useEffect(() => 
	{
		fetchTagValue();
	}, [tagId]);


	const fetchTagValue = async() =>
	{
		if(tagType === "autoString")
		{
			const res = await getAPI("/admin/tags/" + tagId);

			setTagAutoValues(res.values);
		}
		else
		{
			setTagAutoValues(null);
		}
	};

	if(! tagId || (tagId < 0))
		return null;

	if(tagType === "list")
	{
		return (<>
			<FormControl
				as="select"
				value={tagValue ? tagValue : "-1"}
				onChange={(e)=> onValueChange(e.target.value)}
			>
				<option value={"-1"} disabled></option>
				{tagListValues.map(v => <option key={v} value={v}>{v}</option>)}
			</FormControl>
		</>);
	}
	else if(tagType === "autoString")
	{
		return (<>
			<Typeahead
				onChange={(selected)=>
				{
					// this Typeahead is the worst thing ever
					if(! selected || selected.length !== 1)
						return onValueChange("");

					onValueChange(selected[0].label || selected[0]);
				}}
				allowNew={true}
				placeholder={"Start typing to see a list of values"}
				options={tagAutoValues}
				selected={tagValue ? [tagValue] : null}
				id="autoStringTypeahead"
			/>
		</>);
	}
	else if(tagType === "boolean")
	{
		return (<>
			<FormControl
				as="select"
				value={tagValue ? tagValue : "-1"}
				onChange={(e)=> onValueChange(e.target.value)}
			>
				<option value={"-1"} disabled></option>
				<option value={"0"}>No</option>
				<option value={"1"}>Yes</option>
			</FormControl>
		</>);
	}

	return (<>
		<FormControl
			type="text"
			placeholder={"Enter value"}
			value={tagValue}
			onChange={(e)=> onValueChange(e.target.value)}
		>
		</FormControl>
	</>);

};


export default TagValueInput;