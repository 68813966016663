import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { getAPI } from "utils/requestAPI";

class FujisanBacklogStats extends React.PureComponent
{

	constructor(props)
	{
		super(props);
		this.state =
		{
			callsOneNotDone: null,
			incomingDue: null,
			dueCalls: null,
			percentageOfCallsInLessThanThirtyMinutes: 0,
			percentageNotCalled: 0
		};
	}

	componentDidMount()
	{
		this.fetchBacklogStats();
	}

	fetchBacklogStats = () =>
	{
		getAPI("/admin/vendor/customerService/backlog.json")
			.then(data =>
			{

				if(data.noResults)
				{
					this.setState({errorMsg: data.msg});
				}
				else
				{
					const { callsOneNotDone, incomingDue, dueCalls, percentageOfCallsInLessThanThirtyMinutes, percentageNotCalled } = data;

					this.setState({
					callsOneNotDone,
					incomingDue,
					dueCalls,
					percentageOfCallsInLessThanThirtyMinutes,
					percentageNotCalled
					});
				}

			})
			.catch(e =>
			{
				this.setState({errorMsg: e.message});
			});
	}

	render()
	{
		const { incomingDue, dueCalls, percentageOfCallsInLessThanThirtyMinutes, percentageNotCalled } = this.state;

		return (
			<Row align="center" className="mt-4">
				<Col sm={6} xs={12}>
					<Card style={{backgroundColor : "#d9edf7"}}>
						<Card.Body>
							<Row>
								<Col sm={6} xs={12}>
									<Card>
										<Card.Header className="bg-success text-white">
											Incoming
										</Card.Header>
										<Card.Body>
											<h3 align="center">
												&nbsp; ⏱ &nbsp; {incomingDue}
											</h3>
										</Card.Body>
									</Card>
								</Col>

								<Col sm={6} xs={12}>
									<Card>
										<Card.Header className="bg-danger text-white">Overdue</Card.Header>
										<Card.Body>
											<h3 align="center">
												&nbsp; ❗️ &nbsp; {dueCalls} <br/>
											</h3>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>


				<Col sm={6} xs={12}>
					<Card style={{"background-color" : "#d9edf7"}}>
						<Card.Body>
							<Row>
								<Col sm={6} xs={12}>
									<Card>
										<Card.Header className="bg-primary text-white">
											Incoming
										</Card.Header>
										<Card.Body>
											<h3 align="center">
												&nbsp; ⌛ &nbsp; {percentageOfCallsInLessThanThirtyMinutes && percentageOfCallsInLessThanThirtyMinutes.toFixed(1)}%
											</h3>
										</Card.Body>
									</Card>
								</Col>

								<Col sm={6} xs={12}>
									<Card>
										<Card.Header className="bg-primary text-white">Overdue</Card.Header>
										<Card.Body>
											<h3 align="center">
												&nbsp; 🧑 &nbsp; {percentageNotCalled && percentageNotCalled.toFixed(1)}%
											</h3>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		);
	}
}

export default FujisanBacklogStats;