import React from "react";
import { CardGroup, Card, Col } from "react-bootstrap";

class WagyuCards extends React.Component
{
	constructor(props)
	{
		super(props);
	}

	cardItem = (title, body) =>
	{
		return (
			<Card className="text-center mb-lg-0 mb-3">
				<Card.Body>
					<div className="text-center">
						<small className="text-muted">{title}</small>
					</div>
					<h2 align="center">
						<br />
						{body}
					</h2>
				</Card.Body>
			</Card>
		);
	};

	render()
	{
		const {
			callsMade = 0,
			callsAnswered = 0,
			contactRate = 0,
			awnc = 0,
			actionable = 0,
			totalTimeOnPhone = 0
		} = this.props.stats;

		return (
			<>
				<CardGroup className="row mb-3">
					<Col lg={2}>
						{this.cardItem("Calls made", callsMade)}
					</Col>
					<Col lg={2}>
						{this.cardItem("Calls answered", callsAnswered)}
					</Col>
					<Col lg={2}>
						{this.cardItem("AWNC", awnc)}
					</Col>
					<Col lg={2}>
						{this.cardItem("Conversion rate", `${contactRate} %`)}
					</Col>
					<Col lg={2}>
						{this.cardItem("Actionable", `${actionable} %`)}
					</Col>
					<Col lg={2}>
						{this.cardItem("Total time on phone", `${totalTimeOnPhone} min`)}
					</Col>
				</CardGroup>
			</>
		);
	}
}

export default WagyuCards;