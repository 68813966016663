import React, { createContext, useState, useContext, useMemo } from "react";
import { GAConfigContext } from "./gaConfigContext";

const BdCrmContext = createContext();

const BdCrmContextProvider = props =>
{
	const { GA_CONFIG } = useContext(GAConfigContext);
	const bdAdmins = useMemo(() => GA_CONFIG.admins.filter(user => user.getUserAdminOptions.includes(GA_CONFIG.adminOpts.ARE_SALES_TEAM)), [GA_CONFIG]);

	const [filterUser, setFilterUser] = useState(bdAdmins.includes(e => e.id === GA_CONFIG.user.id) ? GA_CONFIG.user.id : null);

	const [prevCallId, setPrevCallId] = useState(null);

	const [scrollPosition, setScrollPosition] = useState(null);

	const clearPrevCallAndScroll = () =>
	{
		setPrevCallId(null);
		setScrollPosition(null);
	};

	return <BdCrmContext.Provider value={{ scrollPosition, setScrollPosition, bdAdmins, filterUser, setFilterUser, prevCallId, setPrevCallId, clearPrevCallAndScroll }}>{props.children}</BdCrmContext.Provider>;
};

export { BdCrmContext, BdCrmContextProvider };