import React from "react";
import _ from "underscore";
import { getAPI, postAPI } from "utils/requestAPI";
import TagEdit from "../shared/tagEdit";

const VendorTagEdit = ({
	vendorPropId,
	vendorPropTags,
	onSaved,
	isEditable
}) =>
{
	const handleFetchTags = async() =>
	{
		const res = await getAPI("/admin/tags.json");

		return _.filter(res.tags, t => t.tagTypes.VENDOR_PROP);
	};

	const handleSave = (chosenTagId, tagValue) =>
	{
	  return postAPI("/admin/vendor/" + vendorPropId + "/tag", {
				tagId: chosenTagId,
				value: tagValue
		});
	};

	const handleDelete = async(id) =>
	{
		await postAPI("/admin/vendor/" + vendorPropId + "/tag/" + id, {}, { method: "DELETE" });

		onSaved();
	};

	return (
		<TagEdit
			tags={vendorPropTags}
			onFetchTags={handleFetchTags}
			onSave={handleSave}
			onSaved={onSaved}
			onDelete={handleDelete}
			isEditable={isEditable}
		/>
	);
};

export default VendorTagEdit;
