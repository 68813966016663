import React from 'react';

class DocumentTitle extends React.Component {
	constructor(props)
	{
		super(props);
		
	}

	render()
	{
		document.title = this.props.title ?  this.props.title : '';
		return null;
	}
}

export default DocumentTitle;