import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import googleSignIn from "images/google-sign-in.png";


const Login = () => (
	<Container>
		<Row className="justify-content-center">
			<Col xs="12" md="5" xl="4" className="mb-5 mt-8">
				<div>
					<h1 className="text-center display-4 mb-3">Login</h1>
					<a className="mt-4 d-block text-center" href={process.env.REACT_APP_API_BASEPATH + "/auth/login/google"}>
						<img src={googleSignIn} height="46px" width="191px"/>
					</a>
					<p className="text-center text-muted my-4">
						Forgot your Google password? Unfortunately you'll need to find a new job.
						<br />
						(Or talk to the Office Manager to reset.)
					</p>
				</div>
			</Col>
		</Row>
	</Container>
);

export default Login;