import React from "react";
import {Nav, Navbar, Container, Form } from "react-bootstrap";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import VendorStateList from "./vendorStateList";
import DocumentTitle from "../shared/documentTitle";
import _ from "underscore";
import "../../styles/vendorIndex.css";

class VendorIndex extends React.Component
{
	static contextType = GAConfigContext;

	state = {
		userFilter: -1,
		mouseOverState: null
	};

	onUserChange(e)
	{
		this.setState({userFilter: parseInt(e.target.value)});
	}

	onMouseOver(state)
	{
		this.setState({mouseOverState: state});
	}

	onMouseOut(state)
	{
		this.setState({mouseOverState: null});
	}

	render()
	{

		return (
			<div className="vendor-props">
				<DocumentTitle title="Vendor CRM" />

				<Container >
					<Navbar bg="light" expand="lg" variant="light">
						<Container className="px-2">

							<Navbar.Brand>
								{(this.state.userFilter === -1) && "Showing All"}
								{(this.state.userFilter === 0) && "Unassigned Properties"}
								{(this.state.userFilter === this.context.GA_CONFIG.user.id) && "My Properties"}
								{(this.state.userFilter !== this.context.GA_CONFIG.user.id) && (this.state.userFilter > 0) && _.findWhere(this.context.GA_CONFIG.admins, {id: this.state.userFilter}).name + "'s Properties"}

							</Navbar.Brand>

							<Form inline>
								<Form.Control as="select" placeholder="select" value={this.state.userFilter} onChange={this.onUserChange.bind(this)}>
									<option value="-1">Show all properties</option>
									<option disabled>Properties assigned to:</option>
									<option value="0">No one</option>
									{this.context.GA_CONFIG.admins.map(a =>
									{
										return (
											<option value={a.id} key={a.id}>{a.id === this.context.GA_CONFIG.user.id ? "Me" : a.name}</option>
										);
									})}
								</Form.Control>
							</Form>

						</Container>
					</Navbar>
				</Container>


				<section className="storyboard">
					<VendorStateList status="NEW" niceName="New" maxNoteAge={3}
						userFilter={this.state.userFilter > 0 ? this.state.userFilter : false} unAssigned={this.state.userFilter === 0}
						onMouseOver={this.onMouseOver.bind(this)} onMouseOut={this.onMouseOut.bind(this)}
					/>

					{/*
					<VendorStateList status="GATHERING_QUOTES" niceName="Gathering quotes" maxNoteAge={2}
						userFilter={this.state.userFilter > 0 ? this.state.userFilter : false} unAssigned={this.state.userFilter === 0}
						onMouseOver={this.onMouseOver.bind(this)} onMouseOut={this.onMouseOut.bind(this)}
						 />
					*/}

					<VendorStateList status="READY_FOR_VALS" niceName="Choose val agents" maxNoteAge={7}
						userFilter={this.state.userFilter > 0 ? this.state.userFilter : false} unAssigned={this.state.userFilter === 0}
						onMouseOver={this.onMouseOver.bind(this)} onMouseOut={this.onMouseOut.bind(this)}
					/>

					<VendorStateList status="VALUATION_REQ" niceName="Val requested" maxNoteAge={1}
						userFilter={this.state.userFilter > 0 ? this.state.userFilter : false} unAssigned={this.state.userFilter === 0}
						onMouseOver={this.onMouseOver.bind(this)} onMouseOut={this.onMouseOut.bind(this)}
					/>

					<VendorStateList status="INTROS_DONE" niceName="Intros done" passive maxNoteAge={14}
						userFilter={this.state.userFilter > 0 ? this.state.userFilter : false} unAssigned={this.state.userFilter === 0}
						onMouseOver={this.onMouseOver.bind(this)} onMouseOut={this.onMouseOut.bind(this)}
					/>

					<VendorStateList status="AGENT_CHOSEN" niceName="Agent chosen" passive maxNoteAge={14}
						userFilter={this.state.userFilter > 0 ? this.state.userFilter : false} unAssigned={this.state.userFilter === 0}
						onMouseOver={this.onMouseOver.bind(this)} onMouseOut={this.onMouseOut.bind(this)}
					/>

					<VendorStateList status="LISTING_LIVE" niceName="Listing live" passive maxNoteAge={75}
						userFilter={this.state.userFilter > 0 ? this.state.userFilter : false} unAssigned={this.state.userFilter === 0}
						onMouseOver={this.onMouseOver.bind(this)} onMouseOut={this.onMouseOut.bind(this)}
					/>

					<VendorStateList status="SALE_AGREED" niceName="Sale agreed" passive maxNoteAge={21}
						userFilter={this.state.userFilter > 0 ? this.state.userFilter : false} unAssigned={this.state.userFilter === 0}
						onMouseOver={this.onMouseOver.bind(this)} onMouseOut={this.onMouseOut.bind(this)}
					/>

					<VendorStateList status="SALE_COMPLETE" niceName="Sale done" maxNoteAge={30}
						userFilter={this.state.userFilter > 0 ? this.state.userFilter : false} unAssigned={this.state.userFilter === 0}
						onMouseOver={this.onMouseOver.bind(this)} onMouseOut={this.onMouseOut.bind(this)}
					/>

					<VendorStateList status="MONEY_PAID" niceName="Money paid" passive
						userFilter={this.state.userFilter > 0 ? this.state.userFilter : false} unAssigned={this.state.userFilter === 0}
						onMouseOver={this.onMouseOver.bind(this)} onMouseOut={this.onMouseOut.bind(this)}
					/>
				</section>

				{this.state.mouseOverState && (
					<div className="floater">
						<span>{this.state.mouseOverState}</span>
					</div>
				)}
			</div>
		);
	}

}

export default VendorIndex;
