import React from 'react';
import { FormControl, InputGroup, Form } from 'react-bootstrap';
import moment from 'moment';

class EditableText extends React.Component {

	linkClick(e)
	{
		e.preventDefault();

		if(this.props.openLinkInWindow)
		{
			window.open(this.props.href, this.props.openLinkInWindow.name || "default", this.props.openLinkInWindow.size || "");
		}
		else
		{
			window.location = this.props.href;
		}
	}

	render()
	{

		let link = null;

		if(this.props.icon && this.props.href)
		{
			link = (
				<a onClick={this.linkClick.bind(this)} href="#">
					{this.props.icon}
				</a>);
		}

		let value = this.props.value;

		if((this.props.type === "date") && (this.props.value))
			value = moment(this.props.value).format('YYYY-MM-DD');
		if((this.props.type === "datetime-local") && (this.props.value))
			value = moment(this.props.value).format('YYYY-MM-DDTHH:mm');

		if(this.props.editing)
		{
			if(this.props.currency)
			{
				return (
					<InputGroup>
						<InputGroup.Prepend><InputGroup.Text>&pound;</InputGroup.Text></InputGroup.Prepend>
						<FormControl 
							type={this.props.type || "text"} 
							value={value} 
							onChange={this.props.onValueChanged} 
							className={this.props.validationState ? "is-invalid" : null} />
					</InputGroup>	
				);
			}
			else if(this.props.percent)
			{
				return (
					<InputGroup>
						<FormControl 
							type={this.props.type || "text"} 
							value={value} 
							onChange={this.props.onValueChanged}
							className={this.props.validationState ? "is-invalid" : null} />
						<InputGroup.Append><InputGroup.Text>%</InputGroup.Text></InputGroup.Append>
					</InputGroup>
				);
			}
			else
			{
				return (
					<FormControl 
						type={this.props.type || "text"} 
						value={value} 
						onChange={this.props.onValueChanged}
						className={this.props.validationState ? "is-invalid" : null}
						 />
				);
			}
			
		}
		else
		{
			let value = this.props.value;

			if(this.props.formatter)
				value = this.props.formatter(value);

			if(link)
			{
				return (
					<div className="form-control-plaintext">
						{value} {link}
					</div>
				);
			}
			else
			{
				return <Form.Control plaintext readOnly value={value} />;
			}
		}
	}
}


export default EditableText;