import React from "react";
import moment from "moment";
import { getAPI } from "utils/requestAPI";
import { Table, Card, Row, Col, Spinner } from "react-bootstrap";
import { adminPanelURL } from "utils/common";
import tickBlue from "images/icons/tick-blue.png";
import iconAlert from "images/icons/icon-alert.png";

class WagyuBacklog extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			loading: false,
			branchesNotContacted: [],
			totalFollowups: 0
		};
	}

	componentDidMount = () =>
	{
		this.fetchVendorsWaitingForVals();
	}

	componentDidUpdate = (prevProps) =>
	{
		if(prevProps.to !== this.props.to || prevProps.from !== this.props.from)
			this.fetchVendorsWaitingForVals();
	}

	fetchVendorsWaitingForVals = async() =>
	{
		const { to, from } = this.props;

		this.setState({loading: true});

		try
		{
			const data = await getAPI(`/admin/vendorsWaitingForVals.json?&from=${from}&to=${to}`);

			this.setState({
				branchesNotContacted: data.branchesNotContacted || [],
				totalFollowups: data.totalFollowUps || 0,
				loading: false,
			});
		}
		catch (err)
		{
			this.setState({errorMsg: err.message});
		}
	}

	render()
	{
		const {
			branchesNotContacted,
			totalFollowups
		} = this.state;

		if(this.state.loading)
		{
			return <div className="text-center mt-5">
				<Spinner size="lg" variant="primary" animation="border"/>
			</div>;
		}

		return (
			<Card>
				<Row>
					<Col className="p-4 flex" style={{textAlign: "center"}}>
						<p style={{marginBottom: 0, height: "fit-content"}}>
							We've followed up on <b>{totalFollowups}</b> of the <b>{branchesNotContacted.length}</b> branches which have yet to contact vendors (last 4 days).
						</p>
					</Col>
				</Row>

				<Table size="sm" className="mb-0 card-table" responsive>
					<thead>
						<tr className="table-info">
							<th><span className="mb-1">Vendor</span></th>
							<th><span className="mb-1">Branch</span></th>
							<th><span className="mb-1">Chased</span></th>
							<th><span className="mb-1">Valuation requested</span></th>
							<th><span className="mb-1">Valuation sent</span></th>
							<th><span className="mb-1">Age</span></th>
						</tr>
					</thead>

					<tbody>
						{Object.keys(branchesNotContacted).length > 0 && (branchesNotContacted.map((br) =>
						{
							return (
								<tr key={`${br.bId}-${br.vpId}`}>
									<td><a href={adminPanelURL(`/vendor/property/${br.vpId}/edit`)} rel="noreferrer" target="_blank">{br.vpId}</a></td>
									<td><a href={adminPanelURL(`/branch/${br.bId}/edit`)} rel="noreferrer" target="_blank">{br.bName}</a></td>
									<td style={{textAlign: "center"}}>
										{
											br.numFollowups > 0 ?
												<img src={tickBlue} width="24" />
												:
												br.daysAgo > 3 ?
													<span style={{fontSize: "18pt"}}>😱</span>
													:
													<img src={iconAlert} width="24" />
										}
									</td>
									<td>{moment(br.valReqAt).format("DD MMM YYYY (HH:mm)")}</td>
									<td>{moment(br.valNagFrom).format("DD MMM YYYY (HH:mm)")}</td>
									<td>
										{br.daysAgo < 1 ?
											Math.round(br.daysAgo * 24) + " hour(s)"
											: Math.round(br.daysAgo) + " day(s)"
										}
									</td>
								</tr>
							);
						}))}
					</tbody>
				</Table>
			</Card>
		);
	}
}

export default WagyuBacklog;