import React, { createContext, useEffect } from "react";
import { useState } from "react";
import { Spinner, Container, Row } from "react-bootstrap";
import Login from "components/login"
import { getAPI } from "utils/requestAPI";

export const GAConfigContext = createContext();

const GAConfigContextProvider = props =>
{

	const [GA_CONFIG, setGAConfig] = useState();
	const [errorMsg, setErrorMsg] = useState(null);

	async function doRequest()
	{
		try
		{
			const res = await getAPI("/admin/config.json");

			if(! res.GA_CONFIG)
				return;

			setGAConfig(res.GA_CONFIG);
			setErrorMsg(null);
		}
		catch (err)
		{
			console.error(err);

			if(process.env.NODE_ENV === "development")
				setErrorMsg("Can't connect to the API. Are you sure you're running the 'ga-api' project locally on port 4000?");
			else
				setErrorMsg("An error occurred when loading your user details. You may not be logged in.");

		}

	}

	useEffect(() =>
	{
		doRequest();

	}, [setGAConfig]);


	if(errorMsg)
		return (<Login errorMsg={errorMsg} />);

	if(!GA_CONFIG)
	{
		return (<Container >
			<Row className="justify-content-center">
				<Spinner  as="span" className="mt-5" animation="border" variant="primary" size="lg" />
			</Row>
		</Container>);
	}


	return (
		<GAConfigContext.Provider value={{ GA_CONFIG }}>
			{props.children}
		</GAConfigContext.Provider>
	);
};

export default GAConfigContextProvider;
