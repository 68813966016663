import _ from "underscore";
import { getPublicURLBasepath } from "utils/requestAPI";

export const publicURL = (endpoint) => getPublicURLBasepath() + endpoint;

export const adminPanelURL = (endpoint) => endpoint;

export const truncate = (str, strLength = 100) => (str.length > strLength) ? str.substr(0, strLength - 1) + "..." : str;

export function gaNiceFormatPrice(x, precision = 0)
{
	if(x === undefined || x === null)
		return "";

	if(_.isFinite(x))
		x = Math.round(x * 100)/100;

	if(! x.toFixed)
    	x = parseFloat(x);

	return x.toFixed(precision).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const csCallMapping = {
    1: "READY_FOR_VALS_1",
    2: "READY_FOR_VALS_2",
    3: "READY_FOR_VALS_3",
    4: "READY_FOR_VALS_4",
    5: "PROSPECT_READY_FOR_VALS_1",
    6: "PROSPECT_READY_FOR_VALS_2",
    7: "CALL_BACKS",
    8: "AUTO_UPSELL_1",
		9: "AUTO_UPSELL_2",
    10: "LL_8_WEEK",
		11: "REVISIT_1",
		12: "REVISIT_2"
};

export const callDataCols = ["Call 1", "Call 2", "Call 3", "Call 4", "Prospect Call 1", "Prospect Call 2", "Call Backs", "Auto Upsell 1", "Auto Upsell 2", "LL - 8 Weeks", "Revisit 1", "Revisit 2", "Total"];

// Call constants
export const CALLCLIENTS = {
	Plivo: "Plivo",
	Twilio: "Twilio",
};

export const PLIVOS = [
	{
		username: "getagent160112172045",
		password: "y8dfxeQZZdiEsdG7sYhX",
	},{
		username: "getagent542460304786739868712",
		password: "ZGdXsQdyidEZ7fhe8xYs",
	},{
		username: "getagent127469979662462603",
		password: "8QEiYGxefZhsdydXd7Zs",
	},{
		username: "getagent170615808144589",
		password: "ddsysxYZd7hEX8fQiGeZ",
	},{
		username: "getagent5455887549803212",
		password: "eXQ7f8hyxYiGdZEddssZ",
	},{
		username: "getagent192951228249708071",
		password: "dfYeX8yZd7ZdEQGxhsis",
	},{
		username: "getagent5839489584741122313482",
		password: "d7ZfsXxEsQGdiYe8Zhyd",
	},{
		username: "getagent539626268296371701393",
		password: "ds8GYQsXyheiEZ7dfZxd",
	},{
		username: "getagent6441226892350608",
		password: "7fyQXshdEZ8ddxeZYsiG",
	}
];

export function callDurationString(duration)
{
	if(duration > 60)
	{
		const mins = Math.floor(duration/60);
		let secs = Math.abs(duration) - (mins * 60);

		if(secs < 10)
			secs = "0" + secs;

		return mins + ":" + secs;
	}
	else if(duration > 10)
	{
		return duration + " sec";
	}
	else
	{
		return "Very short";
	}

}