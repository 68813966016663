import React, { useEffect, useState, useContext } from "react";
import _ from "underscore";
import { Alert, Table } from "react-bootstrap";
import { getAPI, postAPI } from "utils/requestAPI";
import { GAConfigContext } from "contexts/gaConfigContext";

const allCsCallTypes = {
	READY_FOR_VALS_1: "Call 1",
	READY_FOR_VALS_2: "Call 2",
	READY_FOR_VALS_3: "Call 3",
	READY_FOR_VALS_4: "Call 4",
	READY_FOR_VALS_5: "Call 5",
	PROSPECT_READY_FOR_VALS_1: "Prospect Call 1",
	PROSPECT_READY_FOR_VALS_2: "Prospect Call 2",
	CALL_BACK_1: "Call Back 1",
	CALL_BACK_2: "Call Back 2",
	CALL_BACK_3: "Call Back 3",
  AUTO_UPSELL_1: "Auto Upsell 1",
	AUTO_UPSELL_2: "Auto Upsell 2",
	LL_8_WEEK: "LL 8 Weeks",
	REVISIT_1: "Revisit 1",
	REVISIT_2: "Revisit 2"
};

const FujisanCallSettings = ({ csUsers }) =>
{
	const [settings, setSettings] = useState({});
	const { GA_CONFIG } = useContext(GAConfigContext);

	const hasAccess = GA_CONFIG.user.adminOptions[GA_CONFIG.adminOpts.ARE_EXTRA_PRIV];

	const getSettings = async() =>
	{
		const settingsResponse = await getAPI("/admin/calls/csOutboundSettings.json");

		setSettings(settingsResponse?.settings || {});
	};

	useEffect(() =>
	{
		if(hasAccess)
			getSettings();
	}, []);

	const updateSettings = async(updatedSettings) =>
	{
		try
		{
			// Skims off any users that are in the array but no longer a csUser. This ensures the user ids in the settings object don't become stale
			const csUserIds = csUsers.map(u => u.csUserId);
			const skimmedSettings = _.mapObject(updatedSettings, users => csUserIds.filter(id => users.includes(id)));

			await postAPI("/admin/calls/csOutboundSettings.json", { settings: skimmedSettings });

			setSettings(updatedSettings);
		}
		catch
		{
			alert("Sorry, the settings failed to update. Please try again or contact bugs@getagent.co.uk!");
		}
	};

	const handleCheckboxChange = (userId, callTypeKey) =>
	{
		const currentUsers = settings[callTypeKey] || [];
		const isChecked = currentUsers.includes(userId);

		const users = isChecked ? currentUsers.filter(id => id !== userId) : [...currentUsers, userId];

		updateSettings({ ...settings, [callTypeKey]: users });
	};

	const handleHeaderCheckboxChange = (callTypeKey) =>
	{
		const allChecked = csUsers.every(user => settings[callTypeKey]?.includes(user.csUserId));

		const users = allChecked ? [] : csUsers.map(u => u.csUserId);

		updateSettings({ ...settings, [callTypeKey]: users });
	};

	if(!hasAccess)
	{
		return <Alert
			variant="danger"
		>
			<h4>Sorry, but this functionality isn't available for you.</h4> Need access? Talk to your manager.
		</Alert>;
	}

	return (
		<div style={{ display: "flex" }}>
			<div style={{ display: "flex", width: "250px" }}>
				<Table striped responsive bordered size="sm" hover>
					<thead>
						<tr width="200px">
							<td style={{ minWidth: "160px" }}>User</td>
						</tr>
					</thead>
					<tbody>
						{csUsers.map((user, index) => (
							<tr key={index} height="60px">
								<td>
									<span style={{ display: "inline-block" }}>{user.name}</span>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</div>
			<Table striped responsive bordered size="sm" hover>
				<thead>
					<tr style={{ background: "#f0f8ff" }}>
						{Object.keys(allCsCallTypes).map((callTypeKey) => (
							<td key={callTypeKey} style={{ paddingRight: "2px", fontSize: "12px", minWidth: "150px" }}>
								<div style={{ display: "flex" }}>
									<input
										style={{ marginRight: "8px", cursor: "pointer" }}
										type="checkbox"
										onChange={() => handleHeaderCheckboxChange(callTypeKey)}
										checked={csUsers.every(user => settings[callTypeKey]?.includes(user.csUserId))}
									/>
									{allCsCallTypes[callTypeKey]}
								</div>
							</td>
						))}
					</tr>
				</thead>
				<tbody>
					{csUsers.map((user, index) => (
						<tr key={index} height="60px">
							{Object.keys(allCsCallTypes).map((callTypeKey, index) => (
								<td key={index} style={{ minWidth: "120px" }}>
									<input
										style={{ cursor: "pointer" }}
										type="checkbox"
										checked={settings[callTypeKey]?.includes(user.csUserId) || false}
										onChange={() => handleCheckboxChange(user.csUserId, callTypeKey)}
									/>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
};

export default FujisanCallSettings;