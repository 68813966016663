import React, { useEffect, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import _ from "underscore";
import { Table, Alert, ButtonGroup, Container, Pagination, Spinner, Card } from "react-bootstrap";
import DocumentTitle from "../shared/documentTitle";
import { GAConfigContext } from "contexts/gaConfigContext";
import { gaNiceFormatPrice } from "utils/common";
import { getAPI } from "utils/requestAPI";

const LandRegMatch = () =>
{
	const searchQuery = new URLSearchParams(location.search);
	const [paginationData, setPaginationData] = useState([]);
	const [totalPages, setTotalPages] = useState("");
	const [loading, setLoading] = useState(true);
	const [props, setProps] = useState([]);
	const {GA_CONFIG} = useContext(GAConfigContext);
	const [pageNumber, setPageNumber] = useState(searchQuery.get("page") || 1);
	const history = useHistory();

	useEffect(() =>
	{
		history.push({ search: `?page=${pageNumber}`});
		const fetchData = async() =>
		{
			if(!pageNumber || pageNumber < 1 || isNaN(pageNumber))
			{
				setPageNumber(1);
				return false;
			}

			setLoading(true);
			try
			{
				const resp = await getAPI(`/admin/vendor/landregMatch.json?page=${pageNumber}`);

				if(pageNumber > (resp.pagination.pageCount))
				{
					setPageNumber(1);
					return false;
				}

				setProps(resp.props);
				setPaginationData(resp.pagination);
				setTotalPages(resp.pagination.pageCount);
			}
			catch (e)
			{
				alert("no data found...");
			}

			setLoading(false);
		};

		fetchData();
	}, [pageNumber]);

	const movePage = (index) =>
	{
		if(index < 1 || index == isNaN)
			return false;

		setPageNumber(index);
	};

	const makePagination = () =>
	{
		const paginationItemsArray = [];

		for (let i = 1; i <= (totalPages); i++)
		{
			paginationItemsArray.push(
				<Pagination.Item
					key={i}
					active={i == pageNumber}
					onClick={()=> movePage(i)}
				>
					{i}
				</Pagination.Item>);
		}

		return <Pagination className="flex-wrap">{ paginationItemsArray }</Pagination>;
	};

	return <>
		<Container style={{display: "flex", alignItems: "center"}}>
			<DocumentTitle title="Land Reg Match" />
			<h1 className="display-4 my-5">Land Reg Match</h1>
			{loading == true ? <Spinner as="span" animation="border" variant="primary" style={{ marginLeft: "30px"}}/> : null}
		</Container>
		<Container>
			{(props.length > 0) ?
				<Card>
					<Card.Title style={{ padding: "15px"}}> Total properties matched {paginationData.rowCount} 🏡 🛹 </Card.Title>
					<Table size="sm">
						<thead>
							<tr>
								<th>Vendor ID</th>
								<th>Vendor Address</th>
								<th>Vendor Signed Up</th>
								<th>Land Reg Transaction Date</th>
								<th>Status</th>
								<th>Sold Price</th>
							</tr>
						</thead>
						<tbody>
							{props.map(prop => <tr key={prop.id}>
								<td><Link target="_blank" to={`/vendor/property/${prop.id}/edit`}>{prop.id}</Link></td>
								<td>{prop.address}</td>
								<td>{moment(prop.created_at).format("YYYY-MM-DD")}</td>
								<td>{moment(prop.lrTransDate).format("YYYY-MM-DD")}</td>
								<td>{_.invert(GA_CONFIG.vp.archiveStatus)[prop.archive] || _.invert(GA_CONFIG.vp.status)[prop.status]}</td>
								<td>&pound;{gaNiceFormatPrice(prop.price)}</td>
							</tr>
							)}
						</tbody>
					</Table>
					<ButtonGroup  style={{display: "flex", justifyContent: "center"}}>
						{makePagination()}
					</ButtonGroup>
				</Card>
				:
				<>
					{loading ? <Spinner as="span" animation="border" variant="primary" style={{ marginLeft: "30px"}}/> : <Alert variant="danger">No matches found!</Alert>}
				</>
			}
		</Container>
	</>;
};

export default LandRegMatch;
