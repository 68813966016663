import React from "react";
import { FormLabel, Col, Form, Row, FormControl, Button, Table, ListGroup, Card, InputGroup,  ListGroupItem, Badge } from "react-bootstrap";
import _ from "underscore";
import moment from "moment";
import {GAConfigContext} from "../../contexts/gaConfigContext";
import EditableSelect from "../shared/editableSelect";
import EditableText from "../shared/editableText";
import Note from "../shared/note";
import {gaNiceFormatPrice} from "../../utils/common";
import { FaEnvelope,FaShareAlt,FaPhone,FaPencilAlt,FaBriefcase,FaStar,FaTimesCircle} from "react-icons/fa";
import { FcCallTransfer } from "react-icons/fc";
import {MdOpenInNew, MdDashboard} from "react-icons/md";
import NoteInput from "../shared/noteInput";
import BranchSearchTypeaheadAsync from "../branches/branchSearchTypeaheadAsync";
import {Promise} from "bluebird";
import { postAPI, getAPI } from "utils/requestAPI";
import { publicURL, adminPanelURL } from "utils/common";

import BranchVendorPropList from "../branches/branchVendorPropList";

class ShortlistEdit extends React.PureComponent
{
	constructor(props)
	{
		super(props);

		this.state = {
			editing: false,
			saving: false,
			fetching: this.props.vendorProp.branches.reduce((acc, curr) => ({ ...acc, [curr.id]: false }), {}),
			branches: this.sortBranchNotes(props.vendorProp.branches),
			notesShown: {},
			vendorsShown: null,
			contactsShown: {},
			showValReqEmail: {},
			emailsToSend: {},
			selectedBranchVendors: [],
			notesAndCalls: {}
		};

		this.toggleValReqEmail = this.toggleValReqEmail.bind(this);
		this.handleValReqEmailChange = this.handleValReqEmailChange.bind(this);
		this.onhideVendorPhoneChange = this.onhideVendorPhoneChange.bind(this);
	}

	static contextType = GAConfigContext;

	componentDidMount()
	{
		if(!this.context.GA_CONFIG.user || !this.context.GA_CONFIG.user.adminOptions)
			return;

    	const { user, adminOpts } = this.context.GA_CONFIG;

		this._extraPriv = !!user.adminOptions[adminOpts.CAN_EDIT_VENDOR_STATUS];
	}

	sortBranchNotes(branches)
	{
		return _.sortBy(branches, br => br.name).map(branch =>
		{
			branch.signableLink = branch.branchTags.filter(e => e.tag_id === 11).sort((a, b) => moment(a.created_at).unix() - moment(b.created_at).unix()).pop()?.value;
			branch.branchNoteItems = [];

			if(this.state?.notesAndCalls[branch.id] && this.state.notesAndCalls[branch.id].length > 0)
				branch.branchNoteItems = branch.branchNoteItems.concat(this.state.notesAndCalls[branch.id]);

			if(branch.calls && branch.calls.length > 0)
				branch.branchNoteItems = branch.branchNoteItems.concat(branch.calls);

			branch.branchNoteItems = _.sortBy(branch.branchNoteItems, i =>
			{
				if(i.type === "CS_CALL" && i.called_at)
					return moment(i.called_at).unix() * -1;

				if(!i.type && i.note && i.note && (i.note.indexOf("[SUPPORT]") === 0))
					return -9E20;

				return moment(i.created_at).unix() * -1;
			});

			return branch;
		});
	}

	componentWillReceiveProps(nextProps)
	{

		if(! nextProps.vendorProp.branches)
			return console.log("Argh strange properties", nextProps);


		// Need to check if we have different branches being passed in from props
		// (by adding up the branch ids and comparing totals)
		// And if so, update state. Otherwise don't touch state!
		const reducer = (a,c) => a += c.id * ((c.notes?.length || 0) + 1) * ((c.calls?.length || 0) + 1) + c["_pivot_status"];

		const totalNewIds = nextProps.vendorProp.branches.reduce(reducer, 0);
		const totalOldIds = this.props.vendorProp.branches.reduce(reducer, 0);

		if(totalNewIds !== totalOldIds)
			this.setState({branches: this.sortBranchNotes(nextProps.vendorProp.branches.map(br => Object.assign({}, br)))});

	}

	valueChanged(branch, name, e)
	{
		const {branches} = this.state;

		const newBranch = Object.assign({}, branch);

		newBranch[name] = e.target.value;

		const newBranchList = _.filter(branches, br => br.id !== newBranch.id);

		newBranchList.push(newBranch);

		this.setState({branches: newBranchList.map(br => Object.assign({}, br))});
	}

	allowedStateChanges(branch, stateNum, stateString)
	{

		if(stateNum == branch["_pivot_status"])
			return false;

		// admins with extra privileges can do what they want
		if(this._extraPriv)
			return false;

		const allowStateChanges = {
			NEW: ["NO_QUOTE", "QUOTE_RECEIVED", "AGENT_ALREADY_CONTACTED"],
			QUOTE_RECEIVED: ["AGENT_ALREADY_CONTACTED", "NO_QUOTE"],
			VALUATION_REQ: ["AGENT_ALREADY_CONTACTED"],
			VAL_INTRO_DONE: ["AGENT_WILL_NOT_CONTACT", "AGENT_MADE_CONTACT", "AGENT_NOT_CHOSEN", "AGENT_ALREADY_CONTACTED", "AGENT_CHOSEN", "AGENT_WENT_FOR_VAL"],
			AGENT_MADE_CONTACT: ["AGENT_NOT_CHOSEN", "AGENT_ALREADY_CONTACTED", "AGENT_CHOSEN", "AGENT_WENT_FOR_VAL"],
			AGENT_WENT_FOR_VAL: ["AGENT_NOT_CHOSEN","AGENT_CHOSEN"],
			AGENT_CHOSEN: ["AGENT_NOT_CHOSEN"],
			VAL_BOOKED: ["AGENT_WENT_FOR_VAL", "VALUATION_CANCELLED"],
			VALUATION_CANCELLED: ["VAL_BOOKED"]
		};

		// Allow CRM team more options to transition from the 'NEW' status
		if(this.context.GA_CONFIG.user && this.context.GA_CONFIG.user.adminOptions && this.context.GA_CONFIG.user.adminOptions[this.context.GA_CONFIG.adminOpts.ARE_VAL_FOLLOWUP] === true)
			allowStateChanges.NEW.push("AGENT_WILL_NOT_CONTACT", "AGENT_MADE_CONTACT");

		const vendorStatusString = _.invert(this.context.GA_CONFIG.vp.branchStatus)[branch["_pivot_status"]];

		if(! allowStateChanges[vendorStatusString])
			return true;

		return (_.indexOf(allowStateChanges[vendorStatusString], stateString) === -1);

	}

	async saveEdit()
	{
		const {branches} = this.state;

		const {
			vendorProp,
			reloadVendor
		} = this.props;

		// search for changes
		const changedBranches = [];
		const phoneNumberHiddenBranches = [];

		_.each(branches, br =>
		{
			const existingBranch = _.findWhere(vendorProp.branches, {id: br.id});

			if(
				(existingBranch["_pivot_status"] !== br["_pivot_status"])
				|| (existingBranch["_pivot_quote"] !== br["_pivot_quote"])
				|| (existingBranch["_pivot_val_rem_sent"] !== br["_pivot_val_rem_sent"])
				|| (existingBranch["_pivot_val_booked_for"] !== br["_pivot_val_booked_for"])
				|| (existingBranch._pivot_vendor_phone_disabled != br._pivot_vendor_phone_disabled)
			)
				changedBranches.push(br);

		});

		if(changedBranches.length === 0 && phoneNumberHiddenBranches.length === 0)
			return alert("Nothing has changed!");

		try
		{
			await Promise.all(
				changedBranches.map(async br =>
				{
					await postAPI("/admin/vendor/property/" + vendorProp.id + "/shortlist/" + br.id,
						{
						quote: br["_pivot_quote"] || 0,
						status: br["_pivot_status"],
						valrem: br["_pivot_val_rem_sent"] || null,
						valbookedfor: br["_pivot_val_booked_for"] || null,
						vendorPhoneNumberDisabled: br._pivot_vendor_phone_disabled
						});

					const existingBranch = _.findWhere(vendorProp.branches, {id: br.id});

					if(((br["_pivot_status"] == this.context.GA_CONFIG.vp.branchStatus.AGENT_ALREADY_CONTACTED) && (existingBranch["_pivot_status"] != this.context.GA_CONFIG.vp.branchStatus.AGENT_ALREADY_CONTACTED)) ||
						((br["_pivot_status"] == this.context.GA_CONFIG.vp.branchStatus.AGENT_WILL_NOT_CONTACT) && (existingBranch["_pivot_status"] != this.context.GA_CONFIG.vp.branchStatus.AGENT_WILL_NOT_CONTACT)) ||
						((br["_pivot_status"] == this.context.GA_CONFIG.vp.branchStatus.AGENT_MADE_CONTACT) && (existingBranch["_pivot_status"] != this.context.GA_CONFIG.vp.branchStatus.AGENT_MADE_CONTACT)))
					{
						const updatedCallObj = {
							outcome: null,
							crm_team_will_not_chase: 1,
							updated_through_shortlist: 1
						};

						if(br["_pivot_status"] == this.context.GA_CONFIG.vp.branchStatus.AGENT_WILL_NOT_CONTACT ||
							br["_pivot_status"] == this.context.GA_CONFIG.vp.branchStatus.AGENT_ALREADY_CONTACTED)
						{
							updatedCallObj.outcome = "AWNC";

							if(br["_pivot_status"] == this.context.GA_CONFIG.vp.branchStatus.AGENT_WILL_NOT_CONTACT)
								updatedCallObj.awnc_reason = br["_pivot_awnc_reason"];

							if(br["_pivot_status"] == this.context.GA_CONFIG.vp.branchStatus.AGENT_ALREADY_CONTACTED)
								updatedCallObj.awnc_reason = "WITHIN_4_WEEKS";
						}

						if(br["_pivot_status"] == this.context.GA_CONFIG.vp.branchStatus.AGENT_MADE_CONTACT)
						{
							updatedCallObj.outcome = "AGENT_MADE_CONTACT";
							updatedCallObj.why_no_contact = br["_pivot_no_contact_reason"];
						}

						await postAPI("/admin/vendor/CRMTeam/updateCRMCall", {
							vpId: vendorProp.id,
							bId: br.id,
							updatedCallObj
						});
					}
				})
			);

			this.setState({editing: false});

			if(reloadVendor)
				reloadVendor();
		}
		catch (err)
		{
			alert(err.message || "An error occurred when setting the branch quote or status");
		}
	}

	startEditing()
	{
		this.setState({
			editing: true,
			saving: false,
			notesShown: {},
			vendorsShown: null,
			contactsShown: {},
			newBranchSelected: null
		});
	}

	cancelEditing()
	{
		this.setState({
			editing: false,
			branches: this.props.vendorProp.branches.map(br => Object.assign({}, br)),
			newBranchSelected: null,
			saving: false
		});
	}

	async fetchNotesAndCalls(brId)
	{
		try
		{
			const [notesRes, callsRes] = await Promise.all([getAPI(`/admin/branch/${brId}/notes`), getAPI(`/admin/branch/${brId}/calls`)]) ;

			const branchNotesAndCalls = [...notesRes.notes, ...callsRes.calls];

			this.setState((state) => ({ notesAndCalls: { ...state.notesAndCalls, [brId]: branchNotesAndCalls } }));
			this.setState((_, props) =>
			{
				this.sortBranchNotes(props.vendorProp.branches);
			});
		}
		catch (e)
		{
			console.error(e);
		}
	}

	async toggleNotes(branch, e)
	{
		e.preventDefault();

		if(!this.state.notesAndCalls[branch.id])
		{
			this.setState((state) => ({ fetching: { ...state.fetching, [branch.id]: true } }));
			await this.fetchNotesAndCalls(branch.id);
			this.setState((state) => ({ fetching: { ...state.fetching, [branch.id]: false } }));
		}

		const notesShown = Object.assign({}, this.state.notesShown);

		if(! notesShown[branch.id])
			notesShown[branch.id] = true;
		else
			notesShown[branch.id] = false;

		this.setState({notesShown: notesShown});
	}

	async toggleVendors(branch, e)
	{
		e.preventDefault();

		if(this.state.vendorsShown && this.state.vendorsShown === branch.id)
		{
			this.setState({vendorsShown: null});
			return;
		}

		try
		{
			const data = await getAPI("/admin/branch/" + branch.id + ".json?include=vendorPropsIncludeArchived,vendorPropsIncludeArchived.user");

			this.setState({selectedBranchVendors: data.branch.vendorPropsIncludeArchived, vendorsShown: branch.id});
		}
		catch (e)
		{
			alert(e || "An error occurred when loading branch vendors from server");
		}
	}

	toggleContacts(branch, e)
	{
		e.preventDefault();

		const contactsShown = Object.assign({}, this.state.contactsShown);

		if(! contactsShown[branch.id])
			contactsShown[branch.id] = true;
		else
			contactsShown[branch.id] = false;

		this.setState({contactsShown: contactsShown});
	}

	addNoteToBranch(branch, note)
	{
		const {
			vendorProp,
			reloadVendor
		} = this.props;

		this.setState({saving: true});

		const noteObj = {
			note: note.note,
			vendor_prop_id: vendorProp.id
		};

		if(note.reminderDate && note.reminderTime)
		{
			noteObj.reminder_date = note.reminderDate;
			noteObj.reminder_time = note.reminderTime;

			if(note.isAdminGroup)

				noteObj.reminder_team_id = note.reminderAdminGroup;

			else

				noteObj.reminder_user_id = note.reminderUser || this.context.GA_CONFIG.user.id;

		}

		// save to server
		return postAPI("/admin/branch/" + branch.id + "/note", noteObj)
			.then((resp) =>
			{
				if(reloadVendor)
					reloadVendor();

				this.setState({saving: false});
			})
			.catch(e =>
			{
				alert(e || "An error occurred when saving");
			});

	}

	branchNotesChange(branch)
	{
		if(this.props.reloadVendor)
			this.props.reloadVendor();
	}

	newBranchValChange(branch)
	{
		this.setState({newBranchSelected: branch});
	}

	addBranchToShortlist()
	{
		const {newBranchSelected} = this.state;

		const {
			vendorProp,
			reloadVendor
		} = this.props;

		if(! newBranchSelected)
			return alert("You've not selected a new branch");

		this.setState({saving: true});

		// save to shortlist
		postAPI("/admin/vendor/property/" + vendorProp.id + "/shortlist", {branch_id: newBranchSelected.id})
			.then((resp) =>
			{
				if(reloadVendor)
					reloadVendor();

				this.setState({saving: false, editing: false});

			})
			.then(e =>
			{
				alert(e || "An error occurred when adding new branch");
			});
	}

	removeFromShortlist(branch)
	{
		const {
			vendorProp,
			reloadVendor
		} = this.props;

		if(! window.confirm("Are you sure you want to remove " + branch.name + " from the shortlist?\n\nPress CANCEL to abort"))
			return;

		this.setState({saving: true});

		postAPI("/admin/vendor/property/" + vendorProp.id + "/shortlist/" + branch.id, {}, {method: "DELETE"})
			.then((resp) =>
			{
				if(! resp.success)

					return alert(resp.msg || "An error occurred when removing the branch");


				if(reloadVendor)
					reloadVendor();

				this.setState({saving: false, editing: false});
			})
			.catch(e => alert(e.message || "An error occurred when removing the branch"));
	}

	getContactInteractionForBranch(branch)
	{
		const {vendorProp} = this.props;

		if((! vendorProp.interactions) || (vendorProp.interactions.length === 0))
			return null;

		const branchInteractions = _.filter(vendorProp.interactions, int => int.branch_id === branch.id);

		if(branchInteractions.length === 0)
			return null;

		return branchInteractions.map(int =>
		{
			const intMsg = () =>
			{
				if(int.type === 10)
				{
					return (
						<span>
							<FaPhone />&nbsp;{int.from} -&nbsp;

							{int.duration < 0 ? (
								<span className="text-danger">No answer</span>
							):(
								<span className="text-success">{int.duration} second call</span>
							)}
						</span>
					);
				}
				else if(int.type === 20)
				{
					return (
						<span>
							<FaEnvelope /> {int.from} <small>(email from agent)</small>
						</span>
					);
				}
				else if(int.type === 25)
				{
					return (
						<span>
							<FaShareAlt /> {int.from} <small>(response from vendor)</small>
						</span>
					);
				}
				// CRT TRANSFER AB TEST START
				else if(int.type === 35)
				{
					return (
						<span>
							<FcCallTransfer size="1.5rem" />
							<span>&nbsp;Vendor to Agent transfer,&nbsp;</span>
							&nbsp;{int.from} -&nbsp;
							<span>{int.log.DialCallStatus}</span>
							{int.duration ?
								<>
									&nbsp;-&nbsp;
									<span className="text-success">{int.duration} second call</span>
								</>
								: null
							}
						</span>
					);
				}
				else if(int.type === 40)
				{
					return (
						<span>
							<FcCallTransfer size="1.5rem" />
							<span>&nbsp;Agent to Vendor transfer,&nbsp;</span>
							&nbsp;{int.from} -&nbsp;
							<span>{int.log.DialCallStatus}</span>
							{int.duration ?
								<>
									&nbsp;-&nbsp;
									<span className="text-success">{int.duration} second call</span>
								</>
								: null
							}
						</span>
					);
				}
				// CRT TRANSFER AB TEST END
			};

			return (
				<ListGroupItem key={int.id}>
					{moment(int.created_at).format("ddd Do MMM YYYY, HH:mm")} {intMsg()}
				</ListGroupItem>
			);
		});

	}

	refreshShortlist(e)
	{
		const {
			vendorProp,
			reloadVendor
		} = this.props;

		// refresh
		this.setState({saving: true});

		postAPI("/vendor/quotes/" + vendorProp.id + "/refresh")
			.then(resp =>
			{
				if(resp.updated === false)
					alert("Refresh done - no branches changed");

				if(reloadVendor)
					reloadVendor();

				this.setState({saving: false, editing: false});
			})
			.catch(e =>
			{
				alert(e || "An error occurred when regenerating the shortlist");
			});
	}

	toggleValReqEmail(branch)
	{
		this.setState({
			showValReqEmail: {
				...this.state.showValReqEmail,
				[branch.id]: this.state.showValReqEmail[branch.id] ? false : true,
			}
		});
	}

	sendValReqEmail(branch)
	{
		const branchEmail = this.state.emailsToSend[branch.id];

		if(branchEmail && (branchEmail.trim() !== ""))
		{
			//Validate Email
			const emailsAreInvalid = branchEmail
				.trim()
				.toLowerCase()
				.split(",")
				.map(this.validateEmail)
				.includes(false);

			if(emailsAreInvalid)
				return alert("Error Trying to validate emails");

		}

		//make Request to resend the email
		postAPI(`/admin/vendor/property/${this.props.vendorProp.id}/valReqEmailResend/${branch.id}`, { emailsTo: this.state.emailsToSend[branch.id] })
			.then(data =>
			{

				alert("Valuation request email is being re-sent!");
				this.toggleValReqEmail(branch);

			})
			.catch(e =>
			{
				alert(e.message || "Something went wrong!");
			});
	}

	validateEmail(email)
	{
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		return re.test(email.trim());
	}


	handleValReqEmailChange(e, branch)
	{
		this.setState({
			emailsToSend: {
				...this.state.emailsToSend,
				[branch.id]: e.target.value
			}
		});
	}

	openCallWindow(branch, e)
	{
		e.preventDefault();

		window.open(adminPanelURL("/vendor/property/" + this.props.vendorProp.id + "/phone/" + branch.id), "callwin", "width=550,height=600");
	}

	onhideVendorPhoneChange(branch, val)
	{
		const { branches } = this.state;

		const vendor_phone_disabled = val ? 0 : 1;

		const newBranch = Object.assign({}, branch);

		newBranch._pivot_vendor_phone_disabled =vendor_phone_disabled;

		const newBranchList = _.filter(branches, br => br.id !== newBranch.id);

		newBranchList.push(newBranch);

		this.setState({branches: newBranchList.map(br => Object.assign({}, br))});
	}

	render()
	{
		const {
			showBranchAdmins,
			vendorProp,
			highlightBranch,
			branchBlocker,
			isSaving
		} = this.props;

		const {
			branches,
			contactsShown,
			editing,
			newBranchSelected,
			notesShown,
			vendorsShown,
			saving,
			showValReqEmail
		} = this.state;

		// Sort branches by ID to prevent reordering on update
		branches.sort((a, b) => b.id - a.id);

		const title = (
			<>Shortlist</>
		);

		let panelFooter = null;

		if(editing === true)
		{
			panelFooter = (
				<Row>
					<Col md={4}>
						<Button onClick={this.cancelEditing.bind(this)} disabled={saving}>Cancel</Button>
					</Col>

					<Col md={4} className="text-center">
						{(vendorProp.status <= this.context.GA_CONFIG.vp.status.READY_FOR_VALS) && (
							<Button onClick={this.refreshShortlist.bind(this)} disabled={saving}>Regenerate Shortlist</Button>
						)}
					</Col>

					<Col md={4} className="text-right">
						<Button onClick={this.saveEdit.bind(this)} disabled={saving}>Save</Button>
					</Col>
				</Row>
			);
		}
		else
		{
			panelFooter = (
				<Row>
					<Col sm={2}>
						<Button onClick={this.startEditing.bind(this)}>Edit</Button>
					</Col>
					<Col sm={2}>
						<Form readOnly>
							<a href={publicURL("/vendor/quotes/" + vendorProp.id)} target="_blank">Quotes page <MdOpenInNew /></a>
						</Form>
					</Col>
					<Col sm={8}>
						<Form.Group as={Row}>
							<Form.Label column sm={3} className="text-right">
									Vendor Link:
							</Form.Label>
							<Col sm={9}>
								<Form.Control type="text" value={"https://www.getagent.co.uk/vendor/quotes/" + vendorProp.id + "?c=" + vendorProp.loginCode} readOnly />
							</Col>
						</Form.Group>
					</Col>
				</Row>
			);
		}

		const tableRows = [];

		branches.map(branch =>
		{
			const existingBranch = _.findWhere(vendorProp.branches, {id: branch.id});

			branch["_pivot_awnc_reason"] = branch["_pivot_awnc_reason"] || _.first(this.context.GA_CONFIG.crmCalls.awncReasons);
			branch["_pivot_no_contact_reason"] = branch["_pivot_no_contact_reason"] || _.first(this.context.GA_CONFIG.crmCalls.noContactReasons);

			const branchNameCell = (
				<FormLabel>
					<a href={adminPanelURL("/branch/" + branch.id + "/edit")} target="_blank"  className={branch.admins.length === 0 ? "text-danger" : null}>{branch.name}</a>
					&nbsp;
					{branch.pay_per_val ?
						<a href={publicURL("/branch/" + branch.id + "/bills")} target="_blank"><Badge variant="success">PPI</Badge></a>
						: null}
					&nbsp;
					<a href={publicURL("/branch/" + branch.id)} target="_blank"><MdDashboard /></a>
					&nbsp;
					{branch.corporate_id ?
						<a href={adminPanelURL("/corporate/" + branch.corporate_id)} target="_blank"><FaBriefcase className="text-danger" /></a>
						: null}
					&nbsp;
					{ branch.signableLink ?
						<a href={branch.signableLink} target="_blank"><FaPencilAlt /></a>
						: null}
					&nbsp;
					{branch["_pivot_sponsored"] !== 0 ?
						<FaStar className="text-warning" />
						: null}

					{showBranchAdmins && branch.admins.length ? (
						<p>
							<strong>Admins:</strong>
							{branch.admins.map(admin => <span key={admin.email}><br/>{admin.name}, signed up {moment(admin.created_at).format("DD MMM YYYY")} {admin.email}</span>)}
						</p>
					) : null}
				</FormLabel>
			);

			let actionsCell = null;

			if(editing)
			{
				actionsCell = <>
					<Button variant="danger" onClick={this.removeFromShortlist.bind(this, branch)} disabled={saving}>Remove</Button>
					{
						branch["_pivot_status"] >= this.context.GA_CONFIG.vp.branchStatus.VALUATION_REQ ?
							<Form.Group controlId={`hideVendorPhoneNumber-${branch.id}`}>
								<Form.Check type="checkbox"
									checked={!!branch["_pivot_vendor_phone_disabled"]}
									onChange={() => this.onhideVendorPhoneChange(branch, branch["_pivot_vendor_phone_disabled"])}
									label={"Don't allow agent to call"} />
							</Form.Group>
							:
							""
					}
				</>;
			}
			else
			{
				actionsCell = (
					<Form readOnly>
						<a href="#" onClick={this.toggleNotes.bind(this, branch)} style={{cursor: "pointer", fontWeight: (notesShown[branch.id] ? "bold":null)}}>
							{this.state.fetching[branch.id] ? "Fetching notes..." : "Notes"}
						</a>
						<span>&nbsp;|&nbsp;</span>
						{highlightBranch == branch.id ? (
							<>
								<a href="#" onClick={this.toggleVendors.bind(this, branch)} style={{cursor: "pointer", fontWeight: (vendorsShown === branch.id ? "bold":null)}}>Vendors</a>
								<span>&nbsp;|&nbsp;</span>
							</>

						): null}
						{ (branch["_pivot_vendor_email"] || branch["_pivot_vendor_phone"] || (branch["_pivot_val_req_at"])) ? (
							<>
								<span style={{fontWeight: (contactsShown[branch.id] ? "bold":null)}}>
									<span onClick={this.toggleContacts.bind(this, branch)} style={{cursor: "pointer"}}>Contacts
										{this.getContactInteractionForBranch(branch) && this.getContactInteractionForBranch(branch).length > 0 ? " (" + this.getContactInteractionForBranch(branch).length + ")" : null}</span>
								</span>
								<br />
								{branch["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.VAL_INTRO_DONE &&
									<>
										<span 	href="#"
											style={{cursor: "pointer"}}
											onClick={() => this.toggleValReqEmail(branch)}
											className="text-primary"
										>
											<FaEnvelope  />
										</span>
									</>
								}
							</>
						)
							: null}

						&nbsp; <span
							onClick={this.openCallWindow.bind(this, branch)}
							style={{cursor: "pointer"}}
							className={(branch.do_not_call_until && moment(branch.do_not_call_until).isAfter(moment())) ? "text-danger" : "text-primary"}>
							<FaPhone />
						</span>

						{highlightBranch == branch.id ? (
							<>
								<br />
								<br />
								<Button variant="danger"
									onClick={branchBlocker(branch.id, vendorProp.id)}
								>
									<FaTimesCircle style={{marginRight: "5px"}}/>
								CRM team won't chase
								</Button>
							</>
						) : null}

						{ branch._pivot_vendor_phone_disabled ? (
							<>
								<br />
								<br />
								<div className="text-danger">Agent cannot call</div>
							</>
						) : "" }
					</Form>
				);
			}

			const bgColor = (branch["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.AGENT_CHOSEN) ? "#CBE8D3" : (
				highlightBranch == branch.id ? "#fceeca" : null
			);

			tableRows.push(
				<tr key={branch.id} style={{backgroundColor: bgColor}}>
					<td>{branchNameCell}</td>
					<td>
						<EditableSelect
							value={branch["_pivot_status"]}
							options={_.invert(this.context.GA_CONFIG.vp.branchStatus)}
							onValueChanged={this.valueChanged.bind(this, branch, "_pivot_status")}
							editing={editing}
							disabledOptions={this.allowedStateChanges.bind(this, branch)}
						>
						</EditableSelect>
						{editing && branch["_pivot_status"] == this.context.GA_CONFIG.vp.branchStatus.AGENT_WILL_NOT_CONTACT && existingBranch["_pivot_status"] != this.context.GA_CONFIG.vp.branchStatus.AGENT_WILL_NOT_CONTACT ? (
							<>
								<div className="form-control-plaintext">AWNC reason:</div>
								<Form.Control
									as="select"
									onChange={this.valueChanged.bind(this, branch, "_pivot_awnc_reason")}
									value={branch["_pivot_awnc_reason"]}
								>
									{this.context.GA_CONFIG.crmCalls.awncReasons.map((r) =>
									{
										return <option key={`${branch.id}-${r}`}>{r}</option>;
									})}
								</Form.Control>
							</>
						) : null}
						{editing && branch["_pivot_status"] == this.context.GA_CONFIG.vp.branchStatus.AGENT_MADE_CONTACT && existingBranch["_pivot_status"] != this.context.GA_CONFIG.vp.branchStatus.AGENT_MADE_CONTACT ? (
							<>
								<div className="form-control-plaintext">Reasons:</div>
								<Form.Control
									as="select"
									onChange={this.valueChanged.bind(this, branch, "_pivot_no_contact_reason")}
									value={branch["_pivot_no_contact_reason"]}
								>
									{this.context.GA_CONFIG.crmCalls.noContactReasons.map((r) =>
									{
										return <option key={`${branch.id}-${r}`}>{r}</option>;
									})}
								</Form.Control>
							</>
						): null}
						{(branch["_pivot_status"] >= this.context.GA_CONFIG.vp.branchStatus.VALUATION_REQ) && (branch["_pivot_status"] < this.context.GA_CONFIG.vp.branchStatus.AGENT_MADE_CONTACT) && (editing || branch["_pivot_val_rem_sent"]) && (
							<Row>
								<Col sm={4}>
									<div className="form-control-plaintext">Callback</div>
								</Col>
								<Col sm={8}>
									<EditableText
										value={branch["_pivot_val_rem_sent"]}
										onValueChanged={this.valueChanged.bind(this, branch, "_pivot_val_rem_sent")}
										editing={editing}
										type="date"
										formatter={(val) => val ? moment(val).format("ddd Do MMM YYYY") : null}
									/>
								</Col>
							</Row>
						)}
						{editing && (branch["_pivot_status"] >= this.context.GA_CONFIG.vp.branchStatus.AGENT_MADE_CONTACT) && (branch["_pivot_status"] < this.context.GA_CONFIG.vp.branchStatus.AGENT_WENT_FOR_VAL) && (
							<Row className="mt-4">
								<Col sm={4}>
									<div className="form-control-plaintext">Valuation Date</div>
								</Col>
								<Col sm={8}>
									<EditableText
										value={branch["_pivot_val_booked_for"]}
										onValueChanged={this.valueChanged.bind(this, branch, "_pivot_val_booked_for")}
										editing={editing}
										type="datetime-local"
										formatter={(val) => val ? moment(val).format("YYYY-MM-DDThh:mm") : null}
									>
									</EditableText>
								</Col>
							</Row>
						)}
					</td>
					<td>
						<EditableText
							value={branch["_pivot_quote"]}
							onValueChanged={this.valueChanged.bind(this, branch, "_pivot_quote")}
							formatter={(val) =>
							{
								if(val > 100)
									return "£" + gaNiceFormatPrice(val);
								else if(val > 0)
									return val + "%";
								else
									return null;
							}}
							editing={editing} >
						</EditableText>
					</td>
					<td>
						{actionsCell}
					</td>
				</tr>
			);


			if((showValReqEmail[branch.id] === true) && (branch["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.VAL_INTRO_DONE))
			{
				tableRows.push(<tr key={branch.id + "-valReqEmail"}>
					<td colSpan="5">
						<Row>
							<Col xs={8}>
								<InputGroup>
									<InputGroup.Prepend><InputGroup.Text><FaEnvelope /></InputGroup.Text></InputGroup.Prepend>
									<FormControl
										type="text"
										placeholder="Leave blank to re-send to original recepients"
										value={this.state.emailsToSend[branch.id]}
										onChange={e => this.handleValReqEmailChange(e, branch)}
									/>
								</InputGroup>
							</Col>
							<Col xs={4}>
								<Button
									onClick={() => this.sendValReqEmail(branch)}
								>
										Send Valuation Email
								</Button>
							</Col>
						</Row>
					</td>
				</tr>);
			}

			if(contactsShown[branch.id] === true)
			{
				// show contacts row
				tableRows.push(
					<tr key={branch.id + "-contacts"} style={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
						<td colSpan="5">
							<Row>
								<Col sm={6}>
									{branch["_pivot_vendor_email"] ? (
										<InputGroup>
											<InputGroup.Prepend><InputGroup.Text><FaEnvelope /></InputGroup.Text></InputGroup.Prepend>
											<Form.Control type="text" value={branch["_pivot_vendor_email"] + "@getagent.email"} readOnly />
										</InputGroup>
									) : (
										<p className="text-danger">Email deleted</p>
									)}
								</Col>
								<Col sm={6}>
									{branch._pivot_vendor_phone_disabled ?
										<div className="text-danger">Agent cannot call</div>
										: branch["_pivot_vendor_phone"] ? (
											<InputGroup>
												<InputGroup.Prepend><InputGroup.Text><FaPhone /></InputGroup.Text></InputGroup.Prepend>
												<Form.Control type="text" value={branch["_pivot_vendor_phone"].replace("+44", "0")} readOnly />
											</InputGroup>
										) : (
											<Row>
												<Col md={12} className="text-right">
													<InputGroup>
														<InputGroup.Prepend><InputGroup.Text><FaPhone /> &nbsp;&nbsp; 0207 1234 998 +</InputGroup.Text></InputGroup.Prepend>
														<Form.Control type="text" value={branch["_pivot_id"]} readOnly />
													</InputGroup>
												</Col>
												<Col md={12}>
													{
														isSaving ?
															<Col md={12}>Saving....</Col>
															:
															""
													}
												</Col>
											</Row>
										)}
								</Col>
							</Row>
							<ListGroup>
								{this.getContactInteractionForBranch(branch)}
							</ListGroup>
						</td>
					</tr>);
			}

			/* Notes row */
			if(notesShown[branch.id] === true)
			{
				// show notes row
				tableRows.push(
					<tr key={branch.id + "-notes"} style={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
						<td colSpan="5">
							<NoteInput
								branchId={branch.id}
								vendorPropId={vendorProp.id}
								allowReminder
								onAddNote={this.addNoteToBranch.bind(this, branch)}
							/>

							{branch.branchNoteItems.map(note =>
							{
								if(note.note && (note.note.indexOf("[PINNED]") === 0))
								{
									return (
										<div style={{color: "#31708f", backgroundColor: "#d9edf7"}} key={note.id + "-pinned"}>
											<Note key={note.id + "-pinned"} note={note} showBranch={false} addHr={true} onNoteChange={this.branchNotesChange.bind(this, branch)} />
										</div>

									);
								}
								else
								{
									return null;
								}

							})}

							{branch.branchNoteItems.map(note =>
							{
								return (
									<Note key={note.id} note={note} showBranch={false} addHr={true} onNoteChange={this.branchNotesChange.bind(this, branch)} />
								);
							})}

						</td>
					</tr>);
			}

			if(vendorsShown && vendorsShown === branch.id)
			{
				tableRows.push(
					<tr key={branch.id + "-vendors"} style={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
						<td colSpan={4}>
							<BranchVendorPropList vendors={this.state.selectedBranchVendors} className="mb-3" />
						</td>
					</tr>
				);
			}
		});

		if(editing)
		{
			tableRows.push((
				<tr key={"add-branch"}>
					<td>
						<BranchSearchTypeaheadAsync
							onBranchAdded={this.newBranchValChange.bind(this)}
							placeholder="Add a branch"
							value={newBranchSelected && newBranchSelected.name} />
					</td>
					<td colSpan={2}>

					</td>
					<td>
						<Button disabled={! newBranchSelected} onClick={this.addBranchToShortlist.bind(this)}>Add branch</Button>
					</td>
				</tr>
			));
		}

		return (
			<Card className={this.props.className}>
				<Card.Header style={{fontWeight: 600}}>
					{title}
				</Card.Header>

				<Table striped className="mb-0">
					<thead>
						<tr>
							<th width="35%">
								Branch Name
							</th>
							<th width="25%">
								Status
							</th>
							<th width="15%">
								Quote
							</th>
							<th width="25%">
								Actions
							</th>
						</tr>
					</thead>
					<tbody>
						{tableRows}
					</tbody>
				</Table>

				<Card.Footer>
					{panelFooter}
				</Card.Footer>
			</Card>
		);
	}
}

export default ShortlistEdit;