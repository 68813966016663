import React from "react";
import { Col, Row, Button, Table, Badge, Form, FormControl, Spinner } from "react-bootstrap";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import moment from "moment";
import { gaNiceFormatPrice } from "../../utils/common";
import { FaHome, FaUserAlt, FaTag, FaPen, FaClock, FaChevronUp, FaChevronDown} from "react-icons/fa";
import { AiFillPushpin} from "react-icons/ai";
import { Link } from "react-router-dom";
import "../../styles/note.css";
import CsCallNote from "./csCallNote";
import BdCallNote from "./bdCallNote";
import CallNote from "./callNote";
import { postAPI } from "utils/requestAPI";
import { adminPanelURL } from "utils/common";


class Note extends React.Component
{


	constructor(props)
	{
		super(props);

		const { note } = this.props;

		this._maxTimeToDeleteInSeconds = 120;


		this.timeToDelete = -1;

		this.state = {
			deleteInterval: null,
			done: (!! note.done),
			saving: false,
			timeToDelete: this.timeToDelete,
			note: props.note,
			openingTimeState: false,
			closingTimeState: false,
			quoteState: false,
			rowsState:false,
		};

	}

	static contextType = GAConfigContext;

	componentDidMount()
	{
		const { note } = this.props;

		const canDelete = 	((! note.type) || (note.type === "NOTE"))
							&& (note.creator)
							&& (note.creator.id === this.context.GA_CONFIG.user.id)
							&& ((note.note.indexOf("[") !== 0) || (note.note.includes("[CHASE")))
							&& (moment().diff(moment(note.created_at), "seconds") < this._maxTimeToDeleteInSeconds);

		this.setState({
			deleteInterval: canDelete ? setInterval(this.tickTock.bind(this), 1000) : null,
			timeToDelete: canDelete ? this._maxTimeToDeleteInSeconds - moment().diff(moment(note.created_at), "seconds") : this.timeToDelete
		});

	}


	tickTock()
	{

		const timeToDelete = this._maxTimeToDeleteInSeconds - moment().diff(moment(this.props.note.created_at), "seconds");

		this.setState({timeToDelete: timeToDelete});
	}

	componentWillUnmount()
	{
		if(this.state.deleteInterval)
			clearInterval(this.state.deleteInterval);
	}

	componentWillReceiveProps(nextProps)
	{
		this.setState({done: (!! nextProps.note.done)});
	}

	noteDoneChange(e)
	{
		this.setState({saving: true});

		// save to server
		postAPI("/admin/reminders/" + this.props.note.id + "/toggleDone")
			.then((data) =>
			{
				this.setState({
				done: (! this.state.done),
				saving: false,
				note: data.note,
				});

				if(this.props.onNoteChange)

					this.props.onNoteChange(data.note);


			})
			.catch(e =>
			{
				alert(e || "something went wrong :(");
				console.log(e);
				this.setState({saving: false});
			});
	}

	deleteNoteClick(e)
	{
		if(window.confirm("Are you sure you want to delete note?"))
		{
			this.setState({saving: true});

			postAPI("/admin/reminders/" + this.props.note.id, {}, {method: "DELETE"})
				.then(data =>
				{
					if(this.props.onNoteChange)
						this.props.onNoteChange();
				})
				.catch(err =>
				{
					alert("Couldn't delete, soz");
				});
		}
	}

	togglePinnedClick(e)
	{
		this.setState({saving: true});

		// save to server
		return postAPI("/admin/reminders/" + this.props.note.id + "/togglePinned")
			.then((data) =>
			{
				this.setState({saving: false}, () =>
				{
					if(data.success)
						this.setState({note: data.note});
				});

				if(this.props.onNoteChange)
					this.props.onNoteChange();

			})
			.catch((e) =>
			{
				alert(e || "something went wrong :(");
				console.log(e);
				this.setState({saving: false});
			});

	}

	callNote(call)
	{
		return <CallNote call={call} />;
	}

	bdCallNote(bdCall)
	{
		return <BdCallNote call={bdCall} />;
	}

	textNote(note)
	{
		if(note.note.indexOf("[SUPPORT]") === 0)
		{
			return (<p>

				<Badge className="float-right" variant="dark">Support</Badge>
				<span dangerouslySetInnerHTML={{
__html: note.note.replace("[SUPPORT]", "").replace(/\</g, "&lt;").replace(/\>/g, "&gt;")
	.replace(/(?:\r\n|\r|\n)/g, "<br />")
				}}></span>
			</p>);
		}

		const pin = () =>
		{

			if(this.props.note.branch_id)
				return (this.state.saving ? <Spinner size="sm" animation="border" variant="primary" className="float-right" /> : <AiFillPushpin className="float-right note-pin" onClick={this.togglePinnedClick.bind(this)} />);
			else
				return null;
		};


		return (<p className={note.note.indexOf("[PINNED]") === 0 ? "is-pinned" : null}>
			{pin()}
			<span dangerouslySetInnerHTML={{
__html: note.note.replace("[PINNED]", "").replace(/\</g, "&lt;").replace(/\>/g, "&gt;")
	.replace(/(?:\r\n|\r|\n)/g, "<br />")
			}}></span>
		</p>);

	}

	branchMergeNote(note)
	{

		return (
			<p>
				Branch Merged:
				<br />
				<FormControl as="textarea" rows={4} defaultValue={note.note.replace("[BRANCH-MERGED]With ", "")} readOnly></FormControl>
			</p>
		);
	}
	branchEditNote(note, stripVal)
	{
		const jsonNotes = JSON.parse(note.note.replace(stripVal, ""));

		return  (<Table striped size="sm" hover>
			<colgroup>
				<col width="25%"/>
				<col width="25%"/>
				<col width="25%"/>
				<col width="25%"/>

			</colgroup>
			<thead>
				<tr >
					<th>Changes</th>
					    <th >From</th>
					<th >To</th>
					<th >  </th>
				</tr>
			</thead>
			<tbody>
				{this.handleRows(jsonNotes)}
			</tbody>
		</Table>);
	}

	showOpeningTime = () =>
	{
		this.setState({openingTimeState: !this.state.openingTimeState});
	}

	showClosingTime = () =>
	{
		this.setState({closingTimeState: !this.state.closingTimeState});
	}

	showQuotes = () =>
	{
		this.setState({quoteState: !this.state.quoteState});
	}

	showAllRows = () =>
	{
		this.setState({rowsState: !this.state.rowsState});
	}

	displayRow =(changed, from, to, noteNo, cond, isState, displayRows, showName ) =>
	{
		return (
			<tr className={cond && noteNo>0 ? "info": "" } key={noteNo}>
				<td>{showName && changed}</td>
				<td>{from}</td>
				<td>{to}</td>
				<td onClick={displayRows} >
					<span className="float-right">
						{  !isState && noteNo>0 ? <Badge>{noteNo}</Badge> : ""  }
						&nbsp; &nbsp;
						{ cond && noteNo>0 &&
						(isState ? <FaChevronUp  className="float-right"/> : <FaChevronDown  className="float-right"/> )
						}
					</span>
				</td>
			</tr>
		);
	}

	handleRows(jsonNotes)
	{
		const allRows = [], rows =[], timeRows = [], quoteRows = [];

		jsonNotes.map( (noteItem, rowNo) =>
		{

			if(noteItem.changed == "opening_times")
			{
				const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
				const from =  JSON.parse(noteItem.from);
				const to = JSON.parse(noteItem.to);

				const openingRows = [], closingRows = [];
				let openingTimeNo = 0, closingTimeNo = 0, openingRange = 0, closingRange = 0;
				let timeToOpen, timeToClose, timeFromOpen, timeFromClose, timeRange = 0;

				if(to && to.length)
					timeRange = to.length;

				if(from && from.length && (timeRange < from.length))
					timeRange = from.length;

				for (let i = 0; i < timeRange; i++)
				{
					timeToOpen = to && to[i] ?  to[i].open.time : null;
					timeFromOpen = from && from[i] ? from[i].open.time : null;
					timeToClose = to && to[i] ? to[i].close.time : null;
					timeFromClose = from && from[i] ? from[i].open.time : null;

					if(timeFromOpen != timeToOpen)

						openingRange++;


					if(timeToClose != timeFromClose)

						closingRange++;

				}

				for (let i = 0; i < timeRange; i++)
				{
					let toOpen, toClose, fromOpen, fromClose;

					if(from && from[i] && from[i].open && from[i].close)
					{
						fromOpen =  days[i] + " " +from[i].open.time;
						fromClose =  days[i] + " " +from[i].close.time;
					}
					else
					{
						fromOpen = null;
					}

					fromClose = null;

					if(to && to[i] && to[i].open && to[i].close)
					{
						toOpen =  days[i] + " " +to[i].open.time;
						toClose =  days[i] + " " +to[i].close.time;
					}
					else
					{
						toOpen = null;
					}

					toClose = null;


					timeToOpen = to && to[i] ?  to[i].open.time : null;
					timeFromOpen = from && from[i] ? from[i].open.time : null;
					timeToClose = to && to[i] ? to[i].close.time : null;
					timeFromClose = from && from[i] ? from[i].open.time : null;

					if(timeFromOpen != timeToOpen)
					{
						openingTimeNo++;
						openingRows.push(this.displayRow("opening times", fromOpen, toOpen, openingRange, openingTimeNo==1, this.state.openingTimeState, this.showOpeningTime, openingTimeNo==1));
					}

					if(timeToClose != timeFromClose)
					{
						closingTimeNo++;
						closingRows.push(this.displayRow("closing times", fromClose, toClose, closingRange, closingTimeNo==1, this.state.closingTimeState, this.showClosingTime, closingTimeNo==1));
					}
				}


				if(this.state.openingTimeState)
					timeRows.push(openingRows);
				 else
					timeRows.push(openingRows[0]);

				if(this.state.closingTimeState)
					timeRows.push(closingRows);
				else
					timeRows.push(closingRows[0]);

			}
			 else if(noteItem.changed == "quotes")
			{
				const to = noteItem && noteItem.to && JSON.parse(noteItem.to);
				const from = noteItem && noteItem.from && JSON.parse(noteItem.from);
				let quoteNo = 0, quoteRange = 0;

				if(to && to.length)
					quoteRange = to.length;

				if(from && from.length && (quoteRange < from.length))
					quoteRange = from.length;

				for (let i = 0; i < quoteRange; i++)
				{
					let toParams, fromParams;

					if(to && to[i])
						toParams = "bid " + ((to[i].bid < 100) ? to[i].bid + "%" : "£" + gaNiceFormatPrice(to[i].bid))+ " |  max " +gaNiceFormatPrice(to[i].max) + " | min "+gaNiceFormatPrice(to[i].min);
					else
						toParams = null;

					if(from && from[i])
						fromParams = "bid " + ((from[i].bid < 100) ? from[i].bid + "%" : "£" + gaNiceFormatPrice(from[i].bid)) + " |  max " +gaNiceFormatPrice(from[i].max) + " | min "+gaNiceFormatPrice(from[i].min);
					else
						fromParams = null;

					quoteNo++;
					quoteRows.push(this.displayRow("quotes",fromParams, toParams, quoteRange, quoteNo==1, this.state.quoteState, this.showQuotes, quoteNo==1));
				}

				// if(quoteNo==1)
				// {

				// 	console.log(quoteRows[0]);

				// 	quoteRows[0].props.children[3].props.children = '';
				// 	quoteRows[0].props.className = '';
				// }
			}
			else if(noteItem.changed == "website")
			{
				rowNo++;
				rows.push(this.displayRow("website url", <a href={noteItem.from}>Old Url</a>, <a href={noteItem.to}>New Url</a>, (jsonNotes.length-1), rowNo==1, this.state.rowsState, this.showAllRows, true));
			}
			else
			{
				rowNo++;
				rows.push(this.displayRow(noteItem.changed, noteItem.from, noteItem.to, (jsonNotes.length-1), rowNo==1, this.state.rowsState, this.showAllRows, true));
			}
		});
		rows.push(this.state.quoteState ? quoteRows : quoteRows[0]);
		rows.push(timeRows);

		if(this.state.rowsState)
		{
			allRows.push(rows);
		}
		else
		{
			if(jsonNotes.find(item => item.changed === "opening_times" ) && jsonNotes.length<=1)

				allRows.push(rows);
			 else

				allRows.push(rows[0]);

		}

		return allRows;
	}

	customerServiceCall(call)
	{

		return (<CsCallNote  csCall={call} />);

	}

	invoiceChasingNote(note)
	{
		let chasingViewObj = null;

		const jsonString = note.note.replace("[CHASE]", "").trim();

		try
		{
			chasingViewObj = JSON.parse(jsonString);
		}
		catch (err)
		{
			return this.textNote(note);
		}

		const nextActionAt = moment(chasingViewObj.nextActionAt);

		return (
			<div>
				<p>{chasingViewObj.note}</p>
				<p>[WHY_NOT_PAID] {chasingViewObj.whyNotPaid}</p>
				<p>[NEXT_ACTION] {chasingViewObj.nextAction}</p>
				<p>[WHEN_NEXT_ACTION] {nextActionAt ? nextActionAt.format("YYYY-MM-DD") : ""}</p>
			</div>
		);

	}

	userChangeNote(note)
	{

		const matcher = /^\[(.*)\] (.*) \(([0-9]*)\)$/;

		const chunks = matcher.exec(note.note);

		if(chunks === null)
			return this.textNote(note);

		return (
			<p>
				<FaUserAlt className={chunks[1] == "USER_ADDED" ? "bg-success" : "bg-danger"} /> &nbsp;
				{chunks[1] == "USER_ADDED" ? "Added " : "Removed "}
				<Link to={"/user/" + chunks[3] + "/edit"}>{chunks[2]}</Link>
			</p>
		);
	}

	responsibleChangeNote(note)
	{

		let captureFrom = null,
			captureTo = null;

		const matchUserId = /(\d+)/g;

		const capturedIds = note.note.match(matchUserId);

		// If there are no matches at all, then something has gone horribly wrong
		// Just fallback to a normal text note for now
		if((! capturedIds) || (capturedIds.length === 0))

			return this.textNote(note);


		// status changed from null to user id
		if(capturedIds.length === 1)
		{
			captureTo = capturedIds[0];

			let userTo = this.context.GA_CONFIG.admins.find(admin => admin.id == captureTo);

			if(! userTo)
			{
				// user no longer with GetAgent
				// just use old id
				userTo = captureTo;
			}
			else
			{
				userTo = userTo.name;
			}

			return (<p>
				<span dangerouslySetInnerHTML={{
__html: note.note.replace("null", "unassigned").replace(captureTo, userTo).replace(/\</g, "&lt;")
	.replace(/\>/g, "&gt;")
	.replace(/(?:\r\n|\r|\n)/g, "<br />")
				}}></span>
			</p>);
		}

		// status changed from user to diff user
		captureFrom = capturedIds[0];
		captureTo = capturedIds[1];

		let userFrom = this.context.GA_CONFIG.admins.find(admin => admin.id == captureFrom);

		if(!userFrom)
			userFrom = captureFrom;
		else
			userFrom = userFrom.name;

		let userTo = this.context.GA_CONFIG.admins.find(admin => admin.id == captureTo);

		if(!userTo)
			userTo = captureTo;
		else
			userTo = userTo.name;

		return (<p>
			<span dangerouslySetInnerHTML={{
__html: note.note.replace(captureFrom, userFrom).replace(captureTo, userTo).replace(/\</g, "&lt;")
	.replace(/\>/g, "&gt;")
	.replace(/(?:\r\n|\r|\n)/g, "<br />")
			}}></span>
		</p>);

	}

	renderNote(note)
	{
		if(note.type === "CALL")
			return this.callNote(note);
		else if(note.type === "CS_CALL")
			return this.customerServiceCall(note);
		else if(note.type === "BD_CALL")
			return this.bdCallNote(note);
		else if(note.note && note.note.indexOf("[CHASE]") === 0)
			return this.invoiceChasingNote(note);
		else if(note.note && note.note.indexOf("[BRANCH-MERGED]") === 0)
			return this.branchMergeNote(note);
		else if((note.note.indexOf("[USER_ADDED]") === 0)||(note.note.indexOf("[USER_DEREGISTERED]") === 0))
			return this.userChangeNote(note);
		else if(note.note.indexOf("[BRANCH_EDIT] -") === 0)
			return this.branchEditNote(note, "[BRANCH_EDIT] -");
		else if(note.note.indexOf("[BRANCH_EDIT]") === 0)
			return this.branchEditNote(note, "[BRANCH_EDIT]");
		else if(note.note.indexOf("[RESPONSIBLE_CHANGE]") === 0)
			return this.responsibleChangeNote(note);

		return this.textNote(note);
	}

	vendorLink(note)
	{

		if((note.VendorProp))

			note.vendorProp = note.VendorProp;


		if((! note.vendorProp) || (! note.vendorProp.id))
			return null;


		let status = "";

		if(note.vendorProp["_pivot_branch_id"])
		{
			if((note.vendorProp.status > this.context.GA_CONFIG.vp.status.INTROS_DONE) && (note.vendorProp.status < this.context.GA_CONFIG.vp.status.OTHER) && (note.vendorProp["_pivot_status"] === this.context.GA_CONFIG.branchStatus.AGENT_CHOSEN))
				status = "success";
			else if((note.vendorProp.archive == this.context.GA_CONFIG.vp.archiveStatus.PPV_GONE_LIVE) && (note.vendorProp["_pivot_status"] == this.context.GA_CONFIG.branchStatus.AGENT_CHOSEN))
				status = "success";
			else if((note.vendorProp.status > this.context.GA_CONFIG.vp.status.INTROS_DONE) && (note.vendorProp.status < this.context.GA_CONFIG.vp.status.OTHER) && (note.vendorProp["_pivot_status"] !== this.context.GA_CONFIG.branchStatus.AGENT_CHOSEN))
				status = "primary";
			else if((note.vendorProp.archive > this.context.GA_CONFIG.vp.archiveStatus.ALREADY_CHOSEN_AGENT) && (note.vendorProp.archive <= this.context.GA_CONFIG.vp.archiveStatus.CHOSE_REGISTERED_AGENT_ON_LIST_CANNOT_CLAIM))
				status = "warning";
			else if((note.vendorProp.archive !== null) || (note.vendorProp.status >= this.context.GA_CONFIG.vp.status.OTHER))
				status = "danger";
			else
				status = "info";
		}
		else
		{
			if((note.vendorProp.status > this.context.GA_CONFIG.vp.status.INTROS_DONE) && (note.vendorProp.status < this.context.GA_CONFIG.vp.status.OTHER))
				status = "success";
			else if((note.vendorProp.archive >= this.context.GA_CONFIG.vp.archiveStatus.ALREADY_CHOSEN_AGENT) && (note.vendorProp.archive <= this.context.GA_CONFIG.vp.archiveStatus.CHOSE_REGISTERED_AGENT_ON_LIST_CANNOT_CLAIM))
				status = "warning";
			else if((note.vendorProp.archive !== null) || (note.vendorProp.status >= this.context.GA_CONFIG.vp.status.OTHER))
				status = "danger";
			else
				status = "info";

		}

		return (
			<a href={adminPanelURL("/vendor/property/" + note.vendorProp.id + "/edit")} target="_blank" style={{cursor:"pointer"}}>
				<Badge variant={status} style={{whiteSpace: "normal", display: "block"}}>
					<FaHome /> &nbsp;
					{note.vendorProp.user.name}, {note.vendorProp.bedrooms} bed, {note.vendorProp.address1}, £{Math.round(note.vendorProp.estvalue / 1000)}k
				</Badge>
			</a>
		);
	}

	render()
	{

		const {
			note,
			note: {
				remind_team_id: isTeamNote,
				reminderTeam = null,
			},
			userTeams,
			showBranch,
			showVendor,
			addHr
		} = this.props;

		const {
			timeToDelete,
			saving,
			done
		} = this.state;

		let creatorName, creatorTime;

		if(note.creator)
			creatorName = note.creator.name;
		else if(note.type && note.type === "BD_CALL")
			creatorName = note.called_by ? note.calledBy.name : note.assignedTo.name;

		if(note.calledBy)
			creatorName = note.calledBy.name;

		if(note.type && note.type === "CS_CALL")
			creatorTime = note.called_at;
		else if(note.type && note.type === "BD_CALL")
			creatorTime = note.called_at ?? note.updated_at;
		else
			creatorTime = note.created_at;

		return (
			<Row className={addHr ? "border-top mt-3 pt-3" : null}>


				<Col xs={12}>
					{this.renderNote(note)}
				</Col>

				{(timeToDelete > 0) &&
				<Col xs={12} className="text-right">
					<Button onClick={this.deleteNoteClick.bind(this)} variant="danger" size="sm">Delete note ({timeToDelete} seconds left)</Button>
				</Col>}

				<Col sm={3}>
					<Badge variant="primary" style={{whiteSpace: "normal", display: "block"}}><FaPen /> {creatorName || "Auto"}, {moment(creatorTime).format("ddd Do MMM YYYY, HH:mm")}</Badge>
				</Col>

				<Col sm={3}>
					{isTeamNote || (note.remindUser && note.remindUser.name) ? (

						<>
							{note.remindUser && note.remindUser.name ? <Badge variant="danger" style={{whiteSpace: "normal", display: "block"}}><FaClock /> {note.remindUser.name} at {moment(note.reminder_time).format("ddd Do MMM YYYY, HH:mm")}</Badge> : null}

							{isTeamNote ? <Badge variant="danger" style={{whiteSpace: "normal", display: "block"}}><FaClock /> {reminderTeam && reminderTeam.name} at {moment(note.reminder_time).format("ddd Do MMM YYYY, HH:mm")}</Badge> : null}


							{saving ? (
								<strong>Saving...</strong>
							) : (
								<Form.Check type="checkbox" checked={done} onChange={this.noteDoneChange.bind(this)} label="Done" />
							)}
						</>

					) : null}
				</Col>

				<Col sm={3}>
					{((showBranch !== false) && (note.branch)) ? (

						<a href={adminPanelURL("/branch/" + note.branch.id + "/edit")} target="_blank">
							<Badge variant="info" style={{whiteSpace: "normal", display: "block"}}><FaTag /> {note.branch.name}</Badge>
						</a>

					) : null}
				</Col>

				<Col sm={3} >
					{((showVendor !== false) && (note.vendorProp || note.VendorProp)) ? this.vendorLink(note) : null}
				</Col>

			</Row>
		);
	}
}

export default Note;
