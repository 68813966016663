import React from "react";
import { Card, Table, Modal, Form, Button, Col, FormControl, Row} from "react-bootstrap";
import _ from "underscore";
import { FaRegThumbsDown, FaExclamationCircle, FaRegThumbsUp, FaPhone } from "react-icons/fa";
import { MdWarning, MdRecordVoiceOver } from "react-icons/md";
import {GAConfigContext} from "contexts/gaConfigContext";
import RightmoveFullAddress from "./rightmoveFullAddress";
import moment from "moment";
import { getAPI, postAPI } from "utils/requestAPI";
import { adminPanelURL } from "utils/common";
import iconRightmove from "images/icons/icon-rightmove.png";
import iconZoopla from "images/icons/icon-zoopla.png";
import iconOTM from "images/icons/icon-otm.png";

class MatchListDetails extends React.PureComponent
{
	static contextType = GAConfigContext;

	constructor(props)
	{
		super(props);
		this.state =
		{
			listingDetails: [],
			showModal: false
		};
	}

    componentDidUpdate = (prevProps, prevState) =>
    {
    	if(this.props.selectedVendor !== prevProps.selectedVendor)
    		this.fetchListingDetails();
    }
    componentDidMount = () =>
    {
    	this.fetchListingDetails();
    }

    fetchListingDetails()
    {
    	getAPI(`/admin/vendor/property/${this.props.selectedVendor.id}/listing?notConfirmed=1`)
    		.then(data =>
    		{
    			if(data.success)
    				this.setState({listingDetails: data.listings});

    		});
    }

    determineIfMoreThanOneAgentChosen(listing, selectedVendorBranches)
    {
    	const legitBranch = listing.branch.id;

    	const branchesChosen = selectedVendorBranches
    		.filter(branchOnShortlist =>
    			(branchOnShortlist["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.AGENT_CHOSEN) &&
					(branchOnShortlist.id != legitBranch));

    	if(branchesChosen.length)
    		return true;

    	return false;
    }

    async matchListing(ld)
    {
    	const { selectedVendor, removeVendor, selectedVendorBranches } = this.props;

    	if(ld.ppv)
    	{
    		if(ld.valRefunded)
    		{
    			alert("This valuation has been refunded, and yet they won the business! Don't touch anything and talk to Peter please :)");
    			return;
    		}

    		if(window.confirm("Press OK if we are *not* charging 0.25% for this property, and you are sure it is a match"))
    		{
    			// move to listing live and put in IDs
    			const vendorParams = {
						status: this.context.GA_CONFIG.vp.status.AGENT_CHOSEN,
						estvalue: ld.price,
						archive: this.context.GA_CONFIG.vp.archiveStatus.PPV_GONE_LIVE,
    			};

    			// change vendor status to agent chosen
    			const data1 = await postAPI("/admin/vendor/property/" + selectedVendor.id + "/edit", vendorParams);

    			// change branch status to chosen
    			const data2 = await postAPI("/admin/vendor/property/" + selectedVendor.id + "/shortlist/" + ld.branch.id, {status: parseInt(this.context.GA_CONFIG.vp.branchStatus.AGENT_CHOSEN) });

    			// archive vendor
    			await postAPI("/admin/vendor/property/" + selectedVendor.id + "/archive", {archive: this.context.GA_CONFIG.vp.archiveStatus.PPV_GONE_LIVE, reason: "Chose " + ld.branch.name + " who has been billed for the valuation."});

    			// add listing
    			const listingParams = {
					zoopla_id: ld.z_id || undefined,
					rightmove_id: ld.rm_id || undefined,
					am_id: ld.am_id || undefined,
					is_match: 1
    			};

    			await postAPI("/admin/vendor/property/" + selectedVendor.id + "/listing", listingParams);

    			removeVendor();
    			return;
    		}
    	}

    	if(((ld.shortlistSent === true) || (ld.valDone === true)) && (! ld.ppv))
    	{
    		// Don't allow normal flow if more than 1 agent chosen (Sometimes agents report themselves as chosen)
    		if(this.determineIfMoreThanOneAgentChosen(ld, selectedVendorBranches))
    			return alert("No can do: This vendor already has another agent set as AGENT_CHOSEN. Please check and fix the vendor shortlist before retrying.");

    		// perhaps we can claim!
    		if(window.confirm("Are we claiming this property?"))
    		{

    			// move to listing live and put in IDs
    			const vendorParams = {
						status: this.context.GA_CONFIG.vp.status.LISTING_LIVE,
						estvalue: ld.price
    			};


    			await postAPI("/admin/vendor/property/" + selectedVendor.id + "/shortlist/" + ld.branch.id, {status: parseInt(this.context.GA_CONFIG.vp.branchStatus.AGENT_CHOSEN)});

    			await postAPI("/admin/vendor/property/" + selectedVendor.id + "/edit", vendorParams);

    			// move to listing live and put in IDs
    			const listingParams = {
					zoopla_id: ld.z_id || 0,
					rightmove_id: ld.rm_id || 0,
					am_id: ld.am_id || 0,
					is_match: 1
    			};

    			await postAPI("/admin/vendor/property/" + selectedVendor.id + "/listing", listingParams);

    			// reload page
    			this.unarchiveIfNeeded();
    			this.props.removeVendor();
    			return;
    		}
    	}

    	// if vals not done and shortlist not sent branch is shortlisted, listing shoudl be archive
    	let archiveCode = this.context.GA_CONFIG.vp.archiveStatus.CHOSE_UNLISTED_TRAD_AGENT;

    	if(ld.branch.online_only)
    		archiveCode = this.context.GA_CONFIG.vp.archiveStatus.CHOSE_UNLISTED_ONLINE_AGENT;
    	else if(ld.onShortlist && (! ld.branch.registered) && ld.quoted)
    		archiveCode = this.context.GA_CONFIG.vp.archiveStatus.CHOSE_UNREGISTERED_AGENT_ON_LIST_WITH_QUOTE;
    	else if(ld.onShortlist && (! ld.branch.registered))
    		archiveCode = this.context.GA_CONFIG.vp.archiveStatus.CHOSE_UNREGISTERED_AGENT_ON_LIST_NO_QUOTE;
    	else if(ld.onShortlist && (! ld.valDone))
    		archiveCode = this.context.GA_CONFIG.vp.archiveStatus.CHOSE_REGISTERED_AGENT_ON_LIST_CANNOT_CLAIM;

    	// set property parameters for this listings
    	let params = {
				zoopla_id: ld.z_id || 0,
				rightmove_id: ld.rm_id || 0,
				am_id: ld.am_id || 0,
				estvalue: ld.price,
				is_match: 1
    	};

    	// display model view to choose archive status and reason
    	this.setState({ showModal: true, archiveCode: archiveCode, archiveReason: "Vendor chose " + ld.branch.name, params: params});
    }

	// archive listings with reason and status
	archiveListings = (e) =>
	{
		const {archiveCode, archiveReason, params} = this.state;
		const { selectedVendor, removeVendor } = this.props;

		postAPI("/admin/vendor/property/" + selectedVendor.id + "/archive", {archive: archiveCode, reason: archiveReason})
			.then(data =>
			{
				return postAPI("/admin/vendor/property/" + selectedVendor.id + "/listing", params)
					.then(function(data)
					{
						// reload page
						removeVendor();
						return;
					})
					.catch(e => alert(e || "An unknown error occurred when trying to add portal ids"));
			})
			.catch(e => alert(e + "\nError when archiving " + selectedVendor.id + ". Please check and fix!"));

		this.hideModal();
	}

	// make changes to updates on modal view
	changeArchiveReason = (e) =>
	{
		const { target: { name, value} } = e;

		this.setState({[name]: value,});
	}

	hideModal = () =>
	{
		this.setState({ showModal: false });
	};

	unarchiveIfNeeded = () =>
	{
		if(this.props.selectedVendor.archive === null)
			return false;

		// needs unarchiving
		return postAPI("/admin/vendor/property/" + this.props.selectedVendor.id + "/unarchive", {reason: "Matched with live listing"});
	}

	noneMatch = () =>
	{
		// get listing IDs
		this.state.listingDetails.map(ld =>
		{
			postAPI("/admin/vendor/property/" + this.props.selectedVendor.id + "/listing",
				{zoopla_id: ld.z_id || 0, rightmove_id: ld.rm_id || 0, am_id: ld.am_id || 0, is_match: 0})
				.then(data =>
				{
					// reload page
					this.props.removeVendor();
				})
				.catch(e =>
				{
					alert(e || "An unknown error occurred");

					this.props.removeVendor();
				});
		});
	}

    render = () =>
    {
    	const { selectedVendor, selectedVendorBranches, selectedVendorBillings, selectedVendorInteractions } = this.props;
    	const { listingDetails, archiveCode, archiveReason, showModal, showFullAdress } = this.state;


    	const matchListBtn = (listing) =>
    	{
    		if(listing.valDone)
    		{
    			if(listing.ppv)

    				return <Button size="sm" variant="warning" onClick={this.matchListing.bind(this,listing)}>Match (PPI)</Button>;

    			else

    				return <Button size="sm" variant="success" onClick={this.matchListing.bind(this,listing)}>Match (did vals)</Button>;


    		}
    		else if(listing.shortlistSent)
    		{
    			return <Button size="sm" variant="warning" onClick={this.matchListing.bind(this,listing)}>Match (no val)</Button>;
    		}
    		else if(listing.onShortlist)
    		{
    			return <Button size="sm" variant="danger" onClick={this.matchListing.bind(this,listing)}>Match (no shortlist sent)</Button>;
    		}
    		else
    		{
    			return <Button size="sm" variant="danger" onClick={this.matchListing.bind(this,listing)}>Match (not on list)</Button>;
    		}
    	};

    	return (
    		<>
    			<Modal show={showModal} onHide={this.hideModal}
    				dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">

    				<Modal.Header closeButton>
    					<Modal.Title id="example-custom-modal-styling-title"> Archive property </Modal.Title>
    				</Modal.Header>
    				<Modal.Body>
    					<Form>
    						<Row>
    							<Col sm={4}>
    								<Form.Label>Why hide this property?</Form.Label>
    							</Col>

    							<Col sm={8}>
    								<FormControl as="select"  onChange={this.changeArchiveReason}
    									value={archiveCode} name="archiveCode">

    									{Object.keys(this.context.GA_CONFIG.vp.archiveStatus).map((key) =>
    									{
    										return <option key={key} value={this.context.GA_CONFIG.vp.archiveStatus[key]} >{key}</option>;
    									})}
    								</FormControl>
    							</Col>
    						</Row>
    						<Row className="mt-3">
    							<Col sm={4}>
    								<Form.Label>Add a note with a bit more info:</Form.Label>
    							</Col>

    							<Col sm={8}>
    								<FormControl as="textarea" rows="5" onChange={this.changeArchiveReason}
    									value={archiveReason} name="archiveReason"/>
    							</Col>
    						</Row>
    					</Form>
    				</Modal.Body>
    				<Modal.Footer>
    					<Button type="button" variant="default" onClick={this.hideModal} >Cancel</Button>
    					<Button type="button" variant="danger" onClick={this.archiveListings}>Archive this property</Button>
    				</Modal.Footer>
    			</Modal>

    			<Card className={this.props.className}>
    				<Card.Header>
    					<Col>Matches</Col>
    					<Col><Button variant="danger" className="float-right" onClick={this.noneMatch}>None Match <FaRegThumbsDown /></Button></Col>
    				</Card.Header>
    				<Table striped className="mb-0">

    					<thead>
    						<tr>
    							<th>Address</th>
    							<th>Added</th>
    							<th>Branch</th>
    							<th>Portals</th>
    							<th>Status</th>
    							<th>Money maker</th>
    						</tr>
    					</thead>
    					<tbody>
    						{listingDetails.map((listing) =>
    						{
    							let branch = null, billing = null;

    							if(selectedVendorBranches)
    							{
    								for (let i = 0; i < selectedVendorBranches.length; i++)
    								{
    									branch = selectedVendorBranches[i];
    									if(branch.id == listing.branch.id)
    										break;

    									branch = null;
    								}

    								listing.onShortlist = false;
    								listing.shortlistSent = false;
    								listing.valDone = false;
    								listing.madeContact = false;
    								listing.quoted = false;
    								listing.ppv = false;
    								listing.valRefunded = false;
    								listing.branchStatus = false;

    								for (var b = 0; b < selectedVendorBillings.length; b++)
    								{
    									billing = selectedVendorBillings[b];

    									if(billing.branch_id == listing.branch.id)
    									{
    										if(billing.type == 20)
    											listing.valRefunded = true;
    										else if(billing.type == 10)
    											listing.ppv = true;
    									}
    								}

    								if(branch)
    								{
    									listing.onShortlist = true;
    									listing.branchStatus = _.findKey(this.context.GA_CONFIG.vp.branchStatus, (val) => val == branch["_pivot_status"]);

    									if(branch["_pivot_shortlist_sent_at"])
    										listing.shortlistSent = true;

    									if(branch["_pivot_quote"])
    										listing.quoted = true;

    									if((branch["_pivot_val_req_at"] !== null) && (branch["_pivot_status"] >= this.context.GA_CONFIG.vp.branchStatus.VAL_INTRO_DONE))
    									{
    										listing.valDone = true;

    										// has agent used our phone?
    										_.each(selectedVendorInteractions, function(i)
    										{
    											if(
    												(i.branch_id == branch.id) &&
													((i.type == 20) || (i.type == 10 && (i.duration > 50))))
    												listing.madeContact = true;
    										});

    										if(branch["_pivot_status"] == this.context.GA_CONFIG.vp.branchStatus.VALUATION_REFUND)
    											listing.valRefunded = true;
    									}
    								}
    							}

                                const phoneUrl = listing.onShortlist ? adminPanelURL(`/vendor/property/${selectedVendor.id}/phone/${listing.branch.id}`) : adminPanelURL(`/branch/${listing.branch.id}/phone`);

    							return (<tr key={listing.id}>
    								<td>
    									{(listing.rm_id && (! listing.z_id)) ? (
    										<RightmoveFullAddress address={listing.address} rmId={listing.rm_id} autoload={true} />
    									):(
    										<span>{listing.address}</span>
    									)}
    								</td>

    								<td>
    									{moment.utc(listing.dateAdded).format("Do MMM YYYY")} &nbsp;
    									{moment.utc(listing.dateAdded).diff(moment.utc(selectedVendor.created_at), "days") < 2 &&
									<FaExclamationCircle className="text-danger" />}
    									{(moment.utc(listing.dateAdded).diff(moment.utc(selectedVendor.created_at), "days") < 7) ?
    										<MdWarning className="text-warning" /> :
    										<FaRegThumbsUp className="text-success" /> }
    								</td>

    								<td>
    									<a href={adminPanelURL(`/branch/${listing.branch.id}/edit`)} target="_blank" className={listing.branch.registered == true ? "" :  "text-danger"}>
    										{listing.branch.name}
    									</a> &nbsp;
    									{listing.ppv  && <span className="label label-success"> PPI </span> }
    									<a href={`javascript: void(window.open('${phoneUrl}', 'callwin', 'width=500,height=500'))`}>&nbsp;<FaPhone /></a>
    								</td>
    								<td>
    									{listing.z_id && <a href={`http://www.zoopla.co.uk/for-sale/details/${listing.z_id}`} target="_blank"><img src={iconZoopla} width="20" /></a>} &nbsp;
    									{listing.rm_id && <a href={`http://www.rightmove.co.uk/property-for-sale/property-${listing.rm_id}.html`} target="_blank"><img src={iconRightmove} width="20" /></a>} &nbsp;
    									{listing.am_id && <a href={`https://www.onthemarket.com/details/${listing.am_id}/`} target="_blank"><img src={iconOTM} width="20" /></a>  }
    								</td>
    								<td>
    									{listing.branchStatus && listing.branchStatus}
    									{listing.branchStatus  && (listing.branchStatus != "AGENT_MADE_CONTACT" && (listing.branchStatus != "AGENT_CHOSEN") && (listing.branchStatus != "AGENT_WENT_FOR_VAL") && (listing.branchStatus != "VAL_INTRO_DONE") && (listing.branchStatus != "VAL_BOOKED") &&
									<div className="text-danger">BAD STATE - CHECK! </div>)}
    								</td>
    								<td>
    									{matchListBtn(listing)}
    									{listing.valDone && (listing.madeContact && <p className="text-success"><MdRecordVoiceOver /> &nbsp; <FaRegThumbsUp /></p>)}
    								</td>

    							</tr>);
    						})}
    					</tbody>
    				</Table>
    			</Card>
    		</>
    	);
    }
}

export default MatchListDetails;