import React from "react";
import _ from "underscore";

const TeamLeaderboard = ({ title, teamStats, metric }) =>
{
	return (
		<div className="wallboard-card">
			<p className="wallboard-heading">{title}</p>
			<div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
				{_.sortBy(teamStats, metric)
					.reverse()
					.map((team, index) => (
						<div
							key={index}
							className="wallboard-text"
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "end",
								borderBottom: "1px solid",
								borderColor: "var(--secondary)",
							}}
						>
							<p>{team.teamName}</p>
							<p>{team[metric]}</p>
						</div>
					))}
			</div>
		</div>
	);
};

export { TeamLeaderboard };
