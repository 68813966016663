import React from "react";
import WagyuCards from "./wagyuCards";
import WagyuGraphs from "./wagyuGraphs";
import { Spinner, Row, Col, Card } from "react-bootstrap";
import { getAPI } from "utils/requestAPI";

class WagyuDashboard extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			loading: false,
			stats: []
		};
	}

	componentDidMount = () =>
	{
		this.fetchStats();
	}

	componentDidUpdate = (prevProps) =>
	{
		if(prevProps.to !== this.props.to || prevProps.from !== this.props.from)
			this.fetchStats();
	}

	fetchStats = async() =>
	{
		const { to, from } = this.props;

		this.setState({loading: true});

		try
		{
			const data = await getAPI(`/admin/vendor/wagyu.json?&from=${from}&to=${to}`);

			this.setState({
				stats: data.stats || {},
				loading: false,
			});
		}
		catch (err)
		{
			this.setState({errorMsg: err.message});
		}
	}

	render()
	{
		return (
			<>
				{this.loading && <div className="text-center mt-5">
					<Spinner size="lg" variant="primary" animation="border"/>
				</div>}

				{this.state.stats && this.state.stats.callsMade > 0 ? (
					<>
						<WagyuCards stats={this.state.stats}/>
						<WagyuGraphs stats={this.state.stats}/>
					</>
				) : (
					<Card>
						<Row>
							<Col className="p-4 flex" style={{textAlign: "center"}}>
								<p style={{marginBottom: 0, height: "fit-content"}}>
									No stats found for the chosen date selection.
								</p>
							</Col>
						</Row>
					</Card>
				)}
			</>
		);
	}
}

export default WagyuDashboard;