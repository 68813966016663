import React from "react";
import {Card, Row, Col, FormControl, Form, Button, Table} from "react-bootstrap";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import { FaSave } from "react-icons/fa";
import moment from "moment";
import _ from "underscore";
import { adminPanelURL } from "utils/common";

class BranchVendorPropList extends React.Component
{

	state = {
		filter: "SHOW_ALL",
		showArchived: true,
		showSpam: false,
		textSearch: "",
		// helps BD team filter out credited invoices when filter
		// is set to 'OUTSTANDING_INVOICES' as the vendor is
		// archived at this stage
		showArchivedDefaultOff: false
	}

	static contextType = GAConfigContext;

	_filterFuncs = {

		SHOW_ALL: ((vp) => true),
		VALS_FROM_BRANCH: ((vp) => vp["_pivot_val_req_at"] !== null),
		VAL_INTRO_DONE: ((vp) => vp["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.VAL_INTRO_DONE),
		LIVE_WITH_BRANCH: ((vp) => (vp["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.AGENT_CHOSEN) && (vp.status >= this.context.GA_CONFIG.vp.status.LISTING_LIVE)),
		LIVE_WITH_ANY_BRANCH: ((vp) => (vp.status >= this.context.GA_CONFIG.vp.status.LISTING_LIVE) && (vp.status < this.context.GA_CONFIG.vp.status.OTHER)),
		SALE_AGREED_WITH_BRANCH: ((vp) => (vp["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.AGENT_CHOSEN) && (vp.status == this.context.GA_CONFIG.vp.status.SALE_AGREED)),
		OUTSTANDING_INVOICES: ((vp) => (vp["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.AGENT_CHOSEN) && (vp.status == this.context.GA_CONFIG.vp.status.SALE_COMPLETE)),
		PAID_INVOICES: ((vp) => (vp["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.AGENT_CHOSEN) && (vp.status >= this.context.GA_CONFIG.vp.status.MONEY_PAID))
	}

	filterChange(e)
	{
		this.setState({
			filter: e.target.value,
			showArchivedDefaultOff: (e.target.value === "OUTSTANDING_INVOICES") ? true : false
		});
	}

	onCheckboxChange(key, e)
	{
		const o = {};

		o[key] = e.target.checked;

		// We also do this here to allow team to tick the checkbox back
		// in case they want to see archived with this filter
		if(this.state.filter === "OUTSTANDING_INVOICES")
			o["showArchivedDefaultOff"] = !e.target.checked;

		this.setState(o);
	}

	onTextChange(e)
	{
		this.setState({textSearch: e.target.value});
	}

	// export as a CSV file
	handleCSVExport = (e, data) =>
	{

		let rows = [
			["ID", "Date", "Name", "Address", "Status", "Branch Status"].join(",")
		];

		data.map((vp) =>
		{
			const address = vp.address1 +" "+ vp.postcode;

			const row = [vp.id,
				moment(vp.created_at).format("Do MMM YYYY"),
				vp.user.name,
				address.replace(/,/gi, ""),
				_.invert(this.context.GA_CONFIG.vp.status)[vp.status],
				_.invert(this.context.GA_CONFIG.vp.branchStatus)[vp["_pivot_status"]]];

			rows.push(row.join(","));
		});

		const blob = new Blob([rows.join("\n")], { type: "text/csv;charset=utf-8;" });
		const link = document.createElement("a");
		const url = URL.createObjectURL(blob);

		link.setAttribute("href", url);
		link.setAttribute("download", "Leads-"+moment().format("YYYY-MM-DD")+ ".csv");
		document.body.appendChild(link);
		link.click();
	}

	render()
	{


		if((! this.props.vendors) || (this.props.vendors.length === 0))
		{
			return (
				<Card>
					<Card.Header>
						<Card.Title>Vendors</Card.Title>
					</Card.Header>
					<p>This branch hasn't appeared on any shortlists.</p>
				</Card>
			);
		}


		let filteredProps = _.filter(this.props.vendors, this._filterFuncs[this.state.filter]);

		if(filteredProps.length > 0)
		{
			filteredProps = _.filter(filteredProps, vp =>
			{
				if((this.state.showArchived === false) && (vp.archive !== null))
					return false;

				if((this.state.showSpam === false) && (vp.status >= this.context.GA_CONFIG.vp.status.OTHER))
					return false;

				// if no text search then exit
				if((! this.state.textSearch) || (this.state.textSearch.trim().length < 2))
					return true;

				const matcher = new RegExp(this.state.textSearch.toLowerCase().trim(), "i");

				return matcher.test(vp.user.name) || matcher.test(vp.address1) || matcher.test(vp.postcode);
			});
		}

		return (
			<Card className={this.props.className}>
				<Card.Header>
					Vendors ({filteredProps.length} of {this.props.vendors && this.props.vendors.length})
				</Card.Header>
				<Card.Body className="pb-0">
					<Row>
						<Col md={3}>
							<FormControl as="select" placeholder="select" onChange={this.filterChange.bind(this)} value={this.state.filter}>
								<option value="SHOW_ALL">Show all</option>
								<option value="VALS_FROM_BRANCH">Requested valuation from this branch</option>
								<option value="VAL_INTRO_DONE">Valuations not yet contacted</option>
								<option disabled></option>
								<option value="LIVE_WITH_BRANCH">Went live with this branch</option>
								<option value="LIVE_WITH_ANY_BRANCH">Went live with any branch</option>
								<option disabled></option>
								<option value="SALE_AGREED_WITH_BRANCH">Sale agree with this branch</option>
								<option disabled></option>
								<option value="OUTSTANDING_INVOICES">Outstanding invoices</option>
								<option value="PAID_INVOICES">Paid invoices</option>
							</FormControl>
						</Col>
						<Col md={2}>
							<Form.Group controlId="archivedCheckbox">
								<Form.Check type="checkbox"
									checked={this.state.showArchivedDefaultOff ? false : this.state.showArchived}
									onChange={this.onCheckboxChange.bind(this, "showArchived")}
									label={"Show archived"} />
							</Form.Group>
						</Col>
						<Col md={2}>
							<Form.Group controlId="spamCheckbox">
								<Form.Check type="checkbox"
									checked={this.state.showSpam}
									onChange={this.onCheckboxChange.bind(this, "showSpam")}
									label={"Show Spam"} />
							</Form.Group>
						</Col>
						<Col md={3}>
							<FormControl type="text"
								placeholder="Search 🔎"
								onChange={this.onTextChange.bind(this)}
								value={this.state.textSearch}
							/>
						</Col>

						<Col md={2}>
							<Button variant="primary" onClick={((e) => this.handleCSVExport(e, filteredProps))}><FaSave /></Button>
						</Col>
					</Row>
				</Card.Body>
				<div style={{maxHeight: 400, overflowY: "auto"}}>
					<Table striped size="sm" hover responsive className="mb-0 mt-4 card-table">
						<colgroup align="left">
							<col width="10%"/>
							<col width="10%"/>
							<col width="20%"/>
							<col width="20%"/>
							<col width="20%"/>
							<col width="20%"/>
						</colgroup>
						<thead >
							<tr>
								<th>ID</th>
								<th>Date</th>
								<th>Name</th>
								<th>Address</th>
								<th>Status</th>
								<th>Branch Status</th>
							</tr>
						</thead>
						<tbody>
							{filteredProps.map((vp) =>
							{
								return (
									<tr className={(vp["_pivot_status"] == this.context.GA_CONFIG.vp.branchStatus.AGENT_CHOSEN ? "text-success " : "") + "mb-2"} key={vp.id}>
										<td md={1}><a href={adminPanelURL("/vendor/property/" + vp.id + "/edit")} target="_blank">{vp.id}</a></td>
										<td md={2}>{moment(vp.created_at).format("Do MMM YYYY")}</td>
										<td md={2}>{vp.user.name}</td>
										<td md={2}>{vp.address1}, {vp.postcode}</td>
										<td md={2}>{_.invert(this.context.GA_CONFIG.vp.status)[vp.status]}</td>
										<td md={2}>{_.invert(this.context.GA_CONFIG.vp.branchStatus)[vp["_pivot_status"]]}</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</div>

			</Card>
		);
	}
}

export default BranchVendorPropList;