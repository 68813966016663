import React from 'react';
import { getAPI } from "utils/requestAPI";

class RightmoveFullAddress  extends React.PureComponent {

    constructor(props) 
	{
		super(props);
		
        this.state = 
		{
            loading: false,
            fullAddress: null,
            errMsg: null
		}

        if(props.autoload)
            this.fetchAddress();
    }

    componentDidUpdate(prevProps, prevState)
    {
        if(prevProps.rmId !== this.props.rmId)
        {
            this.setState({fullAddress: null, errMsg: null, loading: false})

            if(this.props.autoload)
                this.fetchAddress();
        }
    }

    fetchAddress = () =>
    {
        const {rmId, address} = this.props;

        const regexAddress = address.match(/([A-Z0-9])\w+/g);
        const outcode = regexAddress[regexAddress.length-2];
        const incode = regexAddress[regexAddress.length-1];

        this.setState({loading: true});

        getAPI(`/listing/rightmove/${rmId}/address.json?id=&outcode=${outcode}&incode=${incode}`)
        .then(data =>
        {   
            this.setState({
                fullAddress: data.fullAddress,
                loading: false,
                errMsg: false
            });
        })
        .catch(e =>
        {
            this.setState({
                errMsg: e, 
                loading: false
            });
        })
    }

    onClickAddress = () =>
	{
        this.fetchAddress();
	}

    render()
    {
        const {fullAddress, showFullAdress, loading, errMsg} =  this.state;
        const {address, rmId} = this.props;

        return(
            <div>
                {fullAddress ? fullAddress : address}
                {rmId && (! loading) && (! fullAddress) && (! errMsg) && <span style={{cursor: 'pointer'}} onClick={this.onClickAddress}>🔎</span>}
                {loading && <span>⏳</span>}
                {errMsg && (! loading) && <span title={errMsg}>⚠️</span>}
            </div>
        );
    }
}

export default RightmoveFullAddress;