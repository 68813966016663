import React from "react";
import { Nav, Card } from "react-bootstrap";
import { useState } from "react";
import FujisanAnswerRate from "./fujisanAnswerRate";
import FujisanValuationRate from "./fujisanValuationRate";
import FujisanCallsToLiveListings from "./fujisanCallsToLiveListings";
import FujisanCallsToValuationRate from "./fujisanCallsToValuationRate";
import FujisanVendorsArchived from "./fujisanVendorsArchived";

function FujisanGraphs({ csUsers, csUserFilter})
{

	const [selectedGraph, setSelectedGraph] = useState(0);


	return (
		<Card className="mt-4">
			<Card.Header>
				<Nav variant="tabs" activeKey={1}>
					<Nav.Item key={1}>
						<Nav.Link
							active={selectedGraph === 0}
							onClick={() => setSelectedGraph(0)}
						>
                        Answer Rate
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							active={selectedGraph === 1}
							onClick={() => setSelectedGraph(1)}
						>
                        Valuation Rate
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							active={selectedGraph === 2}
							onClick={() => setSelectedGraph(2)}
						>
                        Calls to Valuation Rate
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							active={selectedGraph === 3}
							onClick={() => setSelectedGraph(3)}
						>
                        Calls to Listing Rate
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							active={selectedGraph === 4}
							onClick={() => setSelectedGraph(4)}
						>
                    Vendors Archived
						</Nav.Link>
					</Nav.Item>
				</Nav>
			</Card.Header>

			<Card.Body>

				<FujisanAnswerRate
					csUsers={csUsers}
					csUserFilter={csUserFilter}
					show={selectedGraph === 0}
				/>
				<FujisanValuationRate
					csUsers={csUsers}
					csUserFilter={csUserFilter}
					show={selectedGraph === 1}
				/>
				<FujisanCallsToValuationRate
					csUsers={csUsers}
					csUserFilter={csUserFilter}
					show={selectedGraph === 2}
				/>
				<FujisanCallsToLiveListings
					csUsers={csUsers}
					csUserFilter={csUserFilter}
					show={selectedGraph === 3}
				/>
				<FujisanVendorsArchived
					csUsers={csUsers}
					csUserFilter={csUserFilter}
					show={selectedGraph === 4}

				/>

			</Card.Body>
		</Card>
	);
}

export default FujisanGraphs;


