let basepath = null;

const buildUrl = (endpoint) =>
{
	if(! basepath)
		return endpoint;

	// already contains schema
	if(endpoint.indexOf("http") === 0)
		return endpoint;

	return basepath + endpoint;
};


export const getAPI = async(endpoint, options = null) =>
{
	const validateSuccess = ! (options && options.skipValidate);

	if(options && options.data)
	{
		const qs = Object.keys(options.data)
			.map(key => `${key}=${options.data[key]}`)
			.join("&");

		endpoint = `${endpoint}?${qs}`;
	}

	const req = new Request(buildUrl(endpoint), {
		method: "get",
		credentials: "include"
	});

	const res = await fetch(req);

	const json = await res.json();

	if((! json.success) && validateSuccess)
		throw new Error(json.msg || "An error occurred when loading " + endpoint);

	return json;

};


export const postAPI = async(endpoint, data, options = null) =>
{
	const validateSuccess = ! (options && options.skipValidate);

	const method = options && options.method ? options.method : "post";

	const req = new Request(buildUrl(endpoint), {
		method,
		credentials: "include",
		headers: {"Content-Type": "application/json"},
		body: JSON.stringify(data)
	});

	const res = await fetch(req);

	const json = await res.json();

	if((! json.success) && validateSuccess)
		throw new Error(json.msg || "An error occurred when saving to " + endpoint);

	return json;
};


export const multipartPostAPI = async(endpoint, data, options) =>
{
	const validateSuccess = ! (options && options.skipValidate);

	if(! data)
		throw new Error("No data specified in the multipartPostAPI");

	const formData = new FormData();

	for (const name in data)

    	formData.append(name, data[name]);


  	const req = new Request(buildUrl(endpoint), {
		method: "POST",
		credentials: "include",
		body: formData
	});

	const res = await fetch(req);

	const json = await res.json();

	if((! json.success) && validateSuccess)
		throw new Error(json.msg || "An error occurred when saving to " + endpoint);

	return json;

};

export const setAPIBasepath = (newBasepath) =>
{
	basepath = newBasepath;
};

export const getAPIBasepath = () => basepath ? basepath : "";

let publicURL = null;

export const setPublicURLBasepath = (newBasepath) =>
{
	publicURL = newBasepath;
};

export const getPublicURLBasepath = () => publicURL ? publicURL : "";
