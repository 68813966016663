import React from "react";
import { Card, Row, Col, Table, Form, Spinner, Button } from "react-bootstrap";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { getAPI } from "utils/requestAPI";
import { adminPanelURL } from "utils/common";
import moment from "moment";
import DocumentTitle from "../shared/documentTitle";
import tickBlue from "images/icons/tick-blue.png";
import iconClock from "images/icons/icon-clock.png";
import iconAlert from "images/icons/icon-alert.png";

const NONE = "None",
	ALL = "All";

const SORT_ORDER = {
	ASC: "ASC",
	DESC: "DESC"
};
const tableHeaderRows = ["id", "agent", "vendor", "age", "chased", "responsible", "qips"];

const sortRowsByFilter = (rows, columnSort) =>
{
	const { name, direction } = columnSort;

	if(! columnSort.name && ! columnSort.direction)
		return rows;

	const newRows = rows.sort((rowA, rowB) =>
	{
		const column = name;

		if(typeof rowA[column] === "number")
		{
			if(typeof rowB[column] === "number")
				return direction === SORT_ORDER.ASC ? rowB[column] - rowA[column] : rowA[column] - rowB[column];

			if(typeof rowB[column] === "string")
				return direction === SORT_ORDER.ASC ? -1 : 1;
		}

		if(direction === SORT_ORDER.ASC)
			return rowB[column] === rowA[column] ? 0 : rowB[column] > rowA[column] ? -1 : 1;

		return rowB[column] === rowA[column] ? 0 : rowB[column] < rowA[column] ? -1 : 1;
	});

	return newRows;
};

const filterByResponsible = (rows, responsible) =>
{
	if(responsible === ALL)
		return rows;


	return rows.filter(row => row.responsible === responsible);
};

class ValReqs extends React.Component
{
	state = {
		firstReload: true,
		responsibleFilter: ALL,
		columnSort: {
			name: "age",
			direction: SORT_ORDER.DESC
		},
		rows: [],
		loading: true,
		timer: null,
	};

	componentDidMount()
	{
		this.fetchData();
		this.timer();
	}

	componentWillUnmount()
	{
		const { timer } = this.state;

		if(timer)
			clearInterval(timer);
	}

	timer()
	{
		const timer = setInterval(() =>
		{
			this.fetchData();
		}, 1000 * 60 * 2); // 2 minutes

		this.setState({ timer });
	}

	setLoading(value)
	{
		this.setState({loading: value});
	}

	async fetchData()
	{
		this.setLoading(true);

		let vendorProps = [];

		try
		{
			const response = await getAPI("/admin/vendor/valReqs.json");

			vendorProps = response.vendorProps;
		}
		catch (e)
		{
			console.log(e);
			return alert(e.message || "Something went wrong when loading outstanding valuation requests");
		}

		const newVendorProps = [];

		vendorProps.forEach(vp =>
		{
			const { id, user, branches, responsible : responsibleData } = vp;

			branches.forEach(br =>
			{

				const agent = br.name;
				const branchId = br.id;
				const vendor = user.name;
				const valReqAt =  br["_pivot_val_req_at"];
				const age = valReqAt ? Math.abs(moment(valReqAt).diff(moment(), "hours")) : NONE;

				let qips = NONE;

				if(br.signupValue)
					qips = br.signupValue.qips_value;

				const chased = br.numCalls;
				const responsible = (responsibleData && responsibleData.name) || NONE;

				newVendorProps.push({ id, agent, vendor, age, chased, responsible, qips, branchId });
			});
		});

		this.setState({rows: newVendorProps});

		this.setLoading(false);

		const { firstReload } = this.state;

		if(firstReload)
			this.setState({firstReload: false});
	}

	handlecolumnSortChange(name, direction)
	{
		this.setState({
			columnSort: {
				name,
				direction
			}
		});
	}

	handleSort(key)
	{
		const { direction, name } = this.state.columnSort;

		if(name === key)
		{
			if(direction === SORT_ORDER.ASC)
				return this.handlecolumnSortChange(key, SORT_ORDER.DESC);

			if(direction === SORT_ORDER.DESC)
				return this.handlecolumnSortChange(null, null);
		}

		this.handlecolumnSortChange(key, SORT_ORDER.ASC);
	}

	handleResponsibleFilterChange(e)
	{
		this.setState({responsibleFilter: e.target.value});
	}

	render()
	{

		const { rows, loading, columnSort, responsibleFilter, firstReload } = this.state;

		// Get all the (unique) responsobleUsers options to be displayed on the "Responsible user filter"
		const responsibleUsers = rows && rows.length > 0 ? [...new Set( [NONE, ...rows.map(r => r.responsible)] )] : [];

		const displayTableHeaderCell = (key, index) =>
		{
			return <th key={index} onClick={() => this.handleSort(key)} style={{cursor: "pointer"}}>{columnSort.name === key && (columnSort.direction === SORT_ORDER.ASC ? <FaCaretUp/> : <FaCaretDown />) } {key}</th>;
		};

		return (
			<>
				<DocumentTitle title="Valuations Outstanding" />

				<Row>
					<Col>
						<h1 className="display-4 my-5">Branches to signup</h1>
					</Col>
				</Row>

				<Card>
					<Row>
						<Col md={4} className="p-4 flex" style={{display:"flex", alignItems: "center", justifyContent: "flex-start"}}>
							{rows.length > 0 && <p style={{marginBottom: 0, height: "fit-content"}}>Total valuations found: {rows.length}</p>}
						</Col>
						<Col md={4} className="p-4">
							<div style={{marginLeft: "auto", width: "fit-content"}}>
								{!firstReload && responsibleUsers && (
									<Form inline>
										<Form.Group>
											<Form.Label>Responsible user</Form.Label>
											<Form.Control as="select" style={{marginLeft:"1rem"}} onChange={(e) => this.handleResponsibleFilterChange(e)} value={responsibleFilter}>
												<option value={ALL}>All</option>
												{responsibleUsers.map((r, rIndex) => (<option key={rIndex} value={r}>{r}</option>))}
											</Form.Control>
										</Form.Group>
									</Form>
								)}
							</div>
						</Col>
						<Col md={4} className="p-4 align-items-center d-flex justify-content-end">
							{!firstReload && <Button disabled={loading} onClick={this.fetchData.bind(this)}>Reload</Button>}
						</Col>
					</Row>
					{loading && <Spinner size="lg" variant="primary" animation="border" style={{margin: "5rem auto", display: "block"}} />}
					{!loading && (
						<Table size="sm" hover className="mb-0 card-table" responsive>
							<thead >
								<tr>
									{tableHeaderRows.map((header, index) => displayTableHeaderCell(header, index))}
								</tr>
							</thead>
							<tbody>
								{rows && sortRowsByFilter(filterByResponsible(rows, responsibleFilter), columnSort).map((row, i) =>
								{
									return (
										<tr key={i}>
											{tableHeaderRows.map((column, columnIndex) =>
											{
												if(column === "id")
												{
													return (
														<td key={columnIndex}>
															<a href={adminPanelURL(`/vendor/property/${row.id}/edit`)} target="_blank">
																{row[column]}
															</a>
														</td>
													);
												}

												if(column === "agent")
												{
													return (
														<td key={columnIndex}>
															{row.branchId &&
														<a href={adminPanelURL(`/branch/${row.branchId}/edit`)} target="_blank">
															{row[column]}
														</a>
															}
															{!row.branchId && row[column]}
														</td>
													);
												}

												if(column === "chased")
												{
													return (
														<td key={columnIndex}>
															{row[column] >= 2 ?
																<img src={tickBlue} width="24" />
																:
																row[column] == 1 ?
																	<img src={iconClock} width="24" />
																	:
																	<img src={iconAlert} width="24" />}

														</td>
													);
												}

												if(column === "qips")
												{
													return (
														<td key={columnIndex}>
															{row[column]}
														</td>
													);
												}


												return (
													<td key={columnIndex}>
														{row[column]}{columnIndex === 2 && typeof row[column] === "number" && "h"}
													</td>
												);
											})}
										</tr>
									);
								})}
							</tbody>
						</Table>)}
				</Card>

			</>
		);
	}

}

export default ValReqs;