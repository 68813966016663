import React from "react";
import { Badge, Row, Col } from "react-bootstrap";
import { callDurationString } from "../../utils/common";
import { CallTranscription } from "./callTranscription";

const RenderCallAudioPlayer = ({ call, transcribingCall, setTranscribingCall }) =>
{
	if(call.duration <= 10)
	{
		return (
			<p className="mt-2">
				<span>
					<a
						href={call.recording}
						target="_blank"
						rel="noreferrer"
					>
						{callDurationString(call.duration)} call ({call.duration}s)
					</a>
						with {" " + String(call.phone_number).replace(" 44","0").replace(" +44","0")}
				</span>
			</p>
		);
	}

	return (
		<Row>
			<Col md={6}>
				<audio style={{height:"30px"}} controls="controls" preload="none" volume="1.0">
					Your browser does not support the <code>audio</code> element.
					<source src={call.recording} type="audio/mp3" />
				</audio>
			</Col>

			<Col md={4}>
				<a
					href={call.recording}
					target="_blank"
					rel="noreferrer"
				>
					{callDurationString(call.duration)} call
				</a>
				{" "}with {" " + String(call.phone_number).replace(" 44","0").replace(" +44","0")}
			</Col>

			<CallTranscription
				call={call}
				transcribingCall={transcribingCall}
				setTranscribingCall={setTranscribingCall}
			/>

		</Row>
	);
};

const CsCallNote = ({ csCall, transcribingCall, setTranscribingCall }) => (
	<>
		<Badge pill className="float-right" variant="secondary">CS Call</Badge>
		<span>{csCall.outcome || "Unknown outcome"}</span>
		{csCall.whyNoVals && (
			<span>&nbsp; - {csCall.whyNoVals}</span>
		)}
		{csCall.note && csCall.note.note && (
			<p>{csCall.note.note}</p>
		)}
		{csCall.call &&
			<RenderCallAudioPlayer
				call={csCall.call}
				transcribingCall={transcribingCall}
				setTranscribingCall={setTranscribingCall}
			/>}
	</>
);

export default CsCallNote;
