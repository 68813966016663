import React from "react";
import _ from "underscore";
import moment from "moment";
import Note from "../shared/note";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";

class ChasingNotes extends React.Component
{
	render()
	{
		const { vendorProp } = this.props;

		let sortedList = [];

		if(vendorProp.calls)
			sortedList = sortedList.concat(vendorProp.calls);

		if(vendorProp.csCalls)
			sortedList = sortedList.concat(vendorProp.csCalls);

		if(vendorProp.notes)
			sortedList = sortedList.concat(vendorProp.notes);

		if(sortedList.length > 0)
			sortedList = _.sortBy(sortedList, (a,b) => moment(b.created_at) - moment(a.created_at));

		return (
			<Card>
				<Card.Header>Notes</Card.Header>
				<ListGroup style={{maxHeight: "500px", overflowY: "auto" }}>
					{sortedList.map(note =>
					{
						if(note && note.note && note.note.indexOf && (note.note.indexOf("[PINNED]") === 0))
						{
							return (<ListGroupItem key={"note-" + note.id} variant="info">
								<Note
									note={note}
									showVendor={true}

								/>
							</ListGroupItem>);
						}
						else
						{
							return null;
						}
					})}
					{sortedList.map(note =>
					{
						return (<ListGroupItem style={{borderLeft: "0", borderRight: "0"}} key={"note-" + note.id}>
							<Note
								note={note}
								showVendor={true}

							/>
						</ListGroupItem>);
					})}
				</ListGroup>
			</Card>
		);
	}
}

export default ChasingNotes;