import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col, Form, Button, Table, Spinner, Alert } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FaSearch, FaAsterisk } from "react-icons/fa";
import moment from "moment";
import DocumentTitle from "../shared/documentTitle";
import { getAPI } from "utils/requestAPI";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import "bootstrap-daterangepicker/daterangepicker.css";

const standardFormat = "YYYY-MM-DD";
const dateRangePickerFormat ="DD/MM/YYYY";

class vendorCSVExport extends Component
{

    state = {
        downloadLink: null,
        data: null,
        loading:false,
        query: {
            chosenOneOfOurAgents: null,
            bookedValsThroughUs: null,
            inScotland: null,
            isOnTheMarket: null,
            vendorSignUpDate : {
                start: moment().format(dateRangePickerFormat),
                end: moment().format(dateRangePickerFormat),
            },
            bookedVals: {
                start: undefined,
                end: undefined
            },
            timeWentLive: {
                start: undefined,
                end: undefined
            }
        }
    }

	static contextType = GAConfigContext;

	handleVendorSignUpDateChange(startDate, endDate)
	{
    	this.setState({
            query: {
                ...this.state.query,
                vendorSignUpDate : {
                    start: startDate.format(dateRangePickerFormat),
                    end: endDate.format(dateRangePickerFormat)
                }
            }
    	});
	}

	handleTimeWentLiveChange(startDate, endDate)
	{
    	this.setState({
            query: {
                ...this.state.query,
                timeWentLive : {
                    start: startDate.format(dateRangePickerFormat),
                    end: endDate.format(dateRangePickerFormat)
                }
            }
    	});
	}

	handleBookedValsChange(startDate, endDate)
	{
    	this.setState({
            query: {
                ...this.state.query,
                bookedVals : {
                    start: startDate.format(dateRangePickerFormat),
                    end: endDate.format(dateRangePickerFormat)
                }
            }
    	});
	}

	handleObjectChange(value, key, objectName)
	{
    	const newObject = {
            ...this.state.query[objectName],
            [key]: value
    	};

    	this.setState({
            query: {
                ...this.state.query,
                [objectName]: newObject
            }
    	});
	}

	handleCheckBoxChange(key, value)
	{
    	let newValue = value;

    	// This checks is you clicking in the same checkbox twice and makes the value null.
    	if(this.state.query[key] === value)

    		newValue = null;


    	this.setState({
            query: {
                ...this.state.query,
                [key]: newValue
            }
    	}, () =>
    	{

    		// If bookedValsThroughUs or isOnTheMarket becames null or false this will removes the filter object for days selected

    		if(key === "bookedValsThroughUs" && ! newValue)
    		{
    			this.handleObjectChange(undefined, "start", "bookedVals");
    			this.handleObjectChange(undefined, "end", "bookedVals");
    		}

    		if(key === "isOnTheMarket" && ! newValue)
    		{
    			this.handleObjectChange(undefined, "start", "timeWentLive");
    			this.handleObjectChange(undefined, "end", "timeWentLive");
    		}
    	});
	}

	async searchVendors()
	{
    	this.setState({loading: true});

    	const newQuery = {
            ...this.state.query,
            vendorSignUpDate: {
                start: moment(this.state.query.vendorSignUpDate.start, dateRangePickerFormat).format(standardFormat),
                end: moment(this.state.query.vendorSignUpDate.end, dateRangePickerFormat).format(standardFormat),
            },
			timeWentLive: {
                start: this.state.query.timeWentLive.start ? moment(this.state.query.timeWentLive.start, dateRangePickerFormat).format(standardFormat) : null,
                end: this.state.query.timeWentLive.end ? moment(this.state.query.timeWentLive.end, dateRangePickerFormat).format(standardFormat) : null,
			},
			bookedVals: {
                start: this.state.query.bookedVals.start ? moment(this.state.query.bookedVals.start, dateRangePickerFormat).format(standardFormat) : null,
                end: this.state.query.bookedVals.end ? moment(this.state.query.bookedVals.end, dateRangePickerFormat).format(standardFormat) : null,
			}
    	};

    	const filters = [];


    	// This function transforms all the state.query in a query like: "?chosenOneOfOurAgents=true&vendorSignUpDateStart=2012/12/02"
    	Object.entries(newQuery).forEach(([key, value], index) =>
    	{
    		if(value !== null && value !== undefined)
    		{
    			if(typeof value === "object")
    			{
    				Object.entries(value).forEach(([valueKey, valueData]) =>
    				{
    					if(valueData !== null && valueData !== undefined && valueData !== "")
    					{
    						const camelCaseKey = valueKey[0].toUpperCase() + valueKey.substr(1);

    						filters.push(`&${key}${camelCaseKey}=${valueData}`);
    					}
    				});
    			}
    			else
    			{
    				filters.push(`${index !== 0 ? "&" : ""}${key}=${value}`);
    			}
    		}
    	});

    	try
    	{
    		const response = await getAPI(`/admin/vendor/export.json?${filters.join("")}`);

    		if(response && response.success)
    		{
    			this.setState({
                    loading: false,
                    data: response.rows
    			}, this.updateDownloadCSVLink);
    		}
    	}
    	catch (e)
    	{
    		alert("There was a problem, we couldn't get the data you requested: ",e);

    		this.setState({loading: false,});
    	}
	}

	updateDownloadCSVLink()
	{
    	const { data } = this.state;
    	const largestRow = data.sort((a,b) => Object.keys(b).length - Object.keys(a).length)[0];

    	if(! largestRow)
    		return;

    	const headers = Object.keys(largestRow).map(key => key);
    	const body = Object.values(data).map(row =>
    	{

    		return Object.values(row).map(column =>
    		{
    			// Adding " arround each column allows to have commas (,) inside the CSV columns.
    			return `"${column}"`;
    		});
    	});
    	const rows = [headers, ...body];

    	const downloadLink = "data:text/csv;charset=utf-8," + encodeURIComponent(rows.join("\n"));

    	this.setState({downloadLink});
	}

	render()
	{
    	const { data, query, loading, downloadLink } = this.state;
    	const { vendorSignUpDate, isOnTheMarket, inScotland, bookedValsThroughUs, chosenOneOfOurAgents, bookedVals, timeWentLive } = query;

    	const { user, adminOpts } = this.context.GA_CONFIG;
    	const canExportVendor = user.adminOptions[adminOpts.CAN_EXPORT_VENDOR];

    	return (
    		canExportVendor ?
    			<>
    			<div className="page-header">
    				<h1 className="pt-4">Export Vendor Data</h1>
    			</div>
    			<DocumentTitle title="Vendors CSV Export" />

    			<Card>
    				<Card.Header className="bg-primary text-white">Filters</Card.Header>
    				<Card.Body>
    					<Row className="mb-5">
    						<Col md={6}>
    							<Form.Label>
                                    Vendor sign up date
    							</Form.Label>
    							<DateRangePicker
    								initialSettings={{
                                        startDate: vendorSignUpDate.start,
                                        endDate: vendorSignUpDate.end,
                                        maxSpan: {days: 365},
                                        locale: {format: "DD/MM/YYYY"},
										maxDate: moment(),
										autoApply: true
    								}}
    								onCallback={this.handleVendorSignUpDateChange.bind(this)}
    							>
    								<input type="text" className="form-control"/>
    							</DateRangePicker>
    						</Col>
    						<Col md={6}>
    							<Form.Label>In Scotland?</Form.Label>
    							<Form.Check label="Yes" checked={inScotland === true} onChange={() => this.handleCheckBoxChange("inScotland", true)} />
    							<Form.Check label="No" checked={inScotland === false} onChange={() => this.handleCheckBoxChange("inScotland", false)} />
    						</Col>
    					</Row>
    					<Row className="mb-5">
    						<Col md={4}>
    							<Form.Label>Booked vals through us?</Form.Label>
    							<Form.Check label="Yes" checked={bookedValsThroughUs === true} onChange={() => this.handleCheckBoxChange("bookedValsThroughUs", true)} />
    							<Form.Check label="No" checked={bookedValsThroughUs === false} onChange={() => this.handleCheckBoxChange("bookedValsThroughUs", false)} />

    							{bookedValsThroughUs && (
    								<Fragment>
    									<Form.Label className="mt-4">
											Date when booked vals through us
    									</Form.Label>
    									<DateRangePicker
    										initialSettings={{
												startDate: bookedVals.start,
												endDate: bookedVals.end,
												maxSpan: {days: 365},
												locale: {format: "DD/MM/YYYY"},
												maxDate: moment(),
												autoApply: true
    										}}
    										onCallback={this.handleBookedValsChange.bind(this)}
    									>
    										<input type="text" className="form-control"/>
    									</DateRangePicker>
    								</Fragment>
    							)}
    						</Col>
    						<Col md={4}>
    							<Form.Label>Chosen one of our agents or not?</Form.Label>
    							<Form.Check label="Yes" checked={chosenOneOfOurAgents === true} onChange={() => this.handleCheckBoxChange("chosenOneOfOurAgents", true)} />
    							<Form.Check label="No" checked={chosenOneOfOurAgents === false} onChange={() => this.handleCheckBoxChange("chosenOneOfOurAgents", false)} />
    						</Col>
    						<Col md={4}>
    							<Form.Label>On the market?</Form.Label>
    							<Form.Check label="Currently on the market" checked={isOnTheMarket === 0} onChange={() => this.handleCheckBoxChange("isOnTheMarket", 0)} />
    							<Form.Check label="Previously on the market, but not now" checked={isOnTheMarket === 1} onChange={() => this.handleCheckBoxChange("isOnTheMarket", 1)} />
    							<Form.Check label="Never been on the market" checked={isOnTheMarket === 2} onChange={() => this.handleCheckBoxChange("isOnTheMarket", 2)} />

    							{isOnTheMarket !== null && isOnTheMarket !== 2 && (
    								<Fragment>
    									<Form.Label className="mt-4">
											Date when it went live
    									</Form.Label>
    									<DateRangePicker
    										initialSettings={{
												startDate: timeWentLive.start,
												endDate: timeWentLive.end,
												maxSpan: {days: 365},
												locale: {format: "DD/MM/YYYY"},
												maxDate: moment(),
												autoApply: true,
                                                autoUpdateInput: true
    										}}
    										onCallback={this.handleTimeWentLiveChange.bind(this)}
    									>
    										<input type="text" className="form-control"/>
    									</DateRangePicker>
    								</Fragment>
    							)}
    						</Col>
    					</Row>
    					<Row className="mb-3 mr-1" style={{float: "right"}}>
    						<Button disabled={loading} onClick={() => !loading && this.searchVendors()}>Search <FaSearch /></Button>
    					</Row>
    				</Card.Body>
    			</Card>

    			{loading && <Spinner size="lg" variant="primary" animation="border" style={{margin: "5rem auto", display: "block"}} />}

    			{! loading && data && data.length === 0 && <h3>No data found! Try changing the filters. 📡</h3>}

    			{data && data.length > 0 && !loading && (
    				<Card className="my-5">
    					<Card.Header>
    						<Card.Title>Vendors ({ data.length } Results)</Card.Title>

    						{downloadLink && <Button href={downloadLink} variant="success" style={{float: "right"}} download>Download (CSV)</Button>}
    					</Card.Header>

    					<div style={{maxHeight: "500px", overflowY: "scroll"}}>
    						<Table size="sm" hover className="mb-0 card-table" responsive>
    							<thead >
    								<tr>
    									{Object.keys(data[0]).map((key, i) =>
    									{
    										return (
    											<th key={i}>{key}</th>
    										);
    									})}
    								</tr>
    							</thead>
    							<tbody>
    								{data.map((row, i) =>
    								{
    									return (
    										<tr key={i}>
    											{Object.entries(row).map(([columnKey, columnValue], columnIndex) =>
    											{

    												if(columnIndex === 0)
    												{
    													return (
    														<td key={columnIndex}>
    															<Link to={`/vendor/property/${columnValue}/edit`} target="_blank">{columnValue}</Link>
    														</td>
    													);
    												}


    												if(columnKey === "created_at" || columnKey === "date_went_on_market")
    												{
    													return (
    														<td key={columnIndex}>
    															{moment(columnValue).format(standardFormat)}
    														</td>
    													);
    												}


    												return (
    													<td key={columnIndex}>
    														{columnValue}
    													</td>
    												);
    											})}
    										</tr>
    									);
    								})}
    							</tbody>
    						</Table>
    					</div>
    				</Card>)}
    		</>
    			:
    			<Alert variant="danger"><h4>Sorry, but this functionality isn't available for you.</h4> Need access? Talk to your manager.</Alert>
    	);
	}
}

export default vendorCSVExport;
