import React from "react";

const PowerHour = ({ timeAndDate }) =>
{
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				background: timeAndDate.isPowerHour ? "#0047ff" : "#005864",
				borderRadius: "8px",
				padding: "8px"
			}}
		>
			<p className="wallboard-heading">{timeAndDate.isPowerHour ? "Power hour countdown!" : "Power hour"}</p>
			{timeAndDate.isPowerHour ? (
				<p className="wallboard-heading">
					🔥{timeAndDate.minutes}:{timeAndDate.seconds < 10 ? "0" : ""}
					{timeAndDate.seconds}🔥
				</p>
			) : null}
		</div>
	);
};

export { PowerHour };
