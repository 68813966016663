import React from 'react';
import { FormControl, Form } from 'react-bootstrap';
import _ from 'underscore';

class EditableSelect extends React.Component {
	

	render()
	{
		let link = null;

		if(this.props.icon && this.props.href)
		{
			link = (
				<a href="#">
					<span className={"glyphicon glyphicon-" + this.props.icon}></span>
				</a>);
		}

		const opts = _.values(_.mapObject(this.props.options, (value, key) =>
		{
			let disabled = false;

			if(this.props.disabledOptions)
			{
				disabled = !! this.props.disabledOptions(key, value);
			}

			return (
				<option value={key} key={key} disabled={disabled}>{value}</option>
			);
		}));

		if(this.props.includeEmptyOption)
		{
			opts.push(
				<option value="0" key="empty"></option>
			);
		}

		if(this.props.editing)
		{
			return (

				<FormControl as="select" placeholder="select" onChange={this.props.onValueChanged} defaultValue={this.props.value || 0}>
					{opts}
				</FormControl>
			);
		}
		else
		{
			return (
				<>
					<Form.Control plaintext readOnly value={this.props.options ? this.props.options[this.props.value] : null} /> {link}
				</>
			);
		}
	}
}

export default EditableSelect;