import React from 'react';
import _ from 'underscore';
import { Row, Col } from 'react-bootstrap';
import $ from 'jquery';

class LeadPieCharts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            totalLeads: 0,
            totalVals: 0
        }

        this.processRefData = this.processRefData.bind(this)
    }

    processRefData(rawData, type)
    {
        if(rawData != null)
       { //Create data format
            const data = { 
                Adwords: 0, 'Adwords Brand':0, Partners:0, Which:0, CompareOnlineAgents:0,'Bing CPC':0, 'Facebook Paid':0,
                'Facebook Organic':0, 'window.google Organic':0, 'Bing Organic':0, Other:0
            };
            
            //Partners to consider
            const partners = ["choosemyagent", "theadvisory", "moneyforthemasses", "localsurveyorsdirect"];

            //Deal with Null
            _.each(rawData, r => 
            {
                if(r.referrer == null)
                    r.referrer = 'Other';
            });
        
            //Map data to format created above
            rawData.map(refCount =>  
            {
                if(refCount.referrer.includes('adwords/Brand'))
                {
                    data['Adwords Brand'] += refCount.leadCount;
                }
                else if(refCount.referrer.includes('adwords'))
                {
                    data['Adwords'] += refCount.leadCount;
                }
                else if(partners.includes(refCount.referrer))
                {
                    data['Partners'] += refCount.leadCount;
                }
                else if(refCount.referrer.includes('bingcpc'))
                {
                    data['Bing CPC'] += refCount.leadCount;
                }
                else if(refCount.referrer === 'facebook.paid')
                {
                    data['Facebook Paid'] += refCount.leadCount;
                }
                else if(refCount.referrer === 'facebook')
                {
                    data['Facebook Organic'] += refCount.leadCount;
                }
                else if(refCount.referrer === 'organic.window.google')
                {
                    data['window.google Organic'] += refCount.leadCount;
                }
                else if(refCount.referrer === 'organic.bing')
                {
                    data['Bing Organic'] += refCount.leadCount;
                }
                else if(refCount.referrer === 'coa')
                {
                    data['CompareOnlineAgents'] += refCount.leadCount;
                }
                else if(refCount.referrer.includes('which'))
                {
                    data['Which'] += refCount.leadCount;
                }
                else
                {
                    data['Other'] += refCount.leadCount;
                }
            });
            
            // converts {a: 1} into ["a", "1"] (don't ask)
            const final = _.pairs(data);

            const total = final.reduce((acc, curr) => acc + curr[1], 0);
            
            if(type == 'leads')
            {
                $(`#lead-total-${type}`).text(`Total Leads: ${total}`);
            }

            if(type == 'vals')
            {
                $(`#lead-total-${type}`).text(`Total Valuations: ${total}`);
            }

            return [['Referrer', 'Leads'], ...final];
        }

    }
    
    createAndRenderPieChart()
    {
        //Configure Chart options here
        const options = { 
            height:300,
            width: '100%',
            sliceVisibilityThreshold:0,
            chartArea: {width: 400, height: 300}
        };
        
        if(this.props.valData !== null)
        {
            const valData = window.google.visualization.arrayToDataTable(this.processRefData(this.props.valData, 'vals'));
            let valChart = new window.google.visualization.PieChart(document.getElementById('lead-val-ref'));
            valChart.draw(valData, options);
        }
        
        if(this.props.leadData !== null)
        {
            const data = window.google.visualization.arrayToDataTable(this.processRefData(this.props.leadData, 'leads'))
            let refChart = new window.google.visualization.PieChart(document.getElementById('lead-pie-chart'));
            refChart.draw(data, options)
        }
    }

    render() 
    { 
        window.google.charts.load('current', {packages: ['corechart']});
        window.google.charts.setOnLoadCallback(this.createAndRenderPieChart.bind(this));

        return ( 
            <Row>
                <Col xs={6}>
                    <h5 id="lead-total-leads" style={{textAlign: 'center'}}>Total Vendors: </h5>
                    <div id="lead-pie-chart" style={{padding: '10px'}}>Loading....</div>
                    <p>This is the total number of vendors signed up during this period (excluding spam).</p>
                </Col>
                <Col xs={6}>
                    <h5 id="lead-total-vals" style={{textAlign: 'center'}}>Total Valuations: </h5>
                    <div id="lead-val-ref" style={{padding: '10px'}}>Loading....</div>
                    <p>This is the total number of vendors who arranged valuations during this period (regardless of when they signed up).</p>
                </Col>
            </Row>
          );
    }
}

export default LeadPieCharts;