import React from 'react';
import moment from 'moment';
import { Row, Alert, Col } from 'react-bootstrap';
import { getAPI } from "utils/requestAPI";

class InvoiceChartPercentPaidOverTime extends React.Component {
	constructor(props)
	{
		super(props);
		this.state = {
			date: props.date ? props.date : moment().format('YYYY-MM-DD'),
			chartData: null,
			loading: true
		};

		this.fetchChartData = this.fetchChartData.bind(this);
		this.createAndRenderBarChart = this.createAndRenderBarChart.bind(this);
		this.processChartData = this.processChartData.bind(this);
	}

	componentDidMount()
	{
		this.fetchChartData();
	}

	fetchChartData()
	{
		getAPI('/admin/invoice/percentPaidOverTime.json')
		.then(cd => 
		{
			this.setState({ chartData: cd.data, loading: false });
		})
		.catch(e => alert('Something prevented the data to properly load from the system.'));
	}

	processFirstArrayForGoogleChartMapFormat(date)
	{
		const startMonth = moment(date).startOf('month').subtract(1, 'year').subtract(2, 'month');

		const key = ['Months past due'];
		
		// We subtract 1 month so that we do not look at the current month
		while(moment(date).subtract(1, 'month').startOf('month').isSameOrAfter(startMonth, 'month'))
		{
			key.push(startMonth.format('MMM-YYYY')); 

			startMonth.add(1, 'month');
		}
		
		return key;
	}

	processChartData(chartData, date)
    {
		
		const percentPaidOverTime = [];
		
		// do a for loop as well 
		for(let i = 0; i < 6; i++)
		{
			const startMonth = moment(date).startOf('month').subtract(1, 'year').subtract(2, 'month');
			
			const analytics = [];
			analytics.push(i);

			while(moment(date).startOf('month').isSameOrAfter(startMonth, 'month'))
			{
				const key = startMonth.format("YYYY-M");

				if(chartData[key])
				{
					const inTheFuture = moment(key, 'YYYY-M').add(i, 'month');
					
					if(moment(date).isSameOrBefore(inTheFuture, 'YYYY-M'))
					{
						analytics.push('');
						startMonth.add(1, 'month');
						continue;
					}

					analytics.push(chartData[key].percentOutstanding[i]);
				}

				startMonth.add(1, 'month');
			}
			
			percentPaidOverTime.push(analytics);
		}
		
		return [this.processFirstArrayForGoogleChartMapFormat(this.state.date), ...percentPaidOverTime];
    }

	createAndRenderBarChart()
	{
		const chartData = window.google.visualization.arrayToDataTable(this.processChartData(this.state.chartData, this.state.date));
		
		const chartOptions = {
			height: '550',
			width: '100%',
			vAxis: {
				minValue: 0,
				title: '% value of invoices outstanding',  
				titleTextStyle: {color: '#333'},
				format: "#'%'"
			},
			legend: {position: 'bottom'} // As of 2020 this still does not work for Material Charts
		};

		const chart = new window.google.charts.Bar(document.getElementById('percent-paid-invoice'));
		
		chart.draw(chartData, window.google.charts.Bar.convertOptions(chartOptions));
	}

	render() 
	{

		if(this.state.loading)
			return <Alert>Loading...</Alert>;

		window.google.charts.load('current', {packages: ['bar']});
        window.google.charts.setOnLoadCallback(this.createAndRenderBarChart);

		return (
			<Row className="my-3">
				<Col md={12}>
					<h4 className="mb-2" style={{fontSize: "18px"}}>Outstanding Invoices</h4>
					<div className="mb-3" id="percent-paid-invoice">Loading....</div>
				</Col>
			</Row>
		);
	}
}

export default InvoiceChartPercentPaidOverTime;