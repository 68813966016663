import React from "react";
import moment from "moment";
import _ from "underscore";
import { postAPI } from "utils/requestAPI";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import { Row, Col, Form, FormControl, FormGroup, Button, Alert } from "react-bootstrap";

class ChasingAdmin extends React.Component
{
	constructor(props)
	{
		super(props);

		this.loadVendorProp = this.props.loadVendorProp;

		this.state = {
			nextAction: this.props.nextAction ? this.props.nextAction : "Chase again",
			note: null,
			whyNotPaid: this.props.whyNotPaid ? this.props.whyNotPaid : "Awaiting payment",
			nextActionAt: moment().format("YYYY-MM-DD"),
			savingNote: false,
			respUser: null,
			noteAuthor: null
		};

		this._whyNotPaid = [
			"Haven't received the emails",
			"Head office involvement",
			"Dispute - lead came direct",
			"Dispute - no contract",
			"Dispute - no introduction from GetAgent",
			"Net an Agent made intro",
			"Unknown",
			"Awaiting payment",
			"Correct person not in",
			"Staff change"
		];

		this._nextAction = [
			"Chase again",
			"LBA",
			"LBC",
			"MCOL post TH",
			"MCOL post LBC",
			"Court post LBC",
			"Write off",
			"Don't Chase",
			"Court post TH"
		];

		// this._opsTeam = {id: GA_CONFIG.adminOpts.ARE_OPS_TEAM, name: "Ops Team", isAdminTeam: true};
		this.saveNoteAndCreateNextAction = this.saveNoteAndCreateNextAction.bind(this);
		this.whyNotPaidChanged = this.whyNotPaidChanged.bind(this);
		this.nextActionChanged = this.nextActionChanged.bind(this);
		this.noteChanged = this.noteChanged.bind(this);
		this.dateChange = this.dateChange.bind(this);
		this.respUserChanged = this.respUserChanged.bind(this);
		this.setDefaultResponsibleUser = this.setDefaultResponsibleUser.bind(this);
	}

	static contextType = GAConfigContext;

	componentWillMount()
	{

		const respUserInVendorObject = this.props.vendorProp.responsible_user_id;

		const currentUser = this.context.GA_CONFIG.user.id;

		const isInOpsDefaults = this.setDefaultResponsibleUser().filter(user => user.id === respUserInVendorObject);

		if(isInOpsDefaults.length > 0)
			this.setState({ respUser: respUserInVendorObject });
		else
			this.setState({ respUser: currentUser, noteAuthor: currentUser });


	}


	setDefaultResponsibleUser()
	{
		const canBeRespUsers = _.values(_.mapObject(this.context.GA_CONFIG.admins, (user, key) =>
		{
			return {
				isOps: user.getUserAdminOptions.includes(this.context.GA_CONFIG.adminOpts.ARE_OPS_TEAM),
				id: user.id,
				name: user.name
			};
		})).filter(user => user.isOps === true);

		return canBeRespUsers;
	}


	respUserChanged(e)
	{
		this.setState({respUser: e.target.value});
	}

	whyNotPaidChanged(e)
	{
		this.setState({whyNotPaid: e.target.value});
	}

	nextActionChanged(e)
	{
		this.setState({nextAction: e.target.value});
	}

	noteChanged(e)
	{
		this.setState({note: e.target.value});
	}

	dateChange(e)
	{
		this.setState({nextActionAt: e.target.value});
	}

	saveNoteAndCreateNextAction()
	{
		const notePrefix = "[CHASE]";
		const { note } = this.state;

		const obj = {
			whyNotPaid: this.state.whyNotPaid,
			nextAction: this.state.nextAction,
			nextActionAt: this.state.nextActionAt,
			note
		};

		const noteObj = {
			note: notePrefix + JSON.stringify(obj),
			vendor_prop_id: this.props.vendorProp.id,
			nextActionAt: this.state.nextActionAt,
			noteAuthor: this.state.noteAuthor
		};

		return postAPI("/admin/branch/" + this.props.branchId + "/note", noteObj)
			.then((resp) =>
			{
				this.setState({savingNote: true});

				return postAPI("/admin/vendor/property/" + this.props.vendorProp.id + "/edit?patch=1",
					{
				cs_followup: moment(noteObj.nextActionAt).format(),
				responsible_user_id: this.state.respUser
					})
					.then((resp) =>
					{

						// resets form
						this.setState({
					savingNote: false,
					note: null,
					nextActionAt: null
						});

						if(this.props.reloadVendor)

							this.props.reloadVendor();


						if(this.props.reloadVendorDetails)

							this.props.reloadVendorDetails(this.props.vendorProp.id);

					});
			})
			.catch(e => alert(e));
	}


	render()
	{
		const isDisabled = this.state.savingNote ? true : false;

		return (
			<div>
				<Row>
					<Col md={6}>
 						<FormGroup>
							<Form.Label>Why not paid?</Form.Label>
							<FormControl as="select" placeholder="select" onChange={this.whyNotPaidChanged} value={this.state.whyNotPaid}>
								{_.map(this._whyNotPaid, (reason, index) => <option value={reason} key={index}>{reason}</option>)}
							</FormControl>
						</FormGroup>
					</Col>
					<Col md={6}>
 						<FormGroup>
							<Form.Label>Next action:</Form.Label>
							<FormControl as="select" placeholder="select" onChange={this.nextActionChanged} value={this.state.nextAction}>
								{_.map(this._nextAction, (next, index) => <option value={next} key={index}>{next}</option>)}
							</FormControl>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<FormControl
							as="textarea"
							placeholder="Your note goes here"
							rows="5"
							value={this.state.note || ""}
							onChange={this.noteChanged}
							style={{marginBottom: "20px"}}
						/>
					</Col>
					<Col md={3}>
						<Form.Label>Next action on:</Form.Label>
						<FormControl
							type="date"
							onChange={this.dateChange}
							value={this.state.nextActionAt || ""}
						/>
						<Button className="mt-4" variant="success" disabled={isDisabled} onClick={this.saveNoteAndCreateNextAction}>{isDisabled ? "Saving" : "Save"}</Button>
					</Col>
					<Col md={3}>
						<Form.Label>Responsible user:</Form.Label>
						<FormControl as="select" placeholder="select" onChange={this.respUserChanged} value={this.state.respUser}>
							{_.map(this.setDefaultResponsibleUser(), (user, index) => <option value={user.id} key={index}>{user.name}</option>)}
						</FormControl>
					</Col>


				</Row>
			</div>	
		);
	}

}

export default ChasingAdmin;