import React from "react";
import {Card, Col, Row, FormLabel, FormGroup, FormControl, Button, Form, Alert, Spinner } from "react-bootstrap";
import { GAConfigContext } from "contexts/gaConfigContext";
import InvoicePanel from "./invoicePanel";
import EditableSelect from "../shared/editableSelect";
import EditableText from "../shared/editableText";
import UserEdit from "../users/userEdit";
import UserSearch from "../users/userSearch";
import VendorTimeline from "./vendorTimeline";
import VendorListingsEdit from "./vendorListingsEdit";
import ShortlistEdit from "./shortlistEdit";
import VendorEmail from "./vendorEmail";
import VendorAddressEdit from "./vendorAddressEdit";
import MyPropertyTracker from "./myPropertyTracker";
import VendorTagEdit from "./vendorTagEdit";
import _ from "underscore";
import moment from "moment";
import { gaNiceFormatPrice } from "utils/common";
import DocumentTitle from "../shared/documentTitle";
import FourOhFour from "../shared/fourOhFour";
import logo from "images/logo/admin-logo.png";
import VendorSaleUpdates from "../shared/vendorSaleUpdates";
import { getAPI, postAPI } from "utils/requestAPI";
import { adminPanelURL } from "utils/common";


class VendorEdit extends React.Component
{

	static contextType = GAConfigContext;

	_fields = [
		"address1",
		"postcode",
		"udprn",
		"bedrooms",
		"status",
		"estvalue",
		"referrer",
		"referralProps",
		"type",
		"exchdate",
		"eta",
		"archive",
		"paid_at",
		"paid",
		"zoopla_id",
		"rightmove_id",
		"am_id",
		"cs_followup",
		"responsible_user_id",
		"loginCode",
		"id"
	];

	_noSaveFields = [
		"responsible",
		"user",
		"branches",
		"interactions",
		"events",
		"notes",
		"created_at",
		"vpSaleUpdates",
		"saleOutcomes",
		"csCalls",
		"calls",
		"vendorPropTags"
	];

	state = {
		editing: false,
		saving: false,
		notFound: false,
		vendorProp: null,
		archiveView: false,
		archiveReason: 50,
		archiveMsg: ""
	};

	_origVendorProp = null;

	componentDidMount()
	{
		// load from props if set, otherwise load from server
		if(this.props.vendorProp)
		{
			this.setState({vendorProp: _.pick(this.props.vendorProp, this._fields, this._noSaveFields)});

			this._origVendorProp = _.pick(this.props.vendorProp, this._fields, this._noSaveFields);
		}
		else
		{
			// load from DB
			this.reload();
		}


	}

	componentWillReceiveProps(nextProps)
	{
		// load from props if set, otherwise load from server
		if(nextProps.vendorProp)
		{
			this.setState({vendorProp: _.pick(nextProps.vendorProp, this._fields, this._noSaveFields)});

			this._origVendorProp = _.pick(nextProps.vendorProp, this._fields, this._noSaveFields);
		}
	}

	valueChanged(name, e)
	{
		const vendorProp = Object.assign({}, this.state.vendorProp);

		vendorProp[name] = e.target.value;

		if((name == "status")
			&& (e.target.value == this.context.GA_CONFIG.vp.status.GATHERING_QUOTES)
			&& (this.props.vendorProp.status === this.context.GA_CONFIG.vp.status.NEW)
			&& (this.props.vendorProp.responsible_user_id === null))
		{
			vendorProp.responsible = this.context.GA_CONFIG.user;
			vendorProp.responsible_user_id = this.context.GA_CONFIG.user.id;
		}

		this.setState({vendorProp: vendorProp});
	}

	archiveChanged(name, e)
	{
		if(name === "msg")
			this.setState({archiveMsg: e.target.value});
		else
			this.setState({archiveReason: e.target.value});
	}

	responsibleUserChange(user)
	{
		const vendorProp = Object.assign({}, this.state.vendorProp);

		if(user && user.id)
		{
			vendorProp.responsible = user;
			vendorProp.responsible_user_id = user.id;
		}
		else
		{
			vendorProp.responsible = null;
			vendorProp.responsible_user_id = null;
		}

		this.setState({vendorProp: vendorProp});
	}

	async reload()
	{
		this.setState({saving: true});

		let vpId = null;

		if(this.props.match && this.props.match.params && this.props.match.params.vpId)
			vpId = this.props.match.params.vpId;
		else
			vpId = this.state.vendorProp.id;


		let resp = null;

		try
		{
			// load again from server
			resp = await getAPI("/admin/vendor/property/" + vpId + "/edit.json");

		}
		catch (err)
		{
			if(err.message.includes("No vendor for id"))
			{
				this.setState({saving: false, notFound: true});
				return false;
			}
			else
			{
				return alert(err.msg || "An error occurred when loading vendor from server");
			}

		}

		this.setState({
				vendorProp: Object.assign({}, _.pick(resp.vendorProp, this._fields, this._noSaveFields)),
				saving: false
		});

		this._origVendorProp = _.pick(resp.vendorProp, this._fields, this._noSaveFields);


	}

	startEditing()
	{
		this.setState({editing: true});
	}

	cancelEditing()
	{
		this.setState({
			editing: false,
			vendorProp: _.pick(this._origVendorProp, this._fields, this._noSaveFields)
		});
	}

	toggleArchive()
	{
		this.setState({
			archiveView: ! this.state.archiveView,
			archiveMsg: "",
			archiveReason: null
		});
	}

	allowedStateChanges(stateNum, stateString)
	{

		if(stateNum == this.state.vendorProp.status)
			return false;

		// allow Alex, Chris, Peter & Colby to edit at will
		if(this.context.GA_CONFIG.user && this.context.GA_CONFIG.user.adminOptions && (this.context.GA_CONFIG.user.adminOptions[this.context.GA_CONFIG.adminOpts.CAN_EDIT_VENDOR_STATUS] === true))
			return false;

		const allowStateChanges = {
			NEW: ["READY_FOR_VALS", "SPAM", "GATHERING_QUOTES"],
			GATHERING_QUOTES: ["READY_FOR_VALS", "SPAM"],
			READY_FOR_VALS: ["SPAM"],
			VALUATION_REQ: ["SPAM"],
			INTROS_DONE: ["AGENT_CHOSEN", "LISTING_LIVE"],
			AGENT_CHOSEN: ["LISTING_LIVE"],
			LISTING_LIVE: ["SALE_AGREED"],
			SALE_AGREED: ["SALE_COMPLETE", "LISTING_LIVE"],
			SALE_COMPLETE: ["MONEY_PAID"],
			SPAM: ["NEW"]
		};

		const vendorStatusString = _.invert(this.context.GA_CONFIG.vp.status)[this.state.vendorProp.status];

		if(! allowStateChanges[vendorStatusString])
			return true;

		return (_.indexOf(allowStateChanges[vendorStatusString], stateString) === -1);

	}

	isCurrentStateValid()
	{
		if(this.state.vendorProp.status === this.context.GA_CONFIG.vp.status.SPAM)
			return true;

		if(! this.state.vendorProp.branches)
			return (this.state.vendorProp.status === this.context.GA_CONFIG.vp.status.NEW);

		let numValReq = 0,
			numIntrosDone = 0,
			isAgentChosen = false;

		// look through branch states
		this.state.vendorProp.branches.map(br =>
		{
			if(br["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.VALUATION_REQ)
			{
				numValReq++;
			}
			else if((br["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.VAL_INTRO_DONE)
					|| (br["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.AGENT_MADE_CONTACT)
					|| (br["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.AGENT_WENT_FOR_VAL)
					|| (br["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.AGENT_NOT_CHOSEN)
					|| (br["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.AGENT_WILL_NOT_CONTACT)
					|| (br["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.AGENT_ALREADY_CONTACTED))
			{
				if(br["_pivot_val_req_at"] !== null)
					numIntrosDone++;
			}
			else if(br["_pivot_status"] === this.context.GA_CONFIG.vp.branchStatus.AGENT_CHOSEN)
			{
				isAgentChosen = true;
			}
		});

		// If an agent has been marked as chosen, then the vendor should be is state AGENT_CHOSEN or further along
		// e.g listing live is fine.
		if(isAgentChosen)
			return (this.state.vendorProp.status >= this.context.GA_CONFIG.vp.status.AGENT_CHOSEN);

		// If valuations have been requested, the vendor should EITHER be in VALUATION_REQ state
		// or further along if other intros have been done but this val couldn't be introed
		if((numValReq > 0) && (this.state.vendorProp.status === this.context.GA_CONFIG.vp.status.VALUATION_REQ))
			return true;

		// If intros have been done then the vendor should be in VALUATION_REQ or further along
		// VALUATION_REQ is covered above
		// Futher along is covered by the agentChosen bit
		if(numIntrosDone > 0)
			return (this.state.vendorProp.status === this.context.GA_CONFIG.vp.status.INTROS_DONE);

		if((numValReq + numIntrosDone) === 0)
			return true;

		return false;

	}

	saveArchive()
	{
		let url = "/admin/vendor/property/" + this.state.vendorProp.id + "/";

		url += this.state.vendorProp.archive ? "unarchive" : "archive";

		postAPI(url, {archive: this.state.archiveReason, reason: this.state.archiveMsg})
			.then((data) =>
			{

				this.reload();

				return this.setState({
			archiveView: false,
			editing: false
				});

			})
			.catch(err =>
			{
				alert(err.message || "An unknown error occurred");
			});
	}

	saveEdit()
	{
		// check for changes
		const changed = {};

		if(!this.state.vendorProp.address1)
		{
			alert("There is no address selected!");
			return;
		}

		_.each(this._fields, (f) =>
		{
			if(this.state.vendorProp[f] !== this._origVendorProp[f])
				changed[f] = this.state.vendorProp[f];
		});

		if(Object.keys(changed).length === 0)
		{
			alert("Nothing has changed!");
			return;
		}

		this.setState({saving: true});

		// save to server
		postAPI("/admin/vendor/property/" + this.state.vendorProp.id + "/edit?patch=1", changed)
			.then((resp) =>
			{

				this._origVendorProp = Object.assign({}, this.state.vendorProp);

				this.setState({editing: false, saving: false});

				this.reload();
			})
			.catch(err =>
			{
				alert(err.message || "An unknown error occurred");
				return;
			});
	}

	getSSTCDate()
	{
		if(this.state.vendorProp)
		{
			const sstcStatusTo = this.state.vendorProp.events
				.filter(e => e.status_to === this.context.GA_CONFIG.vp.status.SALE_AGREED);

			if(sstcStatusTo.length)
				return _.first(sstcStatusTo).created_at;

			return false;
		}

		return false;
	}

	render()
	{
		const address = () =>
		{
			if(this.state.editing)
			{
				return (
					<VendorAddressEdit
						postcode={this.state.vendorProp.postcode}
						address={{ address1: this.state.vendorProp.address1, udprn: this.state.vendorProp.udprn }}
						onPostcodeChange={e =>
							this.setState({vendorProp: {...this.state.vendorProp, postcode: e.currentTarget.value, address1: "", udprn: null}})
						}
						onAddressChange={address =>
							this.setState({vendorProp: {...this.state.vendorProp, address1: address.address1, udprn: address.udprn}})
						}
					/>
				);
			}
			else
			{
				return (
					<Form.Control plaintext readOnly defaultValue={this.state.vendorProp.address1 +", " + this.state.vendorProp.postcode.toUpperCase()} className="mb-3" />
				);
			}
		};

		if(this.state.notFound)
			return <FourOhFour subtitle="The vendor you're looking for can't be found. If you followed a link on the CRM, please add to the bugs board." />;

		if(! this.state.vendorProp)
			return <Spinner as="span" animation="border" variant="primary" />;

		if(this.state.archiveView)
		{
			let panelFooter = (
				<div>
					<Button onClick={this.toggleArchive.bind(this)}>Cancel</Button>
					<Button onClick={this.saveArchive.bind(this)} className="float-right">Save</Button>
				</div>
			);

			return (
				<Card>
					<Card.Header>
						{this.state.vendorProp.archive ? "Unarchive " + this.state.vendorProp.id  : "Archive " + this.state.vendorProp.id}
					</Card.Header>
					<Card.Body>
						<Form horizontal>

							{this.state.vendorProp.archive ? null : (
								<FormGroup>
									<Col sm={4}><FormLabel>Why hide this property?</FormLabel></Col>
									<Col sm={8}>
										<EditableSelect
											options={_.invert(this.context.GA_CONFIG.vp.archiveStatus)}
											value={this.state.archiveReason}
											includeEmptyOption={false}
											onValueChanged={this.archiveChanged.bind(this, "reason")}
											editing={true}
										/>
									</Col>
								</FormGroup>
							)}

							<FormGroup>
								<Col sm={4}><FormLabel>Add a note with a bit more info:</FormLabel></Col>
								<Col sm={8}>
									<FormControl
										as="textarea"
										placeholder="Talk to me"
										rows={5}
										value={this.state.archiveMsg}
										onChange={this.archiveChanged.bind(this, "msg")} />
								</Col>
							</FormGroup>

						</Form>
					</Card.Body>
					<Card.Footer>
						{panelFooter}
					</Card.Footer>
				</Card>
			);
		}


		let panelFooter = null;

		if(this.state.editing === true)
		{
			panelFooter = (
				<Row>
					<Col sm={4}>
						<Button onClick={this.cancelEditing.bind(this)} disabled={this.state.saving}>Cancel</Button>
					</Col>
					<Col sm={4} className="text-center">
						<Button onClick={this.toggleArchive.bind(this)} disabled={this.state.saving}>{this.state.vendorProp.archive ? "Unarchive" : "Archive"}</Button>
					</Col>
					<Col sm={4} className="text-right">
						<Button onClick={this.saveEdit.bind(this)} disabled={this.state.saving}>Save</Button>
					</Col>
				</Row>
			);
		}
		else
		{
			panelFooter = (
				<Button onClick={this.startEditing.bind(this)}>Edit</Button>
			);
		}


		return (
			<div>
				{this.state.vendorProp.archive && (
					<Alert variant="warning">
						<Row>
							<Col sm={9}>
								<strong>Vendor archived as {_.invert(this.context.GA_CONFIG.vp.archiveStatus)[this.state.vendorProp.archive]}</strong>
							</Col>
							<Col sm={3} className="text-right">
								<Button onClick={this.toggleArchive.bind(this)}>Unarchive</Button>
							</Col>
						</Row>
					</Alert>
				)}

				<DocumentTitle title={this.state.vendorProp.address1} />

				<h1 className="mt-3 mb-5">Vendor {this.state.vendorProp.id}</h1>

				<UserEdit user={this.state.vendorProp.user} vendorProp={this.state.vendorProp} reloadVendor={this.reload.bind(this)} className="mb-3" />

				<Card className="mb-3">
					<Card.Header>Property details</Card.Header>
					<Card.Body>
						<Form>
							<Row className="mb-3">
								<Col xs={12} md={4}>
									<Form.Group>
										<Form.Label>
												Address:
										</Form.Label>

										{address()}

										<a href={"https://www.zoopla.co.uk/house-prices/" + this.state.vendorProp.postcode.replace(" ","-") + "/?q="  + this.state.vendorProp.postcode.replace(" ","")} target="_blank">Zoopla Sold</a> |&nbsp;
										<a href={adminPanelURL("/landreg/search?postcode=" + this.state.vendorProp.postcode.replace(" ","+"))} target="_blank"><img src={logo} width={20} alt="screen-logo"/> Sold</a> |&nbsp;
										<a href={adminPanelURL("/listings/search?postcode=" + this.state.vendorProp.postcode.replace(" ","+"))} target="_blank"><img src={logo} width={20} alt="screen-logo"/> Listings</a>

									</Form.Group>
								</Col>
								<Col md={8}>
									<Row>
										<Col xs={12} md={6}>
											<Form.Group>
												<Form.Label>
														Bedrooms:
												</Form.Label>
												<EditableSelect
													value={this.state.vendorProp.bedrooms}
													options={{1: "1 bed or studio", 2: "2 bedrooms", 3: "3 bedrooms", 4: "4 bedrooms", 5: "5+ bedrooms"}}
													onValueChanged={this.valueChanged.bind(this, "bedrooms")}
													editing={this.state.editing} >
												</EditableSelect>

											</Form.Group>
										</Col>
										<Col xs={12} md={6}>
											<Form.Group>
												<Form.Label>
														Estimated Value:
												</Form.Label>
												<EditableText
													value={this.state.vendorProp.estvalue}
													onValueChanged={this.valueChanged.bind(this, "estvalue")}
													editing={this.state.editing}
													formatter={(val) => "£" +gaNiceFormatPrice(val)}
													currency={true}
													type={"number"}
												>
												</EditableText>
											</Form.Group>
										</Col>
										<Col xs={12} md={6}>
											<Form.Group>
												<Form.Label>
														Property Type:
												</Form.Label>
												<EditableSelect
													value={this.state.vendorProp.type}
													options={_.invert(this.context.GA_CONFIG.props.types)}
													includeEmptyOption={true}
													onValueChanged={this.valueChanged.bind(this, "type")}
													editing={this.state.editing} >
												</EditableSelect>
											</Form.Group>
										</Col>
										<Col xs={12} md={6}>
											<Form.Group>
												<Form.Label>
														Exchange Date:
												</Form.Label>
												<EditableText
													value={this.state.vendorProp.exchdate}
													onValueChanged={this.valueChanged.bind(this, "exchdate")}
													editing={this.state.editing}
													type="date"
													formatter={(val) => val ? moment(val).format("ddd Do MMM YYYY") : null}
												>
												</EditableText>
											</Form.Group>
										</Col>
									</Row>
								</Col>

							</Row>


							<Row>
								<Col xs={12} md={4} className={(! this.isCurrentStateValid()) && "text-danger"}>
									<Form.Label>
											Status:
									</Form.Label>
									<EditableSelect
										value={this.state.vendorProp.status}
										options={_.invert(this.context.GA_CONFIG.vp.status)}
										onValueChanged={this.valueChanged.bind(this, "status")}
										editing={this.state.editing}
										disabledOptions={this.allowedStateChanges.bind(this)}
									>
									</EditableSelect>
								</Col>

								<Col xs={12} md={4}>
									<Form.Group>
										<Form.Label>
												Referred By:
										</Form.Label>

										{/* Made uneditable since people shouldn't be changing */}
										<EditableText
											value={this.state.vendorProp.referralProps[0] ?
												`${this.state.vendorProp.referralProps[0].referrer.name} (GetAgent Exchange)`
												: this.state.vendorProp.referrer}
											onValueChanged={this.valueChanged.bind(this, "referrer")}
											editing={false && this.state.editing}
											type="text"
										>
										</EditableText>

									</Form.Group>
								</Col>
								<Col xs={12} md={4}>
									<Form.Label>
											Responsible User:
									</Form.Label>

									{this.state.editing ? (
										<UserSearch
											adminOnly={true}
											value={this.state.vendorProp.responsible ? this.state.vendorProp.responsible.name : null}
											onValueChanged={this.responsibleUserChange.bind(this)}>
										</UserSearch>) : (
										<Form.Control plaintext readOnly defaultValue={this.state.vendorProp.responsible ? this.state.vendorProp.responsible.name : null} />
									)

									}

								</Col>
							</Row>

						</Form>

						{!this.state.editing && this.state.vendorProp.status >= this.context.GA_CONFIG.vp.status.SALE_COMPLETE && this.state.vendorProp.status <= this.context.GA_CONFIG.vp.status.MONEY_PAID && (
							<InvoicePanel vendorProp={this.state.vendorProp} />
						)}
					</Card.Body>
					<Card.Footer>
						{panelFooter}
					</Card.Footer>
				</Card>

				{(this.state.vendorProp.status === this.context.GA_CONFIG.vp.status.SALE_AGREED) &&
					<VendorSaleUpdates
						updates={3}
						saleUpdates={this.state.vendorProp.vpSaleUpdates}
						saleOutcomes={this.state.vendorProp.saleOutcomes}
						showUpdateLink={true}
						className="mb-3"
						sstcDate={this.getSSTCDate()}
					/>
				}

				<VendorListingsEdit vendorProp={this.state.vendorProp} reloadVendor={this.reload.bind(this)} hideIfNoListings={false} className="mb-3" />

				<VendorTagEdit
					vendorPropId={this.state.vendorProp.id}
					vendorPropTags={this.state.vendorProp.vendorPropTags}
					onSaved={this.reload.bind(this)}
				/>

				<ShortlistEdit vendorProp={this.state.vendorProp} reloadVendor={this.reload.bind(this)} isSaving={this.state.saving} className="mb-3" />

				<MyPropertyTracker vendorProp={this.state.vendorProp} />

				{(this.state.vendorProp.status === this.context.GA_CONFIG.vp.status.VALUATION_REQ) && (this.state.vendorProp.archive === null) && (
					<VendorEmail vendorProp={this.state.vendorProp} template={"valcannotdo"} reloadVendor={this.reload.bind(this)} className="mb-3" />
				)}


				<VendorTimeline vendorProp={this.state.vendorProp} reloadVendor={this.reload.bind(this)} className="mb-3" />


			</div>
		);
	}
}

export default VendorEdit;