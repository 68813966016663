import React from "react";
import { Col, Form, Row, Card } from "react-bootstrap";
import { MdOpenInNew } from "react-icons/md";
import { publicURL } from "utils/common";

class MyPropertyTracker extends React.PureComponent
{
	render()
	{
		const { vendorProp } = this.props;

		const title = (
			<> My Property Tracker -
				<a
					href={publicURL("/vendor/quotes/" + vendorProp.id + "/mpt")}
					target="_blank"
					rel="noreferrer"
				>
				&nbsp;view &nbsp;
					<MdOpenInNew />
				</a>
			</>
		);

		const mptLink = (
			<Row className="mt-4 mb-4">
				<Col sm="11">
					<Form.Group as={Row}>
						<Form.Label column sm="3" className="text-left pl-5">
								My Property Tracker Link:
						</Form.Label>
						<Col sm="8">
							<Form.Control
								type="text"
								value={"https://www.getagent.co.uk/vendor/quotes/" + vendorProp.id + "/mpt?c=" + vendorProp.loginCode + "&cs=true"}
								readOnly
							/>
						</Col>
					</Form.Group>
				</Col>
			</Row>
		);

		return (
			<Card>
				<Card.Header style={{ fontWeight: 600 }}>
					{title}
				</Card.Header>
				{mptLink}
			</Card>
		);
	}
}

export default MyPropertyTracker;