import React from "react";
import {AsyncTypeahead, Menu, MenuItem} from "react-bootstrap-typeahead";
import { getAPI } from "utils/requestAPI";

class UserSearch extends React.Component
{
	constructor(props)
	{
		super(props);

		const queryParams = [];

		if(props.adminOnly)
		{
			queryParams.push("admin=true");

			if(props.includeAdminGroups)
				queryParams.push("groups=true");

		}

		this.state = {
			searchUrl: q => `/admin/user/search/${q}.json`,
			isAdmin: props.adminOnly  ? `${queryParams.length ? "?" : ""}${queryParams.join("&")}` : "",
			loading: false,
			options: [],
			selected: props.value ? props.value : null
		};


		this.handleSearch = this.handleSearch.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleSearch(query)
	{
		// Letting parent component know that we are currently searching in the child component
		// As such it should re-send the query as prop so it can control the "selected" prop of the input

		if(this.props.onValueChangedControlled)
			this.props.onValueChangedControlled(false, query);


		this.setState({loading: true});

		getAPI(this.state.searchUrl(query)+this.state.isAdmin, {skipValidate: true})
			.then(data =>
			{
				if(data.error)
				{
					this.setState({ options: [], loading: false });
					return alert(data.msg || "The server responded with an error when trying to search");
				}

				this.setState({ options: data, loading: false });
			})
			.catch(e => console.log(e));

	}

	handleChange(s)
	{
		if(s.length === 0) return null;

		this.setState({selected: s});


		if(this.props.onValueChanged)
			this.props.onValueChanged(s[0]);
	}

	render()
	{
		return (
			<AsyncTypeahead
				id="user-search"
				isLoading={this.state.loading}
				placeholder={(this.props && this.props.placeholder) || "User Search"}
				options={this.state.options}
				labelKey="name"
				filterBy={["name", "email", "id"]}
				useCache={false}
				onSearch={this.handleSearch}
				onChange={this.handleChange}
				renderMenu={(results, menuProps) =>
					<Menu {...menuProps}>
						{results.map((v, index) => <MenuItem key={index} option={v}>{v.name}<br />{v.email}</MenuItem>)}
					</Menu>
				}
				// send this prop if you want to control the component from a parent, needs to be an array
				selected={this.props && this.props.selected}
				defaultSelected={this.props && this.props.defaultSelected}
			/>
		);
	}

}

export default UserSearch;