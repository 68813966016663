import React, { useState } from "react";
import { Col, Button, Spinner, Card } from "react-bootstrap";
import { postAPI } from "utils/requestAPI";
import { FaTty } from "react-icons/fa";

const TranscriptionText = ({ transcription }) =>
{
	const TRUNCATE_AT_LENGTH = 380;

	const [truncate, setTruncate] = useState(true);

	const ReadMore = () => (
		<span
			style={{ color: "blue", fontWeight: "500", "cursor": "pointer"}}
			onClick={() => setTruncate(!truncate)}
		>
			{truncate ? "... Read more" : " Read less"}
		</span>
	);

	// Add in new lines if we have them, this is for transcriptions with multiple speaker included
	const transcriptionBody = transcription.includes("\\n") ?
		transcription.split("\\n").map((text, index) => <p key={index}>{text}</p>) :
		transcription;

	const truncatedText = typeof transcriptionBody === "string" ?
		transcriptionBody.substring(0, TRUNCATE_AT_LENGTH)
		: transcriptionBody.slice(0, 4);

	return (
		<Col sm={12} className="mt-3">

			<Card>
				<Card.Body>
					<Card.Title>Magical Transcription</Card.Title>
					<Card.Text>
						{transcription.length > TRUNCATE_AT_LENGTH ?
							<>
								{truncate ? truncatedText : transcriptionBody} <ReadMore />
							</>

							:
							<>
								{transcription}
							</>}
					</Card.Text>

				</Card.Body>
			</Card>
		</Col>);
};

export const CallTranscription = ({ call}) =>
{
	// We are not letting calls to be transcribed if they are too short or too long.
	const shortestCallDurationAllowed = 20;
	const longestCallDurationAllowed = 2500;

	const [transcribingCall, setTranscribingCall] = useState(false);
	const handleClick = async() =>
	{
		// The docs say it will take up to 30% of the total call duration, so we are using that to calculate the max time it should take and warn the user.
		const maxSecondsTaken = call.duration * 0.3;

		const averageLoadingTime = maxSecondsTaken < 60 ?
			`${parseInt(maxSecondsTaken)} seconds`
			: `${Math.round(maxSecondsTaken / 60)} minute(s)`;

		if(!window.confirm(`Are you sure you wish to transcribe this call? \n\n This will take up to ${averageLoadingTime}`))
			return;

		setTranscribingCall(true);

		try
		{
			const response = await postAPI(`/admin/phone/${call.id}/transcribe`);

			call.transcription = response.transcription;
			setTranscribingCall(false);
		}
		catch (error)
		{
			alert(error.message);
			setTranscribingCall(false);
		}
	};

	// Check if the call hasn't been transcribed before, or if the duration is too long or to short.
	const callCanBeTranscribed = !call.transcription &&
		(call.duration > shortestCallDurationAllowed && call.duration < longestCallDurationAllowed);

	if(callCanBeTranscribed)
	{

		return (
			<Col md={2} className="text-right mb-3">
				<Button
					size="sm"
					variant={transcribingCall ? "secondary" : "primary"}
					onClick={handleClick}
					disabled={!callCanBeTranscribed || transcribingCall}
				>{transcribingCall ? <Spinner as="span" animation="border" variant="primary" /> : <FaTty />}</Button>
			</Col>

		);
	}

	if(call?.transcription?.length > 1)
		return <TranscriptionText transcription={call.transcription}/>;

	return null;

};