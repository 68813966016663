import React from "react";
import { Button, Col, Form, DropdownButton, DropdownItem } from "react-bootstrap";

const TeamInputDropdown = ({ teamIndex, teamMemberIndex, csUsers, teams, handleTeamUpdate }) =>
{
	const buttonText = teams[teamIndex][teamMemberIndex] ? csUsers.find((user) => user.id === teams[teamIndex][teamMemberIndex]).name : `Team member ${teamMemberIndex + 1}`;

	return (
		<div>
			<DropdownButton title={buttonText} variant="secondary" style={{ width: "240px" }}>
				<div style={{ maxHeight: "300px", overflow: "auto" }}>
					{csUsers.map((user, index) =>
					{
						const userAlreadyInTeam = teams.flat().includes(user.id);

						return !userAlreadyInTeam ? (
							<DropdownItem key={index} disabled={teams.flat().includes(user.id)} value={user.id || ""} onClick={() => handleTeamUpdate(teamIndex, teamMemberIndex, user.id)} style={{}}>
								{user.name}
							</DropdownItem>
						) : null;
					})}
					<DropdownItem value={""} onClick={() => handleTeamUpdate(teamIndex, teamMemberIndex, "")}>
						Remove team member
					</DropdownItem>
				</div>
			</DropdownButton>
		</div>
	);
};

const TeamInput = ({ teamIndex, teams, membersPerTeam, csUsers, handleTeamUpdate, teamName, handleTeamNameUpdate, deleteTeam }) =>
{
	const memberArray = new Array(membersPerTeam).fill("");

	return (
		<div className="pt-4">
			<h2>Team number {teamIndex + 1}</h2>
			<Form.Label>Team name</Form.Label>
			<Form.Control value={teamName || ""} onChange={(event) => handleTeamNameUpdate(teamIndex, event.target.value)} />
			<Form.Row className="my-3">
				<Col>
					<div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "16px" }}>
						{memberArray.map((member, index) => (
							<TeamInputDropdown key={index} teamIndex={teamIndex} teamMemberIndex={index} csUsers={csUsers} teams={teams} handleTeamUpdate={handleTeamUpdate} />
						))}
					</div>
				</Col>
				<Col>
					<Button variant="danger" onClick={() => deleteTeam(teamIndex)}>
            Delete team
					</Button>
				</Col>
			</Form.Row>
		</div>
	);
};

export { TeamInput };