import React from "react";
import { Col, Form, Button } from "react-bootstrap";
import { postAPI } from "utils/requestAPI";
import {GAConfigContext} from "../../contexts/gaConfigContext";

class CrmCallForm extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			crmCallOutcome: "",
			crmCallAWNCReason: "",
			crmCallNoContactReason: "",
			crmCallEmailResent: "",
			crmCallCCAdded: "",
			hoursUntilCallback: props.showScheduleCrmCallOption ? 12 : null,
			saveCRMForm: false
		};
	}

	static contextType = GAConfigContext;

	handleSaveCRMForm = async() =>
	{
		if(!this.state.saveCRMForm && this.props.crmCallId)
		{
			try
			{
				const updatedCallObj = {
					outcome: this.state.crmCallOutcome || null,
					awnc_reason: this.state.crmCallAWNCReason || null,
					why_no_contact: this.state.crmCallNoContactReason || null,
					email_resent: this.state.crmCallEmailResent || null,
					cc_added: this.state.crmCallCCAdded || null
				};

				await postAPI("/admin/vendor/CRMTeam/updateCRMCall", {
					crmCallId: this.props.crmCallId,
					hours_until_callback: this.state.hoursUntilCallback,
					updatedCallObj
				});
			}
			catch (err)
			{
				alert("Error while updating the call details: " + err.message);
			}
		}

		this.setState({ saveCRMForm: !this.state.saveCRMForm });
	}

	render()
	{
		return (
			<Form className="my-4" style={{borderTop: "1px solid #d2ddec"}}>
				<Form.Row style={{marginTop:"20px", fontWeight: 600}}>
					<span>To be completed by the CRM team</span>
				</Form.Row>
				<Form.Row style={{marginTop:"15px"}}>
					<Col xs={8}>
						<Form.Label>Outcome:</Form.Label>
						<Form.Control
							as="select"
							onChange={(e) => this.setState({crmCallOutcome: e.target.value})}
							value={this.state.crmCallOutcome}
						>
							<option value="">-</option>
							{this.context.GA_CONFIG.crmCalls.outcomes.map((r) => {
								return <option key={r}>{r}</option>;
							})}
						</Form.Control>

						{this.state.crmCallOutcome === "AWNC" ? (
							<>
								<Form.Label style={{marginTop:"8px"}}>AWNC reason:</Form.Label>
								<Form.Control
									as="select"
									onChange={(e) => this.setState({crmCallAWNCReason: e.target.value})}
									value={this.state.crmCallAWNCReason}
								>
									<option value="">-</option>
									{this.context.GA_CONFIG.crmCalls.awncReasons.map((r) => {
										return <option key={r}>{r}</option>;
									})}
								</Form.Control>
							</>
						) : null}

						<Form.Label style={{marginTop:"8px"}}>Reasons why agent didn't make contact:</Form.Label>
						<Form.Control
							as="select"
							onChange={(e) => this.setState({crmCallNoContactReason: e.target.value})}
							value={this.state.crmCallNoContactReason}
						>
							<option value="">-</option>
							{this.context.GA_CONFIG.crmCalls.noContactReasons.map((r) => {
								return <option key={r}>{r}</option>;
							})}
						</Form.Control>

						<Form.Label style={{marginTop:"8px"}}>Email resent:</Form.Label>
						<Form.Control
							as="select"
							onChange={(e) => this.setState({crmCallEmailResent: e.target.value})}
							value={this.state.crmCallEmailResent}
						>
							<option value="">-</option>
							{this.context.GA_CONFIG.crmCalls.emailResentOptions.map((r) => {
								return <option key={r}>{r}</option>;
							})}
						</Form.Control>

						<Form.Label style={{marginTop:"8px"}}>CC added:</Form.Label>
						<Form.Control
							as="select"
							onChange={(e) => this.setState({crmCallCCAdded: e.target.value})}
							value={this.state.crmCallCCAdded}
						>
							<option value="">-</option>
							{this.context.GA_CONFIG.crmCalls.ccAddedOptions.map((r) => {
								return <option key={r}>{r}</option>;
							})}
						</Form.Control>

						{this.props.showScheduleCrmCallOption ? (
							<>
								<Form.Label style={{marginTop:"8px"}}>Schedule callback in:</Form.Label>
								<Form.Control
									as="select"
									onChange={(e) => this.setState({hoursUntilCallback: e.target.value})}
									value={this.state.hoursUntilCallback}
								>
									<option value="">-</option>
									<option value="1">1 hour</option>
									<option value="3">3 hours</option>
									<option value="6">6 hours</option>
									<option value="12">12 hours</option>
									<option value="24">24 hours</option>
									<option value="48">48 hours</option>
								</Form.Control>
							</>
						): null}
					</Col>
					<Col xs={4} style={{textAlign:"center"}}>
						<Button
							disabled={this.state.saveCRMForm}
							onClick={this.handleSaveCRMForm}
						>
							{this.state.saveCRMForm ? "Saved" : "Save call details"}
						</Button>
					</Col>
				</Form.Row>
			</Form>
		);
	}
}

export default CrmCallForm;