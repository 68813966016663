import React from "react";
import _ from "underscore";
import { gaNiceFormatPrice } from "../../utils/common";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import { Form, Col, Row, Button, Dropdown, DropdownButton, ButtonGroup, FormControl, Card, InputGroup } from "react-bootstrap";
import EditableText from "../shared/editableText";
import BranchWithInvoice from "./branchWithInvoice";
import { FaCalendarAlt } from "react-icons/fa";
import DocumentTitle from "../shared/documentTitle";
import { getAPI } from "utils/requestAPI";

class InvoiceChasingByBranch extends React.Component
{

	static contextType = GAConfigContext;

	constructor(props)
	{
		super(props);

		this.state = {
			branchesWithInvoices: [],
			filterBy: this.props.filterBy ? this.props.filterBy : "chasing",
			sortBy: this.props.sortBy ? this.props.sortBy : "alpha",
			dueMoreThan: this.props.dueMoreThan || "",
			dueLessThan: this.props.dueLessThan || "",
			editing: false,
			toDate: this.props.toDate || "",
			fromDate: this.props.fromDate || "",
			displayDateRange: false,
			noResultsInQuery: false,
			filterByUser: this.props.userFilter ? this.props.userFilter : "",
			nextActionOn: this.props.action,
			nextActionType: this.props.nextActionType
		};

		this.loadAllInvoices = this.loadAllInvoices.bind(this);
		this.sortBranches = this.sortBranches.bind(this);
		this.onFilterByChange = this.onFilterByChange.bind(this);
		this.createQueryString = this.createQueryString.bind(this);
		this.updateUrlAndRequestData = this.updateUrlAndRequestData.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.makeRelevantUsersFilterOptions = this.makeRelevantUsersFilterOptions.bind(this);
		this.shouldClearAdvancedFilters = this.shouldClearAdvancedFilters.bind(this);
		this.createDistributionOfInvoiceTotalsForView = this.createDistributionOfInvoiceTotalsForView.bind(this);

		this._nextActionType = [ "LBA", "LBC" ];

	}

	shouldClearAdvancedFilters()
	{
		if(
			((!this.state.editing) && (this.state.dueMoreThan || this.state.dueLessThan)) ||
			this.state.filterByUser ||
			this.state.nextActionOn ||
			this.state.nextActionType)

			return true;

	}

	clearAdvancedFilters()
	{
		this.setState({
			dueMoreThan: "",
			dueLessThan: "",
			filterByUser: "",
			nextActionOn: "",
			nextActionType: ""
		}, () => this.updateUrlAndRequestData());
	}

	createQueryString()
	{
		let qs = "/admin/invoice/branchesWithInvoices.json";

		qs += "?filterBy=" + this.state.filterBy + "&sortBy=" + this.state.sortBy;

		if(this.state.dueMoreThan)
			qs += "&dueMoreThan=" + this.state.dueMoreThan;

		if(this.state.dueLessThan)
			qs += "&dueLessThan=" + this.state.dueLessThan;

		if(this.state.fromDate)
			qs += "&fromDate=" + this.state.fromDate;

		if(this.state.toDate)
			qs += "&toDate=" + this.state.toDate;

		if(this.state.filterByUser)
			qs += "&user=" + this.state.filterByUser;

		if(this.state.nextActionOn)
			qs += "&action=" + this.state.nextActionOn;

		if(this.state.nextActionType)
			qs += "&nextActionType=" + this.state.nextActionType;

		return qs;
	}

	onDaysDueChanged(name, e)
	{
		const dayParam = e.target.value;

		this.setState({[name]: dayParam});
	}

	saveDueDays()
	{
		this.setState({editing: false}, () => this.updateUrlAndRequestData());
	}

	loadAllInvoices = (queryString) =>
	{
		let url = "";

		if(!queryString)
			url = `/admin/invoice/branchesWithInvoices.json?filterBy=${this.state.filterBy}`;
		else
			url = queryString;

		getAPI(url)
			.then(dataJSON =>
			{

				const { json } = dataJSON;

				if(json.length === 0)
				{
					this.setState({noResultsInQuery: true});
					return;
				}

				// set this to state when done
				const distribution = this.createDistributionOfInvoiceTotalsForView(json);

				this.setState({
					totalDistribution: distribution,
					branchesWithInvoices: json,
					noResultsInQuery: false
				});
			})
			.catch(e => this.setState({ noResultsInQuery: true }));
	}

	onDateChange = (e) =>
	{
		this.setState({[e.target.name]: e.target.value}, () =>
		{
			this.updateUrlAndRequestData();
		});
	}

	onSortByChange(event)
	{
		const type = event.target.innerText;

		if(type === "Alphabetically")

			this.setState({sortBy: "alpha"}, () => this.updateUrlAndRequestData());


		if(type === "Amount Due")

			this.setState({sortBy: "total"}, () => this.updateUrlAndRequestData());

	}

	updateUrlAndRequestData()
	{
		let toDate = "";
		let fromDate = "";
		let filterUser = "";
		let filterNextAction = "";
		let dueMoreThan = "";
		let dueLessThan = "";
		let nextActionType = "";

		if(this.state.toDate)
			toDate += `&toDate=${this.state.toDate}`;

		if(this.state.fromDate)
			fromDate += `&fromDate=${this.state.fromDate}`;

		if(this.state.filterByUser)
			filterUser = `&user=${this.state.filterByUser}`;

		if(this.state.nextActionOn)
			filterNextAction = `&action=${this.state.nextActionOn}`;

		if(this.state.dueMoreThan)
			dueMoreThan = `&dueMoreThan=${this.state.dueMoreThan}`;

		if(this.state.dueLessThan)
			dueLessThan = `&dueLessThan=${this.state.dueLessThan}`;

		if(this.state.nextActionType)
			nextActionType = `&nextActionType=${this.state.nextActionType}`;

		window.history.pushState(null, null, `?filterBy=${this.state.filterBy}&sortBy=${this.state.sortBy}`
			+ toDate + fromDate + filterUser + filterNextAction + dueMoreThan + dueLessThan + nextActionType);

		this.loadAllInvoices(this.createQueryString());
	}

	onNextActionFilter(preset)
	{
		let nextActionDate = null;

		if(preset === "backlog")
			nextActionDate = "backlog";
		else if(preset === "today")
			nextActionDate = "today";
		else if(preset === "week")
			nextActionDate = "week";
		else if(preset === "month")
			nextActionDate = "month";

		this.setState({nextActionOn: nextActionDate}, () => this.updateUrlAndRequestData());

	}

	onNextActionTypeSelected(action)
	{
		if(action === "LBA" || action === "LBC")
			this.setState({nextActionType: action}, () => this.updateUrlAndRequestData());

	}

	onFilterByChange(event)
	{
		const type = event.target.innerText;

		if(type === "Overdue")
		{
			this.setState({
				filterBy: "chasing",
				displayDateRange: false,
				fromDate: "",
				toDate: ""
			}, () => this.updateUrlAndRequestData());
		}

		if(type === "Paid")
		{
			this.setState({
				filterBy: "paid",
				displayDateRange: false,
				fromDate: "",
				toDate: ""
			}, () => this.updateUrlAndRequestData());
		}

		if(type === "Incoming Due")
		{
			this.setState({
				filterBy: "incomingDue",
				displayDateRange: true
			}, () => this.updateUrlAndRequestData());
		}

		if(type === "LBC")
		{
			this.setState({
				filterBy: "lbc",
				displayDateRange: true
			}, () => this.updateUrlAndRequestData());
		}

		if(type === "LBA")
		{
			this.setState({
				filterBy: "lba",
				displayDateRange: true
			}, () => this.updateUrlAndRequestData());
		}

		if(type === "MCOL")
		{
			this.setState({
				filterBy: "mcol",
				displayDateRange: true
			}, () => this.updateUrlAndRequestData());
		}

		if(type === "Court")
		{
			this.setState({
				filterBy: "court",
				displayDateRange: true
			}, () => this.updateUrlAndRequestData());
		}

		if(type === "Write Off")
		{
			this.setState({
				filterBy: "writeOff",
				displayDateRange: true
			}, () => this.updateUrlAndRequestData());
		}

		if(type === "Voided")
		{
			this.setState({
				filterBy: "voided",
				displayDateRange: true
			}, () => this.updateUrlAndRequestData());
		}

	}

	componentDidMount()
	{
		this.updateUrlAndRequestData();
	}

	sortBranches()
	{
		const { sortBy, branchesWithInvoices } = this.state;
		let newVendors;

		if(sortBy === "alpha")
		{
			newVendors = branchesWithInvoices.sort((a, b) =>
			{
				const x = a.name.toLowerCase();
				const y = b.name.toLowerCase();

				return ((x < y) ? -1 : ((x > y) ? 1 : 0));
			});
			return newVendors;
		}

		if(sortBy === "total")
		{
			newVendors = branchesWithInvoices.sort((a, b) => b.totalDue - a.totalDue);
			return newVendors;
		}

	}

	setEditable()
	{
		this.setState({editing: !this.state.editing});
	}

	makeRelevantUsersFilterOptions()
	{
		const canBeRespUsers = _.values(_.mapObject(this.context.GA_CONFIG.admins, (user, key) =>
		{
			if(! this.state.totalDistribution.totalByHuman[user.id])
				return null;

			return {
				isOps: user.getUserAdminOptions.includes(this.context.GA_CONFIG.adminOpts.ARE_OPS_TEAM),
				id: user.id,
				name: user.name,
				numInvoices: this.state.totalDistribution.totalByHuman[user.id].count,
				valueInvoices: this.state.totalDistribution.totalByHuman[user.id].amount,
			};
		})).filter(user => !! user);

		return canBeRespUsers;
	}

	relevantUserSelected(userId)
	{
		this.setState({filterByUser: userId}, () => this.updateUrlAndRequestData());
	}

	createDistributionOfInvoiceTotalsForView(branches)
	{

		const totalForView =
		{
			zeroToThirtyDays: 0,
			thirtyToSixtyDays: 0,
			sixtyToNinetyDays: 0,
			overNinetyDays: 0,
			totalByHuman: {}
		};

		branches.forEach((br, i) => 
		{
			// We want to count each branch per user just once
			// If a branch has 2 invoices, both with the same responsible user, this should count once
			// If the branch's 2 invoices have different responsible users, this should count once for each use
			let responsibleUsersForInvoicesForThisBranch = [];

			br.vendors.forEach((vendor, j) =>
			{
				vendor.invoices.forEach((invoice, k) =>
				{
					if(invoice.dueDays < 30)
						totalForView.zeroToThirtyDays += (invoice.amount + invoice.tax_amount);
					else if(invoice.dueDays < 60)
						totalForView.thirtyToSixtyDays += (invoice.amount + invoice.tax_amount);
					else if(invoice.dueDays < 90)
						totalForView.sixtyToNinetyDays += (invoice.amount + invoice.tax_amount);
					else
						totalForView.overNinetyDays += (invoice.amount + invoice.tax_amount);

					if(! invoice.responsible_user_id)
						return;

					if(! totalForView.totalByHuman[invoice.responsible_user_id])
						totalForView.totalByHuman[invoice.responsible_user_id] = { count: 0, amount: 0 };

					if(responsibleUsersForInvoicesForThisBranch.indexOf(invoice.responsible_user_id) !== -1)
						return;

					responsibleUsersForInvoicesForThisBranch.push(invoice.responsible_user_id);

					totalForView.totalByHuman[invoice.responsible_user_id].count++;

				});
			})
		});

		return totalForView;
	}

	render()
	{
		const showClearFilterButton = this.shouldClearAdvancedFilters();

		if(this.state.branchesWithInvoices && this.state.branchesWithInvoices.length === 0)
			return <div>Loading...</div>;

		const createFilterTitle = () =>
		{
			if(this.state.filterBy === "chasing")
				return "Overdue";

			if(this.state.filterBy === "incomingDue")
				return "Incoming Due";

			if(this.state.filterBy === "lba")
				return "LBA";

			if(this.state.filterBy === "lbc")
				return "LBC";

			if(this.state.filterBy === "mcol")
				return "MCOL";

			if(this.state.filterBy === "court")
				return "Court";

			if(this.state.filterBy === "writeOff")
				return "Write Off";

			if(this.state.filterBy === "voided")
				return "Voided";
		};

		const createSortByTitle = () =>
		{
			if(this.state.sortBy === "alpha")
				return "A - Z";

			if(this.state.sortBy === "total")
				return "Amount Due";
			else
				return "Sort By";
		};

		const createNextActionTitle = () =>
		{
			if(this.state.nextActionOn === "backlog")
				return "Backlog";

			if(this.state.nextActionOn === "today")
				return "Today";

			if(this.state.nextActionOn === "week")
				return "This Week";

			if(this.state.nextActionOn === "month")
				return "This Month";
			else
				return "Action On";
		};

		const createUserFilterTitle = () =>
		{
			if(this.state.filterByUser === "notAssigned")
			{
				return "Not Assigned";
			}
			else if(this.state.filterByUser)
			{
				const findUser = this.context.GA_CONFIG.admins.find(user => user.id === parseInt(this.state.filterByUser));

				return findUser && findUser.name;
			}
			else
			{
				return "User";
			}
		};

		const createNextActionTypeTitle = () =>
		{
			return this.state.nextActionType || "Next Action Type";
		};

		return (
			<>
				<DocumentTitle title={"Invoice Chasing View"} />

				{this.state.noResultsInQuery && <h2>No results match query. Please try with different settings.</h2>}

				<Card>
					<Card.Body>

						<DropdownButton className="mr-2" id="sort-presets" title={createSortByTitle()} size="sm" as={ButtonGroup}>
							<Dropdown.Item onClick={this.onSortByChange.bind(this)}>Alphabetically</Dropdown.Item>
							<Dropdown.Item onClick={this.onSortByChange.bind(this)}>Amount Due</Dropdown.Item>
						</DropdownButton>

						<DropdownButton className="mr-2" id="sort-presets" title={createFilterTitle()} size="sm" as={ButtonGroup}>
							<Dropdown.Item onClick={this.onFilterByChange.bind(this)}>Overdue</Dropdown.Item>
							<Dropdown.Item onClick={this.onFilterByChange.bind(this)}>Incoming Due</Dropdown.Item>
							<Dropdown.Item onClick={this.onFilterByChange.bind(this)}>LBA</Dropdown.Item>
							<Dropdown.Item onClick={this.onFilterByChange.bind(this)}>LBC</Dropdown.Item>
							<Dropdown.Item onClick={this.onFilterByChange.bind(this)}>MCOL</Dropdown.Item>
							<Dropdown.Item onClick={this.onFilterByChange.bind(this)}>Court</Dropdown.Item>
							<Dropdown.Item onClick={this.onFilterByChange.bind(this)}>Write Off</Dropdown.Item>
							<Dropdown.Item onClick={this.onFilterByChange.bind(this)}>Voided</Dropdown.Item>
						</DropdownButton>

						<DropdownButton className="mr-2" id="sort-presets" title={createUserFilterTitle()} size="sm" as={ButtonGroup}>
							<Dropdown.Item onClick={this.relevantUserSelected.bind(this, "notAssigned")}>Not assigned</Dropdown.Item>
							{
								this.makeRelevantUsersFilterOptions()
									.map(user => <Dropdown.Item key={user.id} onClick={this.relevantUserSelected.bind(this, user.id)}>{user.name} ({user.numInvoices})</Dropdown.Item>)
							}
						</DropdownButton>

						<DropdownButton className="mr-2" id="sort-presets" title={createNextActionTitle()} size="sm" as={ButtonGroup}>
							<Dropdown.Item onClick={this.onNextActionFilter.bind(this, "backlog")}>Backlog</Dropdown.Item>
							<Dropdown.Item onClick={this.onNextActionFilter.bind(this, "today")}>Today</Dropdown.Item>
							<Dropdown.Item onClick={this.onNextActionFilter.bind(this, "week")}>This Week</Dropdown.Item>
							<Dropdown.Item onClick={this.onNextActionFilter.bind(this, "month")}>This Month</Dropdown.Item>
						</DropdownButton>


						<DropdownButton className="mr-2" id="sort-presets" title={createNextActionTypeTitle()} size="sm" as={ButtonGroup}>
							{
								this._nextActionType.map((action, index) => <Dropdown.Item key={index} onClick={this.onNextActionTypeSelected.bind(this, action)}>{action}</Dropdown.Item>)
							}
						</DropdownButton>

						{!this.state.editing && <Button className="mr-2" onClick={this.setEditable.bind(this)} size="sm">Days Due 🗓</Button>}

						{showClearFilterButton ? <Button size="sm" variant="danger" onClick={this.clearAdvancedFilters.bind(this)}>Clear filters</Button> : null}

						{this.state.editing &&
							<>
								<Form className="mt-3">
									<Form.Group as={Row}>
										<Form.Label column sm={2}>
											Greater than:
										</Form.Label>
										<Col sm={1}>
											<EditableText
												value={this.state.dueMoreThan}
												editing={this.state.editing}
												onValueChanged={this.onDaysDueChanged.bind(this, "dueMoreThan")}
											/>
										</Col>

										<Form.Label column sm={2}>
											Less than:
										</Form.Label>
										<Col sm={1}>
											<EditableText
												value={this.state.dueLessThan}
												editing={this.state.editing}
												onValueChanged={this.onDaysDueChanged.bind(this, "dueLessThan")}
											/>
										</Col>
										<Col sm={2}>
											<Button className="mr-2" size="sm" variant="success" onClick={this.saveDueDays.bind(this)}>Save</Button>
										</Col>
									</Form.Group>
								</Form>
							</>
						}

						{this.state.displayDateRange &&
							<Row className="mt-3">
								<Col sm={3}>
									<InputGroup>
										<FormControl type="date" name="fromDate" onChange={this.onDateChange} value={this.state.fromDate} />

										<InputGroup.Append>
											<InputGroup.Text>
												<FaCalendarAlt />
											</InputGroup.Text>
										</InputGroup.Append>

									</InputGroup>
								</Col>
								<Col sm={3}>
									<InputGroup>
										<FormControl type="date" name="toDate" onChange={this.onDateChange} value={this.state.toDate} />

										<InputGroup.Append>
											<InputGroup.Text>
												<FaCalendarAlt />
											</InputGroup.Text>
										</InputGroup.Append>

									</InputGroup>
								</Col>
							</Row>
						}

					</Card.Body>
				</Card>

				{!this.state.noResultsInQuery &&

					<Row className="my-3">

						<Col md={3}>
							<Card className="text-center">
								<Card.Header>
									0 - 30 days
								</Card.Header>
								<Card.Body>
									<h2>£{gaNiceFormatPrice(Math.round(this.state.totalDistribution.zeroToThirtyDays))}</h2>
								</Card.Body>
							</Card>
						</Col>
						<Col md={3}>
							<Card className="text-center">
								<Card.Header>
									30 - 60 days
								</Card.Header>
								<Card.Body>
									<h2>£{gaNiceFormatPrice(Math.round(this.state.totalDistribution.thirtyToSixtyDays))}</h2>
								</Card.Body>
							</Card>
						</Col>
						<Col md={3}>
							<Card className="text-center">
								<Card.Header>
									60 - 90 days
								</Card.Header>
								<Card.Body>
									<h2>£{gaNiceFormatPrice(Math.round(this.state.totalDistribution.sixtyToNinetyDays))}</h2>
								</Card.Body>
							</Card>
						</Col>
						<Col md={3}>
							<Card className="text-center">
								<Card.Header>
									> 90 days
								</Card.Header>
								<Card.Body>
									<h2>£{gaNiceFormatPrice(Math.round(this.state.totalDistribution.overNinetyDays))}</h2>
								</Card.Body>
							</Card>
						</Col>

					</Row>
				}

				{!this.state.noResultsInQuery &&
					this.sortBranches().map(branch =>
					{
						return (
							<BranchWithInvoice
								key={branch.branchId}
								id={branch.branchId}
								branchName={branch.name}
								vendors={branch.vendors}
								countInvoices={branch.countInvoices}
								totalDue={branch.totalDue}
								sortBy={this.state.sortBy}
								filterBy={this.state.filterBy}
							/>);
					})}
			</>
		);
	}

}

export default InvoiceChasingByBranch;