import React from "react";
import {Card, Col, Row, Form, ListGroup, ListGroupItem, Badge} from "react-bootstrap";
import {FaPencilAlt} from "react-icons/fa";
import _ from "underscore";
import moment from "moment";
import {gaNiceFormatPrice} from "utils/common";
import Note from "../shared/note";
import NoteInput from "../shared/noteInput";
import {GAConfigContext} from "contexts/gaConfigContext";
import { postAPI } from "utils/requestAPI";


class VendorTimeline extends React.PureComponent
{
	constructor(props)
	{
		super(props);

		this.state = {
			showQuotes: false,
			showStatusChanges: false,
			showPropNotes: true,
			showBranchNotes: true,
			showSystemNotes: false,
			saving: false
		};
	}

	static contextType = GAConfigContext;

	checkboxChange(checkName, e)
	{
		const stateUpdate = {};

		stateUpdate["show" + checkName] = !this.state["show" + checkName];

		this.setState(stateUpdate);
	}

	addNote(note)
	{
		// save note
		this.setState({saving: true});

		const noteObj = {
			note: note.note,
			vendor_prop_id: this.props.vendorProp.id
		};

		if(note.reminderDate && note.reminderTime)
		{
			noteObj.reminder_date = note.reminderDate;
			noteObj.reminder_time = note.reminderTime;

			if(note.isAdminGroup)

				noteObj.reminder_team_id = note.reminderAdminGroup;

			else

				noteObj.reminder_user_id = note.reminderUser || this.context.GA_CONFIG.user.id;

		}

		// save to server
		return postAPI("/admin/vendor/property/" + this.props.vendorProp.id + "/note", noteObj)
			.then((resp) =>
			{
				if(this.props.reloadVendor)
					this.props.reloadVendor();

				this.setState({saving: false});
			})
			.catch(e =>
			{
				alert(e || "An error occurred when saving");
			});
	}

	noteChange()
	{
		if(this.props.reloadVendor)
			this.props.reloadVendor();
	}

	render()
	{


		const isSystemNote = (note) =>
		{
			return note.note && note.note.indexOf &&
				((note.note.indexOf("[BRANCH_ADDED]") === 0)
				|| (note.note.indexOf("[BRANCH_REMOVED]") === 0)
				|| (note.note.indexOf("[PPV_REGISTER]") === 0)
				|| (note.note.indexOf("[PPV_DEREGISTER]") === 0));
		};

		let items = [];

		if(this.state.showPropNotes || this.state.showBranchNotes || this.state.showSystemNotes)
		{
			items = items.concat(this.props.vendorProp.notes, this.props.vendorProp.calls, this.props.vendorProp.csCalls);

			// filter
			items = items.filter(note =>
			{
				if(note.branch_id && this.state.showBranchNotes)
					return true;

				if((! note.branch_id) && this.state.showPropNotes && (! isSystemNote(note)))
					return true;

				if(this.state.showSystemNotes && isSystemNote(note))
					return true;

				return false;
			});
		}

		if(this.state.showQuotes || this.state.showStatusChanges)
		{

			// filter
			const events = _.filter(this.props.vendorProp.events, ev =>
			{

				if(ev.quote && this.state.showQuotes)
					return true;

				if(ev.status_to && this.state.showStatusChanges)
					return true;

				return false;
			});

			items = items.concat(events);
		}

		// sort items
		items = _.sortBy(items, i =>
		{
			if(i.type === "CS_CALL" && i.called_at)
				return moment(i.called_at).unix() * -1;

			if(!i.type && i.note && i.note && (i.note.indexOf("[SUPPORT]") === 0))
				return -9E20;


			return moment(i.created_at).unix() * -1;
		});

		const addOverflow = this.props.enableOverflow ? {maxHeight: "500px", overflowY: "auto"} : {};

		const listGroupClasses = "border-right-0 border-left-0";

		return (
			<Card className={this.props.className}>
				<Card.Header style={{fontWeight: 600}}>Timeline</Card.Header>

				<ListGroup style={addOverflow}>
					<ListGroupItem className={listGroupClasses}>
						<Row className="mb-3">
							<Col sm={2}>
								<Form.Group controlId="quoteCheckbox">
									<Form.Check type="checkbox"
										checked={this.state.showQuotes}
										onChange={this.checkboxChange.bind(this, "Quotes")}
										label={"Quotes ("+ _.filter(this.props.vendorProp.events, ev => !!ev.quote).length + ")"} />
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="statusCheckbox">
									<Form.Check type="checkbox"
										checked={this.state.showStatusChanges}
										onChange={this.checkboxChange.bind(this, "StatusChanges")}
										label={"Status changes (" + _.filter(this.props.vendorProp.events, ev => !!ev.status_to).length + ")"} />
								</Form.Group>

							</Col>
							<Col sm={3}>
								<Form.Group controlId="propNotesCheckbox">
									<Form.Check type="checkbox"
										checked={this.state.showPropNotes}
										onChange={this.checkboxChange.bind(this, "PropNotes")}
										label={"Property Notes (" +_.filter([].concat(this.props.vendorProp.notes, this.props.vendorProp.calls, this.props.vendorProp.csCalls), n => !n.branch_id && (! isSystemNote(n))).length + ")"} />
								</Form.Group>

							</Col>
							<Col sm={2}>
								<Form.Group controlId="branchNotesCheckbox">
									<Form.Check type="checkbox"
										checked={this.state.showBranchNotes}
										onChange={this.checkboxChange.bind(this, "BranchNotes")}
										label={"Branch Notes (" + _.filter(this.props.vendorProp.notes, n => !!n.branch_id).length + ")"} />
								</Form.Group>

							</Col>
							<Col sm={2}>
								<Form.Group controlId="systemNotesCheckbox">
									<Form.Check type="checkbox"
										checked={this.state.showSystemNotes}
										onChange={this.checkboxChange.bind(this, "SystemNotes")}
										label={"System Notes (" +_.filter(this.props.vendorProp.notes, n => isSystemNote(n)).length + ")"} />
								</Form.Group>
							</Col>

						</Row>
						<NoteInput
							defaultRemindTo={this.props.defaultRemindTo}
							vendorPropId={this.props.vendorProp.id}
							allowReminder
							onAddNote={this.addNote.bind(this)}
						/>
					</ListGroupItem>

					{items.length > 0 ? items.map(i =>
					{

						const creatorLabel = () =>
						{
							return (
								<Badge variant="primary">
									<FaPencilAlt />{(i.user && i.user.name) ? i.user.name : "Auto"}, {moment(i.created_at).format("ddd Do MMM YYYY, HH:mm")}
								</Badge>
							);
						};

						if(i.quote > 0)
						{
							return (
								<ListGroupItem key={"quote" + i.id} className={listGroupClasses}>
									<Row>
										<Col sm={9}>
											<strong>{i.branch.name}</strong> quoted {i.quote > 100 ? "£" + gaNiceFormatPrice(i.quote) : i.quote + "%"}
										</Col>
										<Col sm={3}>
											{creatorLabel()}
										</Col>
									</Row>
								</ListGroupItem>
							);
						}
						else if((i.status_to > 0) && i.branch && i.branch.id)
						{
							return (
								<ListGroupItem key={"statechange" + i.id} className={listGroupClasses}>
									<Row>
										<Col sm={9}>
											<strong>{i.branch.name}</strong> status changed to {i.statusToString} {i.status_from ? "(from " + i.statusFromString + ")" : null}
										</Col>
										<Col sm={3}>
											{creatorLabel()}
										</Col>
									</Row>
								</ListGroupItem>
							);
						}
						else if(i.status_to > 0)
						{
							return (
								<ListGroupItem key={"statechange" + i.id} className={listGroupClasses}>
									<Row>
										<Col sm={9}>
												Status changed to <strong>{i.statusToString}</strong> {i.status_from ? "(from " + i.statusFromString + ")" : null}
										</Col>
										<Col sm={3}>
											{creatorLabel()}
										</Col>
									</Row>
								</ListGroupItem>
							);
						}
						else if(i.note !== undefined || i.type)
						{
							let bsStyle = "";

							if(!i.type && i.note && i.note.indexOf("[SUPPORT]") === 0)
								bsStyle = "info";

							return (
								<ListGroupItem key={"note-" + i.id} variant={bsStyle} className={listGroupClasses}>
									<Note
										note={i}
										showVendor={i.vendor_prop_id !== this.props.vendorProp.id}
										onNoteChange={this.noteChange.bind(this)}
									/>
								</ListGroupItem>
							);
						}
						else
						{
							return null;
						}

					}) : null}

				</ListGroup>

			</Card>
		);
	}

}

export default VendorTimeline;